import BibInfo from '@/components/BibInfo.vue'
import { API, type ProjectInfoDetail } from '@murfy-package/api-client'
import { defineStore } from 'pinia'
import { h, render } from 'vue'

export interface BibEntry {
  entryType: string
  citationKey: string
  fields: { [key: string]: string }
}

const apiClient = new API(import.meta.env.VITE_APP_API_BASE_URL)

/**
 * `.bib` 파일 관련 기능을 관리하는 Pinia 스토어
 */
export const useBibFileStore = defineStore('bibFile', () => {
  /**
   * 특정 파일의 내용을 가져오는 함수
   * @param projectId - 프로젝트 ID
   * @param filePath - 파일 경로
   * @returns 파일 내용
   */
  const fetchFileContent = async (
    projectId: string,
    filePath: string | null,
  ): Promise<string | undefined> => {
    if (!projectId || !filePath) return

    const projectTextFile = await apiClient.project.openTextFile(projectId, filePath)
    return projectTextFile.content
  }

  /**
   * `assets` 배열에서 `.bib` 파일만 필터링하여 반환하는 함수
   * @param assets - 프로젝트 자산 리스트
   * @returns `.bib` 파일 목록
   */
  const extractBibFiles = (assets: { filename: string; fullPath: string }[]) =>
    assets.filter((asset) => asset.filename.endsWith('.bib'))

  const parseBibFiles = (assets: { filename: string; fullPath: string }[]) =>
    extractBibFiles(assets).map((bibFile) => ({
      label: bibFile.filename.replace('.bib', ''),
      type: 'keyword',
    }))

  /**
   * 프로젝트 정보를 가져오고 .bib 파일을 처리하는 공통 함수
   * @param projectId - 프로젝트 ID
   * @param assets - 프로젝트 자산 리스트
   * @returns .bib 파일 목록과 파싱된 내용
   */
  const processBibFiles = async (
    projectId: string,
    assets: { filename: string; fullPath: string }[],
  ) => {
    // `.bib` 파일 필터링
    const bibFiles = extractBibFiles(assets)

    const contents = await Promise.allSettled(
      bibFiles.map((file) => fetchFileContent(projectId, file.fullPath)),
    )

    // 성공적으로 가져온 파일 내용만 파싱
    return contents
      .filter((result) => result.status === 'fulfilled')
      .map((result) => (result as PromiseFulfilledResult<string>).value)
      .map((content) => parseBibFileContent(content))
      .flat()
      .map((bib) => ({
        label: bib.citationKey,
        type: 'keyword',
        info: () => {
          const container = document.createElement('div')
          const vnode = h(BibInfo, { fields: bib.fields })

          render(vnode, container)

          return container
        },
      }))
  }

  /**
   * ProjectInfoDetail로부터 .bib 파일을 처리하는 함수
   * @param projectInfoDetail - 프로젝트 상세 정보
   * @returns .bib 파일 목록과 파싱된 내용
   */
  const parseBibFilesFromDetail = async (projectInfoDetail: ProjectInfoDetail) => {
    const projectId = projectInfoDetail.id
    const assets = projectInfoDetail.assets || []
    return processBibFiles(projectId, assets)
  }

  /**
   * projectId로부터 .bib 파일을 처리하는 함수
   * @param projectId - 프로젝트 ID
   * @returns .bib 파일 목록과 파싱된 내용
   */
  const parseBibFilesById = async (projectId: string) => {
    const project = await apiClient.project.get(projectId)
    const assets = project.assets || []
    const bibFiles = parseBibFiles(assets)
    const bibKeywords = await processBibFiles(projectId, assets)
    return {
      bibFiles,
      bibKeywords,
    }
  }

  /**
   * .bib 파일 내용을 파싱하여 BibEntry 배열로 반환하는 함수
   * @param content - .bib 파일 내용
   * @returns 파싱된 BibEntry 배열
   */
  const parseBibFileContent = (content: string): BibEntry[] => {
    // 허용된 엔트리 타입 목록
    const allowedEntryTypes = new Set([
      'article',
      'book',
      'mvbook',
      'inbook',
      'bookinbook',
      'suppbook',
      'booklet',
      'collection',
      'mvcollection',
      'incollection',
      'suppcollection',
      'manual',
      'misc',
      'online',
      'patent',
      'periodical',
      'suppperiodical',
      'proceedings',
      'mvproceedings',
      'inproceedings',
      'reference',
      'mvreference',
      'inreference',
      'report',
      'set',
      'thesis',
      'unpublished',
      'custom',
      'conference',
      'electronic',
      'masterthesis',
      'phdthesis',
      'techreport',
      'mastersthesis',
    ])

    // @string 정의를 찾기 위한 정규 표현식
    const stringRegex = /@string\s*{\s*(\w+)\s*=\s*["{]([^"}]*)["}]\s*}/gi
    // 각 항목을 찾기 위한 정규 표현식 (@article, @book 등)
    const entryRegex = /@(\w+)\s*{\s*([^,]+),([\s\S]*?)}\s*(?=@|\s*$)/g // 수정된 부분
    // 항목의 필드(key-value 쌍)를 찾기 위한 정규 표현식
    const fieldRegex = /(\w+)\s*=\s*[{"]((?:[^{}"\\]+|\\.|{(?:[^{}]|{[^}]*})*})*)[}"]/g

    const stringMap: { [key: string]: string } = {}
    let stringMatch: RegExpExecArray | null

    // @string 정의를 파싱하여 stringMap에 저장
    while ((stringMatch = stringRegex.exec(content)) !== null) {
      const key = stringMatch[1].trim()
      const value = stringMatch[2].trim()
      stringMap[key] = value
    }

    // content에서 모든 @string 정의를 제거
    content = content.replace(stringRegex, '')

    // stringMap 키의 모든 발생을 content에서 해당 값으로 대체
    for (const [key, value] of Object.entries(stringMap)) {
      const regex = new RegExp(`${key}\\s*#\\s*"`, 'g')
      content = content.replace(regex, '"' + value)
    }

    const entries: BibEntry[] = []
    let entryMatch: RegExpExecArray | null

    // 모든 항목을 파싱하여 entries 배열에 추가
    while ((entryMatch = entryRegex.exec(content)) !== null) {
      const [entryType, citationKey, entryFields] = [
        entryMatch[1].trim(),
        entryMatch[2].trim(),
        entryMatch[3],
      ]

      // 허용된 엔트리 타입이 아닌 경우 건너뜀
      if (!allowedEntryTypes.has(entryType.toLowerCase())) continue

      const fields: { [key: string]: string } = {}
      let fieldMatch: RegExpExecArray | null

      // 항목의 모든 필드를 파싱하여 fields 객체에 저장
      while ((fieldMatch = fieldRegex.exec(entryFields)) !== null) {
        fields[fieldMatch[1].trim()] = fieldMatch[2].trim()
      }

      entries.push({ entryType, citationKey, fields })
    }

    return entries
  }

  return {
    parseBibFiles,
    parseBibFilesFromDetail,
    parseBibFilesById,
    parseBibFileContent,
  }
})
