<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M9.99323 3.32654C10.4371 2.88271 11.039 2.63336 11.6667 2.63336C12.2944 2.63336 12.8964 2.88271 13.3402 3.32654C13.784 3.77038 14.0334 4.37235 14.0334 5.00003C14.0334 5.62771 13.784 6.22968 13.3402 6.67351C12.8964 7.11735 12.2944 7.36669 11.6667 7.36669C11.039 7.36669 10.4371 7.11735 9.99323 6.67351C9.54939 6.22968 9.30005 5.62771 9.30005 5.00003C9.30005 4.37235 9.54939 3.77038 9.99323 3.32654ZM11.6667 4.03336C11.4103 4.03336 11.1645 4.13521 10.9832 4.31649C10.8019 4.49778 10.7 4.74365 10.7 5.00003C10.7 5.2564 10.8019 5.50228 10.9832 5.68357C11.1645 5.86485 11.4103 5.9667 11.6667 5.9667C11.9231 5.9667 12.169 5.86485 12.3503 5.68357C12.5315 5.50228 12.6334 5.2564 12.6334 5.00003C12.6334 4.74365 12.5315 4.49778 12.3503 4.31649C12.169 4.13521 11.9231 4.03336 11.6667 4.03336Z"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M2.6333 4.99999C2.6333 4.61339 2.9467 4.29999 3.3333 4.29999H9.99997C10.3866 4.29999 10.7 4.61339 10.7 4.99999C10.7 5.38659 10.3866 5.69999 9.99997 5.69999H3.3333C2.9467 5.69999 2.6333 5.38659 2.6333 4.99999Z"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M12.6333 4.99999C12.6333 4.61339 12.9467 4.29999 13.3333 4.29999H16.6666C17.0532 4.29999 17.3666 4.61339 17.3666 4.99999C17.3666 5.38659 17.0532 5.69999 16.6666 5.69999H13.3333C12.9467 5.69999 12.6333 5.38659 12.6333 4.99999Z"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M4.99323 8.32654C5.43707 7.88271 6.03904 7.63336 6.66672 7.63336C7.29439 7.63336 7.89637 7.88271 8.3402 8.32654C8.78404 8.77038 9.03338 9.37235 9.03338 10C9.03338 10.6277 8.78404 11.2297 8.3402 11.6735C7.89637 12.1173 7.29439 12.3667 6.66672 12.3667C6.03904 12.3667 5.43707 12.1173 4.99323 11.6735C4.54939 11.2297 4.30005 10.6277 4.30005 10C4.30005 9.37235 4.54939 8.77038 4.99323 8.32654ZM6.66672 9.03336C6.41034 9.03336 6.16446 9.13521 5.98318 9.31649C5.80189 9.49778 5.70005 9.74365 5.70005 10C5.70005 10.2564 5.80189 10.5023 5.98318 10.6836C6.16446 10.8649 6.41034 10.9667 6.66672 10.9667C6.92309 10.9667 7.16897 10.8649 7.35025 10.6836C7.53154 10.5023 7.63338 10.2564 7.63338 10C7.63338 9.74365 7.53154 9.49778 7.35025 9.31649C7.16897 9.13521 6.92309 9.03336 6.66672 9.03336Z"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M2.6333 9.99999C2.6333 9.61339 2.9467 9.29999 3.3333 9.29999H4.99997C5.38657 9.29999 5.69997 9.61339 5.69997 9.99999C5.69997 10.3866 5.38657 10.7 4.99997 10.7H3.3333C2.9467 10.7 2.6333 10.3866 2.6333 9.99999Z"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M7.6333 9.99999C7.6333 9.61339 7.9467 9.29999 8.3333 9.29999H16.6666C17.0532 9.29999 17.3666 9.61339 17.3666 9.99999C17.3666 10.3866 17.0532 10.7 16.6666 10.7H8.3333C7.9467 10.7 7.6333 10.3866 7.6333 9.99999Z"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M12.4932 13.3265C12.9371 12.8827 13.539 12.6334 14.1667 12.6334C14.7944 12.6334 15.3964 12.8827 15.8402 13.3265C16.284 13.7704 16.5334 14.3723 16.5334 15C16.5334 15.6277 16.284 16.2297 15.8402 16.6735C15.3964 17.1173 14.7944 17.3667 14.1667 17.3667C13.539 17.3667 12.9371 17.1173 12.4932 16.6735C12.0494 16.2297 11.8 15.6277 11.8 15C11.8 14.3723 12.0494 13.7704 12.4932 13.3265ZM14.1667 14.0334C13.9103 14.0334 13.6645 14.1352 13.4832 14.3165C13.3019 14.4978 13.2 14.7437 13.2 15C13.2 15.2564 13.3019 15.5023 13.4832 15.6836C13.6645 15.8649 13.9103 15.9667 14.1667 15.9667C14.4231 15.9667 14.669 15.8649 14.8503 15.6836C15.0315 15.5023 15.1334 15.2564 15.1334 15C15.1334 14.7437 15.0315 14.4978 14.8503 14.3165C14.669 14.1352 14.4231 14.0334 14.1667 14.0334Z"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M2.6333 15C2.6333 14.6134 2.9467 14.3 3.3333 14.3H12.5C12.8866 14.3 13.2 14.6134 13.2 15C13.2 15.3866 12.8866 15.7 12.5 15.7H3.3333C2.9467 15.7 2.6333 15.3866 2.6333 15Z"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M15.1333 15C15.1333 14.6134 15.4467 14.3 15.8333 14.3H16.6666C17.0532 14.3 17.3666 14.6134 17.3666 15C17.3666 15.3866 17.0532 15.7 16.6666 15.7H15.8333C15.4467 15.7 15.1333 15.3866 15.1333 15Z"
  />
</template>
