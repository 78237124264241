<script setup>
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
</script>
<script>
import { BaseInputText } from '@/components'
import { BaseButton } from '@murfy-package/ui'
import PrimeVueDialog from 'primevue/dialog'

/* eslint-disable vue/component-api-style */
export default {
  components: {
    PrimeVueDialog,
    BaseButton,
    BaseInputText,
  },
  /* FIXME: props 타입 정의 필요 */
  /* eslint-disable-next-line vue/require-prop-types */
  props: ['controller'],
  emits: ['close', 'submit'],
  data() {
    return {
      target: null,
      originalName: '',
      newName: '',
      invalidName: false,
      isDisable: true,
    }
  },
  beforeMount() {
    this.controller.on('open', this.onOpen)
    this.controller.on('disable', this.onDisable)
  },
  beforeUnmount() {
    this.controller.off('open', this.onOpen)
    this.controller.off('disable', this.onDisable)
  },
  methods: {
    onOpen(args) {
      if (args) {
        this.target = args
        this.originalName = args.name
        this.newName = args.name
        this.isDisable = false
      }
    },
    onDisable() {
      this.isDisable = true
    },
    onSubmit() {
      if (this.newName === this.originalName || this.isDisable) {
        return
      }
      this.$emit('submit', this.target, this.newName)
    },
  },
}
</script>

<template>
  <div>
    <PrimeVueDialog
      :visible="controller.visible"
      modal
      :pt="{
        footer: $style.footer,
      }"
      @update:visible="$emit($event ? 'open' : 'close')"
    >
      <template #header>
        <div class="inline-flex items-center justify-center">
          <span class="h2 gray-9">{{ t('header') }}</span>
        </div>
      </template>
      <template #default>
        <div style="padding: 16px 0 0 0">
          <BaseInputText
            v-model="newName"
            class="w-full"
            :class="{ 'p-invalid': invalidName }"
            @submit="onSubmit"
          />
        </div>
      </template>
      <template #footer>
        <div class="flex grow items-center justify-center">
          <BaseButton
            class="w-full"
            :disabled="newName == originalName || isDisable"
            @click="onSubmit"
          >
            <div class="flex w-full flex-wrap justify-center">
              <span class="h4">{{ t('save') }}</span>
            </div>
          </BaseButton>
        </div>
      </template>
    </PrimeVueDialog>
  </div>
</template>

<style module>
.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
</style>

<i18n>
{
  "en": {
      "header": "Rename",
      "save": "Save",
    }
}
</i18n>
