<script setup lang="ts">
import { TextOverflowWithTooltip } from '.'

const props = defineProps<{
  icon: string
  label: string
}>()
</script>

<template>
  <div :class="$style.container">
    <div v-if="props.icon" :class="$style.icon">
      <img :src="icon" alt="icon" />
    </div>
    <TextOverflowWithTooltip :class="['p2', 'gray-7']" :text="label" />
  </div>
</template>

<style lang="scss" module>
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--gray-2);
  border-radius: 8px;
  padding: 8px 16px 8px 12px;
  gap: 8px;
  background-color: var(--gray-0);
  min-height: 40px;
  width: fit-content;
  max-width: 100%;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon img {
  width: 24px;
  height: 24px;
}
</style>
