import { Diagnostic } from '@codemirror/lint'
import { EditorView } from '@codemirror/view'

import { addDisableHint, filterDiagnostics, isLinterDisabled } from './disable-lint'
import { LintError, errorsToDiagnostics } from './errors-to-diagnostics'
import { mergeCompatibleOverlappingDiagnostics } from './merge-overlapping-diagnostics'

const lintWorker: Worker = new Worker(new URL('./latex-linter.worker', import.meta.url), {
  type: 'module',
})

class Deferred {
  public promise: Promise<readonly Diagnostic[]>
  public resolve?: (value: PromiseLike<Diagnostic[]> | Diagnostic[]) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public reject?: (reason?: any) => void

  constructor() {
    this.promise = new Promise((resolve, reject) => {
      this.resolve = resolve
      this.reject = reject
    })
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let linterPromise: Promise<any> | null = null // promise which will resolve to results of the current linting
let queuedRequest: Deferred | null = null // deferred promise for incoming linting requests while the current one is running
let currentView: EditorView | null = null

let currentResolver: ((value: Diagnostic[]) => void) | null = null

const runLinter = () => {
  const doc = currentView!.state.doc
  // 린터를 비활성화하여 문서 전체에 대한 린터 검사를 중지합니다.
  if (isLinterDisabled(doc)) {
    return Promise.resolve([]) // linting 을 비활성화한 경우 빈 결과 반환
  }
  lintWorker.postMessage({ text: doc.toString() })
  return new Promise<Diagnostic[]>((resolve) => {
    currentResolver = resolve
  })
}

lintWorker!.addEventListener('message', (event) => {
  if (event.data) {
    const errors = event.data.errors as LintError[]
    const editorState = currentView!.state
    const doc = editorState.doc
    const cursorPosition = editorState.selection.main.head + 1
    let diagnostics = errorsToDiagnostics(errors, cursorPosition, doc.length)

    diagnostics = filterDiagnostics(diagnostics, doc)
    addDisableHint(diagnostics)

    const mergedDiagnostics = mergeCompatibleOverlappingDiagnostics(diagnostics)
    currentResolver!(mergedDiagnostics)

    // make compile controller aware of lint errors via editor:lint event
    const hasLintingError = errors.some((e) => e.type !== 'info')
    window.dispatchEvent(
      new CustomEvent('editor:lint', {
        detail: { hasLintingError },
      }),
    )
  }
})

const executeQueuedAction = (deferred: Deferred) => {
  runLinter().then((result) => deferred.resolve!(result))
  return deferred.promise
}

const processQueue = () => {
  if (queuedRequest) {
    linterPromise = executeQueuedAction(queuedRequest).then(processQueue)
    queuedRequest = null
  } else {
    linterPromise = null
  }
}

export const latexLinter = (view: EditorView) => {
  // always update the view, we use it to filter the results to the current buffer
  currentView = view
  // if a linting request isn't already running, start it running
  if (!linterPromise) {
    linterPromise = runLinter()
    linterPromise.then(processQueue)
    return linterPromise
  } else {
    // otherwise create a single deferred promise which we will return to all subsequent requests
    if (!queuedRequest) {
      queuedRequest = new Deferred()
    }
    return queuedRequest.promise
  }
}
