<script setup lang="ts">
import { useAICommandStore } from '@/stores'
import type { EditorView } from '@codemirror/view'
import type { MenuItem } from 'primevue/menuitem'
import { useI18n } from 'vue-i18n'

import { useCursor } from '@/editor/hooks/useCursor'

import BaseMenu from './BaseMenu.vue'
import { Actions, AskAIStateMachine, type Task } from './askAIStateMachine'

const { t } = useI18n({ useScope: 'global' })

const props = defineProps<{
  view: EditorView
  stateMachine: AskAIStateMachine
}>()

const aiCommandStore = useAICommandStore()
const { isEmpty } = useCursor(props.view)

const sendRequest = (task: Task) => {
  aiCommandStore.openFloating(task)
  props.stateMachine.send(Actions.SelectionChanged())
}
const requests: MenuItem[] = isEmpty
  ? [
      {
        label: t('global.aiCommand.continueWriting'),
        action: () => sendRequest('continueWriting'),
      },
      /* FIXME: 아래 두 기능에 대한 기획이 완료되면 주석 해제
      {
        label: t('global.aiCommand.writeAbstract'),
        action: () => sendRequest('writeAbstract', props.view.state.doc.toString()),
      },
      {
        label: t('global.aiCommand.writeConclusion'),
        action: () => sendRequest('writeConclusion', props.view.state.doc.toString()),
      },
      */
    ]
  : [
      {
        label: t('global.aiCommand.continueWriting'),
        action: () => sendRequest('continueWriting'),
      },
      {
        label: t('global.aiCommand.correctGrammar'),
        action: () => sendRequest('correctGrammar'),
      },
      {
        label: t('global.aiCommand.makeLonger'),
        action: () => sendRequest('makeLonger'),
      },
      {
        label: t('global.aiCommand.simplifyLanguage'),
        action: () => sendRequest('simplifyLanguage'),
      },
      {
        label: t('global.aiCommand.paraphraseAcademically'),
        action: () => sendRequest('paraphraseAcademically'),
      },
      {
        label: t('global.aiCommand.summarize'),
        action: () => sendRequest('summarize'),
      },
      {
        label: t('global.aiCommand.translateToEnglish'),
        action: () => sendRequest('translateToEnglish'),
      },
    ]
</script>

<template>
  <div :class="$style.container">
    <BaseMenu :class="$style.menu" :model="requests" :popup="false" />
  </div>
</template>

<style module>
.container {
  background: none;
  display: flex;
  flex-direction: column;
  gap: 12px;
  pointer-events: none;
}
.header {
  width: 480px;
  border-radius: 8px;
  border: 1px solid var(--gray-2);
  background-color: var(--gray-bg);
  padding: 12px 16px;
  display: flex;
  gap: 12px;
  justify-content: start;
  align-items: center;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
  pointer-events: auto;
}
.headerText {
  color: var(--gray-5);
}
.menu {
  width: fit-content;
  pointer-events: auto;
  border: 1px solid var(--gray-2);
  background-color: var(--gray-bg);
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
}
</style>
