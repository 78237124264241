<template>
  <path
    d="M8.33325 10.0038C8.33325 10.4458 8.50885 10.8697 8.82141 11.1823C9.13397 11.4949 9.55789 11.6705 9.99992 11.6705C10.4419 11.6705 10.8659 11.4949 11.1784 11.1823C11.491 10.8697 11.6666 10.4458 11.6666 10.0038C11.6666 9.56177 11.491 9.13784 11.1784 8.82528C10.8659 8.51272 10.4419 8.33713 9.99992 8.33713C9.55789 8.33713 9.13397 8.51272 8.82141 8.82528C8.50885 9.13784 8.33325 9.56177 8.33325 10.0038Z"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M8.32648 8.3303C8.77032 7.88646 9.37229 7.63712 9.99997 7.63712C10.6276 7.63712 11.2296 7.88646 11.6735 8.3303C12.1173 8.77413 12.3666 9.3761 12.3666 10.0038C12.3666 10.6315 12.1173 11.2334 11.6735 11.6773C11.2296 12.1211 10.6276 12.3704 9.99997 12.3704C9.37229 12.3704 8.77032 12.1211 8.32648 11.6773C7.88265 11.2334 7.6333 10.6315 7.6333 10.0038C7.6333 9.3761 7.88265 8.77413 8.32648 8.3303ZM9.99997 9.03712C9.74359 9.03712 9.49772 9.13896 9.31643 9.32025C9.13515 9.50153 9.0333 9.74741 9.0333 10.0038C9.0333 10.2602 9.13515 10.506 9.31643 10.6873C9.49772 10.8686 9.74359 10.9704 9.99997 10.9704C10.2563 10.9704 10.5022 10.8686 10.6835 10.6873C10.8648 10.506 10.9666 10.2602 10.9666 10.0038C10.9666 9.74741 10.8648 9.50153 10.6835 9.32025C10.5022 9.13896 10.2563 9.03712 9.99997 9.03712Z"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M3.32266 10.0038C5.19383 12.9477 7.42185 14.3038 10 14.3038C12.5782 14.3038 14.8063 12.9477 16.6774 10.0038C14.8063 7.0598 12.5782 5.70377 10 5.70377C7.42185 5.70377 5.19383 7.0598 3.32266 10.0038ZM1.8998 9.64362C3.98358 6.17066 6.68116 4.30377 10 4.30377C13.3189 4.30377 16.0165 6.17066 18.1003 9.64362C18.2333 9.8653 18.2333 10.1422 18.1003 10.3639C16.0165 13.8369 13.3189 15.7038 10 15.7038C6.68116 15.7038 3.98358 13.8369 1.8998 10.3639C1.7668 10.1422 1.7668 9.8653 1.8998 9.64362Z"
  />
</template>
