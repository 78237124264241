// 괄호 쌍을 처리하는 백스페이스 함수
import { EditorView, keymap } from '@codemirror/view'

const handleBackspace = (editorView: EditorView): boolean => {
  // 괄호의 짝을 정의
  const matchingBrackets: Record<string, string> = {
    '{': '}',
    '[': ']',
    '(': ')',
  }

  const state = editorView.state
  const { from, to, empty } = state.selection.main

  if (empty && from === to) {
    const cursorPos = from
    const doc = state.doc
    const beforeChar = doc.sliceString(cursorPos - 1, cursorPos)
    const afterChar = doc.sliceString(cursorPos, cursorPos + 1)

    // 커서 앞의 문자가 여는 괄호이고 커서 뒤의 문자가 해당 닫는 괄호인지 확인
    if (beforeChar in matchingBrackets && matchingBrackets[beforeChar] === afterChar) {
      editorView.dispatch({
        changes: { from: cursorPos - 1, to: cursorPos + 1, insert: '' },
        selection: { anchor: cursorPos - 1 },
      })
      return true
    }
  }

  // 괄호 쌍이 아닌 경우 기본 백스페이스 동작 수행
  return false
}

// 백스페이스 처리를 위한 커스텀 키맵 정의
const backspaceKeymap = keymap.of([
  {
    key: 'Backspace',
    run: handleBackspace,
  },
])

export default backspaceKeymap
