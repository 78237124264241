import type { Task } from '@/components/askAIStateMachine'
import type { EditorView } from '@codemirror/view'
import { defineStore } from 'pinia'
import { ref } from 'vue'

import {
  addAICommandMark,
  getAICommandMarkContent,
  getAICommandMarkElement,
  removeAICommandMark,
} from '@/editor/extensions/aiCommandMark'

import { useEditorStore } from './editor'

export const useAICommandStore = defineStore('aiCommand', () => {
  const editorStore = useEditorStore()
  /**
   * 현재 선택된 텍스트에 AI Command Mark를 추가합니다.
   */
  const addMark = (from: number, to: number) => {
    if (!editorStore.editorView) {
      throw new Error('EditorView is not initialized')
    }
    addAICommandMark(editorStore.editorView as EditorView, from, to)
  }
  const floatingVisible = ref(false)
  const currentTask = ref<Task>()
  const currentSelectedText = ref('')
  const prepareOpen = async (task: Task) => {
    const { from, to } = getWordCompleteSelection()
    if (!from || !to) {
      throw new Error('Invalid selection')
    }
    currentTask.value = task
    currentSelectedText.value = editorStore.editorView?.state.sliceDoc(from, to) ?? ''
    addMark(from, to)
    editorStore.useCursor()?.set(to)
  }

  const openFloating = (task: Task) => {
    prepareOpen(task)
    floatingVisible.value = true
  }
  const closeFloating = () => {
    floatingVisible.value = false
    removeAICommandMark(editorStore.editorView as EditorView)
  }
  const getMarkedContent = () => getAICommandMarkContent(editorStore.editorView as EditorView)

  const getWordCompleteSelection = () => {
    const view = editorStore.editorView as EditorView
    const mainSelection = view.state.selection.main
    const { from, to } = mainSelection
    const startLine = view.state.doc.lineAt(from)
    const modifiedFrom =
      startLine.text.slice(0, from - startLine.from).lastIndexOf(' ') + startLine.from + 1
    const endLine = view.state.doc.lineAt(to)
    const nextSpaceIndex = endLine.text.slice(to - endLine.from).indexOf(' ')
    const modifiedTo = nextSpaceIndex === -1 ? endLine.to : to + nextSpaceIndex
    return {
      from: modifiedFrom,
      to: modifiedTo,
    }
  }

  const floatingAnchor = ref({ top: 0, left: 0 })
  const updateFloatingAnchor = () => {
    const markElement = getAICommandMarkElement(editorStore.editorView as EditorView)
    if (!markElement) {
      return
    }
    const rect = markElement.getBoundingClientRect()
    floatingAnchor.value = {
      top: rect.bottom,
      left: rect.right,
    }
  }
  return {
    addMark,
    floatingVisible,
    currentTask,
    currentSelectedText,
    openFloating,
    closeFloating,
    getMarkedContent,
    floatingAnchor,
    updateFloatingAnchor,
  }
})
