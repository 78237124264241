<script setup lang="ts">
import { useModalStore, useProjectFileStore, useProjectStore } from '@/stores'
import { nextDuplicatedFilePath } from '@/utils/path'
import { BaseButton } from '@murfy-package/ui'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'

const modalStore = useModalStore()
const { createFile } = useProjectFileStore()
const { projectFileList } = storeToRefs(useProjectStore())
const { t } = useI18n()

const createNewFile = () => {
  // root에 main.tex를 생성, 파일이름이 중복되지 않도록 처리
  const projectPathList = projectFileList.value.map((file) => file.fullPath)
  const newFilePath = nextDuplicatedFilePath('main.tex', projectPathList)
  createFile(newFilePath)
}
</script>

<template>
  <div :class="$style.container">
    <div :class="$style.contentContainer">
      <div :class="$style.content">
        <img :class="$style.icon" src="/icons/insert_drive_file.svg" />
        <span :class="['h2', 'gray-7']"> {{ t('title') }}</span>
        <span :class="['h6', 'gray-6']"> {{ t('content') }}</span>
      </div>
      <div class="flex w-full gap-4 py-4">
        <BaseButton
          class="w-full justify-center"
          severity="secondary"
          @click="modalStore.fileUploadModalVisible = true"
          >{{ t('uploadFiles') }}
        </BaseButton>
        <BaseButton class="w-full justify-center" severity="secondary" @click="createNewFile"
          >{{ t('addNewFile') }}
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<style module>
.container {
  padding: 32px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: transparent;
  overflow-x: hidden;
  overflow-y: hidden;
  min-width: 420px;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  max-width: 486px;
  padding: 40px;
}

.content {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 100%;
  height: 100%;
}

.icon {
  width: 48px;
  height: 48px;
}
</style>

<i18n>
{
  "en": {
    "title": "No Selected File",
    "content": "Oops! You haven’t selected a file yet.Please select a file from the left navigation, upload new files,or add a new file to proceed.",
    "uploadFiles": "Upload Files",
    "addNewFile": "Add a New File"

  }
}
</i18n>
