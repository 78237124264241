<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M2.6333 5.00377C2.6333 4.61717 2.9467 4.30377 3.3333 4.30377H16.6666C17.0532 4.30377 17.3666 4.61717 17.3666 5.00377C17.3666 5.39037 17.0532 5.70377 16.6666 5.70377H3.3333C2.9467 5.70377 2.6333 5.39037 2.6333 5.00377Z"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M2.6333 10.0038C2.6333 9.61717 2.9467 9.30377 3.3333 9.30377H16.6666C17.0532 9.30377 17.3666 9.61717 17.3666 10.0038C17.3666 10.3904 17.0532 10.7038 16.6666 10.7038H3.3333C2.9467 10.7038 2.6333 10.3904 2.6333 10.0038Z"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M2.6333 15.0038C2.6333 14.6172 2.9467 14.3038 3.3333 14.3038H16.6666C17.0532 14.3038 17.3666 14.6172 17.3666 15.0038C17.3666 15.3904 17.0532 15.7038 16.6666 15.7038H3.3333C2.9467 15.7038 2.6333 15.3904 2.6333 15.0038Z"
  />
</template>
