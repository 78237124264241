<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M5.00003 4.03336C4.74365 4.03336 4.49778 4.13521 4.31649 4.31649C4.13521 4.49778 4.03336 4.74365 4.03336 5.00003V10C4.03336 10.3866 3.71996 10.7 3.33336 10.7C2.94676 10.7 2.63336 10.3866 2.63336 10V5.00003C2.63336 4.37235 2.88271 3.77038 3.32654 3.32654C3.77038 2.88271 4.37235 2.63336 5.00003 2.63336H15C15.6277 2.63336 16.2297 2.88271 16.6735 3.32654C17.1173 3.77038 17.3667 4.37235 17.3667 5.00003V10C17.3667 10.3866 17.0533 10.7 16.6667 10.7C16.2801 10.7 15.9667 10.3866 15.9667 10V5.00003C15.9667 4.74365 15.8649 4.49778 15.6836 4.31649C15.5023 4.13521 15.2564 4.03336 15 4.03336H5.00003Z"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M1.79999 15C1.79999 14.6134 2.11339 14.3 2.49999 14.3H8.33332C8.71992 14.3 9.03332 14.6134 9.03332 15C9.03332 15.3866 8.71992 15.7 8.33332 15.7H2.49999C2.11339 15.7 1.79999 15.3866 1.79999 15Z"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M10.9667 15C10.9667 14.6134 11.2801 14.3 11.6667 14.3H17.5C17.8866 14.3 18.2 14.6134 18.2 15C18.2 15.3866 17.8866 15.7 17.5 15.7H11.6667C11.2801 15.7 10.9667 15.3866 10.9667 15Z"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M5.49496 12.005C5.76833 12.2784 5.76833 12.7216 5.49496 12.995L3.48994 15L5.49496 17.005C5.76833 17.2784 5.76833 17.7216 5.49496 17.995C5.2216 18.2683 4.77838 18.2683 4.50501 17.995L2.00501 15.495C1.73165 15.2216 1.73165 14.7784 2.00501 14.505L4.50501 12.005C4.77838 11.7316 5.2216 11.7316 5.49496 12.005Z"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M14.505 12.005C14.7784 11.7316 15.2216 11.7316 15.495 12.005L17.995 14.505C18.2683 14.7784 18.2683 15.2216 17.995 15.495L15.495 17.995C15.2216 18.2683 14.7784 18.2683 14.505 17.995C14.2316 17.7216 14.2316 17.2784 14.505 17.005L16.51 15L14.505 12.995C14.2316 12.7216 14.2316 12.2784 14.505 12.005Z"
  />
</template>
