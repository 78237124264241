import { APIClient } from '../client'
import type { Comment, Reply } from '../schema/response'

/**
 * Comment related API methods.
 */
export class CommentAPI {
  private client: APIClient
  constructor(client: APIClient) {
    this.client = client
  }
  async createComment(projectId: string, fullPath: string, text: string, from: number, to: number) {
    return (
      await this.client.post<Comment>(`/projects/${projectId}/comments`, {
        fullPath,
        text,
        fromLoc: from,
        toLoc: to,
      })
    ).data
  }
  async modifyComment(projectId: string, commentId: string, text: string) {
    await this.client.put(`/projects/${projectId}/comments/${commentId}/text`, { text })
  }
  async resolveComment(projectId: string, commentId: string) {
    await this.client.put(`/projects/${projectId}/comments/${commentId}/resolve`, {})
  }
  async unresolveComment(projectId: string, commentId: string) {
    await this.client.put(`/projects/${projectId}/comments/${commentId}/unresolve`, {})
  }
  async deleteComment(projectId: string, commentId: string) {
    await this.client.delete(`/projects/${projectId}/comments/${commentId}`)
  }
  async getFileComments(projectId: string, fullPath: string) {
    return (
      await this.client.get<Comment[]>(`/projects/${projectId}/project-files/${fullPath}/comments`)
    ).data
  }
  async getProjectComments(projectId: string) {
    return (await this.client.get<Comment[]>(`/projects/${projectId}/comments`)).data
  }
  async updateCommentLocation(projectId: string, commentId: string, from: number, to: number) {
    this.client.put(`/projects/${projectId}/comments/${commentId}/location`, { from, to })
  }
  async getComment(projectId: string, commentId: string) {
    return (await this.client.get<Comment>(`/projects/${projectId}/comments/${commentId}/replies`))
      .data
  }
  async createReply(projectId: string, commentId: string, text: string) {
    return (
      await this.client.post<Reply>(`/projects/${projectId}/comments/${commentId}/replies`, {
        text,
      })
    ).data
  }
  async modifyReply(projectId: string, replyId: string, text: string) {
    return (await this.client.put(`/projects/${projectId}/replies/${replyId}`, { text })).data
  }
  async deleteReply(projectId: string, replyId: string) {
    return (await this.client.delete(`/projects/${projectId}/replies/${replyId}`)).data
  }
}
