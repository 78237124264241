<script setup lang="ts">
import { DashboardHeader, DashboardSide } from '@/components'
import { useUISettingStore } from '@/stores'
import { useDragResize } from '@/utils/useDragResize'
import { storeToRefs } from 'pinia'
import { watch } from 'vue'

const uiSettingStore = useUISettingStore()
const { resizedWidth, onMouseDown } = useDragResize()
const { dashboardUISettings } = storeToRefs(uiSettingStore)
watch(resizedWidth, (newWidth) => {
  uiSettingStore.setDashboardSidebarWidth(newWidth)
})
</script>

<template>
  <div :class="$style.layout">
    <header :class="$style.header">
      <DashboardHeader />
    </header>
    <div :class="$style.main">
      <aside
        :class="[$style.sidebar, dashboardUISettings.isSidebarOpen ? '' : $style.closed]"
        :style="{ width: `${dashboardUISettings.sidebarWidth}px` }"
      >
        <DashboardSide />
        <div :class="$style.sidebarGutter" @mousedown.prevent="onMouseDown" />
      </aside>
      <div
        :class="[$style.content]"
        :style="{
          marginLeft: dashboardUISettings.isSidebarOpen
            ? `${dashboardUISettings.sidebarWidth}px`
            : '0',
        }"
      >
        <slot />
      </div>
    </div>
  </div>
</template>

<style module>
.layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 64px;
}

.main {
  margin-top: 64px;
  overflow: hidden;
  height: 100%;
}

.sidebar {
  position: fixed;
  width: 300px;
  height: 100%;
  background-color: var(--gray-bg);
  transition: transform 0.3s;
  transform: translateX(0);
  z-index: 1000;
}

.sidebar.closed {
  transform: translateX(-100%);
}

.sidebarGutter {
  width: 1px;
  cursor: ew-resize;
  background-color: var(--gray-3);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  transition: background-color 0.3s ease;
}
.sidebarGutter:hover {
  width: 3px;
  background-color: var(--cta-pressed);
}
.sidebarGutter:active {
  width: 3px;
  background-color: var(--cta-pressed);
}

.content {
  background-color: var(--gray-0);
  transition: margin-left 0.3s;
  height: 100%;
}
</style>
