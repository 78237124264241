import type { AxiosRequestConfig } from 'axios'

import { APIClient } from '../client'
import type {
  AIChatMessage,
  AIChatMessageContent,
  AIChatMessageContentImage,
  AskAIResponse,
} from '../schema/response'

/**
 * AskAI related API methods.
 */
export class AskAIAPI {
  private client: APIClient
  constructor(client: APIClient) {
    this.client = client
  }

  async writeAbstract(content: string, options?: AxiosRequestConfig): Promise<AskAIResponse> {
    const response = await this.client.post<AskAIResponse>(
      '/ai-writing/abstract',
      {
        content,
      },
      options,
    )
    return response.data
  }

  async continueWriting(content: string, options?: AxiosRequestConfig): Promise<AskAIResponse> {
    const response = await this.client.post<AskAIResponse>(
      '/ai-writing/continue',
      {
        content,
      },
      options,
    )
    return response.data
  }

  async writeConclusion(content: string, options?: AxiosRequestConfig): Promise<AskAIResponse> {
    const response = await this.client.post<AskAIResponse>(
      '/ai-writing/conclusion',
      {
        content,
      },
      options,
    )
    return response.data
  }

  async makeLonger(content: string, options?: AxiosRequestConfig): Promise<AskAIResponse> {
    const response = await this.client.post<AskAIResponse>(
      '/ai-writing/make-longer',
      {
        content,
      },
      options,
    )
    return response.data
  }

  async paraphraseAcademically(
    content: string,
    options?: AxiosRequestConfig,
  ): Promise<AskAIResponse> {
    const response = await this.client.post<AskAIResponse>(
      '/ai-writing/paraphrase-academically',
      {
        content,
      },
      options,
    )
    return response.data
  }
  async simplifyLanguage(content: string, options?: AxiosRequestConfig): Promise<AskAIResponse> {
    const response = await this.client.post<AskAIResponse>(
      '/ai-writing/simplify',
      {
        content,
      },
      options,
    )
    return response.data
  }
  async summarize(content: string, options?: AxiosRequestConfig): Promise<AskAIResponse> {
    const response = await this.client.post<AskAIResponse>(
      '/ai-writing/summarize',
      {
        content,
      },
      options,
    )
    return response.data
  }
  async correctGrammar(content: string, options?: AxiosRequestConfig): Promise<AskAIResponse> {
    const response = await this.client.post<AskAIResponse>(
      '/ai-writing/grammar-correction',
      {
        content,
      },
      options,
    )
    return response.data
  }
  async translateToEnglish(content: string, options?: AxiosRequestConfig): Promise<AskAIResponse> {
    const response = await this.client.post<AskAIResponse>(
      '/ai-writing/translation',
      {
        content,
      },
      options,
    )
    return response.data
  }
  async feedback(
    requestID: string,
    feedback: 'accept' | 'retry' | 'delete',
    options?: AxiosRequestConfig,
  ): Promise<{ feedbackSubmitted: boolean }> {
    const response = await this.client.post<{ feedbackSubmitted: boolean }>(
      '/ai-writing/feedback',
      {
        id: requestID,
        feedback: feedback,
      },
      options,
    )
    return response.data
  }
  /**
   *
   * AI Chat을 통해 질문을 하고 답변을 받습니다.
   *
   * *FIXME: Dummy API*
   *
   * @param projectId 프로젝트 ID
   * @param messageContents 메시지 컨텐츠
   * @param parentMessageId 부모 메시지 ID, 존재하는 경우 해당 메시지의 하위 메시지로 등록됩니다.
   * @param threadId 채팅 스레드 ID, 존재하는 경우 해당 스레드에 메시지를 추가합니다.
   */
  async askQuestion({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    projectId,
    messageContents,
    parentMessageId,
    threadId,
  }: {
    projectId: string
    messageContents: AIChatMessageContent[]
    parentMessageId?: string
    threadId?: string
  }): Promise<{ threadId: string; question: AIChatMessage; answer: AIChatMessage }> {
    const response = await this.client.post<{
      threadId: string
      question: AIChatMessage
      answer: AIChatMessage
    }>(`/projects/${projectId}/ai-chat/message`, {
      threadId,
      parentMessageId,
      messageContents,
      stream: false,
    })
    // FIXME: BE에서 childrenIds를 null로 반환하는 경우가 있음. FE에서 childrenIds를 []로 변환해야 함.
    if (!response.data.question.childrenIds) {
      response.data.question.childrenIds = []
    }
    if (!response.data.answer.childrenIds) {
      response.data.answer.childrenIds = []
    }
    // FIXME: response.data.question.contents 에 image가 있을 경우 url을 추가해줘야 함. 서버에서 url을 null로 반환함.
    response.data.question.contents.forEach((content) => {
      if (content.contentType === 'image') {
        content.url = `${this.client.baseURL}/projects/${projectId}/ai-chat/images/${content.id}`
      }
    })
    return response.data
  }
  /**
   * AI Chat을 통해 했던 질문을 다시 시도합니다.
   *
   * *FIXME: Dummy API*
   *
   * @param threadId 채팅 스레드 ID
   * @param questionId 질문 메시지 ID
   */
  async retryQuestion({
    projectId,
    threadId,
    question,
  }: {
    projectId: string
    threadId: string
    question: AIChatMessage
  }) {
    const response = await this.client.post<{
      threadId: string
      question: AIChatMessage
      answer: AIChatMessage
    }>(`/projects/${projectId}/ai-chat/message`, {
      threadId,
      parentMessageId: question.id,
      messageContents: question.contents,
      stream: false,
    })
    // FIXME: BE에서 childrenIds를 null로 반환하는 경우가 있음. FE에서 childrenIds를 []로 변환해야 함.
    if (!response.data.question.childrenIds) {
      response.data.question.childrenIds = []
    }
    if (!response.data.answer.childrenIds) {
      response.data.answer.childrenIds = []
    }
    // FIXME: response.data.question.contents 에 image가 있을 경우 url을 추가해줘야 함. 서버에서 url을 null로 반환함.
    response.data.question.contents.forEach((content) => {
      if (content.contentType === 'image') {
        content.url = `${this.client.baseURL}/projects/${projectId}/ai-chat/images/${content.id}`
      }
    })
    return response.data
  }

  async uploadImage(projectId: string, file: File, options: AxiosRequestConfig = {}) {
    const fileForm = new FormData()
    fileForm.append('file', file)
    const response = await this.client.post<{
      id: string
      height: number
      width: number
    }>(`/projects/${projectId}/ai-chat/image`, fileForm, {
      ...options,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return {
      ...response.data,
      contentType: 'image',
      url: `${this.client.baseURL}/projects/${projectId}/ai-chat/images/${response.data.id}`,
    } as AIChatMessageContentImage
  }

  async deleteImage(projectId: string, id: string) {
    await this.client.delete(`/projects/${projectId}/ai-chat/images/${id}`)
  }
}
