<script setup lang="ts">
import { computed } from 'vue'

import {
  IconArrowLeft,
  IconArrowRight,
  IconBase,
  IconCircleExclamationF,
  IconDoubleSquare,
  IconLoading,
  IconRotateRight,
} from '../../atoms'
import { MarkdownReader } from '../../molecules'
import AddPhoto from '../AddPhoto/AddPhoto.vue'

type TextCode = {
  content: string
  code?: boolean
  language?: string
}

type Props = {
  role: 'user' | 'assistant'
  contents: string[]
  currentContentIndex: number
  imageUrl?: string
  imageLoading?: boolean
  loading?: boolean
  // 재귀 컴포넌트 부모, 자식 구분 - 자식은 code 형식으로만 표시
  child?: boolean
  codeLanguage?: string
  error?: boolean
  errorMessage?: string
}

type Emits = {
  retry: []
  prev: []
  next: []
  copy: [Promise<void>]
}

const emit = defineEmits<Emits>()
const props = defineProps<Props>()

// text-code-text-code
const textCodes = computed<TextCode[]>(() => {
  const result: TextCode[] = []
  const content = props.contents[props.currentContentIndex]

  if (props.role !== 'assistant' || !content || props.child) {
    return result
  }

  const regex = /```(?:\w+)?\n([\s\S]*?)```/g
  let lastIndex = 0

  content.replace(regex, (match, code, offset) => {
    if (offset > lastIndex) {
      result.push({ content: content.slice(lastIndex, offset).trim() })
    }
    result.push({ content: code.trim(), code: true, language: match.match(/```(\w+)?/)?.[1] })
    lastIndex = offset + match.length
    return code
  })

  if (lastIndex > 0 && lastIndex < content.length) {
    result.push({ content: content.slice(lastIndex).trim() })
  }

  return result.filter((textCode) => textCode.content)
})
const isCode = computed<boolean>(() => textCodes.value.length === 1 || props.child)

const currentContent = computed<string | undefined>(() => {
  const content = props.contents[props.currentContentIndex]?.trim()
  return isCode.value ? textCodes.value[0]?.content || content : content
})

const activeCodeLanguage = computed<string | undefined>(
  () => textCodes.value[0]?.language || props.codeLanguage,
)

const copyToClipboard = () => {
  if (currentContent.value) {
    emit('copy', navigator.clipboard.writeText(currentContent.value))
  }
}
</script>

<template>
  <!-- UserMessage -->
  <div
    v-if="role === 'user'"
    class="border-color-border-primary bg-color-bg-global-tertiary justify-flex-end flex flex-col gap-4 break-all rounded-bl-3xl rounded-br-none rounded-tl-3xl rounded-tr-3xl border border-solid pb-2.5 pl-4 pr-4 pt-2.5"
  >
    <span class="body-md">{{ currentContent }}</span>
    <div v-if="imageUrl || imageLoading" class="flex pb-4 pt-2">
      <AddPhoto :imageUrl="imageUrl" :loading="imageLoading" />
    </div>
  </div>

  <!-- AIMessage -->
  <div
    v-else
    class="border-color-border-primary bg-color-bg-global-primary flex flex-col items-start gap-1 rounded-bl-none rounded-br-3xl rounded-tl-3xl rounded-tr-3xl border pl-4 pr-4"
    :class="[loading ? 'pb-0' : 'pb-4']"
  >
    <div class="flex h-12 items-center justify-between gap-1 self-stretch p-1.5 px-0">
      <span class="head-sm">
        Result
        <span v-if="activeCodeLanguage"> ({{ activeCodeLanguage }}) </span>
      </span>
      <div class="flex items-center gap-1">
        <template v-if="!loading">
          <span
            v-show="!child && error"
            class="rounded-md pb-1 pl-1.5 pr-1.5 pt-1 hover:bg-gray-200"
            @click="$emit('retry')"
          >
            <IconBase width="20" height="20" class="flex cursor-pointer items-center">
              <IconRotateRight />
            </IconBase>
          </span>
          <span
            v-show="!error"
            class="rounded-md pb-1 pl-1.5 pr-1.5 pt-1 hover:bg-gray-200"
            @click="copyToClipboard"
          >
            <IconBase width="20" height="20" class="flex cursor-pointer items-center">
              <IconDoubleSquare />
            </IconBase>
          </span>
        </template>

        <span v-else class="rounded-md pb-1 pl-1.5 pr-1.5 pt-1">
          <IconBase width="20" height="20" class="flex items-center text-blue-500">
            <IconLoading />
          </IconBase>
        </span>
      </div>
    </div>

    <template v-if="!loading && !error">
      <template v-if="currentContent && !loading && textCodes.length <= 1">
        <div class="flex w-full overflow-x-auto pb-2 pt-2">
          <span v-if="isCode" class="text-color-cursor-primary-009 code-sm">
            <pre>{{ currentContent }}</pre>
          </span>
          <MarkdownReader v-else :content="currentContent" class="pb-2 pt-2" size="sm" />
        </div>
      </template>

      <template v-for="(textCode, index) of textCodes" v-else :key="index">
        <MarkdownReader
          v-if="!textCode.code"
          :content="textCode.content"
          class="pb-2 pt-2"
          size="sm"
        />
        <AIChatMessage
          v-else
          :role="'assistant'"
          :codeLanguage="textCode.language"
          :contents="[textCode.content]"
          :currentContentIndex="0"
          class="w-full"
          child
          @copy="($event) => $emit('copy', $event)"
        />
      </template>

      <div
        v-if="contents.length > 1 && !loading"
        class="flex items-center justify-end gap-1 self-stretch"
      >
        <span class="p-2" @click="$emit('prev')">
          <IconBase
            width="20"
            height="20"
            class="flex cursor-pointer items-center"
            :class="{ 'text-color-text-disabled': !currentContentIndex }"
          >
            <IconArrowLeft />
          </IconBase>
        </span>

        <div class="flex items-center">
          <span class="body-sm w-8 text-center">{{ currentContentIndex + 1 }}</span>
          <span class="body-sm text-color-text-secondary">/</span>
          <span class="body-sm text-color-text-secondary w-8 text-center">
            {{ contents.length }}
          </span>
        </div>

        <span class="p-2" @click="$emit('next')">
          <IconBase
            width="20"
            height="20"
            class="flex cursor-pointer items-center"
            :class="{ 'text-color-text-disabled': currentContentIndex === contents.length - 1 }"
          >
            <IconArrowRight />
          </IconBase>
        </span>
      </div>
    </template>

    <div
      v-show="error"
      class="text-color-text-danger border-color-border-danger bg-color-bg-global-tertiary flex w-full items-center gap-1 border p-2"
    >
      <IconBase>
        <IconCircleExclamationF />
      </IconBase>
      <span class="body-sm"> {{ errorMessage }}</span>
    </div>
  </div>
</template>
