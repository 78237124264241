<script setup lang="ts">
import { SIDEBAR_HEADER_HEIGHT_PIXEL } from '@/stores'
import MarkdownReader from '@murfy-package/ui/src/components/MarkdownReader.vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
</script>

<template>
  <div class="flex h-screen flex-col">
    <!--header-->
    <div
      :style="{
        '--editor-sidebar-header-height': `${SIDEBAR_HEADER_HEIGHT_PIXEL}px`,
      }"
      class="border-color-border-primary flex h-[--editor-sidebar-header-height] flex-row items-center gap-4 border-b px-4 py-2"
    >
      <span class="head-xs text-color-text-primary w-full">{{ t('header') }}</span>
    </div>
    <div class="flex-1 overflow-y-auto px-4 pb-4">
      <MarkdownReader filePath="/md/help-for-murfy.md" />
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "header": "Help for Murfy"
  }
}
</i18n>
