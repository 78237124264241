<script setup lang="ts">
import { INVITE_EDITOR_LINK_PREFIX, INVITE_VIEWER_LINK_PREFIX } from '@/config'
import { useEditorStore, useErrorStore } from '@/stores'
import { usePermission } from '@/stores/permission'
import { toastService } from '@/utils/toast'
import { Permission, Role, useAPIClient } from '@murfy-package/api-client'
import {
  BaseButton,
  BaseTextButton,
  IconBase,
  IconClip,
  IconExpandLess,
  IconExpandMore,
  IconEye,
  IconPencil,
} from '@murfy-package/ui'
import { useClipboard } from '@vueuse/core'
import { storeToRefs } from 'pinia'
import PrimeVueDialog from 'primevue/dialog'
import PrimeVueDropdown from 'primevue/dropdown'
import Skeleton from 'primevue/skeleton'
import { ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { AccessControl } from '.'

const { t } = useI18n()

const props = defineProps<{
  visible: boolean
}>()

const emit = defineEmits<{
  close: []
  'update:visible': [value: boolean]
}>()

enum ModalState {
  Default = 'default',
  DeleteShare = 'deleteShare',
  ChangeOwner = 'changeOwner',
}
const modalState = ref<ModalState>(ModalState.Default)

const loadingShareLink = ref(false)
const shareLinkEditor = ref('')
const shareLinkViewer = ref('')
const loadingPeopleWithAccess = ref(false)
type ViewUserInfo = {
  id: string
  name: string
  email: string
  role: Role
  dropdownOpened: boolean
}
// projectRoleOwner.read, projectRoleEditor.read, projectRoleViewer.read 권한 확인은 서버에서 해서 결과를 주어야함.
const peopleWithAccess = ref<ViewUserInfo[]>([])
const targetUserInfoToDelete = ref<ViewUserInfo | null>(null)
const apiClient = useAPIClient()
const { setError } = useErrorStore()
const { project } = storeToRefs(useEditorStore())

const initializeModal = () => {
  if (!project.value) {
    setError(new Error('Project not found'))
    emit('update:visible', false)
    return
  }
  modalState.value = ModalState.Default
  targetUserInfoToDelete.value = null
  loadingShareLink.value = true
  apiClient.invite
    .getInviteLink(project.value.id)
    .then(({ editLink, viewLink }) => {
      shareLinkEditor.value = INVITE_EDITOR_LINK_PREFIX + editLink
      shareLinkViewer.value = INVITE_VIEWER_LINK_PREFIX + viewLink
    })
    .catch((error) => {
      setError(error)
      emit('update:visible', false)
    })
    .finally(() => {
      loadingShareLink.value = false
    })

  loadingPeopleWithAccess.value = true
  // FIXME: call API to get share links and people with access
  // API should properly handle the case where the user does not have permission to view roles
  apiClient.project
    .getMembers(project.value.id)
    .then((members) => {
      peopleWithAccess.value = members.map((member) => ({
        id: member.userId,
        name: member.name,
        email: member.email,
        role: member.role,
        dropdownOpened: false,
      }))
    })
    .catch((error) => {
      setError(error)
      emit('update:visible', false)
    })
    .finally(() => {
      loadingPeopleWithAccess.value = false
    })
}
watch(
  () => props.visible,
  (visible) => visible && initializeModal(),
  { immediate: true },
)

const handleDropdownChange = (person: ViewUserInfo, dropdownValue: 'delete' | Role) => {
  if (dropdownValue === 'delete') {
    prepareDeleteShare(person)
  } else if (person.role !== dropdownValue) {
    person.role = dropdownValue
    changeUserRole(person.id, person.role)
  }
}

const prepareDeleteShare = (person: ViewUserInfo) => {
  targetUserInfoToDelete.value = person
  modalState.value = ModalState.DeleteShare
  person.dropdownOpened = false
}

const confirmDeleteShare = () => {
  if (!targetUserInfoToDelete.value) return
  deleteUserRole(targetUserInfoToDelete.value.id)
}

const cancelDeleteShare = () => {
  targetUserInfoToDelete.value = null
  modalState.value = ModalState.Default
}

const changeUserRole = (userId: string, role: Role) => {
  if (!project.value) {
    setError(new Error('Project not found'))
    emit('update:visible', false)
    return
  }
  peopleWithAccess.value = peopleWithAccess.value.map((person) =>
    person.id === userId ? { ...person, role } : person,
  )
  apiClient.project.setUserRole(project.value.id, userId, role).catch((error) => {
    setError(error)
  })
}
const deletingShare = ref(false)
const deleteUserRole = (userId: string) => {
  if (!project.value) {
    setError(new Error('Project not found'))
    emit('update:visible', false)
    return
  }
  deletingShare.value = true
  apiClient.project
    .deleteUserRole(project.value.id, userId)
    .then(() => {
      toastService.success(
        t('deleteShare.successToast.summary'),
        t('deleteShare.successToast.detail'),
      )
    })
    .catch((error) => {
      setError(error)
    })
    .finally(() => {
      emit('update:visible', false)
      deletingShare.value = false
    })
}

const roles = ref([
  { value: Role.editor },
  { value: Role.viewer },
  {
    value: 'delete',
    class:
      'text-destructive-primary hover:text-destructive-hover hover:bg-destructive-secondary-hover',
  },
])
const getMenuPermission = (role: Role | 'delete', personRole: Role) => {
  if (role === personRole) {
    return []
  }
  switch (role) {
    case Role.editor:
      return [Permission.projectRoleEditorCreate]
    case Role.viewer:
      return [Permission.projectRoleViewerCreate]
    case 'delete':
      return personRole === Role.viewer
        ? [Permission.projectRoleViewerDelete]
        : [Permission.projectRoleEditorDelete]
    default:
      return []
  }
}

const { copy } = useClipboard()
const copyToClipboard = (text: string) => {
  copy(text)
  toastService.success(t('copiedToast.summary'), t('copiedToast.detail'))
}

const { checkPermission } = usePermission()
const checkEditRolePermission = (role: Role) => {
  switch (role) {
    case Role.editor:
      return (
        checkPermission(Permission.projectRoleEditorUpdate) ||
        checkPermission(Permission.projectRoleEditorDelete)
      )
    case Role.viewer:
      return (
        checkPermission(Permission.projectRoleViewerUpdate) ||
        checkPermission(Permission.projectRoleViewerDelete)
      )
    default:
      return false
  }
}
</script>

<template>
  <PrimeVueDialog
    :visible="visible"
    modal
    :draggable="false"
    :closable="modalState === ModalState.Default"
    :pt="{
      root: 'w-[480px]',
      content: modalState === ModalState.Default ? 'rounded-b-2xl' : '',
      footer: 'w-full',
    }"
    @update:visible="(visible) => emit('update:visible', visible)"
  >
    <template #header>
      <span class="h2 text-gray-9">
        <template v-if="modalState === ModalState.Default">
          {{ t('header') }}
        </template>
        <template v-else-if="modalState === ModalState.ChangeOwner">
          {{ t('changeOwner.header') }}
        </template>
        <template v-else-if="modalState === ModalState.DeleteShare">
          {{ t('deleteShare.header') }}
        </template>
      </span>
    </template>
    <template v-if="modalState === ModalState.Default" #default>
      <div class="flex flex-col gap-3 py-4">
        <span class="h5 text-gray-8">{{ t('inviteLink') }}</span>
        <AccessControl :permissions="[Permission.inviteEditorLinkRead]">
          <div class="border-gray-2 bg-gray-0 flex justify-between rounded-lg border p-4">
            <div class="text-gray-7 h5 flex gap-2">
              <IconBase>
                <IconPencil />
              </IconBase>
              {{ t('inviteLinkEditor') }}
            </div>
            <BaseTextButton :loading="loadingShareLink" @click="copyToClipboard(shareLinkEditor)">
              <IconBase :iconName="t('copy')">
                <IconClip />
              </IconBase>
            </BaseTextButton>
          </div>
        </AccessControl>
        <AccessControl :permissions="[Permission.inviteViewerLinkRead]">
          <div class="border-gray-2 bg-gray-0 flex justify-between rounded-lg border p-4">
            <div class="text-gray-7 h5 flex gap-2">
              <IconBase>
                <IconEye />
              </IconBase>
              {{ t('inviteLinkViewer') }}
            </div>
            <BaseTextButton :loading="loadingShareLink" @click="copyToClipboard(shareLinkViewer)">
              <IconBase :iconName="t('copy')">
                <IconClip />
              </IconBase>
            </BaseTextButton>
          </div>
        </AccessControl>
      </div>
      <div class="flex flex-col gap-3 py-4">
        <span class="h5 text-gray-8">{{ t('peopleWithAccess') }}</span>
        <template v-if="loadingPeopleWithAccess">
          <div
            class="border-gray-2 bg-gray-0 flex items-center justify-between rounded-lg border p-4"
          >
            <div class="flex flex-col gap-1">
              <Skeleton width="120px" height="22px" />
              <Skeleton width="240px" height="19px" />
            </div>
            <Skeleton width="73px" height="24px" />
          </div>
          <div
            class="border-gray-2 bg-gray-0 flex items-center justify-between rounded-lg border p-4"
          >
            <div class="flex flex-col gap-1">
              <Skeleton width="120px" height="22px" />
              <Skeleton width="240px" height="19px" />
            </div>
            <Skeleton width="73px" height="24px" />
          </div>
        </template>
        <template v-else>
          <div
            v-for="person in peopleWithAccess"
            :key="person.id"
            class="border-gray-2 bg-gray-0 flex items-center justify-between rounded-lg border p-4"
          >
            <div class="flex flex-col">
              <span class="p1 text-gray-7">{{ person.name }}</span>
              <span class="p4 text-gray-6">{{ person.email }}</span>
            </div>
            <PrimeVueDropdown
              :modelValue="person.role"
              :options="roles"
              optionLabel="label"
              optionValue="value"
              unstyled
              :pt="{
                panel: 'rounded-lg py-2 shadow-md bg-gray-white-n-black',
                input: 'focus-visible:outline-none',
                trigger: 'hidden',
              }"
              @beforeHide="() => (person.dropdownOpened = false)"
              @beforeShow="() => (person.dropdownOpened = true)"
              @change="handleDropdownChange(person, $event.value)"
            >
              <template #value="slotProps">
                <BaseTextButton
                  v-if="slotProps.value"
                  class="flex items-center gap-2"
                  :disabled="!checkEditRolePermission(person.role)"
                  :title="
                    !checkEditRolePermission(person.role)
                      ? t('noPermissionToChangeRole')
                      : t('changeRole')
                  "
                  :class="[slotProps.value === 'owner' ? 'text-gray-2 cursor-not-allowed' : '']"
                >
                  <div class="p1 hidden sm:block">
                    {{ t(slotProps.value) }}
                  </div>
                  <IconBase v-if="person.dropdownOpened" iconName="ExpandLess">
                    <IconExpandLess />
                  </IconBase>
                  <IconBase v-else iconName="ExpandMore">
                    <IconExpandMore />
                  </IconBase>
                </BaseTextButton>
              </template>
              <template #option="slotProps">
                <AccessControl
                  :permissions="getMenuPermission(slotProps.option.value, person.role)"
                >
                  <div
                    class="hover:bg-cta-secondary-hover active:bg-cta-secondary-pressed p2 gray-7 hover:text-cta-primary flex min-w-[120px] cursor-pointer items-center px-4 py-[10px]"
                    :class="[
                      slotProps.option.value === person.role
                        ? 'text-cta-primary bg-cta-secondary-hover'
                        : '',
                      slotProps.option.class ?? '',
                    ]"
                  >
                    {{ t(slotProps.option.value) }}
                  </div>
                </AccessControl>
              </template>
            </PrimeVueDropdown>
          </div>
        </template>
      </div>
    </template>
    <template v-else-if="modalState === ModalState.DeleteShare" #default>
      <span>
        {{ t('deleteShare.content') }}
      </span>
    </template>
    <template v-if="modalState === ModalState.DeleteShare" #footer>
      <BaseButton
        severity="tertiary"
        class="w-full"
        :disabled="deletingShare"
        @click="cancelDeleteShare"
        >{{ t('cancel') }}</BaseButton
      >
      <BaseButton
        class="w-full"
        severity="secondary"
        actionType="destructive"
        :loading="deletingShare"
        @click="confirmDeleteShare"
      >
        {{ t('delete') }}
      </BaseButton>
    </template>
  </PrimeVueDialog>
</template>

<i18n>
{
  "en": {
    "header": "Share Project",
    "inviteLink": "Invite link",
    "inviteLinkEditor": "Share as an Editor",
    "inviteLinkViewer": "Share as a Viewer",
    "peopleWithAccess": "People with Access",
    "OWNER": "Owner",
    "EDITOR": "Editor",
    "VIEWER": "Viewer",
    "delete": "Delete",
    "cancel": "Cancel",
    "copy": "Copy",
    "copiedToast": {
      "summary": "Copied!",
      "detail": "The link has been copied to your clipboard.",
    },
    "deleteShare": {
      "header": "Delete Share",
      "content": "Even if you delete the share now, you can always invite back. Do you want to continue? Then please check below",
      "successToast": {
        "summary": "Done!",
        "detail": "The share deletion was successful.",
      },
    },
    "changeOwner": {
      "header": "Change Owner",      
    },
    "noPermissionToChangeRole": "You do not have permission to change the role.",
    "changeRole": "Change Role",
  }
}
</i18n>
