<script setup lang="ts">
import { useUserStore } from '@/stores'
import { BaseButton } from '@murfy-package/ui'
import PrimeVueDialog from 'primevue/dialog'
import { useI18n } from 'vue-i18n'

const userStore = useUserStore()
const { t } = useI18n()

const props = defineProps<{
  visible: boolean
}>()

const emit = defineEmits<{
  close: []
  'update:visible': [value: boolean]
}>()

const onLogout = () => {
  userStore.logout()
  emit('close')
}
</script>

<template>
  <PrimeVueDialog
    :visible="props.visible"
    modal
    :pt="{
      header: ['h2', $style.header],
      footer: $style.footer,
      content: ['p2', $style.content],
    }"
    @update:visible="(visible) => emit('update:visible', visible)"
  >
    <template #header>
      {{ t('header') }}
    </template>
    <template #default>
      {{ t('content') }}
    </template>
    <template #footer>
      <BaseButton class="w-full" severity="tertiary" @click="emit('close')">
        {{ t('cancel') }}
      </BaseButton>
      <BaseButton class="w-full" severity="secondary" actionType="destructive" @click="onLogout">
        {{ t('logout') }}
      </BaseButton>
    </template>
  </PrimeVueDialog>
</template>

<style module>
.header {
  color: var(--gray-9);
}
.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: var(--gray-7);
}
</style>

<i18n>
{
  "en":{
    "header": "Log Out",
    "content": "Are you sure you want to log out? This action will end your current session.",
    "cancel": "Cancel",
    "logout": "Logout",
  }
}
</i18n>
