import { useStorage } from '@vueuse/core'
import { defineStore } from 'pinia'

/**
 * Get a value between the min and max values
 */
const getBetween = (value: number, min: number, max: number) => Math.min(Math.max(min, value), max)

const SIDEBAR_WIDTH_DEFAULT_PIXEL = 300
const SIDEBAR_WIDTH_MIN_PIXEL = 248
const SIDEBAR_WIDTH_MAX_PIXEL = 480
const PREVIEW_WIDTH_DEFAULT_FR = 1
const PREVIEW_WIDTH_MIN_FR = 0.1
const PREVIEW_WIDTH_MAX_FR = 10

// FIXME 여기를 정리할 필요가 있다
export const SIDEBAR_WIDTH_MINIMIZED = 0
export const GUTTER_WIDTH_PIXEL = 1
export const ACTIVITY_BAR_WIDTH_PIXEL = 72
export const SIDEBAR_HEADER_HEIGHT_PIXEL = 52
export const EDITOR_TOOLBAR_HEIGHT_PIXEL = 52
export const PDF_VIEWER_TOOLBAR_HEIGHT_PIXEL = 52

export const useUISettingStore = defineStore('ui', () => {
  const dashboardUISettings = useStorage(
    '@murfy-app.ui.dashboard-ui-settings',
    {
      /**
       * Sidebar state
       * If the sidebar is open, the value is `true`
       */
      isSidebarOpen: true,
      /**
       * Sidebar width in pixel
       */
      sidebarWidth: SIDEBAR_WIDTH_DEFAULT_PIXEL,
    },
    localStorage,
    { mergeDefaults: true },
  )
  /**
   * Toggle the dashboard sidebar state
   */
  const toggleDashboardSidebar = () => {
    dashboardUISettings.value.isSidebarOpen = !dashboardUISettings.value.isSidebarOpen
  }
  /**
   * Set the width of the dashboard sidebar
   *
   * @param width - The width of the sidebar in pixel
   * @returns
   */
  const setDashboardSidebarWidth = (width: number) => {
    // If the sidebar is closed, do nothing
    if (!dashboardUISettings.value.isSidebarOpen) {
      return
    }
    // Ensure the width is within the min and max values
    dashboardUISettings.value.sidebarWidth = getBetween(
      width,
      SIDEBAR_WIDTH_MIN_PIXEL,
      SIDEBAR_WIDTH_MAX_PIXEL,
    )
  }

  const editorUISettings = useStorage(
    '@murfy-app.ui.editor-ui-settings',
    {
      /**
       * Sidebar state
       * If the sidebar is open, the value is `true`
       */
      isSidebarOpen: true,
      /**
       * Sidebar width in pixel
       */
      sidebarWidth: SIDEBAR_WIDTH_DEFAULT_PIXEL,
      /**
       * Preview width in fr
       */
      previewWidthFr: PREVIEW_WIDTH_DEFAULT_FR,
    },
    localStorage,
    { mergeDefaults: true },
  )
  /**
   * Toggle the Editor sidebar state
   */
  const toggleEditorSidebar = () => {
    editorUISettings.value.isSidebarOpen = !editorUISettings.value.isSidebarOpen
  }
  /**
   * Set the width of the editor sidebar
   *
   * @param width - The width of the sidebar in pixel
   * @returns
   */
  const setEditorSidebarWidth = (width: number) => {
    // If the sidebar is closed, do nothing
    if (!editorUISettings.value.isSidebarOpen) {
      return
    }
    // Ensure the width is within the min and max values
    editorUISettings.value.sidebarWidth = getBetween(
      width,
      SIDEBAR_WIDTH_MIN_PIXEL,
      SIDEBAR_WIDTH_MAX_PIXEL,
    )
  }
  /**
   * Set the width of the editor preview
   * @param width - The width of the preview in fraction
   * @returns
   */
  const setEditorPreviewWidth = (width: number) => {
    // Ensure the width is within the min and max values
    editorUISettings.value.previewWidthFr = getBetween(
      width,
      PREVIEW_WIDTH_MIN_FR,
      PREVIEW_WIDTH_MAX_FR,
    )
  }
  return {
    dashboardUISettings,
    toggleDashboardSidebar,
    setDashboardSidebarWidth,
    editorUISettings,
    toggleEditorSidebar,
    setEditorSidebarWidth,
    setEditorPreviewWidth,
  }
})
