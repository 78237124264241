<script setup lang="ts">
import { Orientation } from '@/types'
import { ToggleGroup } from 'radix-vue/namespaced'

/**
 * @name ToggleGroup
 * @description
 * A group of radio buttons.
 * see [Radix RadioGroup](https://www.radix-vue.com/components/toggle-group.html)
 */
type ToggleGroupProps = {
  defaultValue?: string
  disabled?: boolean
  orientation?: Orientation
  options: string[]
  multiple?: boolean
  size?: 'sm' | 'md'
}

withDefaults(defineProps<ToggleGroupProps>(), {
  defaultValue: undefined,
  disabled: false,
  orientation: 'horizontal',
  multiple: false,
  size: 'sm',
})

const model = defineModel<string | string[]>()
</script>

<template>
  <ToggleGroup.Root
    v-model="model"
    :defaultValue="defaultValue"
    :disabled="disabled"
    :orientation="orientation"
    :type="multiple ? 'multiple' : 'single'"
    class="flex gap-1"
    :class="[orientation === 'horizontal' ? 'flex-row' : 'flex-col']"
  >
    <ToggleGroup.Item
      v-for="option in options"
      :key="option"
      :value="option"
      :class="[
        {
          md: 'h-10',
          sm: 'h-9',
        }[size],
      ]"
      class="data-[state=on]:text-color-text-selected data-[state=on]:enabled:hover:bg-color-bg-info-tertiary-hover data-[state=on]:enabled:active:bg-color-bg-info-tertiary-pressed data-[state=off]:text-color-text-tertiary data-[state=off]:enabled:hover:bg-color-bg-global-secondary data-[state=off]:enabled:active:bg-color-bg-global-tertiary body-sm disabled:text-color-text-disabled flex cursor-pointer items-center gap-2 rounded-lg bg-transparent p-2 disabled:cursor-not-allowed"
    >
      <slot :option="option">
        {{ option }}
      </slot>
    </ToggleGroup.Item>
  </ToggleGroup.Root>
</template>
