<script setup lang="ts">
const props = defineProps<{
  /**
   * Progress percentage.
   */
  progress: number
}>()
</script>
<template>
  <div :class="$style.progressCircle">
    <svg viewBox="0 0 36 36" :class="$style.circularChart">
      <path
        :class="$style.circleBg"
        d="M18 2.0845
           a 15.9155 15.9155 0 0 1 0 31.831
           a 15.9155 15.9155 0 0 1 0 -31.831"
      />
      <path
        :class="$style.circle"
        :stroke-dasharray="props.progress + ', 100'"
        d="M18 2.0845
           a 15.9155 15.9155 0 0 1 0 31.831
           a 15.9155 15.9155 0 0 1 0 -31.831"
      />
    </svg>
  </div>
</template>

<style module>
.progressCircle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
}
.circularChart {
  display: block;
  margin: 10px auto;
  max-width: 100%;
  max-height: 250px;
}
.circleBg {
  fill: none;
  stroke: var(--cta-primary);
  stroke-width: 3.8;
  stroke-opacity: 0.3;
}
.circle {
  fill: none;
  stroke-width: 2.8;
  stroke-linecap: round;
  stroke: var(--cta-primary);
  transition: stroke-dasharray 0.6s ease 0s;
}
</style>
