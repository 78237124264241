import { API, type UpdateUserInfoRequest, type UserInfo } from '@murfy-package/api-client'
import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useRouter } from 'vue-router'

const apiClient = new API(import.meta.env.VITE_APP_API_BASE_URL)

export const useUserStore = defineStore('user', () => {
  /**
   * Login with Google OAuth2 code.
   * @param code - Google OAuth2 code.
   */
  const login = async (code: string) => {
    await apiClient.auth.googleLogin(code)
    await fetchMe()
  }
  /**
   * Check if the user is logged in.
   * @returns if true, the user is logged in.
   */
  const isLoggedIn = () => !!me.value

  /**
   * User information.
   * @see {@link UserInfo}
   */
  const me = ref<UserInfo | null>(null)
  /**
   * Fetch the user information and set it to `me`.
   */
  const fetchMe = async () => {
    const newMe = await apiClient.user.getMe()
    setMe(newMe)
    return newMe
  }
  /**
   * Set the user information and store it in the local storage.
   * @param newMe - New user information.
   * @see {@link UserInfo}
   */
  const setMe = (newMe: UserInfo) => {
    me.value = newMe
  }
  /**
   * Update the user information.
   * @param updateInfo - New user information.
   * @see {@link UpdateUserInfoRequest}
   * @throws if the user is not logged in.
   */
  const updateMe = async (updateInfo: UpdateUserInfoRequest) => {
    const newMe = await apiClient.user.updateMe(updateInfo)
    setMe(newMe)
  }
  /**
   * Sign up the user.
   * @param signupInfo - User information.
   * @throws if the user is not logged in.
   * @return if the user is already signed up.
   * @see {@link UpdateUserInfoRequest}
   */
  const signup = async (signupInfo: UpdateUserInfoRequest) => {
    if (me.value && !me.value.signupRequired) {
      return
    }
    await apiClient.user.activateMe()
    const updatePayload: {
      promotionAgreement: boolean
      jobTitle?: string
    } = {
      promotionAgreement: signupInfo.promotionAgreement || false,
    }

    if (signupInfo.jobTitle) {
      updatePayload.jobTitle = signupInfo.jobTitle
    }

    await updateMe(updatePayload)
  }

  const router = useRouter()
  /**
   * Logout the user.
   * This method clears the user access token and user information.
   * It also redirects the user to the home page.
   */
  const logout = () => {
    me.value = null
    apiClient.logout()
    router.push({ name: 'login' })
  }

  /**
   * Delete the user and logout
   */
  const deleteMe = async () => {
    await apiClient.user.deleteMe()
    logout()
  }

  return { login, isLoggedIn, me, fetchMe, updateMe, deleteMe, signup, logout }
})
