<script setup lang="ts">
import { useErrorStore } from '@/stores'
import type { EditorView } from '@codemirror/view'
import { APIError, ERROR_CODE, useAPIClient } from '@murfy-package/api-client'
import { API } from '@murfy-package/api-client'
import { BaseProgressSpinner } from '@murfy-package/ui'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

import TextButton from './TextButton.vue'
import { Actions, type Answering, AskAIStateMachine } from './askAIStateMachine'

const { t } = useI18n()

const props = defineProps<{
  view: EditorView
  stateMachine: AskAIStateMachine
}>()

const state = props.stateMachine.state as Answering
const apiClient = useAPIClient()
const isLoading = ref(true)
const { setError } = useErrorStore()

const { token: cancelToken, cancel } = API.createCancelToken()
apiClient.askAI[state.task](state.input, { cancelToken })
  .then(({ id: _requestID, content }) => {
    props.stateMachine.send(Actions.GotResponse(state.task, state.input, content))
  })
  .catch((error) => {
    if (API.isCancel(error)) {
      return
    }
    const apiError = error as APIError
    const errorCode = apiError.errorCode
    const errorField = apiError.detail[0]?.fieldViolations[0]?.field
    if (errorCode === ERROR_CODE.INVALID_ARGUMENT && errorField === 'content') {
      const ContentCantBeEmptyError = new Error(t('ContentCantBeEmptyError'))
      setError(ContentCantBeEmptyError)
    } else {
      setError(apiError)
    }
    props.stateMachine.send(Actions.StopWriting())
  })
  .finally(() => {
    isLoading.value = false
  })

const stopWriting = () => {
  cancel()
  props.stateMachine.send(Actions.StopWriting())
}
</script>

<template>
  <div :class="$style.container">
    <div :class="$style.loadingBox">
      <BaseProgressSpinner stroke="2" height="24px" width="24px" />
      <span class="p1 gray-7"> {{ t('writing') }} </span>
    </div>
    <TextButton :label="t('stopWriting')" @click="stopWriting" />
  </div>
</template>

<style module>
.container {
  width: 480px;
  border-radius: 8px;
  border: 1px solid var(--gray-2);
  background-color: var(--gray-bg);
  padding: 12px 16px;
  display: flex;
  gap: 12px;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
  pointer-events: auto;
}
.buttonBox {
  flex: 0;
  display: flex;
  gap: 16px;
}
.loadingBox {
  flex: 1;
  display: flex;
  gap: 8px;
  align-items: center;
}
</style>

<i18n>
{
  "en": {
    "stopWriting": "Stop Writing",
    "writing": "AI is writing...",
    "ContentCantBeEmptyError": "To use AI, you need to write something first."
  },
}
</i18n>
