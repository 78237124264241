import { FileTree } from '@/utils/fileTree'
import { type ProjectInfoDetail, useAPIClient } from '@murfy-package/api-client'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

import { bibKeywords, bibliographyFiles } from '@/editor/extensions/autocomplete/latexKeywords'

import { useBibFileStore } from './bibFile'

export const useProjectStore = defineStore('project', () => {
  const apiClient = useAPIClient()
  /**
   * 현재 선택된 프로젝트 ID
   */
  const projectId = ref<string | null>(null)
  const openProject = async (id: string) => {
    projectId.value = id
    return await fetchProject()
  }
  /**
   * 현재 작업 중인 프로젝트에 대한 정보입니다.
   */
  const project = ref<ProjectInfoDetail | null>(null)
  const fetchProject = async () => {
    const { parseBibFilesFromDetail, parseBibFiles } = useBibFileStore()
    if (!projectId.value) {
      return Promise.reject(new Error('No project selected'))
    }
    project.value = await apiClient.project.get(projectId.value)
    parseBibFilesFromDetail(project.value).then((bibData) => {
      bibKeywords.length = 0
      bibKeywords.push(...bibData)
    })
    // Bib 파일 목록 가져오기
    if (project.value.assets) {
      const bibFiles = parseBibFiles(project.value.assets)
      bibliographyFiles.length = 0
      bibliographyFiles.push(...bibFiles)
    }
    return project.value
  }

  /**
   * 프로젝트 내 File List 입니다.
   * 폴더를 포함한 파일 리스트를 반환합니다.
   */
  const projectFileList = computed(() => {
    const projectAssets = project.value?.assets || []
    // 서버에서 보내주는 fileList에는 폴더가 포함되어 있지 않음. FileTree로 변환 후 다시 파일 리스트로 변환하면 각 폴더를 포함한 파일 리스트를 얻을 수 있음.
    const fileFolderTree = new FileTree(projectAssets)
    // 루트인 '/'는 포함하지 않음.
    const result = fileFolderTree.toFileList(false).filter((file) => file.fullPath !== '/')
    // 사전 순 정렬
    result.sort((a, b) => a.fullPath.localeCompare(b.fullPath))
    return result
  })
  const fetchProjectFileList = async () => {
    await fetchProject()
    return projectFileList.value
  }
  const $reset = () => {
    projectId.value = null
    project.value = null
  }
  return {
    projectId,
    openProject,
    project,
    fetchProject,
    projectFileList,
    fetchProjectFileList,
    $reset,
  }
})
