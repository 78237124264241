import { Extension } from '@codemirror/state'
import { yCollab } from 'y-codemirror.next'
import { WebsocketProvider } from 'y-websocket'
import * as Y from 'yjs'

// 이름표에 사용할 색상 목록
export const TAG_COLORS = [
  { color: '#DE350B', light: '#FFEBE6' },
  { color: '#7C5A03', light: '#EAE5D7' },
  { color: '#FF991F', light: '#FFFAE6' },
  { color: '#FFC01F', light: '#FFF5DB' },
  { color: '#00875A', light: '#E3FCEF' },
  { color: '#00A3BF', light: '#E6FCFF' },
  { color: '#0086CB', light: '#E9F7FD' },
  { color: '#5243AA', light: '#EAE6FF' },
  { color: '#B01AD6', light: '#F6E4FA' },
  { color: '#FF0099', light: '#FFD6EF' },
]

export type ConnectionState =
  | 'synced'
  | 'connected'
  | 'connecting'
  | 'disconnected'
  | 'syncing'
  | 'deleted'

const sleep = (ms: number = 0) => new Promise((resolve) => setTimeout(resolve, ms))

const MAX_RECONNECT_DELAY = 3600 * 1000
const DEFAULT_RECONNECT_DELAY = 100
const MAX_RECONNECT_COUNT = 4

let reconnectCount = 0
let reconnectDelay = DEFAULT_RECONNECT_DELAY
let state = 'connecting'

const reconnect = async (
  ydoc: Y.Doc,
  endpoint: string,
  filePath: string,
  userName: string,
  tokenIssuer: (filePath: string) => Promise<{ token: string }>,
  stateCallback?: (state: ConnectionState) => void,
): Promise<{
  provider: WebsocketProvider
  close: () => void
}> => {
  await sleep(reconnectDelay)
  return createProvider(ydoc, endpoint, filePath, userName, tokenIssuer, stateCallback)
}

const FILE_DELETED = 4404
const GRANT_REVOKED = 4401
const PROJECT_DELETED = 4410
const CLIENT_CLOSE = 1005

const reloadCodes = [PROJECT_DELETED, GRANT_REVOKED]

const createProvider = async (
  ydoc: Y.Doc,
  endpoint: string,
  filePath: string,
  userName: string,
  tokenIssuer: (filePath: string) => Promise<{ token: string }>,
  stateCallback?: (state: ConnectionState) => void,
): Promise<{
  provider: WebsocketProvider
  close: () => void
}> => {
  let provider: WebsocketProvider
  state = 'connecting'
  stateCallback?.('connecting')
  try {
    const { token } = await tokenIssuer(filePath)
    provider = new WebsocketProvider(endpoint, token, ydoc, {})

    // 접속 종료시 사유 체크
    provider.ws!.onclose = (ev) => {
      provider.shouldConnect = false
      if (reloadCodes.includes(ev.code)) {
        location.reload()
        return
      }
      if (ev.code === FILE_DELETED) {
        state = 'deleted'
        stateCallback?.('deleted')
        return
      }
      if (ev.code === CLIENT_CLOSE && document.hasFocus()) {
        // close in client
        return
      }

      if (reconnectCount > MAX_RECONNECT_COUNT) {
        state = 'disconnected'
        stateCallback?.('disconnected')
      }
      reconnectDelay =
        MAX_RECONNECT_DELAY > reconnectDelay ? reconnectDelay * 2 : MAX_RECONNECT_DELAY
      reconnectCount += 1
      reconnect(ydoc, endpoint, filePath, userName, tokenIssuer, stateCallback).then(
        (newProvider) => {
          provider = newProvider.provider
        },
      )
    }

    provider.on('sync', (synced: boolean) => {
      if (synced) {
        stateCallback?.('synced')
      }
    })

    provider.on('status', (event: { status: 'connected' | 'disconnected' }) => {
      if (state === event.status) {
        return
      }
      if (event.status === 'connected') {
        reconnectCount = 0
        reconnectDelay = DEFAULT_RECONNECT_DELAY
        state = 'connected'
        // 랜덤한 색상을 사용자 이름표에 표시
        const randomNumber = Math.ceil(Math.random() * 1000)
        const { color, light: colorLight } = TAG_COLORS[randomNumber % TAG_COLORS.length]
        // FIX: Reset the awareness state, before updating a single field
        provider.awareness.setLocalStateField('user', {
          name: userName,
          color: color,
          light: colorLight,
        })
      } else if (event.status === 'disconnected') {
        if (reconnectCount > MAX_RECONNECT_COUNT) {
          state = 'disconnected'
          stateCallback?.('disconnected')
        }
      } else {
        stateCallback?.(event.status)
      }
    })
  } catch {
    reconnectDelay = MAX_RECONNECT_DELAY > reconnectDelay ? reconnectDelay * 2 : MAX_RECONNECT_DELAY
    reconnectCount += 1
    if (reconnectCount > MAX_RECONNECT_COUNT) {
      state = 'disconnected'
      stateCallback?.('disconnected')
    }
    const reconnectResult = await reconnect(
      ydoc,
      endpoint,
      filePath,
      userName,
      tokenIssuer,
      stateCallback,
    )
    provider = reconnectResult.provider
  }
  return {
    provider,
    close: () => {
      if (provider?.destroy) {
        provider.destroy()
      }
    },
  }
}

const collaborator = async (
  endpoint: string,
  filePath: string,
  userName: string,
  tokenIssuer: (filePath: string) => Promise<{ token: string }>,
  stateCallback: (state: ConnectionState) => void,
): Promise<{ extension: Extension; close: () => void }> => {
  const ydoc = new Y.Doc()
  const yText = ydoc.getText('codemirror')
  const undoManager = new Y.UndoManager(yText)

  const { provider, close } = await createProvider(
    ydoc,
    endpoint,
    filePath,
    userName,
    tokenIssuer,
    stateCallback,
  )

  return {
    extension: yCollab(yText, provider.awareness, { undoManager }),
    close,
  }
}

export { collaborator }
