<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M11.6666 1.80376C12.0532 1.80376 12.3666 2.11716 12.3666 2.50376V5.83709C12.3666 5.87245 12.3806 5.90636 12.4056 5.93137C12.4306 5.95638 12.4645 5.97042 12.4999 5.97042H15.8332C16.2198 5.97042 16.5332 6.28382 16.5332 6.67042C16.5332 7.05702 16.2198 7.37042 15.8332 7.37042H12.4999C12.0932 7.37042 11.7032 7.20887 11.4157 6.92132C11.1281 6.63376 10.9666 6.24376 10.9666 5.83709V2.50376C10.9666 2.11716 11.28 1.80376 11.6666 1.80376Z"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M5.83322 3.20376C5.57684 3.20376 5.33097 3.3056 5.14968 3.48689C4.9684 3.66817 4.86655 3.91405 4.86655 4.17042V15.8371C4.86655 16.0935 4.9684 16.3393 5.14968 16.5206C5.33097 16.7019 5.57684 16.8038 5.83322 16.8038H14.1666C14.4229 16.8038 14.6688 16.7019 14.8501 16.5206C15.0314 16.3393 15.1332 16.0935 15.1332 15.8371V6.96037L11.3766 3.20376H5.83322ZM4.15973 2.49694C4.60357 2.0531 5.20554 1.80376 5.83322 1.80376H11.6666C11.8522 1.80376 12.0303 1.87751 12.1615 2.00878L16.3282 6.17545C16.4595 6.30672 16.5332 6.48477 16.5332 6.67042V15.8371C16.5332 16.4648 16.2839 17.0667 15.84 17.5106C15.3962 17.9544 14.7942 18.2038 14.1666 18.2038H5.83322C5.20554 18.2038 4.60357 17.9544 4.15973 17.5106C3.7159 17.0667 3.46655 16.4648 3.46655 15.8371V4.17042C3.46655 3.54274 3.7159 2.94077 4.15973 2.49694Z"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M10 8.47043C10.3866 8.47043 10.7 8.78383 10.7 9.17043V14.1704C10.7 14.557 10.3866 14.8704 10 14.8704C9.61345 14.8704 9.30005 14.557 9.30005 14.1704V9.17043C9.30005 8.78383 9.61345 8.47043 10 8.47043Z"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M7.42158 11.5921C7.69494 11.3187 8.13816 11.3187 8.41153 11.5921L9.99989 13.1805L11.5882 11.5921C11.8616 11.3187 12.3048 11.3187 12.5782 11.5921C12.8516 11.8655 12.8516 12.3087 12.5782 12.5821L10.4949 14.6654C10.2215 14.9388 9.77828 14.9388 9.50491 14.6654L7.42158 12.5821C7.14821 12.3087 7.14821 11.8655 7.42158 11.5921Z"
  />
</template>
