<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M5.96655 7.50377C5.96655 7.11717 6.27995 6.80377 6.66655 6.80377H13.3332C13.7198 6.80377 14.0332 7.11717 14.0332 7.50377C14.0332 7.89037 13.7198 8.20377 13.3332 8.20377H6.66655C6.27995 8.20377 5.96655 7.89037 5.96655 7.50377Z"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M5.96655 10.8371C5.96655 10.4505 6.27995 10.1371 6.66655 10.1371H11.6666C12.0532 10.1371 12.3666 10.4505 12.3666 10.8371C12.3666 11.2237 12.0532 11.5371 11.6666 11.5371H6.66655C6.27995 11.5371 5.96655 11.2237 5.96655 10.8371Z"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M5.00005 4.03712C4.52266 4.03712 4.06482 4.22676 3.72726 4.56432C3.38969 4.90189 3.20005 5.35973 3.20005 5.83712V12.5038C3.20005 12.9812 3.38969 13.439 3.72726 13.7766C4.06482 14.1141 4.52266 14.3038 5.00005 14.3038H6.66672C7.05331 14.3038 7.36672 14.6172 7.36672 15.0038V16.2674L10.4732 14.4035C10.582 14.3383 10.7065 14.3038 10.8334 14.3038H15C15.4774 14.3038 15.9353 14.1141 16.2728 13.7766C16.6104 13.439 16.8 12.9812 16.8 12.5038V5.83712C16.8 5.35973 16.6104 4.90189 16.2728 4.56432C15.9353 4.22676 15.4774 4.03712 15 4.03712H5.00005ZM2.73731 3.57437C3.33742 2.97426 4.15136 2.63712 5.00005 2.63712H15C15.8487 2.63712 16.6627 2.97426 17.2628 3.57437C17.8629 4.17449 18.2 4.98842 18.2 5.83712V12.5038C18.2 13.3525 17.8629 14.1664 17.2628 14.7665C16.6627 15.3666 15.8487 15.7038 15 15.7038H11.0273L7.02686 18.104C6.81061 18.2338 6.54128 18.2372 6.32183 18.1129C6.10237 17.9887 5.96672 17.756 5.96672 17.5038V15.7038H5.00005C4.15136 15.7038 3.33742 15.3666 2.73731 14.7665C2.13719 14.1664 1.80005 13.3525 1.80005 12.5038V5.83712C1.80005 4.98842 2.13719 4.17449 2.73731 3.57437Z"
  />
</template>
