<script setup lang="ts">
import { usePermission } from '@/stores/permission'
import type { Permission } from '@murfy-package/api-client'
import { computed } from 'vue'

const props = defineProps<{
  /**
   * If One of the permissions is satisfied, the component will be shown.
   */
  permissions: Permission[]
}>()

const hasPermission = computed(() => {
  // 필요한 permissions을 설정하지 않았을 경우엔 항상 보여줌.
  if (props.permissions.length === 0) {
    return true
  }
  // 필요한 permissions 중 하나라도 만족하면 보여줌.
  const { checkPermission } = usePermission()
  return props.permissions.some((permission) => checkPermission(permission))
})
</script>

<template>
  <template v-if="hasPermission">
    <slot />
  </template>
</template>
