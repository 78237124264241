<script setup lang="ts">
// FIXME : MurDS Modal 컴포넌트 개발 후 수정 필요
import { TextInput } from '@murfy-package/murds'
import { BaseButton } from '@murfy-package/ui'
import PrimeVueDialog from 'primevue/dialog'
import { ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

type Props = {
  visible: boolean
  projectName: string
  isLoading?: boolean
  onCopy: (projectName: string) => void
}

type Emits = {
  cancel: []
}

const props = defineProps<Props>()
defineEmits<Emits>()

const { t } = useI18n()
const newProjectName = ref<string>('')

watch(
  () => props.projectName,
  (value) => {
    newProjectName.value = `${value} (copy)`
  },
)
</script>

<template>
  <PrimeVueDialog
    :visible="props.visible"
    :closable="false"
    :pt="{ footer: 'flex justify-center' }"
    modal
  >
    <template #header>
      <div class="flex flex-col gap-5">
        <span class="head-xl">{{ t('head') }}</span>
        <span class="body-md"> {{ t('message') }}</span>
      </div>
    </template>
    <TextInput v-model="newProjectName" class="w-full" :disabled="isLoading" />
    <template #footer>
      <BaseButton
        :label="t('cancelButton')"
        severity="tertiary"
        class="w-full"
        :disabled="isLoading"
        @click="$emit('cancel')"
      />
      <BaseButton
        :label="t('copyButton')"
        class="w-full"
        :loading="isLoading"
        @click="() => onCopy(newProjectName)"
      />
    </template>
  </PrimeVueDialog>
</template>

<i18n>
{
  "en": {
    "head": "Project Copy",
    "message": "Please set a name to copy the project.",
    "cancelButton": "Cancel",
    "copyButton": "Copy",
  }
}
</i18n>
