export const EDITOR_HEADER_HEIGHT_PIXEL = 64
export const ASK_AI_BUTTON_DELAY_MS = 500

// PDF Viewer
export const PDF_VIEWER_MAX_ZOOM = 1000
export const PDF_VIEWER_MIN_ZOOM = 10
export const PDF_VIEWER_ZOOM_STEP = 10
export const PDF_VIEWER_ZOOM_DEFAULT = 100
export const PDF_VIEWER_ZOOM_DEFAULT_SELECT = 'fit-width'
// PDF Viewer Rendering 간격 - 16ms, 렌더링 요청이 겹칠 때, 최소 이 간격 이후에 렌더링을 수행함
export const PDF_VIEWER_RENDERING_INTERVAL = 16
// 기본값을 -1로 설정하여, 초기화 여부를 확인할 수 있도록 함
export const PDF_VIEWER_INVALID_FLAG_VALUE_FOR_INITIALIZED_CHECK = -1
// PDF Viewer Page Index Number 시작 값
export const PDF_VIEWER_PAGE_INDEX_NUMBER_START_VALUE = 1
// PDF Viewer Page Index Number 증가/감소 단계 값
export const PDF_VIEWER_PAGE_INDEX_NUMBER_STEP = 1

// 이름 유효성 검사 정규식
export const MAX_NAME_LENGTH = 50

// Collaborative Edits Configuration
// Collaborative Edits 웹소켓 URL
export const COLLABORATIVE_EDITS_WS_URL =
  import.meta.env.VITE_APP_API_BASE_URL.replace(/^http/g, 'ws') + `/collaborate-rooms`

// invite link prefix
export const INVITE_EDITOR_LINK_PREFIX = import.meta.env.VITE_APP_BASE_URL + '/invite/'
export const INVITE_VIEWER_LINK_PREFIX = import.meta.env.VITE_APP_BASE_URL + '/invite/view-'
