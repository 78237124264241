import { LatexCompileRuleCode } from './codes'

export type LatexCompileRuleLevel = 'typesetting' | 'warning' | 'error'

export interface LatexCompileRule {
  code: LatexCompileRuleCode
  level: LatexCompileRuleLevel
  regexToMatch: RegExp
  package?: string
  types?: string[]
  cascadesFrom?: string[]
  newMessage?: string
  contentRegex?: RegExp
}

export const latexCompileRules: LatexCompileRule[] = [
  {
    code: LatexCompileRuleCode.MisplacedAlignmentTabCharacter,
    level: 'error',
    regexToMatch: /Misplaced alignment tab character &/,
  },
  {
    code: LatexCompileRuleCode.ExtraAlignmentTabHasBeenChangedToCr,
    level: 'error',
    regexToMatch: /Extra alignment tab has been changed to \\cr/,
  },
  {
    code: LatexCompileRuleCode.DisplayMathShouldEndWith,
    level: 'error',
    regexToMatch: /Display math should end with \$\$/,
  },
  {
    code: LatexCompileRuleCode.MissingInserted,
    level: 'error',
    regexToMatch: /Missing [{$] inserted\./,
  },
  {
    code: LatexCompileRuleCode.ReferenceUndefined,
    level: 'error',
    regexToMatch: /Reference.+undefined/,
  },
  {
    code: LatexCompileRuleCode.ThereWereUndefinedReferences,
    level: 'error',
    regexToMatch: /There were undefined references/,
  },
  {
    code: LatexCompileRuleCode.CitationOnPageUndefinedOnInputLine,
    level: 'error',
    regexToMatch: /Citation .+ on page .+ undefined on input line .+/,
  },
  {
    code: LatexCompileRuleCode.LabelMultiplyDefinedLabels,
    level: 'error',
    regexToMatch: /(Label .+)? multiply[ -]defined( labels)?/,
  },
  {
    code: LatexCompileRuleCode.NoPositionsInOptionalFloatSpecifier,
    level: 'warning',
    regexToMatch: /No positions in optional float specifier/,
  },
  {
    code: LatexCompileRuleCode.UndefinedControlSequence,
    level: 'error',
    regexToMatch: /Undefined control sequence/,
  },
  {
    code: LatexCompileRuleCode.UndefinedEnvironment,
    level: 'error',
    regexToMatch: /LaTeX Error: Environment .+ undefined/,
  },
  {
    code: LatexCompileRuleCode.FileNotFound,
    level: 'error',
    regexToMatch: /File .+ not found/,
  },
  {
    code: LatexCompileRuleCode.UnknownGraphicsExtension,
    level: 'error',
    regexToMatch: /LaTeX Error: Unknown graphics extension: \..+/,
  },
  {
    code: LatexCompileRuleCode.UnknownFloatOptionH,
    level: 'error',
    regexToMatch: /LaTeX Error: Unknown float option `H/,
  },
  {
    code: LatexCompileRuleCode.UnknownFloatOptionQ,
    level: 'error',
    regexToMatch: /LaTeX Error: Unknown float option `q/,
  },
  {
    code: LatexCompileRuleCode.MathAllowedOnlyInMathMode,
    level: 'error',
    regexToMatch: /LaTeX Error: \\math.+ allowed only in math mode/,
  },
  {
    code: LatexCompileRuleCode.MismatchedEnvironment,
    level: 'error',
    types: ['environment'],
    regexToMatch: /Error: `([^']{2,})' expected, found `([^']{2,})'.*/,
    newMessage: 'Error: environment does not match \\begin{$1} ... \\end{$2}',
  },
  {
    code: LatexCompileRuleCode.MismatchedBrackets,
    level: 'error',
    types: ['environment'],
    regexToMatch: /Error: `([^a-zA-Z0-9])' expected, found `([^a-zA-Z0-9])'.*/,
    newMessage: "Error: brackets do not match, found '$2' instead of '$1'",
  },
  {
    code: LatexCompileRuleCode.CanBeUsedOnlyInPreamble,
    level: 'error',
    regexToMatch: /LaTeX Error: Can be used only in preamble/,
  },
  {
    code: LatexCompileRuleCode.MissingRightInserted,
    level: 'error',
    regexToMatch: /Missing \\right inserted/,
  },
  {
    code: LatexCompileRuleCode.DoubleSuperscript,
    level: 'error',
    regexToMatch: /Double superscript/,
  },
  {
    code: LatexCompileRuleCode.DoubleSubscript,
    level: 'error',
    regexToMatch: /Double subscript/,
  },
  {
    code: LatexCompileRuleCode.NoAuthorGiven,
    level: 'error',
    regexToMatch: /No \\author given/,
  },
  {
    code: LatexCompileRuleCode.SomethingsWrongPerhapsAMissingItem,
    level: 'error',
    regexToMatch: /LaTeX Error: Something's wrong--perhaps a missing \\item/,
  },
  {
    code: LatexCompileRuleCode.MisplacedNoAlign,
    level: 'error',
    regexToMatch: /Misplaced \\noalign/,
  },
  {
    code: LatexCompileRuleCode.NoLineHereToEnd,
    level: 'error',
    regexToMatch: /LaTeX Error: There's no line here to end/,
  },
  {
    code: LatexCompileRuleCode.VerbEndedByEndOfLine,
    level: 'error',
    regexToMatch: /LaTeX Error: \\verb ended by end of line/,
  },
  {
    code: LatexCompileRuleCode.IllegalUnitOfMeasurePtInserted,
    level: 'error',
    regexToMatch: /Illegal unit of measure (pt inserted)/,
  },
  {
    code: LatexCompileRuleCode.ExtraRight,
    level: 'error',
    regexToMatch: /Extra \\right/,
  },
  {
    code: LatexCompileRuleCode.MissingBeginDocument,
    level: 'error',
    regexToMatch: /Missing \\begin{document}/,
  },
  {
    code: LatexCompileRuleCode.MismatchedEnvironment2,
    level: 'error',
    types: ['environment'],
    cascadesFrom: ['environment'],
    regexToMatch: /Error: `\\end\{([^}]+)\}' expected but found `\\end\{([^}]+)\}'.*/,
    newMessage: 'Error: environments do not match: \\begin{$1} ... \\end{$2}',
  },
  {
    code: LatexCompileRuleCode.MismatchedEnvironment3,
    level: 'warning',
    types: ['environment'],
    cascadesFrom: ['environment'],
    regexToMatch: /Warning: No matching \\end found for `\\begin\{([^}]+)\}'.*/,
    newMessage: 'Warning: No matching \\end found for \\begin{$1}',
  },
  {
    code: LatexCompileRuleCode.MismatchedEnvironment4,
    level: 'error',
    types: ['environment'],
    cascadesFrom: ['environment'],
    regexToMatch: /Error: Found `\\end\{([^}]+)\}' without corresponding \\begin.*/,
    newMessage: 'Error: found \\end{$1} without a corresponding \\begin{$1}',
  },
  {
    code: LatexCompileRuleCode.CharacterInvalidAtThisPoint,
    level: 'error',
    regexToMatch: /^Package calc error: `(.+?)' invalid at this point/,
    package: 'calc',
  },
  {
    code: LatexCompileRuleCode.CommandAllowedOnlyInMathMode,
    level: 'error',
    regexToMatch: /^Package amsmath error: (.+?) allowed only in math mode/,
    package: 'amsmath',
  },
  {
    code: LatexCompileRuleCode.CommandUndefined,
    level: 'error',
    regexToMatch: /^LaTeX error: (.+?) undefined/,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.LtInMidLine,
    level: 'error',
    regexToMatch: /^LaTeX error: \\< in mid line/,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.ABoxWasSupposedToBeHere,
    level: 'error',
    regexToMatch: /^A <Box> was supposed to be here/i, // note: can be <box>
    package: 'TeX',
  },
  {
    code: LatexCompileRuleCode.AccentNotProvidedByFontFamily,
    level: 'error',
    regexToMatch: /^Package textcomp error: Accent (.+?) not provided by font family (.+)/,
    package: 'textcomp',
  },
  {
    code: LatexCompileRuleCode.ArgumentOfHasAnExtra,
    level: 'error',
    regexToMatch: /^Argument of (.+?) has an extra }/,
    package: 'TeX',
  },
  {
    code: LatexCompileRuleCode.BadLineOrVectorArgument,
    level: 'error',
    regexToMatch: /^LaTeX error: Bad \\line or \\vector argument/,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.BadMathEnvironmentDelimiter,
    level: 'error',
    regexToMatch: /^LaTeX error: Bad math environment delimiter/,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.BeginEnvAllowedOnlyInParagraphMode,
    level: 'error',
    regexToMatch: /^Package amsmath error: \\begin{(.+?)} allowed only in paragraph mode/,
    package: 'amsmath',
  },
  {
    code: LatexCompileRuleCode.BeginEnvOnInputLineXEndedByEndX,
    level: 'error',
    regexToMatch: /^LaTeX error: \\begin{(.+?)} on input line (.+?) ended by \\end{(.+?)}/,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.BeginSplitWontWorkHere,
    level: 'error',
    regexToMatch: /^Package amsmath error: \\begin{split} won't work here/,
    package: 'amsmath',
  },
  {
    code: LatexCompileRuleCode.CannotBeUsedInPreamble,
    level: 'error',
    regexToMatch: /^LaTeX error: Cannot be used in preamble/,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.CannotDefineUnicodeCharValueLt00A0,
    level: 'error',
    regexToMatch: /^Package inputenc error: Cannot define Unicode char value < 00A0/,
    package: 'inputenc',
  },
  {
    code: LatexCompileRuleCode.CannotDetermineSizeOfGraphicInFile,
    level: 'error',
    regexToMatch: /^Package (graphics|graphicx) error: Cannot determine size of graphic in (.+)/,
    package: 'graphics/graphicx',
  },
  {
    code: LatexCompileRuleCode.CannotIncludeGraphicsOfType,
    level: 'error',
    regexToMatch: /^Package (graphics|graphicx) error: Cannot include graphics of type\s*: (.+)/,
    package: 'graphics/graphicx',
  },
  {
    code: LatexCompileRuleCode.CaptionOutsideFloat,
    level: 'error',
    regexToMatch: /^LaTeX error: \\caption outside float/,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.CommandAlreadyDefined,
    level: 'error',
    regexToMatch: /^LaTeX error: Command (.+?) already defined/,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.CommandInvalidInMathMode,
    level: 'error',
    regexToMatch: /^LaTeX error: Command (.+?) invalid in math mode/,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.CommandNotDefinedAsAMathAlphabet,
    level: 'error',
    regexToMatch: /^LaTeX error: Command (.+?) not defined as a math alphabet/,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.CorruptedNfsTables,
    level: 'error',
    regexToMatch: /^LaTeX error: Corrupted NFSS tables/,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.CounterTooLarge,
    level: 'error',
    regexToMatch: /^LaTeX error: Counter too large/,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.DimensionTooLarge,
    level: 'error',
    regexToMatch: /^Dimension too large/,
    package: 'TeX',
  },
  {
    code: LatexCompileRuleCode.DisplayBreakCannotBeAppliedHere,
    level: 'error',
    regexToMatch: /^Package amsmath error: \\displaybreak cannot be applied here/,
    package: 'amsmath',
  },
  {
    code: LatexCompileRuleCode.DivisionBy0,
    level: 'error',
    regexToMatch: /^Package (graphics|graphicx) error: Division by 0/,
    package: 'graphics/graphicx',
  },
  {
    code: LatexCompileRuleCode.EncodingFileNotFound,
    level: 'error',
    regexToMatch: /^Package fontenc error: Encoding file `(.+?)' not found/,
    package: 'fontenc',
  },
  {
    code: LatexCompileRuleCode.EncodingSchemeUnknown,
    level: 'error',
    regexToMatch: /^LaTeX error: Encoding scheme `(.+?)' unknown/,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.EnvironmentUndefined,
    level: 'error',
    regexToMatch: /^LaTeX error: Environment (.+?) undefined/,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.ErroneousNestingOfEquationStructures,
    level: 'error',
    regexToMatch: /^Package amsmath error: Erroneous nesting of equation structures/,
    package: 'amsmath',
  },
  {
    code: LatexCompileRuleCode.ExtraOnThisLine,
    level: 'error',
    regexToMatch: /^Package amsmath error: Extra & on this line/,
    package: 'amsmath',
  },
  {
    code: LatexCompileRuleCode.ExtraEndGroup,
    level: 'error',
    regexToMatch: /^Extra \\endgroup/,
    package: 'TeX',
  },
  {
    code: LatexCompileRuleCode.ExtraOr,
    level: 'error',
    regexToMatch: /^Extra \\or/,
    package: 'TeX',
  },
  {
    code: LatexCompileRuleCode.ExtraOrForgotten,
    level: 'error',
    regexToMatch: /^Extra }, or forgotten \$/,
    package: 'TeX',
  },
  {
    code: LatexCompileRuleCode.ExtraOrForgottenEndGroup,
    level: 'error',
    regexToMatch: /^Extra }, or forgotten \\endgroup/,
    package: 'TeX',
  },
  {
    code: LatexCompileRuleCode.FileEndedWhileScanning,
    level: 'error',
    regexToMatch: /^File ended while scanning (.+)/,
    package: 'TeX',
  },
  {
    code: LatexCompileRuleCode.FloatLost,
    level: 'error',
    regexToMatch: /^LaTeX error: Float\(s\) lost/,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.FontFamilyUnknown,
    level: 'error',
    regexToMatch: /^LaTeX error: Font family `(.+?)\+(.+?)' unknown/,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.FontNotFound,
    level: 'error',
    regexToMatch: /^LaTeX error: Font (.+?) not found/,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.FontNotLoadedNotEnoughRoomLeft,
    level: 'error',
    regexToMatch: /^Font (.+?)=(.+?) not loaded: Not enough room left/,
    package: 'TeX',
  },
  {
    code: LatexCompileRuleCode.FontShapeNotFound,
    level: 'error',
    regexToMatch: /^LaTeX error: Font shape (.+?) not found/,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.ICantFindFile,
    level: 'error',
    regexToMatch: /^I can't find file `(.+?)'/,
    package: 'TeX',
  },
  {
    code: LatexCompileRuleCode.ICantWriteOnFile,
    level: 'error',
    regexToMatch: /^I can't write on file `(.+?)'/,
    package: 'TeX',
  },
  {
    code: LatexCompileRuleCode.IllegalCharacterInArrayArg,
    level: 'error',
    regexToMatch: /^LaTeX error: Illegal character in array arg/,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.IllegalParameterNumberInDefinitionOf,
    level: 'error',
    regexToMatch: /^Illegal parameter number in definition of (.+)/,
    package: 'TeX',
  },
  {
    code: LatexCompileRuleCode.ImproperArgumentForMathAccent,
    level: 'error',
    regexToMatch: /^Package amsmath error: Improper argument for math accent/,
    package: 'amsmath',
  },
  {
    code: LatexCompileRuleCode.ImproperDiscretionaryList,
    level: 'error',
    regexToMatch: /^Improper discretionary list/,
    package: 'TeX',
  },
  {
    code: LatexCompileRuleCode.ImproperHyphenation,
    level: 'error',
    regexToMatch: /^Improper \\hyphenation/,
    package: 'TeX',
  },
  {
    code: LatexCompileRuleCode.ImproperPrevDepth,
    level: 'error',
    regexToMatch: /^Improper \\prevdepth/,
    package: 'TeX',
  },
  {
    code: LatexCompileRuleCode.ImproperSpaceFactor,
    level: 'error',
    regexToMatch: /^Improper \\spacefactor/,
    package: 'TeX',
  },
  {
    code: LatexCompileRuleCode.IncludeCannotBeNested,
    level: 'error',
    regexToMatch: /^LaTeX error: \\include cannot be nested/,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.IncompatibleListCantBeUnboxed,
    level: 'error',
    regexToMatch: /^Incompatible list can't be unboxed/,
    package: 'TeX',
  },
  {
    code: LatexCompileRuleCode.IncompleteAllTextWasIgnoredAfterLine,
    level: 'error',
    regexToMatch: /^Incomplete (.+?); all text was ignored after line (.+)/,
    package: 'TeX',
  },
  {
    code: LatexCompileRuleCode.InfiniteGlueShrinkageFound,
    level: 'error',
    regexToMatch: /^Infinite glue shrinkage found (.+)/,
    package: 'TeX',
  },
  {
    code: LatexCompileRuleCode.Interruption,
    level: 'error',
    regexToMatch: /^Interruption/,
    package: 'TeX',
  },
  {
    code: LatexCompileRuleCode.InvalidUseOfCommand,
    level: 'error',
    regexToMatch: /^Package amsmath error: Invalid use of (.+)/,
    package: 'amsmath',
  },
  {
    code: LatexCompileRuleCode.KeyboardCharacterUsedIsUndefinedInInputEncoding,
    level: 'error',
    regexToMatch:
      /^Package inputenc error: Keyboard character used is undefined in input encoding (.+)/,
    package: 'inputenc',
  },
  {
    code: LatexCompileRuleCode.LanguageDefinitionFileNotFound,
    level: 'error',
    regexToMatch: /^Package babel error: Language definition file (.+?)\.ldf not found /,
    package: 'babel',
  },
  {
    code: LatexCompileRuleCode.LimitControlsMustFollowAMathOperator,
    level: 'error',
    regexToMatch: /^Limit controls must follow a math operator/,
    package: 'TeX',
  },
  {
    code: LatexCompileRuleCode.LoadClassInPackageFile,
    level: 'error',
    regexToMatch: /^LaTeX error: \\LoadClass in package file/,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.LonelyItemPerhapsAMissingListEnvironment,
    level: 'error',
    regexToMatch: /^LaTeX error: Lonely \\item--perhaps a missing list environment/,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.MathAlphabetIdentifierIsUndefinedInMathVersion,
    level: 'error',
    regexToMatch: /^LaTeX error: Math alphabet identifier (.+?) is undefined in math version (.+)/,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.MathVersionIsNotDefined,
    level: 'error',
    regexToMatch: /^LaTeX error: Math version (.+?) is not defined/,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.MisplacedCr,
    level: 'error',
    regexToMatch: /^Misplaced \\cr/,
    package: 'TeX',
  },
  {
    code: LatexCompileRuleCode.MisplacedCrCr,
    level: 'error',
    regexToMatch: /^Misplaced \\crcr/,
    package: 'TeX',
  },
  {
    code: LatexCompileRuleCode.MisplacedOmit,
    level: 'error',
    regexToMatch: /^Misplaced \\omit/,
    package: 'TeX',
  },
  {
    code: LatexCompileRuleCode.MissingControlSequenceInserted,
    level: 'error',
    regexToMatch: /^Missing control sequence inserted/,
    package: 'TeX',
  },
  {
    code: LatexCompileRuleCode.MissingCrInserted,
    level: 'error',
    regexToMatch: /^Missing \\cr inserted/,
    package: 'TeX',
  },
  {
    code: LatexCompileRuleCode.MissingDelimiterInserted,
    level: 'error',
    regexToMatch: /^Missing delimiter \(. inserted\)/,
    package: 'TeX',
  },
  {
    code: LatexCompileRuleCode.MissingEndCsNameInserted,
    level: 'error',
    regexToMatch: /^Missing \\endcsname inserted/,
    package: 'TeX',
  },
  {
    code: LatexCompileRuleCode.MissingNumberTreatedAsZero,
    level: 'error',
    regexToMatch: /^Missing number, treated as zero/,
    package: 'TeX',
  },
  {
    code: LatexCompileRuleCode.MissingPArgInArrayArg,
    level: 'error',
    regexToMatch: /^LaTeX error: Missing p-arg in array arg/,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.MissingExpInArrayArg,
    level: 'error',
    regexToMatch: /^LaTeX error: Missing @-exp in array arg/,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.MissingInsertedInAlignmentPreamble,
    level: 'error',
    regexToMatch: /^Missing # inserted in alignment preamble/,
    package: 'TeX',
  },
  {
    code: LatexCompileRuleCode.MissingInsertedForIfNum,
    level: 'error',
    regexToMatch: /^Missing = inserted for \\ifnum/,
    package: 'TeX',
  },
  {
    code: LatexCompileRuleCode.MissingInsertedForIfDim,
    level: 'error',
    regexToMatch: /^Missing = inserted for \\ifdim/,
    package: 'TeX',
  },
  {
    code: LatexCompileRuleCode.MissingEndGroupInserted,
    level: 'error',
    regexToMatch: /^Missing \\endgroup inserted/,
    package: 'TeX',
  },
  {
    code: LatexCompileRuleCode.MultipleLabelsLabelTiquetteWillBeLost,
    level: 'error',
    regexToMatch: /^Package amsmath error: Multiple \\label's: label (.+?) will be lost/,
    package: 'amsmath',
  },
  {
    code: LatexCompileRuleCode.MultipleTag,
    level: 'error',
    regexToMatch: /^Package amsmath error: Multiple \\tag/,
    package: 'amsmath',
  },
  {
    code: LatexCompileRuleCode.NoCounterDefined,
    level: 'error',
    regexToMatch: /^LaTeX error: No counter '(.+?)' defined/,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.NoCyrillicEncodingDefinitionFilesWereFound,
    level: 'error',
    regexToMatch: /^Package babel error: No Cyrillic encoding definition files were found/,
    package: 'babel',
  },
  {
    code: LatexCompileRuleCode.NoDeclarationForShape,
    level: 'error',
    regexToMatch: /^LaTeX error: No declaration for shape (.+)/,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.NoDriverSpecified,
    level: 'error',
    regexToMatch: /^Package (color|graphics|graphicx) error: No driver specified/,
    package: 'color/graphics/graphicx',
  },
  {
    code: LatexCompileRuleCode.NoRoomForANewRegister,
    level: 'error',
    regexToMatch: /^No room for a new (.+)/,
    package: 'TeX',
  },
  {
    code: LatexCompileRuleCode.NoTitleGiven,
    level: 'error',
    regexToMatch: /^LaTeX error: No \\title given/,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.NotALetter,
    level: 'error',
    regexToMatch: /^Not a letter/,
    package: 'TeX',
  },
  {
    code: LatexCompileRuleCode.NotInOuterParMode,
    level: 'error',
    regexToMatch: /^LaTeX error: Not in outer par mode/,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.NumberTooBig,
    level: 'error',
    regexToMatch: /^Number too big/,
    package: 'TeX',
  },
  {
    code: LatexCompileRuleCode.OkSeeTheTranscriptFile,
    level: 'warning',
    regexToMatch: /^OK \(see the transcript file\)/,
    package: 'TeX',
  },
  {
    code: LatexCompileRuleCode.OldFormShouldBeBegin,
    level: 'error',
    regexToMatch: /^Package amsmath error: Old form (.+?) should be \\begin{(.+?)} /,
    package: 'amsmath',
  },
  {
    code: LatexCompileRuleCode.OnlyOneIsAllowedPerTab,
    level: 'error',
    regexToMatch: /^Only one # is allowed per tab/,
    package: 'TeX',
  },
  {
    code: LatexCompileRuleCode.OptionClashForPackage,
    level: 'error',
    regexToMatch: /^LaTeX error: Option clash for package (.+)/,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.PageHeightAlreadyTooLarge,
    level: 'error',
    regexToMatch: /^LaTeX error: Page height already too large/,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.ParagraphEndedBeforeCommandWasComplete,
    level: 'error',
    regexToMatch: /^Paragraph ended before (.+?) was complete/,
    package: 'TeX',
  },
  {
    code: LatexCompileRuleCode.PleaseTypeACommandOrSayEnd,
    level: 'warning',
    regexToMatch: /^\(Please type a command or say `\\end'\)/,
    package: 'TeX',
  },
  {
    code: LatexCompileRuleCode.PushtabsAndPoptabsDontMatch,
    level: 'error',
    regexToMatch: /^LaTeX error: \\pushtabs and \\poptabs don't match/,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.RequirepackageOrLoadclassInOptionsSection,
    level: 'error',
    regexToMatch: /^LaTeX error: \\RequirePackage or \\LoadClass in Options Section/,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.RotationNotSupported,
    level: 'error',
    regexToMatch: /^Package (graphics|graphicx) error: Rotation not supported/,
    package: 'graphics/graphicx',
  },
  {
    code: LatexCompileRuleCode.Runaway,
    level: 'error',
    regexToMatch: /^Runaway (.+)/,
    package: 'TeX',
  },
  {
    code: LatexCompileRuleCode.YouHaventSpecifiedALanguageOption,
    level: 'error',
    regexToMatch: /^Package babel error: You haven't specified a language option/,
    package: 'babel',
  },
  {
    code: LatexCompileRuleCode.CalculatingMathSizesForSize,
    level: 'warning',
    regexToMatch: /^LaTeX (Font )?(Warning|Info): Calculating math sizes for size (.+)/,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.CheckingDefaultsFor,
    level: 'warning',
    regexToMatch: /^LaTeX (Font )?(Warning|Info): Checking defaults for (.+)/,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.CitationOnPageUndefined,
    level: 'warning',
    regexToMatch: /^LaTeX (Font )?(Warning|Info): Citation `(.+?)' on page (.+?) undefined/,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.CommandInvalidInMathMode,
    level: 'warning',
    regexToMatch: /^LaTeX (Font )?(Warning|Info): Command (.+?) invalid in math mode/,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.DocumentClass,
    level: 'warning',
    regexToMatch: /^LaTeX (Font )?(Warning|Info): Document Class\s*: (.+)/,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.EmptyThebibliographyEnvironment,
    level: 'warning',
    regexToMatch: /^LaTeX (Font )?(Warning|Info): Empty `thebibliography' environment/,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.EncodingHasChangedToFor,
    level: 'warning',
    regexToMatch: /^LaTeX (Font )?(Warning|Info): Encoding (.+?) has changed to (.+?) for/,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.EndOccurredInsideAGroupAtLevel,
    level: 'warning',
    regexToMatch: /^\(\\end occurred inside a group at level (.+)\)/,
    package: 'TeX',
  },
  {
    code: LatexCompileRuleCode.WhenConditionOnLineWasIncomplete,
    level: 'warning',
    regexToMatch: /^\(\\end occurred when (.+?) on line (.+) was incomplete\)/,
    package: 'TeX',
  },
  {
    code: LatexCompileRuleCode.ExternalFontLoadedForSize,
    level: 'warning',
    regexToMatch: /^LaTeX (Font )?(Warning|Info): External font (.+?) loaded for size (.+)/,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.FakingForFontFamilyInTs1Encoding,
    level: 'warning',
    regexToMatch:
      /^Package TeXtcomp (Warning|Info): Faking (.+?) for font family (.+?) in TS1 encoding/,
    package: 'TeXtcomp',
  },
  {
    code: LatexCompileRuleCode.FileAlreadyExistsOnTheSystemNotGeneratingItFromThisSource,
    level: 'warning',
    regexToMatch:
      /^LaTeX (Font )?(Warning|Info): File `(.+?)' already exists on the system\.\nNot generating it from this source\./,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.FileFontDefinition,
    level: 'warning',
    regexToMatch: /^LaTeX (Font )?(Warning|Info): File\s*: (.+?)\.fd (.+)/,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.FloatTooLargeForPageBy,
    level: 'warning',
    regexToMatch: /^LaTeX (Font )?(Warning|Info): Float too large for page by (.+)/,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.FontShapeInSizeNotAvailableExternalFontUsed,
    level: 'warning',
    regexToMatch:
      /^LaTeX (Font )?(Warning|Info): Font shape (.+?) in size (.+?) not available\nexternal font (.+?) used/,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.FontShapeInSizeNotAvailableSizeSubstituted,
    level: 'warning',
    regexToMatch:
      /^LaTeX (Font )?(Warning|Info): Font shape (.+?) in size (.+?) not available\nsize (.+?) substituted/,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.FontShapeInSizeNotAvailableShapeFormeTried,
    level: 'warning',
    regexToMatch:
      /^LaTeX (Font )?(Warning|Info): Font shape (.+?) in size (.+?) not available\nshape (.+?) tried/,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.FontShapeFormeUndefinedUsingAutreFormeInstead,
    level: 'warning',
    regexToMatch:
      /^LaTeX (Font )?(Warning|Info): Font shape (.+?) undefined\. Using `(.+?)' instead/,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.FontShapeFormeWillBeScaledToSizeTaille,
    level: 'warning',
    regexToMatch: /^LaTeX (Font )?(Warning|Info): Font shape (.+?) will be scaled to size (.+)/,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.ForeignCommand,
    level: 'warning',
    regexToMatch: /^Package amsmath (Warning|Info): Foreign command (.+?);/,
    package: 'amsmath',
  },
  {
    code: LatexCompileRuleCode.FormFeedHasBeenConvertedToBlankLine,
    level: 'warning',
    regexToMatch: /^LaTeX (Font )?(Warning|Info): Form feed has been converted to Blank Line/,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.FloatSpecifierChanged,
    level: 'warning',
    regexToMatch: /^LaTeX (Font )?(Warning|Info): `(.+?)' float specifier changed to `(.+?)'/,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.IgnoringTextAfterEnd,
    level: 'warning',
    regexToMatch: /^LaTeX (Font )?(Warning|Info): Ignoring text `(.+?)' after \\end{(.+?)}/,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.LabelMultiplyDefined,
    level: 'warning',
    regexToMatch: /^LaTeX (Font )?(Warning|Info): Label `(.+?)' multiply defined/,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.LabelsMayHaveChangedRerunToGetCrossReferencesRight,
    level: 'warning',
    regexToMatch:
      /^LaTeX (Font )?(Warning|Info): Label\(s\) may have changed\. Rerun to get cross-references right\./,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.LooseHboxBadness,
    level: 'warning',
    regexToMatch: /^Loose \\hbox \(badness (.+?)\) (.+)/,
    package: 'TeX',
  },
  {
    code: LatexCompileRuleCode.LooseVboxBadness,
    level: 'warning',
    regexToMatch: /^Loose \\vbox \(badness (.+?)\) (.+)/,
    package: 'TeX',
  },
  {
    code: LatexCompileRuleCode.MakingAnActiveCharacter,
    level: 'warning',
    regexToMatch: /^Package babel (Warning|Info): Making (.+?) an active character/,
    package: 'babel',
  },
  {
    code: LatexCompileRuleCode.MarginparOnPageMoved,
    level: 'warning',
    regexToMatch: /^LaTeX (Font )?(Warning|Info): Marginpar on page (.+?) moved/,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.MissingCharacterThereIsNoInFont,
    level: 'warning',
    regexToMatch: /^Missing character\s*: There is no (.+?) in font (.+?)!/,
    package: 'TeX',
  },
  {
    code: LatexCompileRuleCode.NoAuxiliaryOutputFiles,
    level: 'warning',
    regexToMatch: /^LaTeX (Font )?(Warning|Info): No auxiliary output files/,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.NoCharactersDefinedByInputEncodingChangeTo,
    level: 'warning',
    regexToMatch:
      /^LaTeX (Font )?(Warning|Info): No characters defined by input encoding change to (.+)/,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.NoFile,
    level: 'warning',
    regexToMatch: /^LaTeX (Font )?(Warning|Info): No file (.+)/,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.NoHyphenationPatternsWereLoadedForTheLanguage,
    level: 'warning',
    regexToMatch:
      /^Package babel (Warning|Info): No hyphenation patterns were loaded for the language `(.+?)'/,
    package: 'babel',
  },
  {
    code: LatexCompileRuleCode.NoInputEncodingSpecifiedForLanguage,
    level: 'warning',
    regexToMatch: /^Package babel (Warning|Info): No input encoding specified for (.+?) language/,
    package: 'babel',
  },
  {
    code: LatexCompileRuleCode.OldstyleDigitsUnavailableForFamily,
    level: 'warning',
    regexToMatch: /^LaTeX (Font )?(Warning|Info): Oldstyle digits unavailable for family (.+)/,
    package: 'Textcomp',
  },
  {
    code: LatexCompileRuleCode.OptionalArgumentOfTwocolumnTooTallOnPage,
    level: 'warning',
    regexToMatch:
      /^LaTeX (Font )?(Warning|Info): Optional argument of \\twocolumn too tall on page (.+)/,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.OvalCircleOrLineSizeUnavailable,
    level: 'warning',
    regexToMatch: /^LaTeX (Font )?(Warning|Info): \\oval, \\circle, or \\line size unavailable/,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.OverfullHboxTooWideQuelquePart,
    level: 'warning',
    regexToMatch: /^Overfull \\hbox \((.+?)pt too wide\) (.+)/,
    package: 'TeX',
  },
  {
    code: LatexCompileRuleCode.OverfullVboxPtTooWide,
    level: 'warning',
    regexToMatch: /^Overfull \\vbox \((.+?)pt too wide\)\) (.+)/,
    package: 'TeX',
  },
  {
    code: LatexCompileRuleCode.OverwritingEncodingSchemeQuelqueChoSeDefaults,
    level: 'warning',
    regexToMatch: /^LaTeX (Font )?(Warning|Info): Overwriting encoding scheme (.+?) defaults/,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.OverwritingInVersion,
    level: 'warning',
    regexToMatch: /^LaTeX (Font )?(Warning|Info): Overwriting (.+?) in version `(.+)'/,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.Package,
    level: 'warning',
    regexToMatch: /^LaTeX (Font )?(Warning|Info): Package: (.+)/,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.RedeclaringFontEncoding,
    level: 'warning',
    regexToMatch: /^LaTeX (Font )?(Warning|Info): Redeclaring font encoding (.+)/,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.RedeclaringMathAccent,
    level: 'warning',
    regexToMatch: /^LaTeX (Font )?(Warning|Info): Redeclaring math accent (.+)/,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.RedeclaringMathAlphabet,
    level: 'warning',
    regexToMatch: /^LaTeX (Font )?(Warning|Info): Redeclaring math alphabet (.+)/,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.RedeclaringMathSymbol,
    level: 'warning',
    regexToMatch: /^LaTeX (Font )?(Warning|Info): Redeclaring math symbol (.+)/,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.RedeclaringMathVersion,
    level: 'warning',
    regexToMatch: /^LaTeX (Font )?(Warning|Info): Redeclaring math version (.+)/,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.RedeclaringSymbolFont,
    level: 'warning',
    regexToMatch: /^LaTeX (Font )?(Warning|Info): Redeclaring symbol font (.+)/,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.ReferenceOnPageUndefined,
    level: 'warning',
    regexToMatch: /^LaTeX (Font )?(Warning|Info): Reference `(.+?)' on page (.+?) undefined/,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.SizeSubstitutionsWithDifferencesUpToHaveOccurred,
    level: 'warning',
    regexToMatch:
      /^LaTeX (Font )?(Warning|Info): Size substitutions with differences up to (.+?) have occurred/,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.SomeFontShapesWereNotAvailableDefaultsSubstituted,
    level: 'warning',
    regexToMatch:
      /^LaTeX (Font )?(Warning|Info): Some font shapes were not available, defaults substituted/,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.TabHasBeenConvertedToBlankSpace,
    level: 'warning',
    regexToMatch: /^LaTeX (Font )?(Warning|Info): Tab has been converted to Blank Space/,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.TextPageContainsOnlyFloats,
    level: 'warning',
    regexToMatch: /^LaTeX (Font )?(Warning|Info): text page (.+?) contains only floats/,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.ThereWereMultiplyDefinedLabels,
    level: 'warning',
    regexToMatch: /^LaTeX (Font )?(Warning|Info): There were multiply-defined labels/,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.TightHboxBadness,
    level: 'warning',
    regexToMatch: /^Tight \\hbox \(badness (.+?)\) (.+)/,
    package: 'TeX',
  },
  {
    code: LatexCompileRuleCode.TightVboxBadness,
    level: 'warning',
    regexToMatch: /^Tight \\vbox \(badness (.+?)\) (.+)/,
    package: 'TeX',
  },
  {
    code: LatexCompileRuleCode.TryLoadingFontInformationFor,
    level: 'warning',
    regexToMatch: /^LaTeX (Font )?(Warning|Info): Try loading font information for (.+?)\+(.+)/,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.UnableToRedefineMathAccent,
    level: 'warning',
    regexToMatch: /^Package amsmath (Warning|Info): Unable to redefine math accent (.+)/,
    package: 'amsmath',
  },
  {
    code: LatexCompileRuleCode.HboxBadnessDetectedAtLine,
    level: 'warning',
    regexToMatch: /^Underfull \\hbox \(badness (.+?)\) (.+?) detected at line (.+)/,
    package: 'TeX',
  },
  {
    code: LatexCompileRuleCode.HboxBadnessHasOccurredWhileOutputIsActive,
    level: 'warning',
    regexToMatch: /^Underfull \\hbox \(badness (.+?)\) (.+?) has occurred while \\output is active/,
    package: 'TeX',
  },
  {
    code: LatexCompileRuleCode.HboxBadnessInAlignmentAtLines,
    level: 'warning',
    regexToMatch: /^Underfull \\hbox \(badness (.+?)\) (.+?) in alignment at lines (.+)/,
    package: 'TeX',
  },
  {
    code: LatexCompileRuleCode.HboxBadnessInParagraphAtLines,
    level: 'warning',
    regexToMatch: /^Underfull \\hbox \(badness (.+?)\) (.+?) in paragraph at lines (.+)/,
    package: 'TeX',
  },
  {
    code: LatexCompileRuleCode.VboxBadnessDetectedAtLine,
    level: 'warning',
    regexToMatch: /^Underfull \\vbox \(badness (.+?)\) (.+?) detected at line (.+)/,
    package: 'TeX',
  },
  {
    code: LatexCompileRuleCode.VboxBadnessHasOccurredWhileOutputIsActive,
    level: 'warning',
    regexToMatch: /^Underfull \\vbox \(badness (.+?)\) (.+?) has occurred while \\output is active/,
    package: 'TeX',
  },
  {
    code: LatexCompileRuleCode.VboxBadnessInAlignmentAtLines,
    level: 'warning',
    regexToMatch: /^Underfull \\vbox \(badness (.+?)\) (.+?) in alignment at lines (.+)/,
    package: 'TeX',
  },
  {
    code: LatexCompileRuleCode.UnusedGlobalOptions,
    level: 'warning',
    regexToMatch: /^LaTeX (Font )?(Warning|Info): Unused global option\(s\)\s*: \[(.+?)]/,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.WritingFile,
    level: 'warning',
    regexToMatch: /^LaTeX (Font )?(Warning|Info): Writing file `(.+?)'/,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.WritingTextBeforeEndAsLastLineOf,
    level: 'warning',
    regexToMatch:
      /^LaTeX (Font )?(Warning|Info): Writing text `(.+?)' before \\end{(.+?)} as last line of (.+)/,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.YouHaveMoreThanOnceSelectedTheAttributeForLanguage,
    level: 'warning',
    regexToMatch:
      /^Package babel (Warning|Info): You have more than once selected the attribute `(.+?)' for language (.+)/,
    package: 'babel',
  },
  {
    code: LatexCompileRuleCode.YouHaveRequestedPackageOrClassButThePackageOrClassProvides,
    level: 'warning',
    regexToMatch:
      /^LaTeX (Font )?(Warning|Info): You have requested (package|class) `(.+?)', but the (package|class) provides `(.+?)'/,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.YouHaveRequestedReleaseDateOfLatexButOnlyReleaseAncienneDateIsAvailable,
    level: 'warning',
    regexToMatch:
      /^LaTeX (Font )?(Warning|Info): You have requested release `(.+?)' of LaTeX, but only release `(.+?)' is available/,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.YouHaveRequestedOnLineVersionOfButOnlyVersionIsAvailable,
    level: 'warning',
    regexToMatch:
      /^LaTeX (Font )?(Warning|Info): You have requested, on line (.+?), version `(.+?)' of (.+?), but only version `(.+?)' is available/,
    package: 'LaTeX',
  },
  {
    code: LatexCompileRuleCode.HboxWarning,
    level: 'warning',
    regexToMatch: /^(Over|Under)full \\(v|h)box/,
  },
  {
    code: LatexCompileRuleCode.NoPdfError,
    level: 'error',
    regexToMatch: /no output PDF file produced/,
  },
  {
    code: LatexCompileRuleCode.UncaughtError,
    level: 'error',
    regexToMatch: /^! (.+)/,
  },
  {
    code: LatexCompileRuleCode.UncaughtLatexWarning,
    level: 'warning',
    regexToMatch: /^LaTeX(?:3| Font)? Warning: (.*)$/,
  },
  {
    code: LatexCompileRuleCode.UncaughtPackageWarning,
    level: 'warning',
    regexToMatch: /^((?:Package|Class|Module) \b.+\b Warning:.*)$/,
  },
  {
    code: LatexCompileRuleCode.UncaughtPdftexWarning,
    level: 'warning',
    regexToMatch: /^pdfTeX warning/,
  },
]

export const matchedRule = (line: string) =>
  latexCompileRules.find((rule) => rule.regexToMatch.test(line))
