<script setup>
import { useI18n } from 'vue-i18n'
import PrimeVueDialog from 'primevue/dialog'
import ProgressSpinner from 'primevue/progressspinner'
import FileUploadDragOrBrowse from '../FileUploadDragOrBrowse.vue'
import { BaseButton } from '@murfy-package/ui'
const { t } = useI18n()
const emit = defineEmits(['close', 'upload'])
const onUpload = (event) => {
  emit('upload', event)
}
</script>
<script>
/* eslint-disable vue/component-api-style */
export default {
  components: {
    FileUploadDragOrBrowse,
  },
  /* FIXME: props 타입 정의 필요 */
  /* eslint-disable-next-line vue/require-prop-types */
  props: ['controller'],

  beforeMount() {
    this.controller.on('open', this.onOpen)
  },
  beforeUnmount() {
    this.controller.off('open', this.onOpen)
  },
  methods: {
    onOpen() {
      // FIXME: eslint-disable vue/no-mutating-props
      /* eslint-disable vue/no-mutating-props */
      this.controller.isUploading = false
      this.controller.isUnsupportedFile = false
    },
  },
}
</script>

<template>
  <PrimeVueDialog
    :visible="controller.visible"
    modal
    :closable="!controller.isUploading && !controller.isUnsupportedFile"
    :pt="{
      footer: $style.footer,
    }"
    @update:visible="$emit($event ? 'open' : 'close')"
  >
    <template #header>
      <div
        class="h2 gray-9 flex w-full items-center"
        :class="{ 'justify-center': controller.isUploading }"
      >
        <span v-if="controller.isUnsupportedFile">
          {{ t('error.header') }}
        </span>
        <span v-else-if="controller.isUploading">
          {{ t('loading.header') }}
        </span>
        <span v-else>
          {{ t('header') }}
        </span>
      </div>
    </template>
    <template #default>
      <div class="flex w-full flex-col gap-2">
        <span v-if="controller.isUnsupportedFile">
          {{ t('error.content') }}
        </span>
        <span v-else>
          <span v-if="controller.isUploading" class="flex justify-center">
            {{ t('loading.content') }}
          </span>
          <span v-else class="flex flex-col gap-2">
            <span class="p2 gray-7">{{ t('content') }}</span>
            <span class="p2 gray-7">
              {{ t('format', { formats: '*.tex, *.zip' }) }}
            </span>
          </span>
        </span>
      </div>
    </template>
    <template #footer>
      <span v-if="controller.isUnsupportedFile">
        <BaseButton :class="$style.modalButton" @click="$emit('close')">
          <div class="flex w-full flex-wrap justify-center">
            <span class="h4">{{ t('error.confirm') }}</span>
          </div>
        </BaseButton>
      </span>
      <template v-else>
        <template v-if="controller.isUploading">
          <div :class="$style.spinnerContainer">
            <ProgressSpinner
              style="width: 64px; height: 64px"
              strokeWidth="4"
              fill="var(--gray-bg)"
              :pt="{
                circle: $style.spinnerCircle,
              }"
            />
          </div>
          <BaseButton
            class="mt-4 w-4/12 justify-center"
            severity="secondary"
            @click="$emit('close')"
          >
            {{ t('cancel') }}
          </BaseButton>
        </template>
        <template v-else>
          <div class="flex w-full items-center justify-center">
            <FileUploadDragOrBrowse :accept="'.zip,.tex'" @upload="onUpload" />
          </div>
        </template>
      </template>
    </template>
  </PrimeVueDialog>
</template>

<style module>
.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 210px;
}
.spinnerContainer {
  padding-bottom: 8px;
}
.spinnerCircle {
  animation: progress-spinner-dash 1.5s ease-in-out infinite;
  stroke: var(--cta-primary);
}
@keyframes progress-spinner-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}
.modalButton {
  width: 100%;
  margin: 0;
}
</style>

<i18n>
{
  "en":{
    "header": "Upload Project",
    "content": "Browse or drag files here to effortlessly import them into your project. Simplify your workflow and get started in seconds!",
    "format": "Available file formats: {formats}",
    "loading": {
      "header": "Uploading Project...",
      "content": "Patience, we're working on it!",
    },
    "error": {
      "header": "Unsupported File",
      "content": "Oops! It seems the file format you're trying to upload is not supported. Please choose a different file and try again. Need assistance? Feel free to contact our support team.",
      "confirm": "Confirm",
    },
    "cancel": "Cancel",
  }
}
</i18n>
