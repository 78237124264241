import { autocompletion } from '@codemirror/autocomplete'

import { bibKeywords, bibliographyFiles } from '../autocomplete/latexKeywords'
import latexCompletions from './latexCompletion'
import { latexUpdateListener } from './latexUpdateListener'

export const latexAutocompletionExtension = [
  autocompletion({
    override: [latexCompletions], // 커스텀 자동 완성 함수를 override로 추가
    activateOnTyping: true,
    icons: false,
  }),
  latexUpdateListener,
]

interface LatexCommand {
  command: string
  keywords: typeof bibKeywords | typeof bibliographyFiles
}

export const autoCompleteLatexCommands: LatexCommand[] = [
  { command: '\\cite{', keywords: bibKeywords },
  { command: '\\bibliography{', keywords: bibliographyFiles },
]
