/**
 * AskAI 기능의 상태를 관리하는 State Machine
 * AskAI 기능은 다음과 같은 상태를 가집니다.
 * - idle: 초기 상태
 * - activate: 어떤 질문을 AI에게 물어볼지 입력 중인 상태
 * - answering: AI가 답변 중인 상태
 * - completed: AI가 답변을 완료한 상태
 *
 * 각 상태는 다음과 같은 액션을 수행할 수 있습니다.
 * [idle]
 * - askAI: AI에게 질문을 물어보는 액션
 *
 * [activate]
 * - sendRequest: AI에게 질문을 전송하는 액션
 * - selectionChanged: 사용자가 선택한 텍스트가 변경된 액션
 *
 * [answering]
 * - gotResponse: AI로부터 답변을 받은 액션
 * - selectionChanged: 사용자가 선택한 텍스트가 변경된 액션
 * - stopWriting: 질문 입력을 중단하는 액션
 *
 * [completed]
 * - retry: AI에게 다시 질문을 물어보는 액션
 * - deleteResult: 답변을 삭제하는 액션
 * - copyToClipboard: 답변을 클립보드에 복사하는 액션
 * - selectionChanged: 사용자가 선택한 텍스트가 변경된 액션
 *
 *
 * reference: https://medium.com/@floyd.may/building-a-typescript-state-machine-cc9e55995fa8
 *
 */
type TaggedState<T extends string> = { tag: T }

export type Idle = TaggedState<'idle'>
export type Activate = TaggedState<'activate'>
export type Comment = TaggedState<'comment'>
export type Answering = TaggedState<'answering'> & { task: Task; input: string }
export type Completed = TaggedState<'completed'> & { task: Task; input: string; output: string }

export type State = Idle | Activate | Answering | Comment | Completed

// State Creators
export const States = {
  Idle: (): Idle => ({ tag: 'idle' }),
  Activate: (): Activate => ({ tag: 'activate' }),
  Comment: (): Comment => ({ tag: 'comment' }),
  Answering: (task: Task, input: string): Answering => ({ tag: 'answering', task, input }),
  Completed: (task: Task, input: string, output: string): Completed => ({
    tag: 'completed',
    task,
    input,
    output,
  }),
}

export const isIdle = (x: State): x is Idle => x.tag === 'idle'
export const isActivate = (x: State): x is Activate => x.tag === 'activate'
export const isComment = (x: State): x is Comment => x.tag === 'comment'
export const isAnswering = (x: State): x is Answering => x.tag === 'answering'
export const isCompleted = (x: State): x is Completed => x.tag === 'completed'

type TaggedAction<T extends string> = { tag: T }

export type AskAI = TaggedAction<'askAI'>
export type AddComment = TaggedAction<'addComment'>
export type Task =
  | 'continueWriting'
  | 'writeAbstract'
  | 'writeConclusion'
  | 'makeLonger'
  | 'simplifyLanguage'
  | 'summarize'
  | 'paraphraseAcademically'
  | 'correctGrammar'
  | 'translateToEnglish'
export type SendRequest = TaggedAction<'sendRequest'> & { task: Task; input: string }
export type GotResponse = TaggedAction<'gotResponse'> & {
  task: Task
  input: string
  output: string
}
export type Retry = TaggedAction<'retry'> & { task: Task; input: string }
export type StopWriting = TaggedAction<'stopWriting'>
export type DeleteResult = TaggedAction<'deleteResult'>
export type CopyToClipboard = TaggedAction<'copyToClipboard'>
export type SelectionChanged = TaggedAction<'selectionChanged'>

export type Action =
  | AskAI
  | AddComment
  | SendRequest
  | GotResponse
  | Retry
  | StopWriting
  | DeleteResult
  | CopyToClipboard
  | SelectionChanged

// Action Creators
export const Actions = {
  AskAI: (): AskAI => ({ tag: 'askAI' }),
  AddComment: (): AddComment => ({ tag: 'addComment' }),
  SendRequest: (task: Task, input: string): SendRequest => ({
    tag: 'sendRequest',
    task,
    input,
  }),
  GotResponse: (task: Task, input: string, output: string): GotResponse => ({
    tag: 'gotResponse',
    task,
    input,
    output,
  }),
  Retry: (task: Task, input: string): Retry => ({ tag: 'retry', task, input }),
  StopWriting: (): StopWriting => ({ tag: 'stopWriting' }),
  DeleteResult: (): DeleteResult => ({ tag: 'deleteResult' }),
  CopyToClipboard: (): CopyToClipboard => ({ tag: 'copyToClipboard' }),
  SelectionChanged: (): SelectionChanged => ({ tag: 'selectionChanged' }),
}

export const isAskAI = (x: Action): x is AskAI => x.tag === 'askAI'
export const isAddComment = (x: Action): x is AddComment => x.tag === 'addComment'
export const isSendRequest = (x: Action): x is SendRequest => x.tag === 'sendRequest'
export const isGotResponse = (x: Action): x is GotResponse => x.tag === 'gotResponse'
export const isRetry = (x: Action): x is Retry => x.tag === 'retry'
export const isStopWriting = (x: Action): x is StopWriting => x.tag === 'stopWriting'
export const isDeleteResult = (x: Action): x is DeleteResult => x.tag === 'deleteResult'
export const isCopyToClipboard = (x: Action): x is CopyToClipboard => x.tag === 'copyToClipboard'
export const isSelectionChanged = (x: Action): x is SelectionChanged => x.tag === 'selectionChanged'

type Reducer = (state: State, action: Action) => State

const reduceIdle: Reducer = (state, action) => {
  if (isAskAI(action)) {
    return States.Activate()
  } else if (isAddComment(action)) {
    return States.Comment()
  }
  return state
}
const reduceComment: Reducer = (state, action) => {
  if (isSelectionChanged(action)) {
    return States.Idle()
  }
  return state
}

const reduceActivate: Reducer = (state, action) => {
  if (isSendRequest(action)) {
    return States.Answering(action.task, action.input)
  } else if (isSelectionChanged(action)) {
    return States.Idle()
  }
  return state
}

const reduceAnswering: Reducer = (state, action) => {
  if (isGotResponse(action)) {
    return States.Completed(action.task, action.input, action.output)
  } else if (isSelectionChanged(action)) {
    return States.Idle()
  } else if (isStopWriting(action)) {
    return States.Activate()
  }
  return state
}

const reduceCompleted: Reducer = (state, action) => {
  if (isRetry(action)) {
    return States.Answering(action.task, action.input)
  } else if (isDeleteResult(action)) {
    return States.Activate()
  } else if (isCopyToClipboard(action)) {
    return States.Activate()
  } else if (isSelectionChanged(action)) {
    return States.Idle()
  }
  return state
}

export const reducer: Reducer = (state, action) => {
  if (isIdle(state)) {
    return reduceIdle(state, action)
  } else if (isComment(state)) {
    return reduceComment(state, action)
  } else if (isActivate(state)) {
    return reduceActivate(state, action)
  } else if (isAnswering(state)) {
    return reduceAnswering(state, action)
  } else if (isCompleted(state)) {
    return reduceCompleted(state, action)
  }
  return state
}

export class AskAIStateMachine {
  public state: State
  constructor() {
    this.state = States.Idle()
  }
  public send(action: Action) {
    this.state = reducer(this.state, action)
  }
}
