<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M12.5801 3.42945C12.951 3.27582 13.3485 3.19675 13.75 3.19675C14.1514 3.19675 14.5489 3.27582 14.9198 3.42945C15.2907 3.58308 15.6277 3.80826 15.9116 4.09213C16.1955 4.376 16.4207 4.713 16.5743 5.0839C16.7279 5.45479 16.807 5.85232 16.807 6.25377C16.807 6.65522 16.7279 7.05274 16.5743 7.42364C16.4207 7.79454 16.1955 8.13154 15.9116 8.41541L7.16161 17.1654C7.03033 17.2967 6.85229 17.3704 6.66663 17.3704H3.3333C2.9467 17.3704 2.6333 17.057 2.6333 16.6704V13.3371C2.6333 13.1515 2.70705 12.9734 2.83833 12.8421L11.5883 4.09213C11.8722 3.80826 12.2092 3.58308 12.5801 3.42945ZM13.75 4.59675C13.5324 4.59675 13.3169 4.63961 13.1159 4.72288C12.9148 4.80615 12.7321 4.92821 12.5783 5.08208L4.0333 13.6271V15.9704H6.37668L14.9217 7.42546C15.0755 7.27159 15.1976 7.08892 15.2809 6.88788C15.3641 6.68685 15.407 6.47137 15.407 6.25377C15.407 6.03616 15.3641 5.82069 15.2809 5.61965C15.1976 5.41862 15.0755 5.23595 14.9217 5.08208C14.7678 4.92821 14.5851 4.80615 14.3841 4.72288C14.183 4.63961 13.9676 4.59675 13.75 4.59675Z"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M10.7551 4.92548C11.0284 4.65212 11.4717 4.65212 11.745 4.92548L15.0784 8.25882C15.3517 8.53218 15.3517 8.9754 15.0784 9.24877C14.805 9.52213 14.3618 9.52213 14.0884 9.24877L10.7551 5.91543C10.4817 5.64207 10.4817 5.19885 10.7551 4.92548Z"
  />
</template>
