import { useUISettingStore } from '@/stores'
import { usePermission } from '@/stores/permission'
import { Permission } from '@murfy-package/api-client'
import { storeToRefs } from 'pinia'
import { computed, ref, watch } from 'vue'

export enum Activity {
  File = 'file',
  Share = 'share',
  Comment = 'comment',
  FormulaTurbo = 'formula-turbo',
  AIChat = 'ai-chat',
}
export enum ExtraActivity {
  Help = 'help',
}
const sharePermissions = [
  Permission.projectRoleEditorCreate,
  Permission.projectRoleEditorRead,
  Permission.projectRoleEditorUpdate,
  Permission.projectRoleEditorDelete,
  Permission.projectRoleViewerCreate,
  Permission.projectRoleViewerRead,
  Permission.projectRoleViewerUpdate,
  Permission.projectRoleViewerDelete,
  Permission.inviteEditorLinkRead,
  Permission.inviteViewerLinkRead,
]
const activityPermissions = {
  [Activity.File]: [Permission.fileRead],
  [Activity.Share]: sharePermissions,
  [Activity.Comment]: [Permission.commentRead],
  [Activity.FormulaTurbo]: [],
  [Activity.AIChat]: [Permission.askAIOn],
  [ExtraActivity.Help]: [],
}
export const useActivity = () => {
  const { checkPermission } = usePermission()
  const activities = computed(() =>
    Object.values(Activity).filter((activity) => {
      const requiredPermissions = activityPermissions[activity]
      if (requiredPermissions.length === 0) {
        return true
      }
      return activityPermissions[activity].some((permission) => checkPermission(permission))
    }),
  )
  const extraActivities = computed(() =>
    Object.values(ExtraActivity).filter((activity) => {
      const requiredPermissions = activityPermissions[activity]
      if (requiredPermissions.length === 0) {
        return true
      }
      return activityPermissions[activity].some((permission) => checkPermission(permission))
    }),
  )
  const activeActivity = ref<Activity | ExtraActivity | undefined>(undefined)
  const { editorUISettings } = storeToRefs(useUISettingStore())
  watch(
    activeActivity,
    (newActivity) => {
      if (newActivity) {
        editorUISettings.value.isSidebarOpen = true
      } else {
        editorUISettings.value.isSidebarOpen = false
      }
    },
    {
      immediate: true,
    },
  )
  return {
    activities,
    extraActivities,
    activeActivity,
  }
}
