<script setup lang="ts">
import { ConfirmModal } from '@/components/index'
import { useCommentStore, useErrorStore, useUserStore } from '@/stores'
import type { Comment, Reply } from '@murfy-package/api-client'
import { TextArea } from '@murfy-package/murds'
import { storeToRefs } from 'pinia'
import { nextTick, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import CommentCard from './CommentCard.vue'

type CommentFloatingProps = {
  commentId?: string
  visible: boolean
}
type CommentFloatingEmits = {
  close: []
}
const { t } = useI18n()
const props = defineProps<CommentFloatingProps>()
const emit = defineEmits<CommentFloatingEmits>()

const {
  fetchComment,
  resolveComment,
  unresolveComment,
  removeComment,
  modifyComment,
  addReply,
  modifyReply,
  removeReply,
} = useCommentStore()

const replyList = ref<HTMLDivElement | null>(null)
const scrollToBottom = () => {
  if (!replyList.value) return
  replyList.value.scrollTop = replyList.value.scrollHeight
}

const comment = ref<Comment | null>(null)
const fetch = () => {
  if (!props.commentId) return
  fetchComment(props.commentId)
    .then((fetchedComment) => {
      comment.value = fetchedComment
      nextTick(() => {
        scrollToBottom()
      })
    })
    .catch((error) => {
      const { setError } = useErrorStore()
      setError(error)
    })
}
const { visibleFileComments } = storeToRefs(useCommentStore())
watch(
  visibleFileComments,
  (newFileComments) => {
    if (!newFileComments) return
    if (!newFileComments.find((fileComment) => fileComment.id === props.commentId)) {
      emit('close')
      return
    }
    const foundComment = newFileComments.find((fileComment) => fileComment.id === props.commentId)
    if (foundComment) {
      comment.value = { ...foundComment }
    }
  },
  { deep: true },
)

const sortedRepliesByCreatedAt = (replies: Reply[], newOneFirst = false) =>
  [...replies].sort((a, b) =>
    newOneFirst
      ? new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      : new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
  )

const textarea = ref<InstanceType<typeof TextArea> | null>(null)
const viewText = ref('')
const loading = ref(false)
const handleSubmit = () => {
  if (viewText.value.length === 0) return
  if (!props.commentId) return
  loading.value = true
  addReply(props.commentId, viewText.value)
    .then(() => {
      viewText.value = ''
      scrollToBottom()
    })
    .finally(() => {
      loading.value = false
      nextTick(() => {
        textarea.value?.focus()
      })
    })
}
const { me } = storeToRefs(useUserStore())

const onResolveComment = () => {
  if (!comment.value) return
  resolveComment(comment.value.id)
  emit('close')
}
const onUnresolveComment = () => {
  if (!comment.value) return
  unresolveComment(comment.value.id)
  comment.value = { ...comment.value, resolved: false }
}

const removeCommentModalVisible = ref(false)
const onRemoveComment = () => {
  removeCommentModalVisible.value = true
}
const confirmRemoveComment = () => {
  if (!comment.value) return
  removeComment(comment.value.id)
  removeCommentModalVisible.value = false
  emit('close')
}
const cancelRemoveComment = () => {
  removeCommentModalVisible.value = false
}

const removeReplyModalVisible = ref(false)
const replyIdRef = ref<string | undefined>(undefined)
const onRemoveReply = (replyId: string) => {
  replyIdRef.value = replyId
  removeReplyModalVisible.value = true
}
const confirmRemoveReply = () => {
  if (!replyIdRef.value || !comment.value) return
  removeReply(replyIdRef.value)
  comment.value.replies = comment.value?.replies?.filter((reply) => reply.id !== replyIdRef.value)
  removeReplyModalVisible.value = false
}
const cancelRemoveReply = () => {
  removeReplyModalVisible.value = false
}

const editingTargetId = ref<string | null>(null)
const onUpdateEditing = (id: string, editing: boolean) => {
  editingTargetId.value = editing ? id : null
}

const handleKeydown = (e: KeyboardEvent) => {
  if (e.key === 'Escape') {
    // FIXME: 디자인시스템으로 콤포넌트를 개발 할 때 모달에서 ESC 키를 누르면 처리 할수 있도록 수정이 필요 함
    // 삭제 모달이 떠 있으면 삭제 모달을 닫고, 아니면 코멘트 창을 닫도록 합니다.
    if (removeCommentModalVisible.value) {
      removeCommentModalVisible.value = false
    } else if (removeReplyModalVisible.value) {
      removeReplyModalVisible.value = false
    } else {
      emit('close')
    }
  }
}
onMounted(() => {
  document.addEventListener('keydown', handleKeydown)
})
onBeforeUnmount(() => {
  document.removeEventListener('keydown', handleKeydown)
})

const resetLocalStates = () => {
  // Comment Floating은 위치 계산을 위해 unmount 하지 않습니다.
  // 따라서 별도의 resetLocalStates 함수를 만들어 초기화합니다.
  viewText.value = ''
  loading.value = false
  editingTargetId.value = null
  removeCommentModalVisible.value = false
  removeReplyModalVisible.value = false
}
watch(
  () => props.visible,
  (newVisible) => {
    if (!newVisible) {
      resetLocalStates()
    } else {
      fetch()
    }
  },
)
</script>

<template>
  <div class="absolute z-20">
    <div
      v-if="!!comment && visible"
      class="bg-color-bg-global-primary mt-2 w-[400px] rounded-lg pb-2 shadow-lg"
    >
      <CommentCard
        :key="comment.id + comment.updatedAt"
        :userName="comment.userName"
        :updatedAt="comment.updatedAt"
        :text="comment.text"
        :modified="comment.modified"
        :resolved="comment.resolved"
        :numberOfReplies="comment.numberOfReplies"
        :editable="comment.userId === me?.id"
        :deletable="comment.userId === me?.id"
        :editing="editingTargetId === comment.id"
        type="main"
        @close="$emit('close')"
        @resolve="onResolveComment()"
        @unresolve="onUnresolveComment()"
        @delete="onRemoveComment()"
        @edit="modifyComment(comment.id, $event)"
        @update:editing="onUpdateEditing(comment.id, $event)"
      />
      <ConfirmModal
        :visible="removeCommentModalVisible"
        :header="t('removeCommentModal.header')"
        :content="
          comment.replies ? t('removeCommentModal.hasContent') : t('removeCommentModal.noContent')
        "
        actionType="destructive"
        severity="secondary"
        @cancel="cancelRemoveComment"
        @confirm="confirmRemoveComment"
      />
      <div class="px-4 py-2">
        <div class="bg-color-border-primary h-[1px]" />
      </div>
      <div ref="replyList">
        <CommentCard
          v-for="reply in sortedRepliesByCreatedAt(comment.replies ?? [])"
          :key="reply.id + reply.updatedAt"
          :userName="reply.userName"
          :updatedAt="reply.updatedAt"
          :text="reply.text"
          :modified="reply.modified"
          :resolved="comment.resolved"
          :editable="reply.userId === me?.id"
          :deletable="reply.userId === me?.id"
          :editing="editingTargetId === reply.id"
          type="reply"
          @edit="modifyReply(reply.id, $event)"
          @delete="onRemoveReply(reply.id)"
          @update:editing="onUpdateEditing(reply.id, $event)"
        />
        <ConfirmModal
          :visible="removeReplyModalVisible"
          :header="t('removeReplyModal.header')"
          :content="t('removeReplyModal.content')"
          actionType="destructive"
          severity="secondary"
          @cancel="cancelRemoveReply"
          @confirm="confirmRemoveReply()"
        />
      </div>
      <div v-if="!comment.resolved" class="px-4 py-2">
        <TextArea
          ref="textarea"
          v-model="viewText"
          :loading="loading"
          :placeholder="t('addComment')"
          @submit="handleSubmit"
        />
      </div>
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "addComment": "Add a comment",
    "removeCommentModal": {
      "header": "Delete comment",
      "noContent": "If you delete the comment now, there is no turning back. Do you want to continue? Then please check below",
      "hasContent": "If you delete the comment now, the reply will also be deleted, and there is no turning back. Do you want to continue? If so, please check below"
    },
    "removeReplyModal": {
      "header": "Delete comment",
      "content": "If you delete the reply now, there is no turning back. Do you want to continue? If so, please check below"
    }
  }
}
</i18n>
