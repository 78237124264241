import { clamp, useElementBounding } from '@vueuse/core'
import { type Ref, computed, ref, watch } from 'vue'

const BOUNDED_PADDING_IN_PIXEL = 16

type Coords = { top: number; left: number }
type Size = { width: number; height: number }

const calculateContainerBoundedPosition = (
  anchorRelativeCoords: Coords,
  elementSize: Size,
  containerSize: Size,
): Coords => {
  const minTop = BOUNDED_PADDING_IN_PIXEL
  const maxTop = containerSize.height - elementSize.height - BOUNDED_PADDING_IN_PIXEL
  const top = clamp(anchorRelativeCoords.top, minTop, maxTop)

  const minLeft = BOUNDED_PADDING_IN_PIXEL
  const maxLeft = containerSize.width - elementSize.width - BOUNDED_PADDING_IN_PIXEL
  const left = clamp(anchorRelativeCoords.left, minLeft, maxLeft)
  return { top, left }
}

type UseContainerBoundedCoordsStyleArgs = {
  /** 화면 기준 위치 */
  anchorCoords: Ref<{ top: number; left: number }>
  container: Ref<HTMLElement | null>
  element: Ref<HTMLElement | null>
  offsetTop?: Ref<number>
  offsetLeft?: Ref<number>
  position?: Ref<'top' | 'bottom'>
  boundX?: boolean
  boundY?: boolean
}
export const useContainerBoundedCoordsStyle = ({
  anchorCoords,
  container,
  element,
  offsetTop = ref(0),
  offsetLeft = ref(0),
  position = ref('bottom'),
  boundX = true,
  boundY = true,
}: UseContainerBoundedCoordsStyleArgs) => {
  const coords = ref({ top: 0, left: 0 })

  const update = () => {
    if (!container.value || !element.value) {
      return
    }
    const containerRect = container.value.getBoundingClientRect()
    const elementRect = element.value.getBoundingClientRect()
    const anchorRelativeCoords = {
      top:
        position.value === 'bottom'
          ? anchorCoords.value.top - containerRect.top + offsetTop.value
          : anchorCoords.value.top - containerRect.top - elementRect.height + offsetTop.value,
      left: anchorCoords.value.left - containerRect.left + offsetLeft.value,
    }
    const { top, left } = calculateContainerBoundedPosition(
      anchorRelativeCoords,
      elementRect,
      containerRect,
    )
    coords.value = {
      top: clamp(
        container.value.scrollTop + (boundY ? top : anchorRelativeCoords.top),
        0,
        Infinity,
      ),
      left: clamp(boundX ? left : anchorRelativeCoords.left, 0, Infinity),
    }
  }

  const { height: containerHeight, width: containerWidth } = useElementBounding(container)
  const { height, width } = useElementBounding(element)
  watch(
    [anchorCoords, height, width, containerHeight, containerWidth, offsetTop, offsetLeft, position],
    update,
    {
      immediate: true,
      deep: true,
    },
  )
  const coordsStyle = computed(() => ({
    top: `${coords.value.top}px`,
    left: `${coords.value.left}px`,
  }))
  return {
    update,
    coordsStyle,
    containerHeight,
    containerWidth,
  }
}
