<script setup lang="ts">
import { useErrorStore, useProjectStore } from '@/stores'
import { useAPIClient } from '@murfy-package/api-client'
import { BaseButton } from '@murfy-package/ui'
import { storeToRefs } from 'pinia'
import Dialog from 'primevue/dialog'
import ProgressSpinner from 'primevue/progressspinner'
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const apiClient = useAPIClient()
const { setError } = useErrorStore()
const { project } = storeToRefs(useProjectStore())

const props = defineProps<{
  path: string
  visible: boolean
}>()

const emit = defineEmits<{
  'update:visible': [visible: boolean]
}>()

const imageURL = ref('')
const imageRef = ref<HTMLImageElement | null>(null)
const isLoading = ref(true)
watch(
  () => props.visible,
  (visible) => {
    if (visible) {
      isLoading.value = true
      imageURL.value = ''
      originalHeight.value = 0
      originalWidth.value = 0
      if (!project.value) return
      apiClient.project
        .getFile(project.value.id, props.path)
        .then((response) => {
          const blob = new Blob([response], { type: 'image/png' })
          imageURL.value = window.URL.createObjectURL(blob)
          if (!imageRef.value) {
            throw Error('No Image element')
          }
          imageRef.value.onload = () => {
            if (!imageRef.value) return
            originalWidth.value = imageRef.value.width
            originalHeight.value = imageRef.value.height
          }
        })
        .catch((e) => {
          setError(e)
        })
        .finally(() => {
          isLoading.value = false
        })
    }
  },
)
const downloadImage = () => {
  const a = document.createElement('a')
  a.href = imageURL.value
  a.download = props.path
  a.click()
}

// 반응형 이미지 크기 조절을 위함
const windowWidth = ref(window.innerWidth)
const windowHeight = ref(window.innerHeight)
const originalWidth = ref(0)
const originalHeight = ref(0)

const updateDimensions = () => {
  windowWidth.value = window.innerWidth
  windowHeight.value = window.innerHeight
}

onMounted(() => {
  window.addEventListener('resize', updateDimensions)
})
onBeforeUnmount(() => {
  window.removeEventListener('resize', updateDimensions)
})
const imageStyle = computed(() => {
  // numbers are from padding and margin of the dialog, and the header and footer
  if (originalWidth.value === 0 || originalHeight.value === 0) {
    return {}
  }
  /**
   * Dialog의 style에 따른 숫자들입니다.
   * Dialog's margin: 40px;
   * Dialog's padding: 16px 32px;
   * DialogHeader's total height: 90px;
   * DialogFooter's total height: 96px;
   */
  const scale = Math.min(
    (windowWidth.value - (40 + 32) * 2) / originalWidth.value,
    (windowHeight.value - (40 + 16) * 2 - 90 - 96) / originalHeight.value,
    1,
  )
  const width = originalWidth.value * scale
  const height = originalHeight.value * scale

  return {
    width: `${width}px`,
    height: `${height}px`,
  }
})
</script>

<template>
  <Dialog
    modal
    :draggable="false"
    :visible="props.visible"
    :pt="{
      root: $style.modalRoot,
      closeButtonIcon: $style.closeButtonIcon,
      content: $style.content,
      footer: $style.footer,
    }"
    @update:visible="
      (value) => {
        emit('update:visible', value)
      }
    "
  >
    <template #header>
      <span :class="['h2', 'gray-9']">{{ t('header') }}</span>
    </template>
    <template #default>
      <ProgressSpinner v-if="isLoading" />
      <img ref="imageRef" :style="imageStyle" :src="imageURL" />
    </template>
    <template #footer>
      <BaseButton
        v-if="!isLoading"
        severity="tertiary"
        class="w-full"
        @click="
          () => {
            downloadImage()
            emit('update:visible', false)
          }
        "
      >
        <div :class="$style.buttonLabel">
          <img :class="$style.icon" src="/icons/download.svg" />
          <span :class="'h4'">{{ t('downloadButton') }}</span>
        </div>
      </BaseButton>
    </template>
  </Dialog>
</template>
<style module>
:global(.p-dialog).modalRoot {
  margin: 40px;
  min-width: 480px;
  width: fit-content;
  height: fit-content;
}
.closeButtonIcon {
  height: 24px;
  width: 24px;
}
:global(.p-dialog) :global(.p-dialog-content).content {
  padding: 16px 32px;
  min-height: 312px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image {
  object-fit: cover;
}
.footer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.buttonLabel {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
}
.icon {
  height: 24px;
  width: 24px;
}
</style>

<i18n>
{
  "en":{
    "header": "Preview",
    "downloadButton": "Download",
  }
}
</i18n>
