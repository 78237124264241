<template>
  <path
    d="M3.33325 10.0038C3.33325 10.2248 3.42105 10.4367 3.57733 10.593C3.73361 10.7493 3.94557 10.8371 4.16659 10.8371C4.3876 10.8371 4.59956 10.7493 4.75584 10.593C4.91212 10.4367 4.99992 10.2248 4.99992 10.0038C4.99992 9.78276 4.91212 9.5708 4.75584 9.41452C4.59956 9.25824 4.3876 9.17044 4.16659 9.17044C3.94557 9.17044 3.73361 9.25824 3.57733 9.41452C3.42105 9.5708 3.33325 9.78276 3.33325 10.0038Z"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M3.0824 8.91956C3.36996 8.63201 3.75997 8.47046 4.16663 8.47046C4.5733 8.47046 4.96331 8.63201 5.25086 8.91956C5.53842 9.20712 5.69997 9.59713 5.69997 10.0038C5.69997 10.4105 5.53842 10.8005 5.25086 11.088C4.96331 11.3756 4.5733 11.5371 4.16663 11.5371C3.75997 11.5371 3.36996 11.3756 3.0824 11.088C2.79485 10.8005 2.6333 10.4105 2.6333 10.0038C2.6333 9.59713 2.79485 9.20712 3.0824 8.91956ZM4.16663 9.87046C4.13127 9.87046 4.09736 9.88451 4.07235 9.90951C4.04735 9.93452 4.0333 9.96843 4.0333 10.0038C4.0333 10.0392 4.04735 10.0731 4.07235 10.0981C4.09736 10.1231 4.13127 10.1371 4.16663 10.1371C4.202 10.1371 4.23591 10.1231 4.26091 10.0981C4.28592 10.0731 4.29997 10.0392 4.29997 10.0038C4.29997 9.96843 4.28592 9.93452 4.26091 9.90951C4.23591 9.88451 4.202 9.87046 4.16663 9.87046Z"
  />
  <path
    d="M9.16675 10.0038C9.16675 10.2248 9.25455 10.4367 9.41083 10.593C9.56711 10.7493 9.77907 10.8371 10.0001 10.8371C10.2211 10.8371 10.4331 10.7493 10.5893 10.593C10.7456 10.4367 10.8334 10.2248 10.8334 10.0038C10.8334 9.78276 10.7456 9.5708 10.5893 9.41452C10.4331 9.25824 10.2211 9.17044 10.0001 9.17044C9.77907 9.17044 9.56711 9.25824 9.41083 9.41452C9.25455 9.5708 9.16675 9.78276 9.16675 10.0038Z"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M8.91566 8.91956C9.20321 8.63201 9.59322 8.47046 9.99989 8.47046C10.4066 8.47046 10.7966 8.63201 11.0841 8.91956C11.3717 9.20712 11.5332 9.59713 11.5332 10.0038C11.5332 10.4105 11.3717 10.8005 11.0841 11.088C10.7966 11.3756 10.4066 11.5371 9.99989 11.5371C9.59322 11.5371 9.20321 11.3756 8.91566 11.088C8.6281 10.8005 8.46655 10.4105 8.46655 10.0038C8.46655 9.59713 8.6281 9.20712 8.91566 8.91956ZM9.99989 9.87046C9.96452 9.87046 9.93061 9.88451 9.90561 9.90951C9.8806 9.93452 9.86655 9.96843 9.86655 10.0038C9.86655 10.0392 9.8806 10.0731 9.90561 10.0981C9.93061 10.1231 9.96452 10.1371 9.99989 10.1371C10.0352 10.1371 10.0692 10.1231 10.0942 10.0981C10.1192 10.0731 10.1332 10.0392 10.1332 10.0038C10.1332 9.96843 10.1192 9.93452 10.0942 9.90951C10.0692 9.88451 10.0352 9.87046 9.99989 9.87046Z"
  />
  <path
    d="M15 10.0038C15 10.2248 15.0878 10.4367 15.2441 10.593C15.4004 10.7493 15.6123 10.8371 15.8333 10.8371C16.0543 10.8371 16.2663 10.7493 16.4226 10.593C16.5789 10.4367 16.6667 10.2248 16.6667 10.0038C16.6667 9.78276 16.5789 9.5708 16.4226 9.41452C16.2663 9.25824 16.0543 9.17044 15.8333 9.17044C15.6123 9.17044 15.4004 9.25824 15.2441 9.41452C15.0878 9.5708 15 9.78276 15 10.0038Z"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M14.7492 8.91956C15.0367 8.63201 15.4267 8.47046 15.8334 8.47046C16.24 8.47046 16.6301 8.63201 16.9176 8.91956C17.2052 9.20712 17.3667 9.59713 17.3667 10.0038C17.3667 10.4105 17.2052 10.8005 16.9176 11.088C16.6301 11.3756 16.24 11.5371 15.8334 11.5371C15.4267 11.5371 15.0367 11.3756 14.7492 11.088C14.4616 10.8005 14.3 10.4105 14.3 10.0038C14.3 9.59713 14.4616 9.20712 14.7492 8.91956ZM15.8334 9.87046C15.798 9.87046 15.7641 9.88451 15.7391 9.90951C15.7141 9.93452 15.7 9.96843 15.7 10.0038C15.7 10.0392 15.7141 10.0731 15.7391 10.0981C15.7641 10.1231 15.798 10.1371 15.8334 10.1371C15.8687 10.1371 15.9027 10.1231 15.9277 10.0981C15.9527 10.0731 15.9667 10.0392 15.9667 10.0038C15.9667 9.96843 15.9527 9.93452 15.9277 9.90951C15.9027 9.88451 15.8687 9.87046 15.8334 9.87046Z"
  />
</template>
