<script setup lang="ts">
import { ErrorBoundary } from '@/components'
import { DashboardLayout, DefaultLayout, EditorLayout } from '@/layouts'
import Toast from 'primevue/toast'
import { computed } from 'vue'
import { RouterView, useRoute } from 'vue-router'

const route = useRoute()
// FIXME: 이 부분은 route 에서 처리하는 게 좋겠음
// - layout이 동적으로 바뀌는 경우가 아니고 route 주소에 종속임
// - https://router.vuejs.org/guide/essentials/nested-routes
// nested named layout으로 쓰는 게 좋겠음
const layout = computed(() => {
  if (route.meta.layout === 'dashboard') {
    return DashboardLayout
  } else if (route.meta.layout === 'editor') {
    return EditorLayout
  }
  return DefaultLayout
})
</script>

<template>
  <ErrorBoundary>
    <component :is="layout">
      <RouterView />
    </component>
  </ErrorBoundary>
  <Toast position="top-center" />
</template>
