<script setup lang="ts">
import {
  BaseButton,
  IconArrowLeft,
  IconArrowRight,
  IconBase,
  IconDoubleSquare,
  IconLoading,
  IconRotateRight,
  IconXmark,
} from '@murfy-package/murds'
import { ErrorMessage } from '@murfy-package/murds'
import { computed } from 'vue'

const props = defineProps<{
  title: string
  content?: string
  loading?: boolean
  errorTitle?: string
  errorContent?: string
  currentContentNumber: number
  totalContentNumber: number
}>()

defineEmits<{
  close: []
  retry: []
  copy: []
  prev: []
  next: []
}>()

const hasContent = computed(() => !!props.content || !!props.errorContent || !!props.errorTitle)
const hasError = computed(() => !!props.errorContent || !!props.errorTitle)
</script>

<template>
  <div
    class="bg-color-bg-global-primary border-color-border-focused w-[480px] rounded-lg border px-4 pt-1.5 shadow-lg"
    :class="[hasContent ? 'pb-4' : 'pb-1.5']"
  >
    <header class="flex w-full items-center justify-between gap-1">
      <span class="text-color-text-primary head-sm">
        {{ title }}
      </span>
      <div class="flex items-center gap-1">
        <IconBase v-show="loading" class="text-color-text-brand mx-2">
          <IconLoading />
        </IconBase>
        <BaseButton
          v-show="!loading && hasContent"
          severity="secondary"
          variant="text"
          @click="$emit('retry')"
        >
          <template #icon>
            <IconRotateRight />
          </template>
        </BaseButton>
        <BaseButton v-show="content" severity="secondary" variant="text" @click="$emit('copy')">
          <template #icon>
            <IconDoubleSquare />
          </template>
        </BaseButton>
        <BaseButton severity="secondary" variant="text" @click="$emit('close')">
          <template #icon>
            <IconXmark />
          </template>
        </BaseButton>
      </div>
    </header>
    <div v-show="hasContent" class="pt-2">
      <ErrorMessage v-if="hasError" :title="errorTitle" :content="errorContent" />
      <pre
        v-else
        class="text-color-text-primary body-sm max-h-[50vh] overflow-auto whitespace-pre-wrap"
        >{{ content }}</pre
      >
    </div>
    <footer v-show="totalContentNumber > 1 && !hasError" class="flex pt-2">
      <BaseButton
        variant="text"
        severity="secondary"
        :disabled="currentContentNumber === 1"
        @click="$emit('prev')"
      >
        <template #icon>
          <IconArrowLeft />
        </template>
      </BaseButton>
      <div class="body-sm flex items-center text-center">
        <span class="w-8">{{ currentContentNumber }}</span>
        <span class="text-color-text-secondary">/</span>
        <span class="text-color-text-secondary w-8">
          {{ totalContentNumber }}
        </span>
      </div>
      <BaseButton
        variant="text"
        severity="secondary"
        :disabled="currentContentNumber === totalContentNumber"
        @click="$emit('next')"
      >
        <template #icon>
          <IconArrowRight />
        </template>
      </BaseButton>
    </footer>
  </div>
</template>
