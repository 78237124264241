<script setup lang="ts">
import { MenuButton, TextOverflowWithTooltip } from '@/components'
import TimeDisplay from '@/components/TimeDisplay.vue'
import { usePermission } from '@/stores/permission'
import { Permission, type ProjectInfoSummary, Role } from '@murfy-package/api-client'
import { BaseButton, BaseChip } from '@murfy-package/ui'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const props = defineProps<{
  info: ProjectInfoSummary
}>()

const filterMode = computed(() => (props.info.archived ? 'archived' : 'all'))

const emit = defineEmits<{
  openRename: []
  openArchive: []
  openRestore: []
  openProject: [info: ProjectInfoSummary]
  openLeave: []
}>()

const isMouseOver = ref(false)

const { checkPermissionByRole } = usePermission()
const normalDetailMenuItems = computed(() => {
  const menuItemsWithPermission = [
    {
      label: t('menu.rename'),
      action: () => {
        emit('openRename')
      },
      permission: Permission.projectUpdate,
    },
    checkPermissionByRole(props.info.role, Permission.projectDelete)
      ? {
          label: t('menu.archive'),
          class: ['destructive-primary'],
          action: () => {
            emit('openArchive')
          },
        }
      : {
          label: t('menu.leave'),
          class: ['destructive-primary'],
          action: () => {
            emit('openLeave')
          },
        },
  ]
  return menuItemsWithPermission.filter((item) => {
    if (!item.permission) return true
    return checkPermissionByRole(props.info.role, item.permission)
  })
})

const archivedDetailMenuItems = computed(() => [
  checkPermissionByRole(props.info.role, Permission.projectDelete)
    ? {
        label: t('menu.restore'),
        class: ['cta-primary'],
        action: () => {
          emit('openRestore')
        },
      }
    : {
        label: t('menu.leave'),
        class: ['cta-primary'],
        action: () => {
          emit('openLeave')
        },
      },
])
const menuItems = computed(() => {
  if (filterMode.value === 'all') {
    return normalDetailMenuItems.value
  }
  return archivedDetailMenuItems.value
})

const onClickOpen = () => {
  emit('openProject', props.info)
}
const onClickRestore = () => {
  emit('openRestore')
}
const onClickLeave = () => {
  emit('openLeave')
}
</script>

<template>
  <div :class="$style.cardBox">
    <div :class="$style.card" @pointerover="isMouseOver = true" @pointerout="isMouseOver = false">
      <div v-show="isMouseOver" :class="$style.mask" />
      <MenuButton
        :class="$style.cardMenuButton"
        icon="/icons/more_horiz.svg"
        iconSize="24px"
        :model="menuItems"
      />
      <img :class="$style.thumbnail" src="/icons/logo.svg" />
      <BaseButton
        v-if="filterMode === 'all'"
        v-show="isMouseOver"
        scale="small"
        :class="[$style.openButton, 'p1']"
        @click="onClickOpen"
      >
        {{ t('open') }}
      </BaseButton>
      <BaseButton
        v-else-if="checkPermissionByRole(props.info.role, Permission.projectDelete)"
        v-show="isMouseOver"
        severity="secondary"
        scale="small"
        :class="[$style.openButton, 'p1']"
        @click="onClickRestore"
      >
        {{ t('menu.restore') }}
      </BaseButton>
      <BaseButton
        v-else
        v-show="isMouseOver"
        severity="secondary"
        actionType="destructive"
        scale="small"
        :class="[$style.openButton, 'p1']"
        @click="onClickLeave"
      >
        {{ t('menu.leave') }}
      </BaseButton>
    </div>
    <div :class="$style.cardInfoBox">
      <div class="flex items-center gap-1">
        <BaseChip
          v-if="info.role !== Role.owner"
          class="bg-secondary-2"
          :label="t('shared')"
          size="xs"
        />
        <TextOverflowWithTooltip class="h4 gray-8" :text="info.name" />
      </div>
      <span v-if="filterMode === 'all'" class="p4 gray-6">
        {{ t('modified') }} <br />
        <TimeDisplay :datetime="info.updatedAt" />
      </span>
      <span v-else class="p4 gray-6">
        {{ t('archived') }} <br />
        <TimeDisplay :datetime="info.updatedAt" />
      </span>
    </div>
  </div>
</template>

<style module>
.cardBox {
  display: flex;
  flex-direction: column;
  width: 180px;
}
.cardInfoBox {
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  width: 180px;
  gap: 4px;
}
.card {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 240px;
  width: 180px;
  background-color: var(--gray-bg);
  border: 1px solid var(--gray-2);
  border-radius: 8px;
  position: relative;
}
.mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.4);
}
.cardMenuButton {
  position: absolute;
  top: 8px;
  right: 8px;
}
.thumbnail {
  max-width: 90%;
  max-height: 90%;
}
.openButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 156px;
  padding: 6px 16px;
  position: absolute;
}
</style>

<i18n>
{
  "en": {
    "modified": "Modified",
    "archived": "Archived",
    "open": "Open",
    "menu":{
      "rename": "Rename",
      "duplicate": "Duplicate",
      "archive": "Archive",
      "restore": "Restore",
      "delete": "Delete",
      "leave": "Leave",
    },
    "shared": "Shared",
  }
}
</i18n>
