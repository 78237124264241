<script setup lang="ts">
import { FileTreeSelect } from '@/components'
import { useProjectStore } from '@/stores'
import { FileTree, type FileTreeNode } from '@/utils/fileTree'
import { BaseButton } from '@murfy-package/ui'
import { storeToRefs } from 'pinia'
import Dialog from 'primevue/dialog'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const props = withDefaults(
  defineProps<{
    targetPath: string
    visible: boolean
    isLoading?: boolean
  }>(),
  {
    isLoading: false,
  },
)

const { projectFileList } = storeToRefs(useProjectStore())
const fileTree = computed(() => {
  const projectFolderList = projectFileList.value.filter((file) => file.fullPath.endsWith('/'))
  return new FileTree(projectFolderList)
})

const emit = defineEmits<{
  'update:visible': [visible: boolean]
  submit: [node: FileTreeNode]
}>()
const selectedNode = ref<FileTreeNode | null>(null)
const onSelect = (node: FileTreeNode) => {
  selectedNode.value = node
}
const onSubmit = () => {
  if (!selectedNode.value) return
  emit('submit', selectedNode.value)
}
</script>

<template>
  <Dialog
    :visible="props.visible"
    modal
    :draggable="false"
    :class="$style.modal"
    :pt="{ content: $style.content, header: $style.headerContainer, footer: $style.footer }"
    @update:visible="(value) => emit('update:visible', value)"
  >
    <template #header>
      <div :class="[$style.header]">
        <div :class="['h2', 'gray-9']">
          {{ t('header') }}
        </div>
        <div :class="['p2', 'gray-7']">
          {{ t('description') }}
        </div>
      </div>
    </template>
    <template #default>
      <FileTreeSelect
        :fileTree="fileTree.root.children || []"
        :disabled="props.isLoading"
        @select="onSelect"
      />
    </template>
    <template #footer>
      <BaseButton class="w-full" :loading="props.isLoading" @click="onSubmit">
        {{ t('moveButton') }}
      </BaseButton>
    </template>
  </Dialog>
</template>

<style module>
.modal {
  max-width: 480px;
}
.headerContainer {
  align-items: start;
}
.header {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  word-wrap: break-all;
  gap: 12px;
}

.content {
  padding: 0 !important;
  min-height: 52px;
}

.footer {
  display: flex;
  justify-content: space-around;
  overflow: hidden;
  gap: 0.5rem;
}

.button {
  flex-grow: 1;
  justify-content: center;
}
</style>

<i18n>
{
  "en":{
    "header": "Move",
    "description": "Please select a folder to move the file/folder to.",
    "moveButton": "Move",
  }
}
</i18n>
