import type { EditorView } from '@codemirror/view'

/**
 * \section{${sectionTitle}}이 문서에 존재하는지 확인한다.
 *
 * @param sectionTitle
 * @param docText
 * @returns
 */
export const isLatexSectionExists = (sectionTitle: string, docText: string) => {
  const sectionRegex = new RegExp(`\\\\section\\{${sectionTitle}\\}`, 'gi')
  return sectionRegex.test(docText)
}

/**
 * \section{${sectionTitle}}이 문서에 존재하는 경우, 명령어 직후부터 다음 \section{} 명령어 직전까지의 범위를 반환한다.
 * 다음 \section{} 명령어가 없는 경우, 문서의 끝까지의 범위를 반환한다.
 *
 * @param sectionTitle
 * @param docText
 * @returns
 */
export const getLatexSectionRange = (sectionTitle: string, docText: string) => {
  // \section{sectionTitle}으로 시작하고 \section{으로 끝나거나 문서의 끝까지 해당하는 문자열을 찾는다.
  const sectionRegex = new RegExp(
    `\\\\section\\{${sectionTitle}\\}[\\s\\S]*?(?=(\\\\section\\{|$))`,
    'gi',
  )
  const sectionMatch = sectionRegex.exec(docText)
  if (!sectionMatch) {
    return null
  }
  // \section{sectionTitle} 을 포함하지 않은 문자열의 시작과 끝 위치를 반환한다.
  const sectionStartOffset = `\\section{${sectionTitle}}`.length
  const sectionStart = sectionMatch.index + sectionStartOffset
  const sectionEnd = sectionStart + sectionMatch[0].length - sectionStartOffset
  return { from: sectionStart, to: sectionEnd }
}

/**
 * \section{${sectionTitle}}이 문서에 존재하는 경우, 명령어 직후부터 다음 \section{} 명령어 직전까지의 content를 반환한다.
 * 다음 \section{} 명령어가 없는 경우, 문서의 끝까지의 content를 반환한다.
 *
 * @param sectionTitle
 * @param docText
 * @returns
 */
export const getLatexSectionContent = (sectionTitle: string, docText: string) => {
  const range = getLatexSectionRange(sectionTitle, docText)
  if (!range) {
    return ''
  }
  return docText.substring(range.from, range.to)
}

export const useLatex = (view: EditorView) => {
  const isSectionExists = (sectionTitle: string) => {
    const docText = view.state.doc.toString()
    return isLatexSectionExists(sectionTitle, docText)
  }
  const getSectionRange = (sectionTitle: string) => {
    const docText = view.state.doc.toString()
    return getLatexSectionRange(sectionTitle, docText)
  }

  const getSectionContent = (sectionTitle: string) => {
    const docText = view.state.doc.toString()
    return getLatexSectionContent(sectionTitle, docText)
  }

  return {
    isSectionExists,
    getSectionRange,
    getSectionContent,
  }
}
