<script lang="ts" setup>
import { useDateFormat, useNow, useTimeAgo } from '@vueuse/core'
import { computed } from 'vue'

const props = withDefaults(
  defineProps<{
    datetime: string
    timeFormat?: string
  }>(),
  {
    timeFormat: 'YYYY/MM/DD',
  },
)

// 게시물 작성 시간을 Date 객체로 변환
const datetime = props.datetime
// FIXME: 서버에서 보내주는 형식이 일관되도록 수정 해야 합니다.
// 서버에서 보내는 시간 형식이 UTC 형식일 경우 'Z'가 포함되지 않으면 추가
const timeStringWithZ = datetime.endsWith('Z') ? datetime : datetime + 'Z'
const date = new Date(timeStringWithZ)
const now = useNow()
const twentyFourHoursInMilliseconds = 24 * 60 * 60 * 1000

// 경과 시간 계산 (로컬 시간대로 변환된 시간을 기준으로)
const timeAgo = useTimeAgo(date, { updateInterval: 1000 })

// 현재 시간과 게시물 작성 시간 차이 비교
const displayTime = computed(() => {
  const timeDifference = now.value.getTime() - date.getTime()

  if (timeDifference < twentyFourHoursInMilliseconds) {
    return timeAgo.value // 경과 시간 표시
  } else {
    // 24시간 이상 경과 시, useDateFormat을 사용하여 props로 받은 시간 포맷에 따라 표시
    return useDateFormat(date, props.timeFormat).value
  }
})
</script>

<template>
  <span>{{ displayTime }}</span>
</template>
