import type { PDFViewerPageWrapper } from '@/components/PDFViewer'
import PDFViewerPage from '@/components/PDFViewerPage.vue'
import {
  PDF_VIEWER_INVALID_FLAG_VALUE_FOR_INITIALIZED_CHECK,
  PDF_VIEWER_PAGE_INDEX_NUMBER_START_VALUE,
  PDF_VIEWER_PAGE_INDEX_NUMBER_STEP,
} from '@/config'
import { useScroll } from '@vueuse/core'
import { type Ref, computed, onBeforeUnmount, onMounted, reactive, ref, watch } from 'vue'

export interface Page {
  wrapper: PDFViewerPageWrapper
  element: Element
  pageIndexNumber: number
}

export interface PDFViewerPageScrollResult {
  scrollX: Ref<number>
  scrollY: Ref<number>
  isScrolling: Ref<boolean>
  // 현재 페이지 index number, 1부터 시작
  currentPageIndexNumber: Ref<number>
  increaseCurrentPageIndexNumber: () => void
  decreaseCurrentPageIndexNumber: () => void
  pageRefs: Ref<InstanceType<typeof PDFViewerPage>[]>
}

export const usePDFViewerPageScroll = (
  scrollContainer: Ref<HTMLElement | null>,
  pages: Ref<PDFViewerPageWrapper[]>,
): PDFViewerPageScrollResult => {
  const currentPresentedPages = reactive<Record<string, Page>>({})

  // 현재 화면에 보이는 페이지들
  const presentedPages = computed(() => Object.values(currentPresentedPages))

  // 스크롤 위치에 따라 현재 페이지 index number를 식별하는 것을 무시할지 여부
  const ignoreIdentifyCurrentPageIndexByScrollPosition = ref(false)
  // 스크롤 위치에 따라 현재 페이지 index number를 식별
  const identifyCurrentPageIndexByScrollPosition = (e: Event) => {
    if (e.target !== scrollContainer.value) {
      return
    }
    // 무시할 경우, 무시하고 초기화
    if (ignoreIdentifyCurrentPageIndexByScrollPosition.value) {
      ignoreIdentifyCurrentPageIndexByScrollPosition.value = false
      return
    }
    let minValue = Number.MAX_SAFE_INTEGER
    // page index 는 1부터 시작
    let pageScrollTargetIndexNumber = PDF_VIEWER_PAGE_INDEX_NUMBER_START_VALUE
    // 페이지가 없는 경우는 존재할 수 없고, 무시되면 됨
    presentedPages.value.forEach(({ wrapper, element }) => {
      const rect = element.getBoundingClientRect()
      const distance = rect.top
      if (minValue > Math.abs(distance)) {
        minValue = Math.abs(distance)
        pageScrollTargetIndexNumber = wrapper.pageIndexNumber
      }
    })
    // 전파되지 않도록 내부 변수를 변경
    innerCurrentPageIndexNumber.value = pageScrollTargetIndexNumber
  }

  const {
    y: scrollY,
    x: scrollX,
    isScrolling,
  } = useScroll(scrollContainer, {
    onStop: identifyCurrentPageIndexByScrollPosition,
  })

  const pageRefs = ref<InstanceType<typeof PDFViewerPage>[]>([])

  const decreaseCurrentPageIndexNumber = () =>
    (currentPageIndexNumber.value =
      currentPageIndexNumber.value - PDF_VIEWER_PAGE_INDEX_NUMBER_STEP)
  const increaseCurrentPageIndexNumber = () =>
    (currentPageIndexNumber.value =
      currentPageIndexNumber.value + PDF_VIEWER_PAGE_INDEX_NUMBER_STEP)

  // 현재 페이지 index number, 1부터 시작, 초기화 여부를 확인하기 위해 -1로 설정
  const innerCurrentPageIndexNumber = ref(PDF_VIEWER_INVALID_FLAG_VALUE_FOR_INITIALIZED_CHECK)

  const currentPageIndexNumber = computed({
    get: () =>
      innerCurrentPageIndexNumber.value > PDF_VIEWER_PAGE_INDEX_NUMBER_START_VALUE
        ? innerCurrentPageIndexNumber.value
        : PDF_VIEWER_PAGE_INDEX_NUMBER_START_VALUE,
    set: (value) => {
      innerCurrentPageIndexNumber.value = Math.max(
        Math.min(pages.value.length, value),
        PDF_VIEWER_PAGE_INDEX_NUMBER_START_VALUE,
      )
      scrollToPageByCurrentPageIndexNumber()
    },
  })

  /**
   * 페이지 index number를 기준으로 스크롤 위치를 이동
   */
  const scrollToPageByCurrentPageIndexNumber = () => {
    ignoreIdentifyCurrentPageIndexByScrollPosition.value = true
    // page index number 는 1부터 시작하기 때문에 -1을 해줌
    pageRefs.value[currentPageIndexNumber.value - 1]?.scrollIntoView({
      behavior: 'instant',
      inline: 'nearest',
      block: 'start',
    })
  }

  // 현재 보이는 페이지들을 지정
  const serCurrentPresentedPages = (entry: IntersectionObserverEntry) => {
    const pageKey = entry.target.getAttribute('data-page-key') as string
    if (!pageKey) {
      return
    }
    const find = pages.value.find(({ key }) => key === pageKey)
    if (!find) {
      return
    }
    if (entry.isIntersecting) {
      currentPresentedPages[pageKey] = {
        wrapper: find,
        element: entry.target,
        // page index number, 1부터 시작
        pageIndexNumber: find.pageIndexNumber,
      }
    } else {
      delete currentPresentedPages[pageKey]
    }
  }

  // 페이지들을 관찰, 현제 보이는 페이지를 지정
  let observer: IntersectionObserver | null = null
  const observePages = () => {
    disconnectPages()
    observer = new IntersectionObserver((entries) => {
      entries.forEach(serCurrentPresentedPages)
    })
    pageRefs.value.forEach((page) => {
      observer?.observe(page.$el)
    })
  }

  const disconnectPages = () => {
    if (!observer) {
      return
    }
    observer.disconnect()
    observer = null
  }

  const isAllMounted = computed(() => pageRefs.value.length === pages.value.length)
  watch(isAllMounted, (isAll) => {
    if (!isAll) {
      return
    }
    observePages()
  })

  onMounted(observePages)
  onBeforeUnmount(disconnectPages)

  return {
    scrollY,
    scrollX,
    isScrolling,
    currentPageIndexNumber,
    decreaseCurrentPageIndexNumber,
    increaseCurrentPageIndexNumber,
    pageRefs,
  }
}
