<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M9.30005 7.49999C9.30005 7.11339 9.61345 6.79999 10 6.79999H10.0084C10.395 6.79999 10.7084 7.11339 10.7084 7.49999C10.7084 7.88659 10.395 8.19999 10.0084 8.19999H10C9.61345 8.19999 9.30005 7.88659 9.30005 7.49999Z"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M8.46667 9.99999C8.46667 9.61339 8.78008 9.29999 9.16667 9.29999H10C10.3866 9.29999 10.7 9.61339 10.7 9.99999V12.6333H10.8333C11.2199 12.6333 11.5333 12.9467 11.5333 13.3333C11.5333 13.7199 11.2199 14.0333 10.8333 14.0333H10C9.61341 14.0333 9.30001 13.7199 9.30001 13.3333V10.7H9.16667C8.78008 10.7 8.46667 10.3866 8.46667 9.99999Z"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M4.49502 4.49496C3.59263 5.39736 3.20005 6.99498 3.20005 9.99999C3.20005 13.005 3.59263 14.6026 4.49502 15.505C5.39742 16.4074 6.99505 16.8 10 16.8C13.0051 16.8 14.6027 16.4074 15.5051 15.505C16.4075 14.6026 16.8 13.005 16.8 9.99999C16.8 6.99498 16.4075 5.39736 15.5051 4.49496C14.6027 3.59257 13.0051 3.19999 10 3.19999C6.99505 3.19999 5.39742 3.59257 4.49502 4.49496ZM3.50507 3.50501C4.85268 2.15741 7.00505 1.79999 10 1.79999C12.995 1.79999 15.1474 2.15741 16.495 3.50501C17.8426 4.85262 18.2 7.00499 18.2 9.99999C18.2 12.995 17.8426 15.1474 16.495 16.495C15.1474 17.8426 12.995 18.2 10 18.2C7.00505 18.2 4.85268 17.8426 3.50507 16.495C2.15747 15.1474 1.80005 12.995 1.80005 9.99999C1.80005 7.00499 2.15747 4.85262 3.50507 3.50501Z"
  />
</template>
