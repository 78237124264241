import {
  ACTIVITY_BAR_WIDTH_PIXEL,
  EDITOR_TOOLBAR_HEIGHT_PIXEL,
  GUTTER_WIDTH_PIXEL,
  ProjectUILayout,
  ProjectUIMode,
  SIDEBAR_WIDTH_MINIMIZED,
  useUISettingStore,
} from '@/stores'
import { clamp, useDraggable } from '@vueuse/core'
import { storeToRefs } from 'pinia'
import type { Ref } from 'vue'
import { computed, ref, watch } from 'vue'

export const useEditorStyle = (mode: Ref<string>, layout: Ref<string>, isLoading: Ref<boolean>) => {
  const uiSettingStore = useUISettingStore()
  const { editorUISettings } = storeToRefs(uiSettingStore)

  const currentEditorSidebarWidth = computed(() =>
    editorUISettings.value.isSidebarOpen
      ? editorUISettings.value.sidebarWidth
      : SIDEBAR_WIDTH_MINIMIZED,
  )

  // Sidebar 사이즈 조절, Sidebar는 pixel로 사이즈를 정의함.
  const sidebarGutterRef = ref<HTMLElement | null>(null)
  const { x: sidebarX, isDragging: isSidebarDragging } = useDraggable(sidebarGutterRef)
  watch(sidebarX, (newX) => {
    const newWidth = newX - ACTIVITY_BAR_WIDTH_PIXEL
    uiSettingStore.setEditorSidebarWidth(newWidth)
  })

  // Preview 사이즈 조절, Preview는 fr로 사이즈를 정의함.
  const previewGutterRef = ref<HTMLElement | null>(null)
  const { x: previewX, isDragging: isPreviewDragging } = useDraggable(previewGutterRef)
  watch(previewX, (newPreviewX) => {
    const leftoverWidth = Math.max(
      window.innerWidth -
        currentEditorSidebarWidth.value -
        (editorUISettings.value.isSidebarOpen ? 2 * GUTTER_WIDTH_PIXEL : GUTTER_WIDTH_PIXEL) -
        ACTIVITY_BAR_WIDTH_PIXEL,
      1,
    )
    const newWidth = clamp(window.innerWidth - newPreviewX, 1, leftoverWidth)
    const newFr = newWidth / leftoverWidth / (1 - newWidth / leftoverWidth)
    uiSettingStore.setEditorPreviewWidth(newFr)
  })
  const currentPreviewWidthFr = computed(() => editorUISettings.value.previewWidthFr)
  const isResizing = computed(() => isSidebarDragging.value || isPreviewDragging.value)

  const showResult = computed(
    () => mode.value === ProjectUIMode.Preview || layout.value === ProjectUILayout.Double,
  )

  const loaderStyle = computed(() => ({
    '--loader-start-col': isLoading.value ? '4' : '2',
    '--loader-start-row': isLoading.value ? '3' : '2',
  }))

  const editorWidthStyle = computed(() => {
    const defaultValues = {
      '--editor-activitybar-width': `${ACTIVITY_BAR_WIDTH_PIXEL}px`,
      '--editor-sidebar-width': `${currentEditorSidebarWidth.value}px`,
      '--editor-sidebar-gutter-width': `${editorUISettings.value.isSidebarOpen ? GUTTER_WIDTH_PIXEL : 0}px`,
      '--editor-preview-width': `${currentPreviewWidthFr.value}fr`,
      '--editor-preview-min-width': '432px',
      '--editor-preview-gutter-width': `${GUTTER_WIDTH_PIXEL}px`,
      '--editor-editor-start-col': '4',
      '--editor-editor-end-col': '5',
      '--editor-result-start-col': '6',
      '--editor-result-end-col': '-1',
      '--editor-height': 'calc(100vh - 116px)', // Editor 와 오른쪽 사이드 콘텐츠를 스크롤 하려면 높이 지정 필요
      '--editor-toolbar-height': `${EDITOR_TOOLBAR_HEIGHT_PIXEL}px`,
      'grid-template-columns':
        'var(--editor-activitybar-width) var(--editor-sidebar-width) var(--editor-sidebar-gutter-width) 1fr var(--editor-preview-gutter-width) minmax(var(--editor-preview-min-width), var(--editor-preview-width))',
      'grid-template-rows': '64px var(--editor-toolbar-height) auto 1fr',
    }
    if (layout.value === ProjectUILayout.Double) {
      return defaultValues
    }
    if (mode.value === ProjectUIMode.Preview) {
      return {
        ...defaultValues,
        '--editor-editor-end-col': '-1',
        '--editor-result-start-col': '4',
      }
    }
    return {
      ...defaultValues,
      '--editor-editor-end-col': '-1',
      '--editor-editor-start-col': '4',
    }
  })
  return {
    showResult,
    loaderStyle,
    editorWidthStyle,
    editorUISettings,
    uiSettingStore,
    isResizing,
    sidebarGutterRef,
    previewGutterRef,
  }
}
