<script setup lang="ts">
import { type LegacyFileTreeNode } from '@/utils/fileTree'

import TextOverflowWithTooltip from './TextOverflowWithTooltip.vue'

const props = withDefaults(
  defineProps<{
    node: LegacyFileTreeNode
    depth?: number
    disabled?: boolean
  }>(),
  {
    depth: 0,
    disabled: false,
  },
)

const emit = defineEmits<{
  toggle: [node: LegacyFileTreeNode]
  select: [to: LegacyFileTreeNode]
}>()

const onToggle = (node: LegacyFileTreeNode) => {
  emit('toggle', node)
}
const onSelect = (to: LegacyFileTreeNode) => {
  emit('select', to)
}
</script>

<template>
  <li>
    <label :for="props.node.key">
      <div :class="[$style.content]" :style="{ paddingLeft: 32 + 20 * props.depth + 'px' }">
        <div :class="$style.iconBox" @click="onToggle(props.node)">
          <img v-if="props.node.open" :class="$style.icon" src="/icons/expand_more.svg" />
          <img v-else :class="$style.icon" src="/icons/chevron_right.svg" />
        </div>
        <div :class="$style.infoBox">
          <TextOverflowWithTooltip
            :class="[$style.p1, 'gray-7', $style.info]"
            :text="props.node.label"
          />
        </div>
        <input
          :id="props.node.key"
          :class="$style.radioBox"
          type="radio"
          :checked="props.node.selected"
          variant="filled"
          :disabled="props.disabled"
          @click="onSelect(props.node)"
        />
      </div>
    </label>
    <ul v-show="props.node.open" v-if="props.node.children" :class="$style.ul">
      <FileTreeSelectItem
        v-for="item in props.node.children"
        :key="item.key"
        :node="item"
        :depth="props.depth + 1"
        :disabled="props.disabled"
        @toggle="onToggle"
        @select="onSelect"
      />
    </ul>
  </li>
</template>

<style module>
.ul {
  list-style-type: none;
  padding-left: 0;
}

.pressed {
  background-color: var(--cta-secondary-pressed);
}

.p1 {
  font-weight: 500;
  font-size: 0.9375em;
  line-height: 160%;
  letter-spacing: 0;
}

.content {
  padding-top: 14px;
  padding-bottom: 14px;
  padding-right: 32px;
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 12px;
  justify-content: space-between;
}
.content:hover {
  background-color: var(--cta-secondary-pressed);
}
.iconBox {
  flex: none;
  height: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
}
.icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.infoBox {
  flex: 1;
  min-width: 0;
}
.info {
  display: block;
  height: 24px;
}
.radioBox {
  flex: 0;
}
</style>
