import { Extension } from '@codemirror/state'

import autoCloseBeginEndKeymap from './autoCloseBeginEndKeymap'
import backspaceKeymap from './backspaceKeymap'
import generalKeymap from './generalKeymap'
import latexKeymap from './latexKeymap'

// 모든 키맵 Extension 결합
// generalKeymap 은 항상 마지막에 적용되어야 합니다.
// 원하는 커스텀 keymap 이 등록이 되지 않습니다.
// 그래서 순서가 바뀌면 Cmd/Ctrl + I 키를 눌렀을 때 기울이기가 적용되지 않습니다.
const editorKeymap: Extension = [
  backspaceKeymap,
  autoCloseBeginEndKeymap,
  latexKeymap,
  generalKeymap,
]

export default editorKeymap
