<script setup lang="ts">
import type { MenuItem } from 'primevue/menuitem'
import { computed, ref } from 'vue'

import BaseMenu from './BaseMenu.vue'

const props = withDefaults(
  defineProps<{
    icon: string
    model: MenuItem[]
    showButton?: boolean
    iconSize?: string
  }>(),
  {
    showButton: true,
    iconSize: '18px',
  },
)

const isMenuFocused = ref(false)
const onMenuFocus = () => {
  isMenuFocused.value = true
}

const onMenuBlur = () => {
  isMenuFocused.value = false
}
const visible = computed(() => props.showButton || isMenuFocused.value)

const menuRef = ref<InstanceType<typeof BaseMenu> | null>(null)
const toggleMenu = (event: MouseEvent) => {
  menuRef.value?.toggle(event)
}

const iconStyle = computed(() => ({
  height: props.iconSize,
  width: props.iconSize,
}))
</script>

<template>
  <div
    v-if="visible"
    :class="$style.container"
    :style="{ 'min-width': iconStyle.width, 'min-height': iconStyle.height }"
  >
    <img
      :class="$style.icon"
      :src="props.icon"
      :style="iconStyle"
      aria-controls="overlay_menu"
      @click.stop="toggleMenu"
    />
    <BaseMenu
      ref="menuRef"
      :model="props.model"
      :popup="true"
      @blur="onMenuBlur"
      @focus="onMenuFocus"
    />
  </div>
</template>

<style module>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon {
  cursor: pointer;
}
</style>
