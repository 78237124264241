import { Text } from '@codemirror/state'
import { ref, watchEffect } from 'vue'

import { LATEX_MATH_ENVIRONMENTS } from '../extensions/formula-turbo/config'
import { Range, Token } from '../extensions/formula-turbo/formulaTurbo'
// FIXME: 절대 경로로 수정 해야 함
import { escapeRegExp, replaceCommentsToBlank } from '../utils/stringUtils'

// 매칭할 수 있는 모든 수학 패턴을 포함하는 정규 표현식을 생성하는 함수
const createMathPatterns = (): RegExp => {
  const patterns = LATEX_MATH_ENVIRONMENTS.flatMap((p) => [p.open, p.close].map(escapeRegExp)).join(
    '|',
  )
  return new RegExp(patterns, 'g')
}

// 문서 내에서 패턴으로 일치하는 수학 범위를 찾는 함수
const findMathRanges = (doc: Text): Range[] => {
  const ranges: Range[] = []
  const stack: Token[] = []
  const rawText: string = doc.toString()
  const text: string = replaceCommentsToBlank(rawText)
  const mathPatterns = createMathPatterns()
  let match: RegExpExecArray | null

  while ((match = mathPatterns.exec(text)) !== null) {
    const token = match[0]
    const index = match.index

    const pattern = LATEX_MATH_ENVIRONMENTS.find(
      (pattern) => pattern.open === token || pattern.close === token,
    )
    if (pattern) {
      if (stack.length && stack[stack.length - 1].token === pattern.open) {
        const open = stack.pop()
        if (open) {
          const content = text.slice(open.index + pattern.open.length, index)
          ranges.push({
            open: pattern.open,
            close: pattern.close,
            from: open.index,
            to: index + pattern.open.length,
            content: content,
            offset: pattern.open.length - pattern.close.length,
          })
        }
      } else {
        stack.push({ token: pattern.open, index: index })
      }
    }
  }

  return ranges // 쌍을 이루는 범위를 반환
}

export const useMathPatterns = (doc: Text, cursorPos?: number) => {
  const ranges = ref<Range[]>([])
  const rangeAtCursor = ref<Range | undefined>(undefined)

  watchEffect(() => {
    ranges.value = findMathRanges(doc)

    if (cursorPos !== undefined) {
      // 현재 커서 위치가 속한 범위를 찾음
      rangeAtCursor.value = ranges.value.find(
        (range) => range.from <= cursorPos && range.to >= cursorPos,
      )
    }
  })

  return { ranges, rangeAtCursor }
}
