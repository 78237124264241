import { Decoration, DecorationSet, EditorView, ViewPlugin, ViewUpdate } from '@codemirror/view'

// 괄호 자동 완성을 처리하는 메인 클래스
class BracketsHandlerExtension {
  view: EditorView
  decorations: DecorationSet

  constructor(view: EditorView) {
    this.view = view
    this.decorations = Decoration.none
  }

  update(update: ViewUpdate) {
    if (update.docChanged) {
      this.handleInput(update)
    }
  }

  // 괄호 자동 완성 및 삭제를 위한 입력 이벤트 처리 함수
  handleInput(update: ViewUpdate) {
    const openBrackets = ['{', '[', '(']
    const closeBrackets = ['}', ']', ')']

    const { state } = update
    const cursorPos: number = state.selection.main.head // 커서의 현재 위치
    const doc = state.doc
    const lastChar = doc.sliceString(cursorPos - 1, cursorPos) // 커서 바로 앞의 마지막 문자 가져오기

    update.changes.iterChanges((fromA, toA, fromB, toB) => {
      // 백스페이스 키를 입력 안했는지 체크 하는 로직
      if (fromA === toA || fromB !== toB) {
        // 마지막 문자가 여는 괄호인지 확인
        if (openBrackets.includes(lastChar)) {
          const index = openBrackets.indexOf(lastChar)
          const matchingClose = closeBrackets[index] // 매칭되는 닫는 괄호

          // 매칭되는 닫는 괄호가 이미 없는 경우에만 추가
          requestAnimationFrame(() => {
            if (doc.sliceString(cursorPos, cursorPos + 1) !== matchingClose) {
              this.view.dispatch({
                changes: { from: cursorPos, insert: matchingClose },
                selection: { anchor: cursorPos },
              })
            }
          })
        }
      }
    })
  }
}

const bracketsHandlerExtension = ViewPlugin.fromClass(BracketsHandlerExtension)

export default bracketsHandlerExtension
