<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M3.46666 8.3333C3.46666 7.9467 3.78006 7.6333 4.16666 7.6333H15.8333C16.2199 7.6333 16.5333 7.9467 16.5333 8.3333C16.5333 8.7199 16.2199 9.0333 15.8333 9.0333H4.16666C3.78006 9.0333 3.46666 8.7199 3.46666 8.3333Z"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M3.46666 5.00005C3.46666 4.61345 3.78006 4.30005 4.16666 4.30005H15.8333C16.2199 4.30005 16.5333 4.61345 16.5333 5.00005C16.5333 5.38665 16.2199 5.70005 15.8333 5.70005H4.16666C3.78006 5.70005 3.46666 5.38665 3.46666 5.00005Z"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M3.46666 11.6666C3.46666 11.28 3.78006 10.9666 4.16666 10.9666H11.6667C12.0533 10.9666 12.3667 11.28 12.3667 11.6666C12.3667 12.0532 12.0533 12.3666 11.6667 12.3666H4.16666C3.78006 12.3666 3.46666 12.0532 3.46666 11.6666Z"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M3.46666 15C3.46666 14.6134 3.78006 14.3 4.16666 14.3H9.16666C9.55326 14.3 9.86666 14.6134 9.86666 15C9.86666 15.3866 9.55326 15.7 9.16666 15.7H4.16666C3.78006 15.7 3.46666 15.3866 3.46666 15Z"
  />
</template>
