<script setup lang="ts">
import * as Sentry from '@sentry/browser'

const props = defineProps<{
  error?: Error | null
  sentryReport?: boolean
}>()

if (props.sentryReport && props.error) {
  Sentry.captureException(props.error)
}
</script>

<template>
  <div v-if="props.error" class="flex flex-col items-center justify-center gap-2">
    <img class="w-4/5 max-w-xs" src="/icons/warning.svg" alt="Error" />
    <p>{{ props.error }}</p>
  </div>
</template>
