<script setup lang="ts">
import { onBeforeUnmount, ref } from 'vue'

import { IconBase, IconMurfyLogo, IconMurfyLogoFull, IconMurfyLogoFullAnimation } from '../../atoms'

/** Animation Frame Per Second */
const ANIMATION_FPS = 60

/** Animation Second Per Frame */
const ANIMATION_SPF = 1 / ANIMATION_FPS

const props = defineProps<{
  message?: string
  animation?: boolean
}>()

const endAnimationLoop = ref(true)
if (!props.message) {
  // svg animation의 경우 브라우저의 최적화 문제 때문에 애니메이션이 멈추는 경우가 있음.
  // 이를 방지하기 위해 requestAnimationFrame을 사용하여 애니메이션을 지속적으로 실행
  endAnimationLoop.value = false
  const loopRequestAnimationFrame = () => {
    if (endAnimationLoop.value) {
      return
    }
    requestAnimationFrame(() =>
      setTimeout(() => {
        loopRequestAnimationFrame()
      }, ANIMATION_SPF * 1000),
    )
  }
  loopRequestAnimationFrame()
}
onBeforeUnmount(() => {
  endAnimationLoop.value = true
})
</script>
<template>
  <div class="bg-color-bg-global-primary z-[100] flex flex-col items-center justify-center gap-10">
    <IconBase v-if="message" width="120px" height="120px" viewBox="0 0 24 24">
      <IconMurfyLogo />
    </IconBase>
    <IconBase v-else-if="animation" :width="120" :height="120" :viewBox="'0 0 120 24'">
      <IconMurfyLogoFullAnimation />
    </IconBase>
    <IconBase v-else :width="120" :height="120" :viewBox="'0 0 120 24'">
      <IconMurfyLogoFull />
    </IconBase>
    <span
      v-if="message"
      class="head-sm animate-gradient bg-[linear-gradient(to_right,var(--gray-600),var(--static-murfyBlue),var(--gray-600))] bg-[length:200%_auto] bg-clip-text text-transparent"
    >
      {{ message }}
    </span>
  </div>
</template>
