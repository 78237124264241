import { StateEffect, StateField } from '@codemirror/state'
import { Decoration, DecorationSet, EditorView } from '@codemirror/view'

type AICommandMarkSpec = {
  from: number
  to: number
}

const addMark = StateEffect.define<AICommandMarkSpec>({
  map: ({ from, to }, change) => ({
    from: change.mapPos(from),
    to: change.mapPos(to),
  }),
})

const removeMark = StateEffect.define({
  map: (value) => value,
})

export const aiCommandField = StateField.define<DecorationSet>({
  create() {
    return Decoration.none
  },
  update(decorationSet, tr) {
    decorationSet = decorationSet.map(tr.changes)

    for (const e of tr.effects) {
      if (e.is(addMark)) {
        // 기존 aiCommandMark를 제거하고 새로운 aiCommandMark를 추가.
        // aiCommandMark는 하나만 표시됨.
        decorationSet = decorationSet.update({
          filter: (_from, _to) => false,
        })
        decorationSet = decorationSet.update({
          add: [createAICommandMark().range(e.value.from, e.value.to)],
        })
      } else if (e.is(removeMark)) {
        // aiCommandMark를 제거
        decorationSet = decorationSet.update({
          filter: (_from, _to) => false,
        })
      }
    }
    return decorationSet
  },
  provide: (f) => EditorView.decorations.from(f),
})

/**
 * .cm-ai-command-input 의 style 정보는 editor의 config.ts 에 있습니다
 *
 * @param commentId
 */
export const createAICommandMark = () =>
  Decoration.mark({
    class: 'cm-ai-command',
  })

/**
 * 선택한 텍스트에 표시를 추가합니다.
 * @param view - 에디터 뷰 객체
 * @param from - 표시를 추가할 시작 위치
 * @param to - 표시를 추가할 끝 위치
 */
export const addAICommandMark = (view: EditorView, from: number, to: number) => {
  const effects: StateEffect<unknown>[] = view.state.selection.ranges
    .filter((r) => !r.empty)
    .map(() => addMark.of({ from, to }))
  if (!effects.length) return false
  if (!view.state.field(aiCommandField, false)) {
    effects.push(StateEffect.appendConfig.of([aiCommandField]))
  }
  view.dispatch({ effects })
  return true
}

export const removeAICommandMark = (view: EditorView) => {
  view.dispatch({ effects: [removeMark.of(null)] })
}

export const getAICommandMarkContent = (view: EditorView) => {
  const decorations = view.state.field(aiCommandField)
  if (!decorations) return ''
  const decoration = decorations.iter()
  if (!decoration) return ''
  return view.state.sliceDoc(decoration.from, decoration.to)
}

export const getAICommandMarkElement = (view: EditorView) => {
  if (!view?.dom) return null
  const commentDOMs = view.dom.querySelectorAll('.cm-ai-command')
  return commentDOMs.item(commentDOMs.length - 1)
}
