<script lang="ts" setup>
import SharedErrorPanel from '@/components/SharedErrorPanel.vue'
import {
  PDF_VIEWER_INVALID_FLAG_VALUE_FOR_INITIALIZED_CHECK,
  PDF_VIEWER_RENDERING_INTERVAL,
} from '@/config'
import type { PDFPageProxy } from 'pdfjs-dist'
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

const props = withDefaults(
  defineProps<{
    page: Promise<PDFPageProxy>
    // 페이지 인덱스 번호, 1부터 시작
    currentPageIndexNumber: number
    // 지금 페이지의 인덱스 번호
    pageIndexNumber: number
  }>(),
  {
    // page index 는 1부터 시작
    // 기본값은 -1로 설정하여 페이지 번호가 없는 경우를 구분
    currentPageIndexNumber: PDF_VIEWER_INVALID_FLAG_VALUE_FOR_INITIALIZED_CHECK,
    // 지금 페이지의 인덱스 번호
    pageIndexNumber: PDF_VIEWER_INVALID_FLAG_VALUE_FOR_INITIALIZED_CHECK,
  },
)

const canvas = ref<HTMLCanvasElement | null>(null)

const emit = defineEmits<{
  'update:currentPageIndexNumber': [value: number]
}>()

let pdfPage: PDFPageProxy
let canvasContext: CanvasRenderingContext2D | null

const { t } = useI18n()
const error = ref<Error | null>(null)
const hasError = computed(() => !!error.value)
const currentScale = computed(() => 40 / 100)
const viewPortRect = ref({ width: 0, height: 0 })
const originalViewPortRect = ref({ width: 0, height: 0 })

const load = async () => {
  const htmlCanvasElement = canvas.value
  if (!htmlCanvasElement) {
    // 캔버스가 없는 경우, 렌더링을 할 수 없음
    // 실제로 이 경우는 없어야 하므로 발생하는 경우 센트리로 보냄
    error.value = new Error(t('noCanvas'))
    return
  }
  canvasContext = htmlCanvasElement.getContext('2d', {
    willReadFrequently: true,
  })
  pdfPage = await props.page
  originalViewPortRect.value = {
    width: pdfPage.view[2],
    height: pdfPage.view[3],
  }
  return render()
}

const nextRender = ref<NodeJS.Timeout | null>(null)
const rendering = ref(false)
const render = async () => {
  if (!pdfPage) {
    return
  }
  const canvasElement = canvas.value
  if (!canvasElement) {
    return
  }

  if (rendering.value) {
    if (nextRender.value) {
      clearTimeout(nextRender.value)
    }
    nextRender.value = setTimeout(() => {
      render()
      clearTimeout(nextRender.value as NodeJS.Timeout)
    }, PDF_VIEWER_RENDERING_INTERVAL)
    return
  }
  rendering.value = true
  const viewport = pdfPage.getViewport({
    scale: currentScale.value,
  })
  viewPortRect.value = {
    width: viewport.width,
    height: viewport.height,
  }
  canvasElement.width = viewPortRect.value.width
  canvasElement.height = viewPortRect.value.height
  await pdfPage.render({
    canvasContext: canvasContext as CanvasRenderingContext2D,
    viewport,
  }).promise
  rendering.value = false
}

onMounted(() => {
  load()
})

const canvasStyle = computed(() => ({
  width: '100%',
}))
</script>

<template>
  <a
    v-if="!hasError"
    href="#"
    @click.prevent="emit('update:currentPageIndexNumber', props.pageIndexNumber)"
  >
    <canvas ref="canvas" :style="canvasStyle" />
  </a>
  <SharedErrorPanel v-else :error="error" />
</template>

<i18n>
{
  "ko": {
    "noCanvas": "캔버스 요소가 없습니다."
  },
  "en": {
    "noCanvas": "Canvas element is not found."
  }
}
</i18n>
