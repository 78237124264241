<script setup lang="ts">
import { useResizeObserver } from '@vueuse/core'
import { MaybeElement } from '@vueuse/core'
import { GlobalWorkerOptions, type PDFDocumentProxy, getDocument } from 'pdfjs-dist'
import Worker from 'pdfjs-dist/build/pdf.worker?worker'
import type {
  DocumentInitParameters,
  PDFDataRangeTransport,
  TypedArray,
} from 'pdfjs-dist/types/src/display/api'
import { Ref, onMounted, ref, watch } from 'vue'

import { useSteppedValue } from '../../composables'
import PDFViewerDocument, { type PDFHighlight } from './PDFViewerDocument.vue'
import PDFViewerToolbar, { type FitScale } from './PDFViewerToolbar.vue'

/**
 * fit-width 또는 fit-height이 활성화 된 상태에서 재랜더링하는 스케일 차이의 임계점
 * 윈도우의 경우 스크롤바의 너비 때문에 화면떨림 현상이 생겨 이를 방지하기 위해 적용
 */
const FIT_SCALE_THRESHOLD = window.navigator.userAgent.includes('Windows') ? 0.03 : 0

const SCALE_STEPS = [
  0.25, 0.33, 0.5, 0.67, 0.75, 0.8, 0.9, 1, 1.1, 1.25, 1.5, 1.75, 2, 2.5, 3, 4, 5,
]

GlobalWorkerOptions.workerPort = new Worker()

export type PDFSource =
  | string
  | TypedArray
  | URL
  | ArrayBuffer
  | DocumentInitParameters
  | PDFDataRangeTransport

const props = withDefaults(
  defineProps<{
    /**
     * 랜더링할 PDF 데이터
     *
     * 다양한 형식을 지원함.
     *
     * 자세한 내용은 [PDFJS.getDocument()](https://mozilla.github.io/pdf.js/api/)를 참고.
     */
    src: PDFSource
    /**
     * 다운로드 시 파일명 ( 확장자 제외 )
     * 제공되지 않을 경우 document.pdf로 저장됨.
     */
    title?: string
    fitScale?: FitScale
  }>(),
  {
    title: 'document',
    fitScale: 'fitWidth',
  },
)

defineEmits<{
  /**
   * PDF 랜더링이 완료되었을 때 발생
   */
  loaded: []
  /**
   * 페이지가 더블클릭되었을 때 발생
   */
  pageDblclick: [event: MouseEvent, pageNumber: number, scale: number]
}>()

const pageNumber = ref(1)
const lastPageNumber = ref(1)

const pdfViewerDocumentRef = ref<InstanceType<typeof PDFViewerDocument> | null>(null)
const handleUpdatePageNumber = (newPageNumber: number) => {
  pageNumber.value = newPageNumber
  pdfViewerDocumentRef.value?.scrollToPage(newPageNumber)
}
const handlePageChanged = (newPageNumber: number) => {
  pageNumber.value = newPageNumber
}

const { currentValue: scale, increase: zoomIn, decrease: zoomOut } = useSteppedValue(1, SCALE_STEPS)
const handleUpdateScale = (newScale: number) => {
  scale.value = newScale
  // ToolBar의 inputText를 통해 scale을 변경한 경우, fitScale 토글 해제
  fitScale.value = undefined
}
const handleZoomIn = () => {
  zoomIn()
  fitScale.value = undefined
}
const handleZoomOut = () => {
  zoomOut()
  fitScale.value = undefined
}

const useFitScale = (
  actualScale: Ref<number>,
  documentRef: Ref<InstanceType<typeof PDFViewerDocument> | null>,
  defaultFitScale: FitScale,
) => {
  const FIT_WIDTH_MARGIN_IN_PIXEL = 40
  const fitScale = ref<FitScale>(defaultFitScale)
  const setFitScale = (newFitScale: FitScale) => {
    fitScale.value = newFitScale
    if (!newFitScale) return
    newFitScale === 'fitWidth' ? fitWidth() : fitHeight()
  }
  const fitWidth = () => {
    const document = documentRef.value
    if (!document) {
      return
    }
    const pageSize = document.getPageSize(pageNumber.value)
    if (!pageSize) {
      return
    }
    const containerWidth = document.$el.clientWidth
    const targetScale = containerWidth / (pageSize.width + FIT_WIDTH_MARGIN_IN_PIXEL)
    if (Math.abs(actualScale.value - targetScale) < FIT_SCALE_THRESHOLD) {
      return
    }
    actualScale.value = targetScale
  }
  const fitHeight = () => {
    const document = documentRef.value
    if (!document) {
      return
    }
    const pageSize = document.getPageSize(pageNumber.value)
    if (!pageSize) {
      return
    }
    const containerHeight = document.$el.clientHeight
    const targetScale = containerHeight / pageSize.height
    if (Math.abs(actualScale.value - targetScale) < FIT_SCALE_THRESHOLD) {
      return
    }
    actualScale.value = containerHeight / pageSize.height
  }
  useResizeObserver(documentRef as Ref<MaybeElement>, () => {
    setFitScale(fitScale.value)
  })
  return { fitScale, setFitScale }
}

const { fitScale, setFitScale } = useFitScale(scale, pdfViewerDocumentRef, props.fitScale)

/**
 * PDF 문서를 랜더링하는 PDFDocumentProxy 객체
 *
 * 함수형 객체로 선언하지 않으면 private field에 접근할 수 없다는 에러 발생
 */
const documentProxy = ref<(() => PDFDocumentProxy) | null>(null)

/**
 * 리랜더링 시 스크롤 위치를 저장하기 위한 변수
 */
const scrollPosition = ref({ x: 0, y: 0 })
onMounted(() => {
  watch(
    () => props.src,
    async (newSrc) => {
      if ((newSrc instanceof Uint8Array && newSrc.length === 0) || !newSrc) {
        return
      }
      scrollPosition.value = pdfViewerDocumentRef.value?.getScrollPosition() || { x: 0, y: 0 }
      documentProxy.value = null
      const internalDocumentProxy = await getDocument(newSrc).promise
      lastPageNumber.value = internalDocumentProxy.numPages
      documentProxy.value = () => internalDocumentProxy
    },
    {
      immediate: true,
    },
  )
})
const handleLoaded = async () => {
  setFitScale(props.fitScale)
}

const handleDownload = async () => {
  if (!documentProxy.value) {
    return
  }
  const pdfData = await documentProxy.value().getData()
  const url = URL.createObjectURL(new Blob([pdfData], { type: 'application/pdf' }))
  const link = document.createElement('a')
  link.href = url
  link.download = props.title + '.pdf'
  link.click()
}

defineExpose({
  drawHighlight: (highlight: PDFHighlight) => {
    pdfViewerDocumentRef.value?.drawHighlight(highlight)
  },
})
</script>

<template>
  <div
    :style="{
      'grid-template-rows': '52px 1fr',
    }"
    class="grid h-full grid-cols-1 overflow-hidden"
  >
    <PDFViewerToolbar
      :pageNumber="pageNumber"
      :lastPageNumber="lastPageNumber"
      :scale="scale"
      :fitScale="fitScale"
      :disabled="!documentProxy"
      @update:fitScale="setFitScale"
      @update:pageNumber="handleUpdatePageNumber"
      @update:scale="handleUpdateScale"
      @zoomIn="handleZoomIn"
      @zoomOut="handleZoomOut"
      @download="handleDownload"
    />
    <PDFViewerDocument
      v-if="documentProxy"
      ref="pdfViewerDocumentRef"
      :documentProxy="documentProxy()"
      :scale="scale"
      @pageChanged="handlePageChanged"
      @zoomIn="handleZoomIn"
      @zoomOut="handleZoomOut"
      @loaded="handleLoaded"
      @pageDblclick="(event, pageNumber) => $emit('pageDblclick', event, pageNumber, scale)"
    />
  </div>
</template>
