<script setup lang="ts">
import Menu from 'primevue/menu'
import type { MenuItem } from 'primevue/menuitem'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

const props = defineProps<{
  model: MenuItem[]
  popup: boolean
}>()

const emit = defineEmits<{
  onBlur: []
  onFocus: []
}>()

const menu = ref<Menu | null>(null)

const onBlur = () => {
  menu.value?.hide()
  emit('onBlur')
}
const onFocus = () => {
  emit('onFocus')
}
const toggle = (event: MouseEvent) => {
  menu.value?.toggle(event)
}

defineExpose({
  toggle,
})

const { t } = useI18n()
const viewModel = computed(() => {
  if (props.model.length === 0) {
    return [
      {
        label: t('noAvailableMenu'),
        disabled: true,
      },
    ]
  }
  return props.model
})
</script>

<template>
  <Menu
    id="overlay_menu"
    ref="menu"
    :model="viewModel"
    :popup="props.popup"
    :pt="{
      root: 'min-w-[120px] py-2',
      content: [$style.menuContent, 'p2'],
      separator: 'border-t border-gray-200',
    }"
    @focus="onFocus"
    @blur="onBlur"
  >
    <template #item="{ item }">
      <div :class="$style.menuItem" @click="item.action()">
        <span :class="item.class">{{ item.label }}</span>
      </div>
    </template>
  </Menu>
</template>

<style module>
.menuItem {
  height: 40px;
  padding: 10px 16px 10px 16px;
  display: flex;
  justify-content: left;
  align-items: center;
  cursor: pointer;
}
.menuItem:hover {
  background-color: var(--cta-secondary-pressed);
}

.menuContent {
  background-color: transparent !important;
}
</style>

<i18n>
{
  "en": {
    "noAvailableMenu": "No available menu",
  }
}
</i18n>
