<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M12.995 7.0088C13.2684 7.28216 13.2684 7.72538 12.995 7.99875L7.99502 12.9987C7.72166 13.2721 7.27844 13.2721 7.00507 12.9987C6.73171 12.7254 6.73171 12.2822 7.00507 12.0088L12.0051 7.0088C12.2784 6.73543 12.7217 6.73543 12.995 7.0088Z"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M10.0648 5.03492L9.69642 5.46135C9.4437 5.75391 9.00166 5.78621 8.7091 5.53349C8.41654 5.28078 8.38424 4.83874 8.63695 4.54618L9.02279 4.09951C9.03393 4.08662 9.04553 4.07413 9.05758 4.06209C9.97037 3.14943 11.2083 2.63675 12.4991 2.63684C13.7899 2.63693 15.0278 3.14978 15.9404 4.06257C16.8531 4.97536 17.3658 6.21332 17.3657 7.50411C17.3656 8.79489 16.8527 10.0328 15.94 10.9454C15.9284 10.957 15.9165 10.9681 15.9041 10.9788L15.4591 11.3655C15.1673 11.6191 14.7252 11.588 14.4716 11.2962C14.2181 11.0044 14.2491 10.5623 14.5409 10.3087L14.9666 9.93884C15.6065 9.29034 15.9656 8.4157 15.9657 7.50401C15.9658 6.58452 15.6006 5.70267 14.9504 5.05245C14.3003 4.40223 13.4185 4.03691 12.499 4.03684C11.5877 4.03678 10.7133 4.39554 10.0648 5.03492Z"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M10.301 14.506L10.6693 14.0795C10.922 13.787 11.3641 13.7547 11.6566 14.0074C11.9492 14.2601 11.9815 14.7022 11.7288 14.9947L11.3429 15.4414C11.3318 15.4543 11.3202 15.4668 11.3081 15.4788C10.3954 16.3915 9.1574 16.9041 7.86661 16.9041C6.57582 16.904 5.33794 16.3911 4.42528 15.4783C3.51262 14.5655 2.99994 13.3276 3.00003 12.0368C3.00012 10.746 3.51297 9.50811 4.42576 8.59545C4.43731 8.58391 4.44925 8.57278 4.46158 8.56207L4.90658 8.1754C5.1984 7.92183 5.64053 7.95284 5.8941 8.24467C6.14767 8.53649 6.11666 8.97862 5.82483 9.23219L5.39917 9.60205C4.75926 10.2506 4.4001 11.1252 4.40003 12.0369C4.39997 12.9564 4.76517 13.8382 5.4153 14.4884C6.06542 15.1387 6.94722 15.504 7.86671 15.5041C8.77806 15.5041 9.65244 15.1453 10.301 14.506Z"
  />
</template>
