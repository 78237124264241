<template>
  <!--<svg width="120" height="24" viewBox="0 0 120 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
  <defs>
    <!-- Gradient definition for the mask with gradient on both sides -->
    <linearGradient id="mask-gradient" x1="0%" y1="0%" x2="100%" y2="0%">
      <stop offset="0%" stop-color="white" stop-opacity="0" />
      <!-- Left side transparent -->
      <stop offset="25%" stop-color="white" stop-opacity="1" />
      <!-- Transition to opaque -->
      <stop offset="75%" stop-color="white" stop-opacity="1" />
      <!-- Opaque in center -->
      <stop offset="100%" stop-color="white" stop-opacity="0" />
      <!-- Right side transparent -->
    </linearGradient>

    <!-- Moving mask with gradient effect -->
    <mask id="moving-mask">
      <rect
        x="-120"
        y="0"
        width="360"
        height="24"
        fill="url(#mask-gradient)"
        :style="{ animation: 'slideMask 4s linear infinite', willChange: 'transform' }"
      />
    </mask>

    <clipPath id="clip0_159_1473">
      <rect width="120" height="24.0038" fill="white" />
    </clipPath>
  </defs>

  <!-- Apply the mask to the icon group -->
  <g clip-path="url(#clip0_159_1473)">
    <!-- #DEE2E5 background -->
    <g>
      <path
        d="M78.3086 0.00152588H95.047V5.78431H85.0063V9.66827H93.7294V15.0483H85.0063V23.6678H78.3086V0.00152588Z"
        fill="#DEE2E5"
      />
      <path
        d="M40.4241 24.0015C38.5246 24.038 36.6434 23.6239 34.9348 22.7932C33.4023 22.0535 32.1026 20.907 31.1774 19.4789C30.2591 18.0385 29.7867 16.3591 29.8195 14.6512V0.00152588H37.2306V14.3463C37.2208 14.9467 37.3612 15.5401 37.6392 16.0725C37.9035 16.5741 38.3019 16.9924 38.79 17.2808C39.2891 17.5778 39.8606 17.7311 40.4414 17.7239C41.0463 17.7351 41.6429 17.582 42.1676 17.2808C42.6554 17.0003 43.0619 16.5978 43.3472 16.1128C43.6434 15.5861 43.7961 14.9908 43.7902 14.3866V0.00152588H51.0288V14.6512C51.0588 16.3587 50.5866 18.0374 49.6708 19.4789C48.7412 20.9074 47.4376 22.0538 45.9019 22.7932C44.1966 23.6215 42.3197 24.0355 40.4241 24.0015Z"
        fill="#DEE2E5"
      />
      <path
        d="M70.3394 14.8411C71.6002 14.1747 72.6488 13.168 73.366 11.9354C74.1093 10.6059 74.4846 9.10236 74.4535 7.57958C74.4756 6.1996 74.0979 4.84272 73.366 3.6726C72.6155 2.52244 71.5704 1.59452 70.3394 0.985474C68.9653 0.311483 67.4506 -0.0257889 65.9203 0.00153755H54.769V23.8001H61.6336V15.8769H62.7844L67.2437 23.8001H75.4375L70.2589 14.8757L70.3394 14.8411ZM61.6336 5.75555H64.6257C65.1459 5.73645 65.6636 5.83684 66.139 6.04901C66.52 6.23042 66.8312 6.53152 67.0251 6.90636C67.2262 7.30142 67.3251 7.7406 67.3128 8.18375C67.325 8.66383 67.2265 9.14028 67.0251 9.57622C66.8361 9.95447 66.54 10.2687 66.1735 10.4796C65.7395 10.7075 65.2537 10.8185 64.7638 10.8018H61.6336V5.75555Z"
        fill="#DEE2E5"
      />
      <path
        d="M13.2653 6.77976C11.468 6.77823 9.74485 6.06361 8.47401 4.79278C7.20318 3.52194 6.48855 1.79876 6.48703 0.00152588H0.445312C0.445313 3.40159 1.79598 6.66239 4.20019 9.0666C6.60439 11.4708 9.8652 12.8215 13.2653 12.8215C14.9493 12.8222 16.617 12.4912 18.173 11.8473C19.7291 11.2033 21.143 10.2592 22.3341 9.06863C23.5251 7.87812 24.4699 6.4646 25.1146 4.90884C25.7592 3.35307 26.091 1.68555 26.091 0.00152588L20.0492 0.00152588C20.0462 1.79929 19.3303 3.52246 18.0585 4.79313C16.7868 6.0638 15.063 6.77824 13.2653 6.77976Z"
        fill="#DEE2E5"
      />
      <path d="M26.091 0.00152588H20.0493V23.6678H26.091V0.00152588Z" fill="#DEE2E5" />
      <path d="M6.48703 0.00152588H0.445312V23.6678H6.48703V0.00152588Z" fill="#DEE2E5" />
      <path
        d="M109.196 15.8941C107.337 15.9307 105.495 15.5265 103.822 14.7145C102.323 13.986 101.051 12.8644 100.139 11.4693C99.2425 10.0569 98.7803 8.41225 98.8102 6.73948V0.00152588H106.066V6.44027C106.052 7.0405 106.191 7.63447 106.469 8.16647C106.722 8.65677 107.107 9.06712 107.579 9.3518C108.068 9.6422 108.628 9.79157 109.196 9.78335C109.778 9.80266 110.353 9.66213 110.861 9.37705C111.368 9.09198 111.787 8.67327 112.073 8.16647C112.369 7.64009 112.518 7.04393 112.505 6.44027V0.00152588H119.553V6.73948C119.583 8.41225 119.121 10.0569 118.224 11.4693C117.313 12.8644 116.04 13.986 114.542 14.7145C112.877 15.5221 111.046 15.9262 109.196 15.8941Z"
        fill="#DEE2E5"
      />
      <path d="M112.551 11.8318H105.79V23.662H112.551V11.8318Z" fill="#DEE2E5" />
    </g>
    <g mask="url(#moving-mask)">
      <path
        d="M78.3086 0.00152588H95.047V5.78431H85.0063V9.66827H93.7294V15.0483H85.0063V23.6678H78.3086V0.00152588Z"
        fill="#0086CB"
      />
      <path
        d="M40.4241 24.0015C38.5246 24.038 36.6434 23.6239 34.9348 22.7932C33.4023 22.0535 32.1026 20.907 31.1774 19.4789C30.2591 18.0385 29.7867 16.3591 29.8195 14.6512V0.00152588H37.2306V14.3463C37.2208 14.9467 37.3612 15.5401 37.6392 16.0725C37.9035 16.5741 38.3019 16.9924 38.79 17.2808C39.2891 17.5778 39.8606 17.7311 40.4414 17.7239C41.0463 17.7351 41.6429 17.582 42.1676 17.2808C42.6554 17.0003 43.0619 16.5978 43.3472 16.1128C43.6434 15.5861 43.7961 14.9908 43.7902 14.3866V0.00152588H51.0288V14.6512C51.0588 16.3587 50.5866 18.0374 49.6708 19.4789C48.7412 20.9074 47.4376 22.0538 45.9019 22.7932C44.1966 23.6215 42.3197 24.0355 40.4241 24.0015Z"
        fill="#0086CB"
      />
      <path
        d="M70.3394 14.8411C71.6002 14.1747 72.6488 13.168 73.366 11.9354C74.1093 10.6059 74.4846 9.10236 74.4535 7.57958C74.4756 6.1996 74.0979 4.84272 73.366 3.6726C72.6155 2.52244 71.5704 1.59452 70.3394 0.985474C68.9653 0.311483 67.4506 -0.0257889 65.9203 0.00153755H54.769V23.8001H61.6336V15.8769H62.7844L67.2437 23.8001H75.4375L70.2589 14.8757L70.3394 14.8411ZM61.6336 5.75555H64.6257C65.1459 5.73645 65.6636 5.83684 66.139 6.04901C66.52 6.23042 66.8312 6.53152 67.0251 6.90636C67.2262 7.30142 67.3251 7.7406 67.3128 8.18375C67.325 8.66383 67.2265 9.14028 67.0251 9.57622C66.8361 9.95447 66.54 10.2687 66.1735 10.4796C65.7395 10.7075 65.2537 10.8185 64.7638 10.8018H61.6336V5.75555Z"
        fill="#0086CB"
      />
      <path
        d="M13.2653 6.77976C11.468 6.77823 9.74485 6.06361 8.47401 4.79278C7.20318 3.52194 6.48855 1.79876 6.48703 0.00152588H0.445312C0.445313 3.40159 1.79598 6.66239 4.20019 9.0666C6.60439 11.4708 9.8652 12.8215 13.2653 12.8215C14.9493 12.8222 16.617 12.4912 18.173 11.8473C19.7291 11.2033 21.143 10.2592 22.3341 9.06863C23.5251 7.87812 24.4699 6.4646 25.1146 4.90884C25.7592 3.35307 26.091 1.68555 26.091 0.00152588L20.0492 0.00152588C20.0462 1.79929 19.3303 3.52246 18.0585 4.79313C16.7868 6.0638 15.063 6.77824 13.2653 6.77976Z"
        fill="#005580"
      />
      <path d="M26.091 0.00152588H20.0493V23.6678H26.091V0.00152588Z" fill="#0086CB" />
      <path d="M6.48703 0.00152588H0.445312V23.6678H6.48703V0.00152588Z" fill="#0086CB" />
      <path
        d="M109.196 15.8941C107.337 15.9307 105.495 15.5265 103.822 14.7145C102.323 13.986 101.051 12.8644 100.139 11.4693C99.2425 10.0569 98.7803 8.41225 98.8102 6.73948V0.00152588H106.066V6.44027C106.052 7.0405 106.191 7.63447 106.469 8.16647C106.722 8.65677 107.107 9.06712 107.579 9.3518C108.068 9.6422 108.628 9.79157 109.196 9.78335C109.778 9.80266 110.353 9.66213 110.861 9.37705C111.368 9.09198 111.787 8.67327 112.073 8.16647C112.369 7.64009 112.518 7.04393 112.505 6.44027V0.00152588H119.553V6.73948C119.583 8.41225 119.121 10.0569 118.224 11.4693C117.313 12.8644 116.04 13.986 114.542 14.7145C112.877 15.5221 111.046 15.9262 109.196 15.8941Z"
        fill="#0086CB"
      />
      <path d="M112.551 11.8318H105.79V23.662H112.551V11.8318Z" fill="#0086CB" />
    </g>
  </g>
</template>

<style>
@keyframes slideMask {
  0% {
    transform: translate3d(-240px, 0, 0);
  }
  100% {
    transform: translate3d(240px, 0, 0);
  }
}
</style>
