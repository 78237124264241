<script setup>
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
</script>

<script>
/* eslint-disable vue/component-api-style */
export default {
  /* FIXME: props 타입 정의 필요 */
  /* eslint-disable-next-line vue/require-prop-types */
  props: ['type'],
  emits: ['createDocument'],
  data() {
    return {
      image: {
        empty: '/icons/note_add.svg',
        search: '/icons/search.svg',
        archive: '/icons/note.svg',
      },
    }
  },
}
</script>

<template>
  <div class="empty-box flex w-full flex-wrap justify-center">
    <div class="flex flex-col gap-4">
      <div class="flex flex-wrap justify-center">
        <img class="add-icon" :src="image[type]" />
      </div>
      <div class="flex flex-wrap justify-center">
        <span class="h3 gray-7">
          {{ t(type + 'MessageMain') }}
        </span>
      </div>
      <div class="flex flex-wrap justify-center">
        <span class="p2 gray-6">
          {{ t(type + 'MessageSub') }}
        </span>
      </div>
    </div>
  </div>
</template>

<style scoped>
.empty-box {
  padding: 40px 0;
}
.createButton {
  width: 200px;
}
.add-icon {
  width: 40px;
  height: 40px;
  filter: invert(80%) sepia(12%) saturate(191%) hue-rotate(166deg) brightness(90%) contrast(83%);
}
</style>

<i18n>
{
  "en":{
    "myProjects": "My Projects",
    "emptyMessageMain": "Get Started on Murfy!",
    "emptyMessageSub": "New to Murfy? Let's create your first document!",
    "emptyMessageButton": "Create Document",
    "searchMessageMain": "No Project Found",
    "searchMessageSub": "Oops! It seems there are no results matching your search",
    "archiveMessageMain": "Can’t Find Archived Projects",
    "archiveMessageSub": "You don’t have any archived project.",
  },
}
</i18n>
