// https://github.com/primefaces/primevue/issues/3209
import toasteventbus from 'primevue/toasteventbus'

export const toastService = {
  previewUnsupported: (path: string) => {
    toasteventbus.emit('remove-group', 'previewUnsupported')
    toasteventbus.emit('add', {
      group: 'previewUnsupported',
      detail: path,
    })
  },
  /**
   * Show a toast as an error
   *
   * @param summary - The header of the toast
   * @param detail - The content of the toast
   * @param life - The life of the toast in milliseconds
   */
  error: (summary: string, detail: string = '', life = 3000) => {
    toasteventbus.emit('add', {
      severity: 'error',
      summary,
      detail,
      life,
    })
  },
  success: (summary: string, detail: string = '') => {
    toasteventbus.emit('add', {
      severity: 'success',
      summary,
      detail,
      life: 3000,
    })
  },
}
