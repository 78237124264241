import { INVITE_EDITOR_LINK_PREFIX, INVITE_VIEWER_LINK_PREFIX } from '@/config'
import { useErrorStore, usePermission, useProjectStore } from '@/stores'
import { toastService } from '@/utils/toast'
import { Permission, Role, useAPIClient } from '@murfy-package/api-client'
import { useClipboard } from '@vueuse/core'
import { defineStore } from 'pinia'
import { storeToRefs } from 'pinia'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

type ViewUserInfo = {
  id: string
  name: string
  email: string
  role: Role
  dropdownOpened: boolean
}

export const useShareStore = defineStore('share', () => {
  const { t } = useI18n()
  const apiClient = useAPIClient()
  const { setError } = useErrorStore()
  const { project } = storeToRefs(useProjectStore())
  const { checkPermission } = usePermission()

  // State Variables
  const loadingShareLink = ref(false)
  const shareLinkEditor = ref('')
  const shareLinkViewer = ref('')
  const loadingPeopleWithAccess = ref(false)
  const peopleWithAccess = ref<ViewUserInfo[]>([])
  const targetUserInfoToDelete = ref<ViewUserInfo | null>(null)
  const deleteModalVisible = ref(false)
  const deletingShare = ref(false)
  const roles = ref([
    { value: Role.editor, label: t('EDITOR') },
    { value: Role.viewer, label: t('VIEWER') },
    {
      value: 'delete',
      label: t('delete'),
      class:
        'text-destructive-primary hover:text-destructive-hover hover:bg-destructive-secondary-hover',
    },
  ])

  const { copy } = useClipboard()

  // Computed Properties
  const getMenuPermission = (role: Role | 'delete', personRole: Role) => {
    if (role === personRole) {
      return []
    }
    switch (role) {
      case Role.editor:
        return [Permission.projectRoleEditorCreate]
      case Role.viewer:
        return [Permission.projectRoleViewerCreate]
      case 'delete':
        return personRole === Role.viewer
          ? [Permission.projectRoleViewerDelete]
          : [Permission.projectRoleEditorDelete]
      default:
        return []
    }
  }

  const checkEditRolePermission = (role: Role) => {
    switch (role) {
      case Role.editor:
        return (
          checkPermission(Permission.projectRoleEditorUpdate) ||
          checkPermission(Permission.projectRoleEditorDelete)
        )
      case Role.viewer:
        return (
          checkPermission(Permission.projectRoleViewerUpdate) ||
          checkPermission(Permission.projectRoleViewerDelete)
        )
      default:
        return false
    }
  }

  // Methods
  const fetchInviteData = async () => {
    const projectId = project.value?.id
    if (!projectId) return
    targetUserInfoToDelete.value = null
    loadingShareLink.value = true
    try {
      const { editLink, viewLink } = await apiClient.invite.getInviteLink(projectId)
      shareLinkEditor.value = INVITE_EDITOR_LINK_PREFIX + editLink
      shareLinkViewer.value = INVITE_VIEWER_LINK_PREFIX + viewLink
    } catch (error) {
      setError(error)
    } finally {
      loadingShareLink.value = false
    }

    loadingPeopleWithAccess.value = true
    try {
      const members = await apiClient.project.getMembers(projectId)
      peopleWithAccess.value = members.map((member) => ({
        id: member.userId,
        name: member.name,
        email: member.email,
        role: member.role,
        dropdownOpened: false,
      }))
    } catch (error) {
      setError(error)
    } finally {
      loadingPeopleWithAccess.value = false
    }
  }

  const handleDropdownChange = (person: ViewUserInfo, dropdownValue: 'delete' | Role) => {
    if (dropdownValue === 'delete') {
      prepareDeleteShare(person)
    } else if (person.role !== dropdownValue) {
      person.role = dropdownValue
      changeUserRole(person.id, person.role).then(() => {})
    }
  }

  const prepareDeleteShare = (person: ViewUserInfo) => {
    targetUserInfoToDelete.value = person
    deleteModalVisible.value = true
    person.dropdownOpened = false
  }

  const confirmDeleteShare = async () => {
    if (!targetUserInfoToDelete.value) return
    await deleteUserRole(targetUserInfoToDelete.value.id)
    deleteModalVisible.value = false
  }

  const cancelDeleteShare = () => {
    targetUserInfoToDelete.value = null
    deleteModalVisible.value = false
  }

  const deleteUserRole = async (userId: string) => {
    const projectId = project.value?.id
    if (!projectId) return
    deletingShare.value = true
    try {
      await apiClient.project.deleteUserRole(projectId, userId)
      toastService.success(
        t('deleteShare.successToast.summary'),
        t('deleteShare.successToast.detail'),
      )
      peopleWithAccess.value = peopleWithAccess.value.filter((person) => person.id !== userId)
    } catch (error) {
      setError(error)
    } finally {
      deletingShare.value = false
    }
  }

  const changeUserRole = async (userId: string, role: Role) => {
    const projectId = project.value?.id
    if (!projectId) return
    const originalPeople = [...peopleWithAccess.value]
    peopleWithAccess.value = peopleWithAccess.value.map((person) =>
      person.id === userId ? { ...person, role } : person,
    )
    try {
      await apiClient.project.setUserRole(projectId, userId, role)
    } catch (error) {
      peopleWithAccess.value = originalPeople
      setError(error)
    }
  }

  const copyToClipboard = (text: string) => {
    copy(text)
    toastService.success(t('copiedToast.summary'), t('copiedToast.detail'))
  }

  return {
    // State
    loadingShareLink,
    shareLinkEditor,
    shareLinkViewer,
    loadingPeopleWithAccess,
    peopleWithAccess,
    targetUserInfoToDelete,
    deleteModalVisible,
    deletingShare,
    roles,

    // Methods
    fetchInviteData,
    handleDropdownChange,
    prepareDeleteShare,
    confirmDeleteShare,
    cancelDeleteShare,
    deleteUserRole,
    changeUserRole,
    copyToClipboard,
    getMenuPermission,
    checkEditRolePermission,
  }
})
