export default class ModalController {
  constructor(attrs) {
    this.visible = false
    this.isUploading = false
    this.event = {
      open: null,
      close: null,
      disable: null,
    }
    if (attrs) {
      for (const key in attrs) {
        this[key] = attrs[key]
      }
    }
  }
  open(args) {
    this.visible = true
    if (this.event.open) this.event.open(args)
  }
  close() {
    this.visible = false
    if (this.event.close) this.event.close()
  }
  disable() {
    if (this.event.disable) this.event.disable()
  }
  on(name, callback) {
    this.event[name] = callback
  }
  off(name) {
    delete this.event[name]
  }
}
