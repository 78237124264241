<script setup lang="ts">
import { i18n } from '@/main'
import { useAICommandStore } from '@/stores'
import { toastService } from '@/utils/toast'
import { API, useAPIClient } from '@murfy-package/api-client'
import { storeToRefs } from 'pinia'
import { Ref, computed, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { Task } from '../askAIStateMachine'
import AICommandFloatingView from './AICommandFloatingView.vue'

const { t } = useI18n()
defineProps<{
  visible?: boolean
}>()

const emit = defineEmits<{
  close: []
}>()

const aiCommandStore = useAICommandStore()
const { currentTask, currentSelectedText } = storeToRefs(aiCommandStore)

const useAICommand = (task: Ref<Task | undefined>, selectedText: Ref<string | undefined>) => {
  const apiClient = useAPIClient()
  let cancel: () => void = () => {}
  const loading = ref(false)
  const error = ref<Error>()
  const contentHistory = ref<string[]>([])
  const currentContentIndex = ref(0)
  const content = computed(() => contentHistory.value[currentContentIndex.value])
  const doTask = async () => {
    if (!task.value || !selectedText.value) return
    const { token: cancelToken, cancel: cancelRetry } = API.createCancelToken()
    cancel = cancelRetry
    loading.value = true
    try {
      const { id: _requestID, content: responseContent } = await apiClient.askAI[task.value](
        selectedText.value,
        { cancelToken },
      )
      contentHistory.value.push(responseContent)
      currentContentIndex.value = contentHistory.value.length - 1
      return responseContent
    } catch (e) {
      if (API.isCancel(e)) {
        return
      } else if (e instanceof Error) {
        error.value = e
      } else {
        error.value = new Error(t('UnknownError'))
      }
    } finally {
      loading.value = false
    }
  }
  const reset = () => {
    contentHistory.value = []
    currentContentIndex.value = 0
    error.value = undefined
    cancel = () => {}
  }
  return {
    doTask,
    cancel,
    content,
    contentHistory,
    currentContentIndex,
    loading,
    error,
    reset,
  }
}
const { doTask, cancel, content, contentHistory, currentContentIndex, loading, error, reset } =
  useAICommand(currentTask, currentSelectedText)

const handlePrev = () => {
  if (currentContentIndex.value > 0) {
    currentContentIndex.value--
  }
}
const handleNext = () => {
  if (currentContentIndex.value < contentHistory.value.length - 1) {
    currentContentIndex.value++
  }
}
onMounted(() => {
  watch(
    [currentTask, currentSelectedText],
    async () => {
      reset()
      doTask()
    },
    { immediate: true },
  )
})

const handleClose = () => {
  cancel()
  emit('close')
}
const handleCopy = () => {
  if (!content.value) return
  navigator.clipboard.writeText(content.value)
  toastService.success(t('Copied'))
}
const handleRetry = () => {
  const markedContent = aiCommandStore.getMarkedContent()
  if (markedContent === currentSelectedText.value) {
    doTask()
  } else {
    currentSelectedText.value = markedContent
  }
}
</script>

<template>
  <div class="absolute z-20">
    <AICommandFloatingView
      v-show="visible"
      class="mt-2"
      :title="currentTask ? i18n.t(`global.aiCommand.${currentTask}`) : ''"
      :content="content"
      :loading="loading"
      :errorTitle="error?.name"
      :errorContent="error?.message"
      :currentContentNumber="currentContentIndex + 1"
      :totalContentNumber="contentHistory.length"
      @close="handleClose"
      @retry="handleRetry"
      @copy="handleCopy"
      @next="handleNext"
      @prev="handlePrev"
    />
  </div>
</template>

<i18n>
{
  "en": {
    "Copied": "Copied",
    "UnknownError": "Unknown error"
  },
  "ko": {
    "Copied": "복사되었습니다",
    "UnknownError": "알 수 없는 오류"
  }
}
</i18n>
