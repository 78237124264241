import type { ChangeSpec, EditorState, Text } from '@codemirror/state'
import type { EditorView } from '@codemirror/view'
import { ShallowRef, computed, ref } from 'vue'

import {
  DISABLE_COMMENT,
  isLinterDisabled,
} from '../extensions/languages/latex/linter/disable-lint'

const disableCommentLength = DISABLE_COMMENT.length

// lint를 비활성화하는 문자열을 제거하는 함수
const removeAllLintDisableComments = (view: EditorView) => {
  const state: EditorState = view.state
  const changes: ChangeSpec[] = []
  const doc: Text = state.doc

  // 특정 문자열을 찾아서 제거
  for (let pos = 0; pos < doc.length; ) {
    const found = doc.sliceString(pos).indexOf(DISABLE_COMMENT)
    if (found === -1) break
    const from = pos + found
    // 첫 번째 줄일 때만 개행 문자까지 제거
    const to =
      from + (doc.lineAt(from).number === 1 ? disableCommentLength + 1 : disableCommentLength)
    changes.push({ from, to })
    pos = to + 1
  }

  if (!changes.length) {
    return
  }
  view.dispatch(
    state.update({
      changes,
    }),
  )
}

export const useSyntaxChecker = (
  // FIXME: 정상적인 ref가 쓰이면 바꿔야 함
  editorViewRef: ShallowRef<EditorView | undefined>,
) => {
  const doc = computed(() => editorViewRef.value?.state.doc)
  const syntaxCheckingValue = ref(doc.value ? isLinterDisabled(doc.value) : false)
  const syntaxChecking = computed({
    get: () => syntaxCheckingValue.value,
    set: (value) => {
      if (value === syntaxCheckingValue.value || !editorViewRef.value) {
        return
      }
      value ? turnOnSyntaxChecker(editorViewRef.value) : turnOffSyntaxChecker(editorViewRef.value)
    },
  })

  const turnOffSyntaxChecker = (view: EditorView) => {
    if (!view || isLinterDisabled(view.state.doc)) {
      return
    }
    const { state } = view
    const transaction = state.update({
      changes: {
        // 라인은 1부터 시작
        from: state.doc.line(1).from,
        insert: DISABLE_COMMENT + '\n',
      },
    })
    view.dispatch(transaction)
  }

  const turnOnSyntaxChecker = (view: EditorView) => {
    if (!view || !isLinterDisabled(view.state.doc)) {
      return
    }
    // 엔터를 지우기 위해 1을 추가
    removeAllLintDisableComments(view)
  }
  const checkState = (text: Text) => {
    // 추가 이벤트가 일어나지 않도록
    syntaxCheckingValue.value = !isLinterDisabled(text)
  }
  return {
    syntaxChecking,
    checkState,
    turnOffSyntaxChecker,
    turnOnSyntaxChecker,
  }
}
