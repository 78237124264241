<script setup lang="ts">
import { useElementBounding } from '@vueuse/core'
import { ScrollArea } from 'radix-vue/namespaced'
import { computed, ref, useSlots, watch } from 'vue'

import { IconDescendingBars } from '../../atoms'
import { BaseButton } from '../../molecules'
import LoadingCover from '../LoadingCover/LoadingCover.vue'

export type TableHeadItem = {
  key: string
  width?: string
  label?: string
  align?: 'start' | 'center' | 'end'
  sortable?: boolean
  compareFunction?: (a: unknown, b: unknown) => number
}

export type TableBodyItem = {
  key: string
  disabled?: boolean
  [key: string]: unknown
}

const props = withDefaults(
  defineProps<{
    headItems: TableHeadItem[]
    bodyItems: TableBodyItem[]
    size?: 'sm' | 'md'
    selectable?: boolean
    clickable?: boolean
    defaultSortKey?: string
    defaultSortOrder?: 'asc' | 'desc'
    loading?: boolean
    loadingMessage?: string
  }>(),
  {
    size: 'sm',
    defaultSortKey: undefined,
    defaultSortOrder: 'asc',
    loadingMessage: '',
  },
)

const emit = defineEmits<{
  clickRow: [item: TableBodyItem]
}>()

const slots = useSlots()
const headSuffix = '-head'
const bodySuffix = '-body'

const selectModel = defineModel<string[]>('selectModel', {
  default: [],
})
const selectAll = ref(false)
watch(
  () => selectModel.value.length,
  () => {
    selectAll.value = selectModel.value.length === props.bodyItems.length
  },
)
const handleSelectAll = () => {
  if (selectAll.value) {
    selectModel.value = props.bodyItems.map((item) => item.key)
  } else {
    selectModel.value = []
  }
}

const handleClickRow = (item: TableBodyItem) => {
  if (props.clickable && !item.disabled) {
    emit('clickRow', item)
  }
}

const viewBodyItems = ref<TableBodyItem[]>(props.bodyItems)
const currentSortKey = ref<string | undefined>(props.defaultSortKey)
const currentSortOrder = ref<'asc' | 'desc'>(props.defaultSortOrder)
const currentCompareFunction = computed(() => {
  const headItem = props.headItems.find((item) => item.key === currentSortKey.value)
  return headItem?.compareFunction
})
const sortedBodyItemsByKey = (sortKey: string) =>
  [...props.bodyItems].sort((a, b) => {
    const left = a[sortKey]
    const right = b[sortKey]
    if (!left || !right) {
      return 0
    }
    if (currentCompareFunction.value) {
      return currentSortOrder.value === 'desc'
        ? currentCompareFunction.value(left, right)
        : currentCompareFunction.value(right, left)
    } else if (typeof left === 'string' && typeof right === 'string') {
      return currentSortOrder.value === 'desc'
        ? left.localeCompare(right)
        : right.localeCompare(left)
    } else if (typeof left === 'number' && typeof right === 'number') {
      return currentSortOrder.value === 'desc' ? left - right : right - left
    } else {
      return 0
    }
  })

watch(
  () => props.bodyItems,
  () => {
    selectModel.value = []
    if (currentSortKey.value) {
      viewBodyItems.value = sortedBodyItemsByKey(currentSortKey.value)
    } else {
      viewBodyItems.value = props.bodyItems
    }
  },
  { deep: true, immediate: true },
)
const handleClickSort = (sortKey: string) => {
  if (currentSortKey.value === sortKey) {
    currentSortOrder.value = currentSortOrder.value === 'asc' ? 'desc' : 'asc'
  } else {
    currentSortKey.value = sortKey
  }
  viewBodyItems.value = sortedBodyItemsByKey(sortKey)
}

// 각 Column의 너비를 계산하기 위한 로직
const tableRef = ref<HTMLDivElement | null>(null)
const columnWidths = ref<number[]>([])
const getColumnWidths = () => {
  const columns = tableRef.value?.querySelectorAll('th')
  if (!columns) {
    return []
  }
  return Array.from(columns).map((column) => column.getBoundingClientRect().width)
}
const { width } = useElementBounding(tableRef)
watch(
  width,
  () => {
    columnWidths.value = getColumnWidths()
  },
  { immediate: true },
)
</script>

<template>
  <table
    ref="tableRef"
    class="border-color-border-primary flex h-full w-full border-separate border-spacing-0 flex-col rounded-lg border px-4 py-[14px]"
    :class="[size === 'sm' ? 'gap-1' : 'gap-3']"
  >
    <thead
      class="border-color-border-primary block overflow-hidden border-b pb-3 pl-2.5 pr-10 pt-1"
    >
      <tr class="flex h-10 items-center justify-stretch gap-5">
        <th v-if="selectable" class="w-[22px] flex-none">
          <div class="flex items-center justify-center">
            <input
              v-model="selectAll"
              type="checkbox"
              :disabled="loading"
              @change="handleSelectAll"
            />
          </div>
        </th>
        <th
          v-for="headItem in headItems"
          :key="headItem.key"
          :class="[
            headItem.align ? `justify-${headItem.align}` : '',
            headItem.width === 'auto' ? 'flex-1' : 'flex-none',
          ]"
          :style="{ width: headItem.width }"
          class="text-color-text-primary head-sm border-color-border-primary flex items-center gap-2"
        >
          <slot
            v-if="slots[headItem.key + headSuffix]"
            :name="headItem.key + headSuffix"
            :item="headItem"
          />
          <template v-else>
            {{ headItem.label }}
          </template>
          <BaseButton
            v-if="headItem.sortable && !loading"
            size="xs"
            severity="secondary"
            variant="text"
            @click="handleClickSort(headItem.key)"
          >
            <template #icon>
              <IconDescendingBars />
            </template>
          </BaseButton>
        </th>
      </tr>
    </thead>
    <ScrollArea.Root class="h-full overflow-hidden" type="hover">
      <ScrollArea.Viewport class="h-full w-full">
        <LoadingCover v-if="loading" :message="loadingMessage" />
        <tbody
          v-show="!loading"
          class="flex flex-col pr-[30px]"
          :class="[size === 'sm' ? 'gap-1' : 'gap-3']"
        >
          <tr
            v-for="(bodyItem, rowIndex) in viewBodyItems"
            :key="bodyItem.key"
            class="flex items-center gap-5 rounded-lg px-2.5 py-2"
            :class="[
              selectModel.includes(bodyItem.key) ? 'bg-color-bg-info-tertiary-pressed' : '',
              props.clickable && !bodyItem.disabled
                ? 'hover:bg-color-bg-info-tertiary-hover active:bg-color-bg-info-tertiary-pressed cursor-pointer'
                : '',
              bodyItem.disabled ? 'cursor-not-allowed' : '',
            ]"
            @click="handleClickRow(bodyItem)"
          >
            <td v-if="selectable" class="w-[22px] flex-none">
              <div class="flex items-center justify-center">
                <input
                  :id="bodyItem.key"
                  v-model="selectModel"
                  type="checkbox"
                  :value="bodyItem.key"
                  @click.stop
                />
              </div>
            </td>
            <td
              v-for="(headItem, colIndex) in headItems"
              :key="`${rowIndex}-${colIndex}`"
              :class="[
                bodyItem.align
                  ? `flex justify-${bodyItem.align}`
                  : headItem.align
                    ? `flex justify-${headItem.align}`
                    : '',
                bodyItem.disabled ? 'text-color-text-disabled' : 'text-color-text-primary',
              ]"
              :style="{ width: `${columnWidths[colIndex + (selectable ? 1 : 0)]}px` }"
              class="body-md flex-none"
            >
              <slot
                v-if="slots[headItem.key + bodySuffix]"
                :name="headItem.key + bodySuffix"
                :item="bodyItem"
              />
              <template v-else>
                {{ bodyItem[headItem.key] }}
              </template>
            </td>
          </tr>
        </tbody>
      </ScrollArea.Viewport>
      <ScrollArea.Scrollbar
        class="flex w-4 touch-none select-none bg-transparent px-1 transition-colors duration-[160ms] ease-out data-[orientation=horizontal]:h-2.5 data-[orientation=horizontal]:flex-col"
        orientation="vertical"
      >
        <ScrollArea.Thumb
          class="relative flex-1 rounded-full bg-gray-300 before:absolute before:left-1/2 before:top-1/2 before:h-full before:min-h-[44px] before:w-full before:min-w-[44px] before:-translate-x-1/2 before:-translate-y-1/2 before:content-['']"
        />
      </ScrollArea.Scrollbar>
    </ScrollArea.Root>
  </table>
</template>
