<script setup lang="ts">
import { EditorHeaderMainMenu } from '@/components'
import { EditorHeaderRenameBox } from '@/components/legacy'
import {
  ProjectUILayout,
  ProjectUIMode,
  useEditorStore,
  useProjectCompileStore,
  useProjectUIStore,
} from '@/stores'
import {
  BaseBadge,
  BaseButton,
  IconBase,
  IconLayoutDouble,
  IconLayoutSingle,
  IconPlayF,
  IconRotateRight,
  IconStopF,
  IconTrash,
  RadioGroup,
} from '@murfy-package/murds'
import { storeToRefs } from 'pinia'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const { isSaving, isModifiedAfterSave } = storeToRefs(useEditorStore())
const projectUIStore = useProjectUIStore()
const { layout, mode, compileLogVisible } = storeToRefs(projectUIStore)
const { isRenderingPdf, isCleanCompileCache, pdfPreviewSrc, previewLog, parsedCompileLog } =
  storeToRefs(useProjectCompileStore())
const { renderPdf, cleanCompileCache } = useProjectCompileStore()
const isLocalOrDev = computed(
  () => import.meta.env.VITE_ENV === 'LOCAL' || import.meta.env.VITE_ENV === 'DEV',
)

const currentLayout = ref<ProjectUILayout>(layout.value)
watch(currentLayout, (newLayout) => {
  layout.value = newLayout
  if (newLayout === ProjectUILayout.Double) {
    renderPdf()
  }
})

const startPreview = async () => {
  mode.value = ProjectUIMode.Preview
  await renderPdf()
}
const refreshPreview = async () => {
  await renderPdf()
}
const cleanCompile = async () => {
  await cleanCompileCache()
  await renderPdf()
}
const stopPreview = () => {
  mode.value = ProjectUIMode.Edit
}

const renameBoxRef = ref<InstanceType<typeof EditorHeaderRenameBox> | null>(null)
const onRename = () => {
  renameBoxRef.value?.setFocus()
}

// preview log 관련
enum PreviewLogStatus {
  Default = 'default',
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
}
const previewLogCount = computed(() => parsedCompileLog.value.length)
const previewLogStatus = computed(() => {
  if (!pdfPreviewSrc.value && previewLog.value === '') {
    return PreviewLogStatus.Default
  }
  if (previewLogCount.value > 0) {
    if (parsedCompileLog.value.some((log) => log.level === 'error')) {
      return PreviewLogStatus.Error
    } else if (parsedCompileLog.value.some((log) => log.level === 'warning')) {
      return PreviewLogStatus.Warning
    }
  }
  return PreviewLogStatus.Success
})
const previewLogBadgeType = computed(() =>
  previewLogStatus.value === PreviewLogStatus.Default ? 'secondary' : 'primary',
)
const previewLogBadgeColor = computed(() => {
  switch (previewLogStatus.value) {
    case PreviewLogStatus.Default:
      return 'gray'
    case PreviewLogStatus.Success:
      return 'green'
    case PreviewLogStatus.Warning:
      return 'yellow'
    case PreviewLogStatus.Error:
      return 'red'
  }
  return 'red'
})

const onClickPreviewLogStatus = () => {
  // previewLog가 없으면 동작하지 않음.
  if (previewLog.value === '') {
    return
  }
  // pdfPreview가 없고 single layout이면 preview버튼과 동일하게 동작함.
  if (!pdfPreviewSrc.value && layout.value === ProjectUILayout.Single) {
    if (compileLogVisible.value) {
      mode.value = ProjectUIMode.Edit
    } else {
      mode.value = ProjectUIMode.Preview
      compileLogVisible.value = !compileLogVisible.value
    }
    return
  }
  // pdfPreview가 있으면 pdfPreview <-> previewLog 전환
  // single layout 이고 mode가 edit이면 preview로 변경
  if (layout.value === ProjectUILayout.Single && mode.value === ProjectUIMode.Edit) {
    mode.value = ProjectUIMode.Preview
  }
  compileLogVisible.value = !compileLogVisible.value
  return
}
</script>

<template>
  <div
    class="border-gray-3 bg-gray-white-n-black grid grid-flow-col grid-cols-[repeat(2,fit-content(100px))_1fr_repeat(3,fit-content(100px))] grid-rows-1 content-between items-center gap-4 border-b px-4 py-3.5"
  >
    <div class="p-2">
      <EditorHeaderMainMenu @rename="onRename" />
    </div>
    <div class="grid grid-flow-col items-center gap-2">
      <EditorHeaderRenameBox ref="renameBoxRef" class="max-h-[2.2rem]" />
      <span v-if="isSaving">{{ t('saving') }}</span>
      <span v-if="isModifiedAfterSave">*</span>
    </div>
    <div />
    <RadioGroup v-model="currentLayout" :options="[ProjectUILayout.Single, ProjectUILayout.Double]">
      <template #default="{ option }">
        <IconBase>
          <IconLayoutSingle v-if="option === ProjectUILayout.Single" />
          <IconLayoutDouble v-else />
        </IconBase>
      </template>
    </RadioGroup>
    <template v-if="mode === ProjectUIMode.Edit">
      <BaseButton
        v-if="currentLayout === ProjectUILayout.Single"
        :disabled="isRenderingPdf || isCleanCompileCache"
        class="whitespace-nowrap"
        @click="startPreview"
      >
        <template #icon>
          <IconPlayF />
        </template>
        {{ t('preview') }}
      </BaseButton>
      <BaseButton
        v-else
        :disabled="isRenderingPdf || isCleanCompileCache"
        class="whitespace-nowrap"
        @click="refreshPreview"
      >
        <template #icon>
          <IconRotateRight />
        </template>
        {{ t('refresh') }}
      </BaseButton>
    </template>
    <template v-else>
      <BaseButton
        :disabled="isRenderingPdf || isCleanCompileCache"
        class="whitespace-nowrap"
        @click="stopPreview"
      >
        <template #icon>
          <IconStopF />
        </template>
        {{ t('endPreview') }}
      </BaseButton>
    </template>

    <BaseButton
      v-if="isLocalOrDev"
      :disabled="isRenderingPdf || isCleanCompileCache"
      severity="info"
      class="whitespace-nowrap"
      @click="cleanCompile"
    >
      <template #icon>
        <IconTrash />
      </template>
      {{ t('cleanAndRender') }}
    </BaseButton>
    <BaseBadge
      :class="[previewLog.length > 0 ? 'cursor-pointer' : 'cursor-default']"
      :title="t('logTitle')"
      :label="String(previewLogCount)"
      :color="previewLogBadgeColor"
      :type="previewLogBadgeType"
      @click="onClickPreviewLogStatus"
    />
  </div>
</template>

<i18n>
{
  "en": {
    "single": "Editor Only",
    "double": "Editor & Preview",
    "saving": "Saving",
    "layout": "Layout",
    "preview": "Preview",
    "refresh": "Refresh Preview",
    "endPreview": "End Preview",
    "logTitle": "Logs and output files",
    "share": "Share",
    "comments": "Comments",
    "cleanAndRender": "Clean and render"
  },
}
</i18n>
