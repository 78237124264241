<script setup lang="ts">
import { computed } from 'vue'

const props = withDefaults(
  defineProps<{
    label: string
    scale?: 'h5' | 'p1' | 'p2' | 'p3'
    severity?: 'primary' | 'secondary' | 'error'
  }>(),
  {
    scale: 'p1',
    severity: 'primary',
  },
)

const emit = defineEmits<{
  click: []
}>()

const textColor = computed(() => {
  if (props.severity === 'secondary') {
    return 'gray-6'
  } else if (props.severity === 'error') {
    return 'destructive-primary'
  }
  return 'cta-primary'
})
</script>

<template>
  <button :class="[$style.button, props.scale, textColor]" @click="emit('click')">
    {{ props.label }}
  </button>
</template>

<style module>
.button {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
}
.button:hover {
  color: var(--cta-hover);
}
.button:active {
  color: var(--cta-pressed);
}
</style>
