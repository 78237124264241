<script setup lang="ts">
import { useElementBounding, useTextareaAutosize } from '@vueuse/core'
import { computed, ref, watch } from 'vue'

import { BaseButton } from '..'
import { IconArrowNarrowUp } from '../../atoms'

type TextAreaProps = {
  modelValue?: string
  placeholder?: string
  disabled?: boolean
  readonly?: boolean
  loading?: boolean
  severity?: 'default' | 'error'
}

const props = withDefaults(defineProps<TextAreaProps>(), {
  modelValue: '',
  placeholder: '',
  disabled: false,
  readonly: false,
  severity: 'default',
})

type TextInputEmits = {
  focus: []
  blur: []
  input: [value: Event]
  keydown: [event: KeyboardEvent]
  submit: [value: string]
  paste: [event: ClipboardEvent]
  'update:modelValue': [value: string]
}
const emit = defineEmits<TextInputEmits>()

const { textarea, input } = useTextareaAutosize()
/**
 * 한글 조합 입력 상태를 추적하는 변수 추가
 * https://developer.mozilla.org/en-US/docs/Web/API/Element/compositionstart_event
 */
const isComposing = ref(false)

watch(
  () => props.modelValue,
  () => {
    input.value = props.modelValue
  },
  { immediate: true },
)

watch(input, () => {
  if (!isComposing.value) {
    emit('update:modelValue', input.value)
  }
})

defineExpose({
  focus: () => textarea.value?.focus(),
  blur: () => textarea.value?.blur(),
})

const handleCompositionStart = () => {
  isComposing.value = true
}

const handleCompositionEnd = () => {
  isComposing.value = false
  emit('update:modelValue', input.value)
}

const handleKeydown = (event: KeyboardEvent) => {
  if (event.key === 'Enter' && !event.shiftKey && !isComposing.value) {
    event.preventDefault()
    handleSubmit()
  } else {
    emit('keydown', event)
  }
}
const handleSubmit = () => {
  emit('submit', input.value)
}
const singleLineRef = ref<HTMLElement | null>(null)
const { width: singleLineWidth } = useElementBounding(singleLineRef)
const containerRef = ref<HTMLElement | null>(null)
const { width: containerWidth } = useElementBounding(containerRef)
/**
 * 단일 라인의 최대 너비를 계산
 *
 * `컨테이너 너비 - (버튼 너비 * 2) - (패딩 * 2)`
 */
const singleLineMaxWidth = computed(() => containerWidth.value - 36 * 2 - 16 * 2)
const isMultiLine = computed(
  () => singleLineWidth.value > singleLineMaxWidth.value || input.value.includes('\n'),
)
</script>

<template>
  <label
    ref="containerRef"
    class="bg-color-bg-global-input-default hover:bg-color-bg-global-input-hover focus-within:border-color-border-focused disabled:bg-color-bg-global-input-disabled disabled:border-color-border-disabled flex w-full items-center rounded-lg border px-4 py-2"
    :class="[
      severity === 'error' ? 'border-color-border-danger' : 'border-color-border-primary',
      isMultiLine ? 'flex-col' : 'flex-row',
    ]"
  >
    <Teleport to="body">
      <span
        ref="singleLineRef"
        class="body-md absolute bottom-0 left-0 max-w-[100vw] overflow-hidden text-transparent"
        >{{ input }}</span
      >
    </Teleport>
    <textarea
      ref="textarea"
      v-model="input"
      autocomplete="off"
      wrap="hard"
      class="body-md placeholder-color-text-secondary text-color-text-primary disabled:text-color-text-tertiary scrollbar-hide box-border w-full resize-none bg-transparent focus:outline-none"
      :placeholder="placeholder"
      :disabled="disabled || loading"
      :readonly="readonly"
      @focus="$emit('focus')"
      @blur="$emit('blur')"
      @input="$emit('input', $event)"
      @keydown="handleKeydown"
      @compositionstart="handleCompositionStart"
      @compositionend="handleCompositionEnd"
      @paste="$emit('paste', $event)"
    />
    <div class="flex items-end justify-end" :class="[isMultiLine ? 'w-full' : 'flex-none']">
      <slot name="actions" />
      <BaseButton
        severity="secondary"
        variant="text"
        :loading="loading"
        :disabled="disabled"
        @click.stop="handleSubmit"
      >
        <template #icon>
          <slot name="submitIcon">
            <IconArrowNarrowUp />
          </slot>
        </template>
      </BaseButton>
    </div>
  </label>
</template>
