<script setup lang="ts">
import { useProjectStore, useProjectUIStore, useSyncTexStore } from '@/stores'
import { type PDFSource, PDFViewer } from '@murfy-package/murds'
import { clamp } from '@vueuse/core'
import { storeToRefs } from 'pinia'
import { ref, watch } from 'vue'

const PDF_HIGHLIGHT_TOP_OFFSET_IN_PIXEL = 10

withDefaults(
  defineProps<{
    src: PDFSource
    title?: string
  }>(),
  {
    title: 'document',
  },
)

const syncTexStore = useSyncTexStore()
const projectStore = useProjectStore()
const projectUIStore = useProjectUIStore()
const { syncCodeToPdfResults } = storeToRefs(syncTexStore)

const handlePageDblclick = (event: MouseEvent, pageNumber: number, scale: number) => {
  if (projectUIStore.layout === 'single') return
  const projectId = projectStore.projectId
  if (!projectId) return
  const boundingRect = (event.currentTarget as HTMLDivElement).getBoundingClientRect()
  const offsetX = (event.clientX - boundingRect.left) / scale
  const offsetY = (event.clientY - boundingRect.top) / scale
  syncTexStore.syncPdfToCode(projectId, pageNumber, offsetX, offsetY)
}

const pdfViewer = ref<InstanceType<typeof PDFViewer> | null>(null)
watch(syncCodeToPdfResults, (results) => {
  if (!pdfViewer.value) return
  const highlights = results.map((result) => ({
    pageNumber: result.page,
    rect: {
      top: clamp(result.v - PDF_HIGHLIGHT_TOP_OFFSET_IN_PIXEL, 0, Infinity),
      left: clamp(result.h, 0, Infinity),
      width: clamp(result.width, 0, Infinity),
      height: clamp(result.height, 0, Infinity),
    },
  }))
  pdfViewer.value.drawHighlight(highlights)
})
</script>

<template>
  <PDFViewer ref="pdfViewer" :title="title" :src="src" @pageDblclick="handlePageDblclick" />
</template>
