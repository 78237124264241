<script setup lang="ts">
import { BaseButton } from '@murfy-package/ui'
import Dialog from 'primevue/dialog'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const props = withDefaults(
  defineProps<{
    target: string
    visible: boolean
    folder?: boolean
  }>(),
  {
    folder: false,
  },
)

const emit = defineEmits<{
  close: []
  confirm: []
}>()

watch(props, (newValue) => {
  if (!newValue.visible) {
    inputTextValue.value = ''
  }
})
const inputTextValue = ref('')

const content = computed(() =>
  props.folder
    ? t('content.folder', { target: props.target })
    : t('content.file', { target: props.target }),
)
</script>

<template>
  <Dialog
    :visible="props.visible"
    modal
    :closable="false"
    :class="$style.modal"
    :pt="{ footer: $style.footer }"
  >
    <template #header>
      <div :class="$style.header">
        <div :class="['h2', 'gray-9']">
          {{ t(`header.${folder ? 'folder' : 'file'}`) }}
        </div>
      </div>
    </template>
    <template #default>
      <div class="body-md">
        {{ content }}
      </div>
    </template>
    <template #footer>
      <BaseButton severity="tertiary" :class="$style.button" @click="emit('close')">
        {{ t('cancel') }}
      </BaseButton>
      <BaseButton
        severity="secondary"
        actionType="destructive"
        :class="$style.button"
        @click="emit('confirm')"
      >
        {{ t('delete') }}
      </BaseButton>
    </template>
  </Dialog>
</template>

<style module>
.modal {
  max-width: 480px;
}
.header {
  display: flex;
  align-items: center;
  justify-content: center;
}
.inputBox {
  display: flex;
  flex-direction: column;
  padding-top: 16px;
  gap: 16px;
}

.footer {
  display: flex;
  justify-content: space-around;
  overflow: hidden;
  gap: 0.5rem;
}

.button {
  flex-grow: 1;
  justify-content: center;
}
</style>

<i18n>
{
  "en": {
    "header": {
      "folder": "Delete folder",
      "file": "Delete file",
    },
    "content": {
      "folder": "Are you sure you want to delete this folder? When you delete the folder, shared recipients will no longer have access to it. This operation cannot be cancelled",
      "file": "Are you sure you want to delete {target}? Once deleted, it cannot be recovered.",
    },
    "placeholder": "Delete {target}",
    "cancel": "Cancel",
    "delete": "Delete",
  }, 
}
</i18n>
