<template>
  <g clip-path="url(#clip0_8646_28470)">
    <path
      d="M22.495 11.8937C19.3414 11.891 16.3177 10.637 14.0878 8.40713C11.8579 6.17721 10.604 3.15357 10.6013 0H0C0 5.96603 2.37 11.6877 6.58862 15.9063C10.8072 20.125 16.5289 22.495 22.495 22.495C25.4499 22.4963 28.3761 21.9154 31.1065 20.7855C33.8369 19.6556 36.3179 17.9989 38.4078 15.9099C40.4977 13.8209 42.1556 11.3406 43.2867 8.61078C44.4178 5.88091 45 2.95493 45 0L34.3987 0C34.3934 3.1545 33.1372 6.17812 30.9057 8.40774C28.6741 10.6374 25.6495 11.891 22.495 11.8937Z"
      fill="#005580"
    />
    <path d="M45 0H34.3987V41.5268H45V0Z" fill="#0086CB" />
    <path d="M10.6013 0H0V41.5268H10.6013V0Z" fill="#0086CB" />
  </g>
  <defs>
    <clipPath id="clip0_8646_28470">
      <rect width="45" height="41.53" fill="white" />
    </clipPath>
  </defs>
</template>
