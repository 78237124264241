<script setup lang="ts">
import { useCommentStore } from '@/stores'
import type { EditorView } from '@codemirror/view'
import { TextArea } from '@murfy-package/murds'
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { useCursor } from '@/editor/hooks/useCursor'

import { Actions, AskAIStateMachine } from './askAIStateMachine'

const { t } = useI18n()

const props = defineProps<{
  view: EditorView
  stateMachine: AskAIStateMachine
}>()

const viewText = ref('')
const { addComment, openComment } = useCommentStore()

const loading = ref(false)
const handleSubmit = (value: string) => {
  if (value.length === 0) return
  // call api to get comment id
  const { from, to } = props.view.state.selection.main
  loading.value = true
  addComment(value, from, to)
    .then((comment) => {
      if (!comment) return
      viewText.value = ''
      openComment(comment.id)
      useCursor(props.view).set(to)
    })
    .finally(() => {
      props.stateMachine.send(Actions.SelectionChanged())
      loading.value = false
    })
}

const textarea = ref<HTMLTextAreaElement | null>(null)
onMounted(() => {
  textarea.value?.focus()
})
const handleEscape = () => {
  props.view.focus()
  props.stateMachine.send(Actions.SelectionChanged())
}
</script>

<template>
  <div
    class="bg-color-bg-global-primary border-color-border-primary pointer-events-auto flex w-[384px] flex-row gap-3 rounded-lg border p-2"
  >
    <TextArea
      ref="textarea"
      v-model="viewText"
      :placeholder="t('addComment')"
      :loading="loading"
      @submit="handleSubmit"
      @keydown.esc="handleEscape"
    />
  </div>
</template>

<i18n>
{
  "en": {
    "addComment": "Add a comment"
  }
}
</i18n>
