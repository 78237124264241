<script setup lang="ts">
import { useErrorStore, useProjectStore } from '@/stores'
import { usePermission } from '@/stores/permission'
import { Permission, useAPIClient } from '@murfy-package/api-client'
import { BaseButton, TextInput } from '@murfy-package/murds'
import { storeToRefs } from 'pinia'
import { watch } from 'vue'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { AccessControl } from '..'

const { t } = useI18n()
const apiClient = useAPIClient()
const { setError } = useErrorStore()
const { project } = storeToRefs(useProjectStore())

const focused = ref(false)
const inputBoxRef = ref<HTMLInputElement | null>(null)
const buttonRef = ref<InstanceType<typeof BaseButton> | null>(null)
const viewProjectName = ref('')

watch(
  project,
  (newProjectInfo) => (viewProjectName.value = newProjectInfo?.name ?? viewProjectName.value),
)
/**
 * Set focus on the input box
 */
const setFocus = () => {
  inputBoxRef.value?.focus()
}

defineExpose({
  setFocus,
})

const onFocus = () => {
  focused.value = true
}

const onBlur = (event: FocusEvent) => {
  // 다음 focus가 BaseButton일 경우에는 실행하지 않음
  if (event.relatedTarget === buttonRef.value?.$el) {
    return
  }
  focused.value = false
  viewProjectName.value = project.value?.name ?? ''
}

const onSaveTitleWithEnter = () => {
  if (inputBoxRef.value) {
    inputBoxRef.value.blur()
  }
  onSaveTitle()
}

const onSaveTitle = async () => {
  if (!inputBoxRef.value || !project.value) {
    focused.value = false
    return
  }
  const newName = viewProjectName.value
  if (project.value.name === newName) {
    focused.value = false
    return
  }

  try {
    const newProject = await apiClient.project.rename(project.value.id, newName)
    project.value = { ...project.value, ...newProject }
  } catch (error) {
    setError(error)
    if (!project.value) {
      return
    }
    viewProjectName.value = project.value.name
  } finally {
    focused.value = false
  }
}

const { checkPermission } = usePermission()
</script>

<template>
  <div class="flex gap-4">
    <TextInput
      id="renameInput"
      ref="inputBoxRef"
      v-model="viewProjectName"
      :placeholder="t('titlePlaceholder')"
      type="text"
      class="w-[360px]"
      :readonly="!checkPermission(Permission.projectUpdate)"
      @focus="onFocus"
      @blur="onBlur($event)"
      @keydown.enter="onSaveTitleWithEnter"
    />
    <AccessControl :permissions="[Permission.projectUpdate]">
      <span v-show="focused" class="z-10 block">
        <BaseButton ref="buttonRef" severity="info" @click.prevent="onSaveTitle">
          {{ t('titleSave') }}
        </BaseButton>
      </span>
    </AccessControl>
  </div>
</template>

<i18n>
{
  "en": {
    "titlePlaceholder": "Untitled",
    "titleSave": "Save",
  }
}
</i18n>
