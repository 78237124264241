<script setup lang="ts">
import { BaseButton } from '@murfy-package/ui'
import PrimeVueDialog from 'primevue/dialog'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const props = withDefaults(
  defineProps<{
    visible: boolean
    header: string
    content: string
    cancelLabel?: string
    confirmLabel?: string
    onConfirm: () => void
    onCancel?: () => void
    severity?: 'primary' | 'secondary' | 'tertiary'
    actionType?: 'default' | 'destructive'
  }>(),
  {
    cancelLabel: undefined,
    confirmLabel: undefined,
    onCancel: undefined,
    severity: 'primary',
    actionType: 'default',
  },
)
</script>

<template>
  <PrimeVueDialog
    :visible="props.visible"
    :modal="true"
    :closable="false"
    :pt="{ footer: 'flex justify-between' }"
  >
    <template #header>
      <div class="head-xl">
        {{ props.header }}
      </div>
    </template>
    <pre class="body-md whitespace-pre-wrap">{{ props.content }}</pre>
    <template #footer>
      <BaseButton
        v-if="!!onCancel"
        class="flex-1"
        :label="!!props.cancelLabel ? props.cancelLabel : t('cancelButton')"
        severity="tertiary"
        @click="onCancel()"
      />
      <BaseButton
        class="flex-1"
        :label="!!props.confirmLabel ? props.confirmLabel : t('confirmButton')"
        :severity="props.severity"
        :actionType="props.actionType"
        @click="onConfirm()"
      />
    </template>
  </PrimeVueDialog>
</template>
<i18n>
{
  "en": {
    "cancelButton": "Cancel",
    "confirmButton": "Confirm",
  },
}
</i18n>
