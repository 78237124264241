import type { Plugin } from 'vue'

import Component from './component'
import { injectGlobalConfig } from './config'
import type { Props } from './properties'

export type { Events } from './events'
export { DEFAULT_CONFIG } from './config'

export const MurfyEditor = Component
export const install: Plugin = (app, defaultConfig?: Props) => {
  if (Component.name) {
    app.component(Component.name, Component)
  }
  app.component('MurfyEditor', Component)
  injectGlobalConfig(app, defaultConfig)
}

// Exporting hooks and other necessary modules
export { useEditor } from './hooks'
export type { EditorPayload } from './component'

// Exporting everything as default
export default {
  MurfyEditor,
  install,
}
