<script setup lang="ts">
import { toastService } from '@/utils/toast'
import type { EditorView } from '@codemirror/view'
import { useClipboard } from '@vueuse/core'
import type { MenuItem } from 'primevue/menuitem'
import { useI18n } from 'vue-i18n'

import { useCursor } from '@/editor/hooks/useCursor'

import BaseMenu from './BaseMenu.vue'
import { Actions, AskAIStateMachine, type Completed } from './askAIStateMachine'

const { t } = useI18n()

const props = defineProps<{
  view: EditorView
  stateMachine: AskAIStateMachine
}>()
const state = props.stateMachine.state as Completed
const { copy } = useClipboard()
const copyResultToClipboard = () => {
  copy(state.output)
  toastService.success('Success', 'Copied to clipboard')
  props.stateMachine.send(Actions.CopyToClipboard())
}
const { insertContent, replaceContent, isEmpty } = useCursor(props.view)

const replaceMenu: MenuItem[] = isEmpty
  ? []
  : [
      {
        label: t('replaceContent'),
        action: () => {
          replaceContent(state.output)
          // FIXME: selection update 시 SelectionChanged가 적용되도록 해야함
          // 다른 PR에 적용되어있음.
          props.stateMachine.send(Actions.SelectionChanged())
        },
      },
    ]
const menuItems: MenuItem[] = replaceMenu.concat([
  {
    label: t('insertContent'),
    action: () => {
      insertContent(state.output)
      // FIXME: selection update 시 SelectionChanged가 적용되도록 해야함
      // 다른 PR에 적용되어있음.
      props.stateMachine.send(Actions.SelectionChanged())
    },
  },
  {
    label: t('copyToClipboard'),
    action: copyResultToClipboard,
  },
  {
    separator: true,
  },
  {
    label: t('retry'),
    action: () => props.stateMachine.send(Actions.Retry(state.task, state.input)),
  },
  {
    label: t('deleteResult'),
    class: 'destructive-primary',
    action: () => props.stateMachine.send(Actions.DeleteResult()),
  },
])
</script>

<template>
  <div :class="$style.container">
    <div :class="$style.outputContainer">
      <div :class="$style.outputBox">
        <pre :class="[$style.outputText, 'p2', 'gray-7']">{{ state.output }}</pre>
        <div :class="[$style.outputStatus, 'p2', 'gray-7']">
          <img src="/icons/check_circle.svg" alt="Check icon" />
          {{ t('complete') }}
        </div>
      </div>
      <div :class="$style.header">
        <img src="/icons/insights.svg" alt="Ask AI Activated Header" />
        <span class="gray-5 p2">{{ t('askAI') }}</span>
      </div>
    </div>
    <BaseMenu :class="$style.menu" :model="menuItems" :popup="false" />
  </div>
</template>

<style module>
.container {
  background: none;
  pointer-events: none;
  display: flex;
  flex-direction: row;
  gap: 12px;
}
.outputContainer {
  background-color: var(--gray-bg);
  border-radius: 8px;
  border: 1px solid var(--gray-2);
  box-shadow: 0px 2px 16px rgb(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  width: 480px;
  pointer-events: auto;
  height: fit-content;
}
.outputBox {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 12px 16px;
  border-bottom: 1px solid var(--gray-3);
}
.outputText {
  font-family: inherit;
  margin: 0;
  padding: 0;
  white-space: pre-wrap;
}
.outputStatus {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 0px;
}
.header {
  padding: 12px 16px;
  display: flex;
  gap: 12px;
  justify-content: start;
  align-items: center;
}
.menu {
  height: fit-content;
  pointer-events: auto;
  border: 1px solid var(--gray-2);
  background-color: var(--gray-bg);
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
}
</style>

<i18n>
{
  "en": {
    "askAI": "Ask AI to edit or generate...",
    "complete": "AI writing is complete.",
    "insertContent": "Insert Content",
    "replaceContent": "Replace Content",
    "retry": "Try Again",
    "deleteResult": "Delete",
    "copyToClipboard": "Copy to Clipboard"
  },
}
</i18n>
