<script setup lang="ts" generic="T">
import { ref } from 'vue'

/**
 * 이 컴포넌트는 <input type="radio">를 래핑한 컴포넌트입니다.
 * input 태그의 value는 타입이 any이기 때문에, 이 컴포넌트는 제네릭을 사용합니다.
 */
defineProps<{
  value: T
  modelValue: T
  /**
   * radio button의 name
   */
  name?: string
  /**
   * default는 value
   */
  label?: string
}>()

defineEmits<{
  'update:modelValue': [modelValue: T]
}>()
const inputRef = ref<HTMLInputElement | null>(null)
const inputId = Math.random().toString(36).slice(2, 9)
</script>

<template>
  <label
    :for="inputId"
    class="group flex h-fit w-full cursor-pointer items-center gap-[1rem] rounded-md px-[14px] py-4 hover:border-[var(--cta-hover)] hover:bg-[var(--cta-secondary-hover)] active:border-[var(--cta-pressed)] active:bg-[var(--cta-secondary-pressed)]"
    :class="[
      inputRef?.checked
        ? 'hover: border-2 border-[var(--cta-primary)]'
        : 'border border-[var(--gray-4)]',
    ]"
  >
    <input
      :id="inputId"
      ref="inputRef"
      class="m-0.5 h-[1.25rem] w-[1.25rem] appearance-none rounded-full"
      :class="[
        inputRef?.checked
          ? 'border-[5.6px] border-[var(--cta-primary)] group-hover:border-[var(--cta-hover)] group-active:border-[var(--cta-pressed)]'
          : 'border-[2px] border-[var(--gray-4)]',
      ]"
      type="radio"
      :name="name"
      :value="value"
      @change="$emit('update:modelValue', ($event.target as HTMLInputElement).value as T)"
    />

    <span class="h5 text-gray-800">{{ label ?? value }}</span>
  </label>
</template>
