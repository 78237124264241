<script setup lang="ts">
import { computed } from 'vue'

const props = withDefaults(
  defineProps<{
    label?: string
    size?: 'xs' | 'sm' | 'md'
  }>(),
  {
    label: '',
    size: 'md',
  },
)

const sizeClass = computed(() => {
  switch (props.size) {
    case 'xs':
      return `p5 h-5 rounded-md px-2 py-1`
    case 'sm':
      return `p3 h-6 rounded-md px-2 py-1`
    default:
      return `p1 h-8 rounded-lg px-3 py-1`
  }
})
</script>

<template>
  <span :class="[sizeClass]" class="flex w-fit items-center text-white">
    <slot>
      {{ label }}
    </slot>
  </span>
</template>
