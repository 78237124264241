<script setup lang="ts">
import type { SelectionRange } from '@codemirror/state'
import type { EditorView } from '@codemirror/view'
import { useConfirmDialog } from '@vueuse/core'
import { computed, reactive, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { useCursor } from '@/editor/hooks/useCursor'

import AskAIStateRenderer from './AskAIStateRenderer.vue'
import ConfirmModal from './ConfirmModal.vue'
import { Actions, AskAIStateMachine, isAnswering, isCompleted, isIdle } from './askAIStateMachine'

const { t } = useI18n()
const props = defineProps<{
  view: EditorView
  selection: SelectionRange | null
}>()

const askAIStateMachine = reactive(new AskAIStateMachine())
const visible = defineModel<boolean>('visible', { default: false })

// 커서 위치 변경 시 상태 변경 로직
const { isRevealed, reveal, cancel, confirm, onConfirm } = useConfirmDialog()
const { set: setCursor } = useCursor(props.view)
let selectionAfterConfirm = null as SelectionRange | null
let isRestoringSelection = false
watch(
  () => props.selection,
  (newSelection, oldSelection) => {
    if (isRestoringSelection) {
      isRestoringSelection = false
      return
    }
    if (isAnswering(askAIStateMachine.state) || isCompleted(askAIStateMachine.state)) {
      // 답변 중이거나 답변이 완료된 상태에서는 커서 위치를 변경하지 않음
      selectionAfterConfirm = newSelection
      isRestoringSelection = true
      if (oldSelection) {
        // FIXME: Editor의 Update 자체를 막아야 함
        // 현재 구현은 변경된 커서위치를 원래대로 되돌리는 방식으로 구현되어 있음
        setCursor(oldSelection.anchor, oldSelection.head)
      }
      reveal()
      return
    }
    askAIStateMachine.send(Actions.SelectionChanged())
  },
)
onConfirm(() => {
  isRestoringSelection = true
  if (selectionAfterConfirm) {
    setCursor(selectionAfterConfirm.anchor, selectionAfterConfirm.head)
  }
  askAIStateMachine.send(Actions.SelectionChanged())
})

const isCurrentStateIdle = computed(() => isIdle(askAIStateMachine.state))
defineExpose({
  isCurrentStateIdle,
})
</script>

<template>
  <div class="pointer-events-none flex flex-col items-center justify-center">
    <AskAIStateRenderer v-if="visible" :stateMachine="askAIStateMachine" :view="props.view" />
    <ConfirmModal
      :visible="isRevealed"
      :header="
        isAnswering(askAIStateMachine.state)
          ? t('unsavedProgress.header')
          : t('postAIOptions.header')
      "
      :content="
        isAnswering(askAIStateMachine.state)
          ? t('unsavedProgress.content')
          : t('postAIOptions.content')
      "
      severity="secondary"
      actionType="destructive"
      @cancel="cancel"
      @confirm="confirm"
    />
  </div>
</template>
<i18n>
{
  "en": {
    "unsavedProgress": {
      "header": "Unsaved Progress",
      "content": "It seems you're trying to stop writing with unsaved progress. If you continue, the current content will be discarded. Are you sure you want to stop the AI and discard the content?"
    },
    "postAIOptions": {
      "header": "Post AI Options",
      "content": "Your AI writing is complete. If you close now, the current content will be discarded. Are you sure you want to discard the content?"
    }
  }
}
</i18n>
