<script setup lang="ts">
import { SIDEBAR_HEADER_HEIGHT_PIXEL } from '@/stores'
import { useShareStore } from '@/stores/share'
import { Permission } from '@murfy-package/api-client'
import {
  IconArrowDown,
  IconArrowUp,
  IconBase,
  IconEye,
  IconLink,
  IconPen,
} from '@murfy-package/murds'
import { BaseTextButton } from '@murfy-package/ui'
import { storeToRefs } from 'pinia'
import PrimeVueDropdown from 'primevue/dropdown'
import Skeleton from 'primevue/skeleton'
import { computed, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'

import { AccessControl, ConfirmModal } from '.'

const shareStore = useShareStore()
const { t } = useI18n()

// Destructure the store for easier access
const {
  loadingShareLink,
  shareLinkEditor,
  shareLinkViewer,
  loadingPeopleWithAccess,
  peopleWithAccess,
  deleteModalVisible,
  roles,
} = storeToRefs(shareStore)

const {
  fetchInviteData,
  handleDropdownChange,
  confirmDeleteShare,
  cancelDeleteShare,
  checkEditRolePermission,
  copyToClipboard,
  getMenuPermission,
} = shareStore

onMounted(async () => {
  await fetchInviteData()
})

const rolesWithLabels = computed(() =>
  roles.value.map((role) => ({
    ...role,
    label: t(role.label.toString()),
  })),
)
</script>

<template>
  <div>
    <!--header-->
    <div
      :style="{
        '--editor-sidebar-header-height': `${SIDEBAR_HEADER_HEIGHT_PIXEL}px`,
      }"
      class="border-color-border-primary flex h-[--editor-sidebar-header-height] flex-row items-center gap-4 border-b px-4 py-2"
    >
      <span class="head-xs text-color-text-primary w-full">{{ t('header') }}</span>
    </div>
    <!--invite link-->
    <div class="head-sm text-color-text-primary p-4">
      {{ t('inviteLink') }}
    </div>
    <div class="flex flex-col gap-2.5 px-4 pb-3">
      <AccessControl :permissions="[Permission.inviteEditorLinkRead]">
        <div
          class="border-color-border-primary bg-color-bg-global-primary flex justify-between rounded-lg border px-4 py-3.5"
        >
          <div class="text-color-text-primary body-md flex items-center gap-2">
            <IconBase>
              <IconPen />
            </IconBase>
            {{ t('inviteLinkEditor') }}
          </div>
          <BaseTextButton :loading="loadingShareLink" @click="copyToClipboard(shareLinkEditor)">
            <IconBase :iconName="t('copy')">
              <IconLink />
            </IconBase>
          </BaseTextButton>
        </div>
      </AccessControl>
      <AccessControl :permissions="[Permission.inviteViewerLinkRead]">
        <div
          class="border-color-border-primary bg-color-bg-global-primary flex justify-between rounded-lg border px-4 py-3.5"
        >
          <div class="text-color-text-primary body-md flex items-center gap-2">
            <IconBase>
              <IconEye />
            </IconBase>
            {{ t('inviteLinkViewer') }}
          </div>
          <BaseTextButton :loading="loadingShareLink" @click="copyToClipboard(shareLinkViewer)">
            <IconBase :iconName="t('copy')">
              <IconLink />
            </IconBase>
          </BaseTextButton>
        </div>
      </AccessControl>
    </div>
    <!--people with access-->
    <div class="head-sm text-color-text-primary p-4">
      {{ t('peopleWithAccess') }}
    </div>
    <template v-if="loadingPeopleWithAccess">
      <div
        v-for="i in [1, 2]"
        :key="i"
        class="border-color-border-primary flex items-center justify-between border-b p-4"
      >
        <div class="flex flex-col gap-1">
          <Skeleton width="60px" height="22px" />
          <Skeleton width="120px" height="19px" />
        </div>
        <Skeleton width="60px" height="24px" />
      </div>
    </template>
    <template v-else>
      <div
        v-for="person in peopleWithAccess"
        :key="person.id"
        class="border-color-border-primary flex items-center justify-between border-b p-4"
      >
        <div class="flex flex-col">
          <span class="body-sm text-color-text-primary">{{ person.name }}</span>
          <span class="body-sm text-color-text-tertiary">{{ person.email }}</span>
        </div>
        <PrimeVueDropdown
          :modelValue="person.role"
          :options="rolesWithLabels"
          optionLabel="label"
          optionValue="value"
          unstyled
          :pt="{
            panel: 'rounded-lg py-2 shadow-md bg-color-bg-global-primary',
            input: 'focus-visible:outline-none',
            trigger: 'hidden',
          }"
          @beforeHide="() => (person.dropdownOpened = false)"
          @beforeShow="() => (person.dropdownOpened = true)"
          @change="handleDropdownChange(person, $event.value)"
        >
          <template #value="slotProps">
            <BaseTextButton
              v-if="slotProps.value"
              class="flex items-center gap-2"
              :disabled="!checkEditRolePermission(person.role)"
              :title="
                !checkEditRolePermission(person.role)
                  ? t('noPermissionToChangeRole')
                  : t('changeRole')
              "
              :class="[
                slotProps.value === 'owner' ? 'text-color-text-disabled cursor-not-allowed' : '',
              ]"
            >
              <div class="body-sm hidden sm:block">
                {{ t(slotProps.value) }}
              </div>
              <IconBase v-if="person.dropdownOpened" iconName="ExpandLess">
                <IconArrowUp />
              </IconBase>
              <IconBase v-else iconName="ExpandMore">
                <IconArrowDown />
              </IconBase>
            </BaseTextButton>
          </template>
          <template #option="slotProps">
            <AccessControl :permissions="getMenuPermission(slotProps.option.value, person.role)">
              <div
                class="hover:bg-color-bg-info-tertiary-hover active:bg-color-bg-info-tertiary-pressed body-sm flex min-w-[120px] cursor-pointer items-center px-4 py-[10px]"
                :class="[
                  slotProps.option.value === person.role
                    ? 'text-color-text-brand'
                    : 'text-color-text-primary',
                  slotProps.option.class ?? '',
                ]"
              >
                {{ t(slotProps.option.value) }}
              </div>
            </AccessControl>
          </template>
        </PrimeVueDropdown>
      </div>
    </template>
    <ConfirmModal
      :visible="deleteModalVisible"
      :header="t('deleteShare.header')"
      :content="t('deleteShare.content')"
      :cancelLabel="t('cancel')"
      :confirmLabel="t('delete')"
      :onConfirm="confirmDeleteShare"
      :onCancel="cancelDeleteShare"
      :severity="'tertiary'"
      :actionType="'destructive'"
    />
  </div>
</template>

<i18n>
  {
    "en": {
      "header": "Project Sharing",
      "inviteLink": "Invite link",
      "inviteLinkEditor": "Share as an Editor",
      "inviteLinkViewer": "Share as a Viewer",
      "peopleWithAccess": "People with Access",
      "OWNER": "Owner",
      "EDITOR": "Editor",
      "VIEWER": "Viewer",
      "delete": "Delete",
      "cancel": "Cancel",
      "copy": "Copy",
      "copiedToast": {
        "summary": "Copied!",
        "detail": "The link has been copied to your clipboard."
      },
      "deleteShare": {
        "header": "Delete Share",
        "content": "Even if you delete the share now, you can always invite back. Do you want to continue? Then please check below",
        "successToast": {
          "summary": "Done!",
          "detail": "The share deletion was successful."
        }
      },
      "changeOwner": {
        "header": "Change Owner"
      },
      "noPermissionToChangeRole": "You do not have permission to change the role.",
      "changeRole": "Change Role"
    }
  }
  </i18n>
