import { APIClient } from '../client'
import type { UpdateUserInfoRequest } from '../schema/request'
import type { ActivateUserResponse, DeleteUserResponse, UserInfo } from '../schema/response'

/**
 * User related API methods.
 */
export class UserAPI {
  private client: APIClient
  constructor(client: APIClient) {
    this.client = client
  }
  /**
   * Get the current user's information.
   *
   * @returns
   */
  async getMe(): Promise<UserInfo> {
    const response = await this.client.get<UserInfo>('/users/me')
    return response.data
  }
  /**
   * Update the current user's information.
   * This endpoint updates the authenticated user's information with the provided data. It requires the user to be authenticated and only allows updating their own information.
   * Fields not present in UpdateUserInfoRequest are ignored.
   *
   * @param data
   */
  async updateMe(data: UpdateUserInfoRequest): Promise<UserInfo> {
    const response = await this.client.put<UserInfo>('/users/me', data)
    return response.data
  }
  /**
   * Activate the current user.
   *
   */
  async activateMe(): Promise<ActivateUserResponse> {
    const response = await this.client.post<ActivateUserResponse>('/users/me', null)
    return response.data
  }
  /**
   * Delete the current user.
   * FIXME: DELETE /users/me throws 500 error
   *
   */
  async deleteMe() {
    const response = await this.client.delete<DeleteUserResponse>('/users/me')
    return response.data
  }
}
