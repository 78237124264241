<script setup lang="ts">
import { toRefs } from 'vue'

const props = withDefaults(
  defineProps<{
    modelValue: boolean
    textOnly: boolean
  }>(),
  {
    modelValue: () => false,
    textOnly: false,
  },
)

const { modelValue } = toRefs(props)

const emit = defineEmits<{
  'update:modelValue': [value: boolean]
}>()

const emitChecked = () => {
  emit('update:modelValue', !modelValue.value)
}
</script>
<template>
  <label class="inline-flex cursor-pointer items-center">
    <span
      class="hover:text-cta-hover active:text-cta-pressed text-sm font-medium"
      :class="[
        !textOnly
          ? 'text-gray-900 dark:text-gray-300'
          : modelValue
            ? 'text-cta-primary'
            : 'text-gray-4',
      ]"
      ><slot
    /></span>
    <input
      type="checkbox"
      class="peer sr-only"
      :checked="modelValue"
      @change.prevent="emitChecked"
    />
    <span
      v-if="!textOnly"
      class="peer-checked:bg-cta-primary dark:peer-focus:ring-cta-hover peer-focus:ring-cta-pressed after:bg-gray-white-n-black peer relative block h-6 w-11 rounded-full bg-gray-200 after:absolute after:start-[2px] after:top-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:transition-all after:content-[''] peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 rtl:peer-checked:after:-translate-x-full dark:border-gray-600 dark:bg-gray-700"
    />
  </label>
</template>
