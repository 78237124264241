import { EditorView, keymap } from '@codemirror/view'

// \begin{}와 \end{} 자동 완성 기능을 처리하는 함수
const handleBeginEndAutoClose = (editorView: EditorView): boolean => {
  const state = editorView.state
  const { from, to } = state.selection.main

  // 현재 줄의 텍스트를 가져옴
  const line = state.doc.lineAt(from)
  const lineText = line.text

  // 커서 위치를 기준으로 남은 문자열을 추출
  const cursorOffsetInLine = from - line.from
  const remainingTextAfterCursor = lineText.slice(cursorOffsetInLine)

  // \begin{any_text} 패턴을 감지하기 위한 정규 표현식
  const beginMatch = lineText.match(/\\begin\{([^}]+)}/)

  if (beginMatch) {
    // 중괄호 안의 텍스트 (환경 이름)
    const envName = beginMatch[1]

    // 현재 문서에서 해당 \begin{envName}와 \end{envName}의 개수 비교
    const docText = state.doc.toString()
    const beginRegex = new RegExp(`\\\\begin\\{${envName}\\}`, 'g')
    const endRegex = new RegExp(`\\\\end\\{${envName}\\}`, 'g')

    const beginCount = (docText.match(beginRegex) || []).length
    const endCount = (docText.match(endRegex) || []).length

    // \begin{envName}의 개수가 \end{envName}의 개수보다 많을 경우에만 \end{} 삽입
    if (beginCount > endCount) {
      const insertText = `\n\n\\end{${envName}}`

      editorView.dispatch(
        state.update({
          changes: { from: to + remainingTextAfterCursor.length, insert: insertText },
          selection: {
            anchor: to + remainingTextAfterCursor.length + 1,
          },
        }),
      )

      return true
    }
  }

  return false
}

// Enter 및 Tab 키에 대한 자동 완성 기능을 정의하는 keymap 확장 생성
const autoCloseBeginEndKeymap = keymap.of([
  {
    key: 'Enter',
    run: handleBeginEndAutoClose,
  },
  {
    key: 'Tab',
    run: handleBeginEndAutoClose,
  },
])

export default autoCloseBeginEndKeymap
