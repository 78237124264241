<script setup lang="ts">
import { toastService } from '@/utils/toast'
import type { AIChatMessage, AIChatMessageContentImage } from '@murfy-package/api-client'
import { AIChatMessage as AIChatMessageComponent } from '@murfy-package/murds'
import { useI18n } from 'vue-i18n'

export type ViewAIChatMessage = AIChatMessage & {
  loading?: boolean
  error?: boolean
  errorMessage?: string
}

const props = defineProps<{
  messages: ViewAIChatMessage[]
  messageMapping: Record<string, AIChatMessage>
  currentMessageId?: string
}>()

defineEmits<{
  retry: [message: AIChatMessage]
  prev: [message: AIChatMessage]
  next: [message: AIChatMessage]
}>()

const { t } = useI18n()

const brotherMessages = (msg: AIChatMessage) => {
  if (!msg.parentId) {
    return [msg]
  }
  const parentMessage = props.messageMapping[msg.parentId]
  if (!parentMessage || !parentMessage.childrenIds) {
    return [msg]
  }
  const brothers = parentMessage.childrenIds.map((id) => props.messageMapping[id])
  if (brothers.length === 0) {
    // api 요청전에 messages에 더미 메시지가 추가된 케이스
    return [msg]
  }
  return brothers
}

const brotherContents = (msg: AIChatMessage) =>
  brotherMessages(msg).map(
    (msg) => msg.contents.find((content) => content.contentType === 'text')?.text ?? '',
  )

const currentContentIndex = (msg: AIChatMessage) =>
  brotherMessages(msg).findIndex((m) => m.id === msg.id)

const questionImage = (msg: AIChatMessage) =>
  msg.contents.find((content) => content.contentType === 'image') as
    | AIChatMessageContentImage
    | undefined

const handleCopy = () => {
  toastService.success(t('copied'))
}
</script>

<template>
  <div class="flex h-full flex-col gap-2">
    <div v-show="messages.length === 0" class="text-color-text-secondary body-sm">
      {{ t('placeholder') }}
    </div>
    <AIChatMessageComponent
      v-for="msg in messages"
      :key="msg.id"
      class="w-full"
      :class="[msg.role === 'user' ? 'self-end' : 'self-start']"
      :contents="brotherContents(msg)"
      :currentContentIndex="currentContentIndex(msg)"
      :role="msg.role === 'system' ? 'assistant' : msg.role"
      :loading="msg.loading"
      :imageUrl="questionImage(msg)?.url"
      :error="msg.error"
      :errorMessage="msg.errorMessage"
      @retry="() => $emit('retry', msg)"
      @prev="() => $emit('prev', msg)"
      @next="() => $emit('next', msg)"
      @copy="handleCopy()"
    />
  </div>
</template>

<i18n>
  {
    "en": {
      "placeholder": "Murfy AI can help you!",
      "copied": "Copied!"
    }
  }
</i18n>
