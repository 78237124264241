<script setup lang="ts">
import { BaseButton, IconBase, IconFindInPage } from '@murfy-package/ui'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
</script>

<template>
  <div class="flex w-full justify-center">
    <div class="mt-12 flex w-[436px] flex-col items-center gap-4 p-10">
      <IconBase width="48px" height="48px" class="text-gray-6">
        <IconFindInPage />
      </IconBase>
      <span class="h2 text-gray-7">{{ t('pageNotFound') }}</span>
      <span class="h6 text-gray-6">{{ t('pageNotFoundDescription') }}</span>
      <BaseButton class="mt-2 w-[200px]" @click="() => $router.push('/')">
        {{ t('confirm') }}
      </BaseButton>
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "pageNotFound": "Lost in cyberspace?",
    "pageNotFoundDescription": "Oops! The page you're seeking has gone astray. Head back to safety or contact support for help.",
    "confirm": "Confirm"
  },
}
</i18n>
