<script setup lang="ts">
import { useUserStore } from '@/stores'
import { BaseButton } from '@murfy-package/ui'
import { storeToRefs } from 'pinia'
import PrimeVueDialog from 'primevue/dialog'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import TextButton from './TextButton.vue'

const { t } = useI18n()
const props = defineProps<{
  visible: boolean
}>()

const emit = defineEmits<{
  close: []
  'update:visible': [value: boolean]
}>()

const openTermsOfService = () => window.open(t('global.url.termsOfService'), '_blank')
const openPrivacyPolicy = () => window.open(t('global.url.privacyPolicy'), '_blank')
const openPromotion = () => window.open(t('global.url.promotion'), '_blank')
const userStore = useUserStore()
const { me } = storeToRefs(userStore)
const promotionAgreement = computed(() => !!me.value?.promotionAgreementDate)
const viewPromotionAgreement = ref(false)
watch(
  () => props.visible,
  () => {
    viewPromotionAgreement.value = promotionAgreement.value
  },
)

const isDirty = computed(() => viewPromotionAgreement.value !== promotionAgreement.value)
const isSaving = ref(false)
const onSaveChanges = () => {
  if (isSaving.value) return
  isSaving.value = true
  userStore
    .updateMe({ promotionAgreement: viewPromotionAgreement.value })
    .then(() => {
      emit('update:visible', false)
    })
    .finally(() => {
      isSaving.value = false
    })
}
</script>

<template>
  <PrimeVueDialog
    :visible="props.visible"
    modal
    @update:visible="(visible) => $emit('update:visible', visible)"
  >
    <template #header>
      <span class="h2 text-gray-9">
        {{ t('termsAndCondition') }}
      </span>
    </template>
    <template #default>
      <div :title="t('requiredTerms.title')" class="flex flex-col gap-3 py-4">
        <span class="h5 text-gray-8">{{ t('requiredTerms.header') }}</span>
        <div class="bg-gray-0 border-gray-2 flex flex-col gap-3 rounded-xl border-[1px] p-4">
          <TextButton
            :label="t('requiredTerms.termsOfService')"
            scale="h5"
            severity="primary"
            class="w-fit"
            @click="openTermsOfService"
          />
          <TextButton
            :label="t('requiredTerms.privacyPolicy')"
            scale="h5"
            severity="primary"
            class="w-fit"
            @click="openPrivacyPolicy"
          />
        </div>
      </div>
      <div :title="t('optional.title')" class="flex flex-col gap-3 pt-4">
        <span class="h5 text-gray-8">{{ t('optional.header') }}</span>
        <div class="bg-gray-0 border-gray-2 flex flex-col gap-3 rounded-xl border-[1px] p-4">
          <TextButton
            :label="t('optional.promotion')"
            scale="h5"
            severity="primary"
            class="w-fit"
            @click="openPromotion"
          />
        </div>
        <label class="flex items-center gap-2">
          <input
            v-model="viewPromotionAgreement"
            class="h-[24px] w-[24px] appearance-none bg-[url('/icons/Checkbox_Empty.svg')] checked:bg-[url('/icons/Checkbox_Check.svg')]"
            type="checkbox"
          />
          <span class="h6 text-gray-8 text-nowrap leading-6"> {{ t('optional.agreement') }} </span>
        </label>
      </div>
    </template>
    <template #footer>
      <BaseButton
        class="w-full justify-center"
        :disabled="!isDirty"
        :loading="isSaving"
        @click="onSaveChanges"
        >{{ t('saveButton') }}</BaseButton
      >
    </template>
  </PrimeVueDialog>
</template>

<i18n>
{
  "en": {
    "termsAndCondition": "Terms & Conditions",
    "requiredTerms": {
      "title": "Required Terms",
      "header": "Read required terms",
      "termsOfService": "Terms of Service >",
      "privacyPolicy": "Privacy Policy >",
    },
    "optional": {
      "title": "Optional Terms",
      "header": "Optional",
      "promotion": "Promotion Terms and Conditions >",
      "agreement": "I want to receive emails about promotions(optional).",
    },
    "saveButton": "Save Changes",
  }
}
</i18n>
