import { EditorView, ViewPlugin, ViewUpdate } from '@codemirror/view'

import { useMathPatterns } from '../../hooks/useMathPatterns'

class FormulaTurboFracParSnippet {
  view: EditorView

  constructor(view: EditorView) {
    this.view = view
    this.replaceFractionPattern(view)
  }

  update(update: ViewUpdate): void {
    if (update.selectionSet || update.docChanged) {
      this.replaceFractionPattern(update.view)
    }
  }

  replaceFractionPattern(view: EditorView) {
    const state = view.state
    const cursor = state.selection.main.head

    // 커서 위치가 유효한지 확인
    if (cursor <= 0) return

    const line = state.doc.lineAt(cursor - 1)
    const text = state.doc.toString().slice(line.from, cursor)

    // Math 모드 인지 체크 합니다.
    const { rangeAtCursor } = useMathPatterns(view.state.doc, cursor)
    if (!rangeAtCursor.value) return

    // 커서 바로 앞에 있는 문자 (space 또는 enter인지 확인)
    const prevChar = state.doc.sliceString(cursor - 1, cursor)
    if (prevChar !== ' ' && prevChar !== '\n') return

    const match = /par (\S+) (\S+)$/.exec(text.trim())
    if (!match) return

    const x = match[1] // 첫 번째 캡처 그룹 (x 값)
    const y = match[2] // 두 번째 캡처 그룹 (y 값)

    const from = cursor - match[0].length - 1
    const to = cursor

    const replacement = `\\frac{\\partial ${x}}{\\partial ${y}}`

    requestAnimationFrame(() => {
      const currentState = view.state
      const transaction = currentState.update({
        changes: {
          from: from,
          to: to,
          insert: replacement,
        },
        selection: {
          anchor: from + replacement.length,
        },
      })
      view.dispatch(transaction)
    })
  }
}

const formulaTurboFracParSnippet = ViewPlugin.fromClass(FormulaTurboFracParSnippet)

export default formulaTurboFracParSnippet
