<script setup lang="ts">
const props = defineProps({
  viewBox: {
    type: String,
    default: '0 0 24 24',
  },
  iconName: {
    type: String,
    default: '',
  },
  width: {
    type: [Number, String],
    default: 24,
  },
  height: {
    type: [Number, String],
    default: 24,
  },
  iconColor: {
    type: String,
    default: 'currentColor',
  },
})
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="props.width"
    :height="props.height"
    :viewBox="props.viewBox"
    :aria-labelledby="props.iconName"
    class="inline-block align-baseline"
  >
    <title v-if="iconName" :id="props.iconName" lang="en">{{ props.iconName }}</title>
    <g :fill="props.iconColor">
      <slot />
    </g>
  </svg>
</template>
