import { matchedRule } from './rules'

export interface LogEntry {
  code: string
  level: string
  fileName: string
  lineNumber?: number
  message: string
  rawLog: string
}

const ErrorLineNumberRegex = /^l\.\d+/
const WarningLineNumberRegex = /lines? ([0-9]+)/i
const OpenedFileParenthesesRegex = /^ *\)* *\([./]/
const FrontClosingFileParenthesesRegex = /^\)*/
const BackClosingFileParenthesesRegex = /\)*$/

/**
 * LaTeX Compile log를 파싱하기 위한 클래스
 *
 * @export
 * @class LatexLogParser
 * @example
 * const logParser = new LatexLogParser(rawLog)
 * logParser.parse()
 * logParser.getErrors()
 * logParser.getWarnings()
 * logParser.getMessages()
 */
export class LatexLogParser {
  private logLines: LogLines
  private ruleLogEntries: LogEntry[]
  private currentRuleLogEntry: LogEntry | null
  private fileStack: string[]
  private rootFileName: string = ''
  private blankLineCount = 0
  constructor(rawLog: string) {
    // FIXME: rawLog에 포함되는 파일명 모두 마스킹해야함.
    // 파일명에 괄호가 포함될 경우 제대로 동작하지 않을 수 있기 때문임.
    this.logLines = new LogLines(rawLog)
    this.ruleLogEntries = []
    this.currentRuleLogEntry = null
    this.fileStack = []
    // 제일 열리는 파일까지 스킵하고 root 파일명으로 저장
    this.logLines.nextLinesBeforeMatching(OpenedFileParenthesesRegex)
    const initialOpenedFileNameLine = this.logLines.current() ?? ''
    const initialOpenedFileName = LatexLogParser.parseOpenedFileName(initialOpenedFileNameLine)
    if (!initialOpenedFileName) {
      throw new Error('No root file name found')
    }
    this.rootFileName = initialOpenedFileName
    this.pushOpenedFileName(initialOpenedFileName)
  }

  get currentLineIsRuleContent() {
    return this.currentRuleLogEntry !== null
  }

  get currentFileName() {
    return this.fileStack[this.fileStack.length - 1] ?? null
  }

  pushOpenedFileName(openedFileName: string | null) {
    if (!openedFileName || this.currentFileName === openedFileName) {
      return
    }
    this.fileStack.push(openedFileName)
  }

  popOpenedFileNameRepeat(n: number) {
    for (let i = 0; i < n; i++) {
      this.fileStack.pop()
      if (this.fileStack.length === 0) {
        this.fileStack.push(this.rootFileName)
      }
    }
  }

  pushCurrentLogEntry() {
    if (this.currentRuleLogEntry) {
      this.ruleLogEntries.push(this.currentRuleLogEntry)
    }
    this.currentRuleLogEntry = null
    this.blankLineCount = 0
  }

  wrapAndPushErrorLogEntry() {
    if (!this.currentRuleLogEntry || this.currentRuleLogEntry.level !== 'error') {
      return
    }
    // error일 경우 무조건 l.로 시작하는 줄이 있어야 함
    // l. 로 시작하는 줄 까지 이동
    this.currentRuleLogEntry.rawLog += this.logLines
      .nextLinesBeforeMatching(ErrorLineNumberRegex)
      .join('\n')
    this.currentRuleLogEntry.rawLog += '\n'
    const line = this.logLines.current()
    if (line === null) {
      // error가 발생했는 데 끝나는 줄이 없는 경우는 다음고 ㅏ같음.
      // ! ==> Fatal error occurred, the output PDF file is not finished
      // push하고 끝냄
      this.pushCurrentLogEntry()
      return
    }
    this.currentRuleLogEntry.rawLog += line + '\n'
    this.currentRuleLogEntry.lineNumber = LatexLogParser.parseLineNumber(line)
    this.currentRuleLogEntry.rawLog += this.logLines.nextLinesBeforeNthBlankLine(2, true).join('\n')
    this.pushCurrentLogEntry()
  }

  parse() {
    let line: string | null = null
    while ((line = this.logLines.next()) !== null) {
      const rule = LatexLogParser.parseRule(line)
      const lineNumber = LatexLogParser.parseLineNumber(line)
      const {
        isFileOpenedOrClosed,
        openedFileName,
        numFrontClosedFileParentheses,
        numBackClosedFileParentheses,
      } = LatexLogParser.parseFileOpenClose(line)
      const isBlankLine = this.logLines.currentLineIsEmpty()

      if (rule) {
        // 새로운 rule이 나오면 현재까지의 log entry를 저장
        this.currentLineIsRuleContent && this.pushCurrentLogEntry()
        this.currentRuleLogEntry = {
          code: rule.code,
          level: rule.level,
          fileName: this.currentFileName,
          lineNumber: lineNumber,
          message: this.logLines.current() ?? rule.code,
          rawLog: '',
        }
        if (rule.level === 'error') {
          this.currentRuleLogEntry.rawLog += line + '\n'
          this.wrapAndPushErrorLogEntry()
        }
      } else if (!!lineNumber && !!this.currentRuleLogEntry?.lineNumber) {
        // lineNumber는 제일 처음 등장하는 lineNumber만 사용
        this.currentRuleLogEntry.lineNumber = lineNumber
      } else if (isFileOpenedOrClosed) {
        // 파일이 열리거나 닫히는 경우 rule content가 아니라고 판단하여 현재까지의 log entry를 저장
        this.currentLineIsRuleContent && this.pushCurrentLogEntry()
        this.popOpenedFileNameRepeat(numFrontClosedFileParentheses)
        this.pushOpenedFileName(openedFileName)
        this.popOpenedFileNameRepeat(numBackClosedFileParentheses)
      } else if (isBlankLine) {
        // Error가 아닌 rule 들은 최대 빈 줄이 4개 이상까지만 저장
        // 4개는 휴리스틱하게 정한 값임.
        this.blankLineCount > 4 && this.pushCurrentLogEntry()
        this.blankLineCount++
      }
      if (this.currentLineIsRuleContent) {
        this.currentRuleLogEntry!.rawLog += this.logLines.current() + '\n'
      }
    }
    return this.ruleLogEntries
  }

  static parseLineNumber(line: string) {
    const errorMatch = line.match(ErrorLineNumberRegex)
    if (errorMatch) {
      return parseInt(errorMatch[0].split('.')[1])
    }
    const warningMatch = line.match(WarningLineNumberRegex)
    if (warningMatch) {
      return parseInt(warningMatch[1])
    }
    return undefined
  }

  static parseFileOpenClose(line: string) {
    const openedFileName = LatexLogParser.parseOpenedFileName(line)
    const numFrontClosedFileParentheses = LatexLogParser.countFrontClosedFileParentheses(line)
    const numBackClosedFileParentheses = LatexLogParser.countBackClosedFileParentheses(line)
    const isFileOpened = openedFileName !== null
    const isFileClosed = numFrontClosedFileParentheses > 0 || numBackClosedFileParentheses > 0
    return {
      isFileOpenedOrClosed: isFileOpened || isFileClosed,
      openedFileName,
      numFrontClosedFileParentheses,
      numBackClosedFileParentheses,
    }
  }

  /**
   * 문자열의 앞에 등장하는 파일 닫는 괄호의 개수를 반환합니다.
   * ')'로 시작하는 줄을 파일 닫는 괄호로 판단합니다.
   * 여러 파일을 동시에 닫는 경우 )가 여러개 나올 수 있음
   *
   * @returns 파일 닫는 괄호의 개수
   */
  static countFrontClosedFileParentheses(line: string): number {
    const frontClosingFileParenthesesMatch = line.trim().match(FrontClosingFileParenthesesRegex)
    if (!frontClosingFileParenthesesMatch) {
      return 0
    }
    return frontClosingFileParenthesesMatch[0].length
  }

  /**
   * 문자열의 뒤에 등장하는 파일 닫는 괄호의 개수를 반환합니다.
   * (/file/path)) 와 같이 파일을 열고 여러번 닫히기도 합니다.
   *
   * @returns 파일 닫는 괄호의 개수
   */
  static countBackClosedFileParentheses(line: string): number {
    // Back closing file parentheses 는 파일을 여는 괄호가 존재할 경우만 카운트
    if (!line.match(OpenedFileParenthesesRegex)) {
      return 0
    }
    const backClosingFileParenthesesMatch = line.trim().match(BackClosingFileParenthesesRegex)
    if (!backClosingFileParenthesesMatch) {
      return 0
    }
    return backClosingFileParenthesesMatch[0].length
  }

  /**
   * 열린 파일명을 나타내는 줄이면 열린 파일명을 반환합니다.
   * 열린 파일명을 나타내는 줄은 다음과 같습니다.
   * 1. (.path/to/filename
   * 2. (/path/to/filename
   * 3. ) (/path/to/filename
   * 4. )) (/path/to/filename
   * 5.  (./path/to/filename
   *
   * @returns 열린 파일명을 반환합니다.
   */
  static parseOpenedFileName(line: string): string | null {
    /**
     * 다음과 같은 로그를 파일 여는 로그로 판단
     *
     * 1. (.path/to/filename
     * 2. (/path/to/filename
     * 3. ) (/path/to/filename
     * 4. )) (/path/to/filename
     * 5.  (./path/to/filename
     *
     */
    const match = line.match(OpenedFileParenthesesRegex)
    if (!match) {
      return null
    }
    const matchedPrefix = match[0].slice(0, -1)
    // 닫힌 괄호가 있는 경우 제거
    const numBackClosing = LatexLogParser.countBackClosedFileParentheses(line)
    return line.slice(matchedPrefix.length, line.length - numBackClosing)
  }

  static parseRule(line: string) {
    const rule = matchedRule(line)
    // rule 리스트가 완벽하게 검토되지 않았기 때문에 warning이 error로 잘못 판단되는 경우가 있음
    // 표시되는 텍스트가 Warning인데 Error로 표시되는 것은 오류로 보여질 수 있어 Warning으로 강제변경
    if (rule?.level === 'error' && /warning/gi.test(line)) {
      rule.level = 'warning'
    }
    return rule
  }
}

const LOG_WRAP_LIMIT = 79
/**
 * LaTeX Compile log를 줄 단위로 탐색하기 위한 클래스
 *
 * rawLog는 length limit에 맞춰 강제 줄바꿈이 되어있는 경우가 있음
 * 강제 줄바꿈이 되어있는 경우에는 줄바꿈을 제거하고 한 줄로 합침
 *
 * @export
 * @class LogLines
 * @extends {Array<string>}
 * @example
 * const logLines = new LogLines(rawLog)
 * logLines.next() // 다음 줄 반환
 * logLines.prev() // 이전 줄 반환
 * logLines.nextLinesBeforeBlankLine() // 다음 빈 줄 전까지의 줄 리스트 반환
 * logLines.nextLinesBeforeMatching(/regex/) // 다음 regex에 매칭되는 줄 전까지의 줄 리스트 반환
 *
 */
export class LogLines extends Array<string> {
  private currentLineNumber: number
  constructor(rawLog: string) {
    const preprocessedLine = LogLines.preprocess(rawLog)
    super(...preprocessedLine)
    this.currentLineNumber = 0
  }
  /**
   * line이 LOG_WRAP_LIMIT에 맞춰 강제 줄바꿈이 되어있는지 확인
   * ...로 끝나면 Latex이 limit에 맞춰 이미 줄인 것으로 판단
   * 길이가 LOG_WRAP_LIMIT의 배수라면 강제줄바꿈이 되어있는 것으로 판단
   *
   * @param line
   * @returns
   */
  static isWrappedLine(line: string) {
    return !this.isEmptyLine(line) && line.length % LOG_WRAP_LIMIT === 0 && !line.endsWith('...')
  }

  static isEmptyLine(line: string) {
    return line.trim() === ''
  }

  /**
   * 이전 줄이 isWrappedLine이면서 현재 줄이 isWrappedLine이 아니면 한 줄로 합침
   *
   * @param lines
   */
  static combineWrappedLines(lines: string[]) {
    return lines.reduce<string[]>((acc, line) => {
      if (acc.length <= 1) {
        // 첫 줄은 wrap 되지 않음.
        // 두 번째 줄부터 wrap 여부를 판단하기 때문에 두 번째 줄까지는 그냥 추가
        acc.push(line)
        return acc
      }
      const prevLine = acc[acc.length - 1]
      if (this.isWrappedLine(prevLine) && !line.startsWith('!')) {
        // 이전 줄이 wrap 되어있고 현재 줄이 에러 메시지가 아니면 합침
        acc[acc.length - 1] += line
      } else {
        acc.push(line)
      }
      return acc
    }, [])
  }

  /**
   *
   * 파일을 열고 닫는 로그를 쉽게 파싱하기 위해 []로 감싸진 부분에 줄바꿈을 추가
   * 아래와 같은 특이 케이스가 있어서 추가함.
   * 또한 []은 페이지 랜더링의 시작과 끝을 나타내기 때문에 줄바꿈을 추가해도 로그를 파싱하는데 문제가 없음
   * ```
   * <./frog.jpg>] [4{/usr/local/texlive/2024/texmf-dist/fonts/enc/dvips/cm-super/cm-super-ts1.enc}] (./output.bbl) [5] (./output.aux (./sub.aux))
   * ```
   * @returns
   */
  static splitOnSquareBracket(lines: string[]) {
    return lines.reduce<string[]>((acc, line) => {
      const lineWithLineBreak = line.replace(/\]/g, ']\n').replace(/\[/g, '\n[')
      const splittedLines = lineWithLineBreak.split('\n')
      acc.push(...splittedLines)
      return acc
    }, [])
  }

  static prepareForFileOpenCloseParsing(lines: string[]) {
    const splittedLinesOnBracket = this.splitOnSquareBracket(lines)
    return this.splitOnFileOpen(splittedLinesOnBracket)
  }

  /**
   * 파일을 열고 닫는 로그를 쉽게 파싱하기 위해 /\([./])/ 에 줄바꿈을 추가
   * 아래와 같은 특이 케이스가 있어서 추가함.
   * ```
   * 1. ) (./output.aux (./sub.aux))
   * 2. (./output.out) (./output.out)
   * ```
   */
  static splitOnFileOpen(lines: string[]) {
    return lines.reduce<string[]>((acc, line) => {
      const lineWithLineBreak = line.replace(/ +\(\./g, '\n(.').replace(/ +\(\//g, '\n(/')
      const splittedLines = lineWithLineBreak.split('\n')
      acc.push(...splittedLines)
      return acc
    }, [])
  }

  static preprocess(rawLog: string) {
    const wrappedLines = rawLog.replace(/(\r\n)|\r/g, '\n').split('\n')
    // 강제 줄바꿈이 되어있는 줄을 합침
    const combinedLines = this.combineWrappedLines(wrappedLines)
    // 파일을 열고 닫는 로그를 쉽게 파싱하기 위해 줄바꿈을 추가
    return this.prepareForFileOpenCloseParsing(combinedLines)
  }

  /**
   * 현재 줄을 반환
   */
  current(): string | null {
    return this[this.currentLineNumber] ?? null
  }

  /**
   * 다음 줄을 반환
   * 다음 줄이 없으면 null 반환
   *
   * @returns
   */
  next() {
    if (this.currentLineNumber >= this.length - 1) {
      this.currentLineNumber = this.length
      return null
    }
    this.currentLineNumber++
    return this[this.currentLineNumber]
  }

  /**
   * 이전 줄을 반환
   * 이전 줄이 없으면 null 반환
   *
   * @returns
   */
  prev() {
    if (this.currentLineNumber <= 0) {
      this.currentLineNumber = -1
      return null
    }
    this.currentLineNumber--
    return this[this.currentLineNumber]
  }

  nextLinesBeforeBlankLine() {
    return this.nextLinesBeforeMatching(/^ *$/)
  }

  nextLinesBeforeNthBlankLine(n: number, stopAtRule = false) {
    let blankLineCount = 0
    const lines = []
    let nextLine: string | null
    while ((nextLine = this.next())) {
      if (!nextLine) {
        break
      }
      if (stopAtRule && matchedRule(nextLine)) {
        // rule이 나오면 중단, rule은 포함하지 않음
        this.prev()
        break
      }
      if (nextLine.match(/^ *$/)) {
        blankLineCount++
        if (blankLineCount === n) {
          break
        }
      } else {
        lines.push(nextLine)
      }
    }
    return lines
  }

  nextLinesBeforeMatching(match: RegExp) {
    const lines = []
    let nextLine: string | null
    while ((nextLine = this.next()) !== null) {
      if (nextLine.match(match)) {
        break
      }
      lines.push(nextLine)
    }
    return lines
  }

  currentLineIsEmpty() {
    const currentLine = this.current()
    if (!currentLine) {
      return true
    }
    return LogLines.isEmptyLine(currentLine)
  }
}
