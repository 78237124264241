<script setup lang="ts">
import {
  BaseButton,
  IconCircleCheck,
  IconCircleCheckF,
  IconPen,
  IconTrash,
  IconXmark,
  TextArea,
} from '@murfy-package/murds'
import { computed, nextTick, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import TimeDisplay from './TimeDisplay.vue'

// FIXME: 비지니스 로직이 포함된 컴포넌트라 디자인 시스템에 적합하지는 않음.
type CommentCardProps = {
  userName: string
  updatedAt: string
  text: string
  /**
   * main: 메인 댓글
   * reply: 대댓글
   * summary: Sidebar에 표시되는 댓글 요약
   */
  type: 'main' | 'reply' | 'summary'
  modified?: boolean
  resolved?: boolean
  numberOfReplies?: number
  editing?: boolean
  editable?: boolean
  deletable?: boolean
  clampLine?: boolean
}

type CommentCardEmits = {
  resolve: []
  unresolve: []
  delete: []
  close: []
  edit: [value: string]
  'update:editing': [value: boolean]
  click: []
}

const props = withDefaults(defineProps<CommentCardProps>(), {
  resolved: false,
  modified: false,
  editable: false,
  deletable: false,
  numberOfReplies: 0,
  clampLine: false,
})
const emit = defineEmits<CommentCardEmits>()

const { t } = useI18n()

const isSummary = computed(() => props.type === 'summary')
const isMain = computed(() => props.type === 'main')
const numberOfRepliesText = computed(() => {
  if (props.numberOfReplies === 1) {
    return `${props.numberOfReplies} ${t('reply')}`
  }
  return `${props.numberOfReplies} ${t('replies')}`
})

const viewText = ref(props.text)
const textarea = ref<HTMLTextAreaElement | null>(null)
const edit = () => {
  emit('update:editing', true)
  nextTick(() => {
    if (textarea.value) {
      textarea.value.focus()
    }
  })
}
const handleSubmit = () => {
  emit('update:editing', false)
  emit('edit', viewText.value)
}
const resetEdit = () => {
  emit('update:editing', false)
  viewText.value = props.text
}
</script>

<template>
  <div
    class="flex w-full flex-row pb-2"
    :class="[
      isSummary
        ? 'hover:bg-color-bg-info-tertiary-hover active:bg-color-bg-info-tertiary-pressed border-color-border-primary border-b bg-transparent'
        : '',
    ]"
    @click="$emit('click')"
  >
    <!-- left resolve button -->
    <div v-if="isSummary" class="flex justify-start pl-4 pt-3">
      <BaseButton
        v-if="!resolved"
        severity="secondary"
        variant="text"
        :title="t('resolve')"
        @click.stop="$emit('resolve')"
      >
        <template #icon>
          <IconCircleCheck />
        </template>
      </BaseButton>
      <BaseButton
        v-else
        severity="secondary"
        variant="text"
        :title="t('unresolve')"
        @click.stop="$emit('unresolve')"
      >
        <template #icon>
          <IconCircleCheckF />
        </template>
      </BaseButton>
    </div>
    <div class="flex w-full flex-col">
      <!-- Header -->
      <div class="flex h-[52px] w-full flex-row items-center px-4 pb-1 pt-3">
        <div
          class="body-sm w-full"
          :class="[resolved ? 'text-color-text-secondary' : 'text-color-text-primary']"
        >
          {{ userName }}
        </div>
        <template v-if="!isSummary">
          <template v-if="isMain">
            <BaseButton
              v-if="!resolved"
              severity="secondary"
              variant="text"
              :title="t('resolve')"
              @click="$emit('resolve')"
            >
              <template #icon>
                <IconCircleCheck />
              </template>
            </BaseButton>
            <BaseButton v-else severity="secondary" variant="text" @click.stop="$emit('unresolve')">
              <template #icon>
                <IconCircleCheckF />
              </template>
            </BaseButton>
          </template>
          <BaseButton
            v-if="deletable"
            :disabled="resolved"
            severity="secondary"
            variant="text"
            :title="t('delete')"
            @click="$emit('delete')"
          >
            <template #icon>
              <IconTrash />
            </template>
          </BaseButton>
          <BaseButton
            v-if="editable"
            :disabled="resolved"
            severity="secondary"
            variant="text"
            :title="t('edit')"
            @click="edit"
          >
            <template #icon>
              <IconPen />
            </template>
          </BaseButton>
          <BaseButton
            v-if="isMain"
            severity="secondary"
            variant="text"
            :title="t('close')"
            @click="$emit('close')"
          >
            <template #icon>
              <IconXmark />
            </template>
          </BaseButton>
        </template>
      </div>
      <!-- Time and Edit -->
      <div class="flex flex-row gap-2 px-4">
        <TimeDisplay class="body-sm text-color-text-tertiary" :datetime="updatedAt" />
        <span v-if="props.modified" class="body-sm text-color-text-tertiary">{{
          t('modified')
        }}</span>
      </div>
      <!-- Content -->
      <pre
        v-if="!editing"
        class="body-sm whitespace-pre-wrap px-4 pt-2"
        :class="[
          resolved ? 'text-color-text-secondary' : 'text-color-text-primary',
          clampLine ? 'line-clamp-2' : '',
        ]"
        >{{ viewText.trim() }}</pre
      >
      <div v-else class="px-4 pb-2 pt-4">
        <TextArea
          ref="textarea"
          v-model="viewText"
          @submit="handleSubmit"
          @keydown.esc="resetEdit"
        />
      </div>
      <!-- Replies -->
      <span
        v-if="isSummary && numberOfReplies > 0"
        class="body-sm text-color-text-brand px-4 pb-2 pt-4"
      >
        {{ numberOfRepliesText }}
      </span>
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "modified": "Edited",
    "reply": "reply",
    "replies": "replies",
    "resolve": "Resolve",
    "unresolve": "Unresolve",
    "delete": "Delete",
    "edit": "Edit",
    "close": "Close",
  }
}
</i18n>
