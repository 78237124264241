<script setup lang="ts">
import { defineProps, provide, ref, withDefaults } from 'vue'

const props = withDefaults(
  defineProps<{
    size: 'sm' | 'md'
  }>(),
  {
    size: 'md',
  },
)

const style = {
  sm: {
    radius: 'rounded-[2px]',
  },
  md: {
    radius: 'rounded-[8px]',
  },
}

// size 값을 제공
const listSize = ref(props.size)
provide('listSize', listSize)
</script>

<template>
  <ul
    :class="[
      'bg-static-white border-color-border-primary m-0 border border-solid pb-1 pt-1 shadow-md',
      style[props.size].radius,
    ]"
  >
    <slot />
  </ul>
</template>
