import { APIClient } from '../client'
import type { InviteLinkResponse, VerifyInviteResponse } from '../schema/response'

/**
 * Invite related API methods.
 */
export class InviteAPI {
  private client: APIClient
  constructor(client: APIClient) {
    this.client = client
  }
  /**
   * Get the invite link of a project.
   */
  async getInviteLink(projectId: string): Promise<InviteLinkResponse> {
    const response = await this.client.get<InviteLinkResponse>(`/invite-link/${projectId}`)
    return response.data
  }
  /**
   * Verify the editor invite link.
   */
  async verifyEditorInviteLink(token: string): Promise<VerifyInviteResponse> {
    const response = await this.client.get<VerifyInviteResponse>(
      `/invite-link/verify-edit/${token}`,
    )
    return response.data
  }

  /**
   * Verify the viewer invite link.
   */
  async verifyViewerInviteLink(token: string): Promise<VerifyInviteResponse> {
    const response = await this.client.get<VerifyInviteResponse>(
      `/invite-link/verify-view/${token}`,
    )
    return response.data
  }

  /**
   * Verify the invite link.
   */
  async verifyInviteLink(token: string): Promise<VerifyInviteResponse> {
    if (token.startsWith('view-')) {
      return this.verifyViewerInviteLink(token.replace('view-', ''))
    }
    return this.verifyEditorInviteLink(token)
  }
}
