<script setup lang="ts">
import { computed, getCurrentInstance, inject, ref, useSlots } from 'vue'

import { IconArrowRight, IconBase } from '../../atoms'

// Props 정의
const props = withDefaults(
  defineProps<{
    title: string
    description?: string
    severity?: 'default' | 'danger'
    showArrowIcon?: boolean
  }>(),
  {
    description: '',
    severity: 'default',
    showArrowIcon: false,
  },
)

// size 타입 정의
type SizeType = 'sm' | 'md'

// 부모로부터 size 값을 주입받음 (반응형으로)
const providedSize = inject('listSize', ref<SizeType>('md')) // 'md'는 기본값
const size = computed<SizeType>(() => providedSize.value) // 반응형으로 처리

const emit = defineEmits<{
  click: [MouseEvent]
}>()

// 슬롯 사용
const slots = useSlots()

// 아이콘 슬롯 존재 여부
const hasIcon = !!slots.icon

// 현재 인스턴스 가져오기
const instance = getCurrentInstance()

// 클릭 가능 여부 계산
const isClickable = !!(instance?.vnode?.props?.onClick || instance?.vnode?.props?.['on-click'])

// size별 클래스 매핑
const style = {
  sm: {
    padding: 'py-1 px-2',
    iconMarginEnd: 'me-1.5',
    iconMarginLeft: 'ml-1.5',
  },
  md: {
    padding: 'py-2.5 px-4',
    iconMarginEnd: 'me-3',
    iconMarginLeft: 'ml-3',
  },
}

const titleStyle = {
  default: {
    title: 'text-color-text-primary',
  },
  danger: {
    title: 'text-color-text-danger',
  },
}

// 클릭 핸들러
const handleClick = (event: MouseEvent) => {
  if (isClickable) {
    emit('click', event)
  }
}
</script>

<template>
  <li
    :class="[
      'flex items-center justify-between',
      style[size].padding,
      {
        'hover:bg-color-bg-info-tertiary-hover active:bg-color-bg-info-tertiary-pressed cursor-pointer':
          isClickable,
      },
    ]"
    @click="handleClick"
  >
    <div class="flex items-center">
      <IconBase v-if="hasIcon" :class="style[size].iconMarginEnd">
        <slot name="icon" />
      </IconBase>
      <div>
        <p :class="['body-sm', titleStyle[props.severity].title]">
          {{ props.title }}
        </p>
        <p v-if="props.description" class="body-xs text-color-text-tertiary">
          {{ props.description }}
        </p>
      </div>
    </div>
    <div class="flex items-center">
      <slot name="badge" />
      <IconBase v-if="showArrowIcon" :class="style[size].iconMarginLeft">
        <IconArrowRight />
      </IconBase>
    </div>
  </li>
</template>
