<script setup lang="ts">
import { useErrorStore } from '@/stores'
import { useAPIClient } from '@murfy-package/api-client'
import { onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

import { LoadingView } from '.'

const props = defineProps<{
  token: string
}>()

const { setError } = useErrorStore()
const apiClient = useAPIClient()
const router = useRouter()
onMounted(() => {
  apiClient.invite
    .verifyInviteLink(props.token)
    .then(({ projectId }) => {
      if (projectId) {
        router.push(`/editor/${projectId}`)
      }
    })
    .catch((error) => {
      setError(error)
      router.push('/')
    })
})
const { t } = useI18n()
</script>

<template>
  <LoadingView :message="t('enterProject')" />
</template>

<i18n>
{
  "en":{
    "enterProject": "Entering Project..."
  },
}
</i18n>
