<template>
  <g clip-path="url(#clip0_159_1478)">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10 1.80383C8.92321 1.80383 7.85691 2.01593 6.86204 2.42802C5.86717 2.84011 4.96321 3.44412 4.20177 4.20556C3.44033 4.967 2.83633 5.87096 2.42424 6.86583C2.01215 7.8607 1.80005 8.92699 1.80005 10.0038C1.80005 11.0807 2.01215 12.147 2.42424 13.1418C2.83633 14.1367 3.44033 15.0407 4.20177 15.8021C4.96321 16.5635 5.86717 17.1676 6.86204 17.5796C7.85691 17.9917 8.92321 18.2038 10 18.2038C11.0769 18.2038 12.1432 17.9917 13.1381 17.5796C14.1329 17.1676 15.0369 16.5635 15.7983 15.8021C16.5598 15.0407 17.1638 14.1367 17.5759 13.1418C17.9879 12.147 18.2 11.0807 18.2 10.0038C18.2 8.92699 17.9879 7.8607 17.5759 6.86583C17.1638 5.87096 16.5598 4.967 15.7983 4.20556C15.0369 3.44412 14.1329 2.84011 13.1381 2.42802C12.1432 2.01593 11.0769 1.80383 10 1.80383ZM7.3978 3.72145C8.22282 3.37972 9.10706 3.20383 10 3.20383C10.893 3.20383 11.7773 3.37972 12.6023 3.72145C13.4273 4.06318 14.1769 4.56407 14.8084 5.19551C15.4398 5.82695 15.9407 6.57657 16.2824 7.40159C16.6242 8.2266 16.8 9.11084 16.8 10.0038C16.8 10.8968 16.6242 11.7811 16.2824 12.6061C15.9407 13.4311 15.4398 14.1807 14.8084 14.8122C14.1769 15.4436 13.4273 15.9445 12.6023 16.2862C11.7773 16.6279 10.893 16.8038 10 16.8038C9.10706 16.8038 8.22282 16.6279 7.3978 16.2862C6.57279 15.9445 5.82316 15.4436 5.19172 14.8122C4.56028 14.1807 4.0594 13.4311 3.71767 12.6061C3.37594 11.7811 3.20005 10.8968 3.20005 10.0038C3.20005 9.11084 3.37594 8.2266 3.71767 7.40159C4.0594 6.57657 4.56028 5.82695 5.19172 5.19551C5.82316 4.56407 6.57279 4.06318 7.3978 3.72145ZM7.50005 9.30383C7.11345 9.30383 6.80005 9.61723 6.80005 10.0038C6.80005 10.3904 7.11345 10.7038 7.50005 10.7038H12.5C12.8866 10.7038 13.2 10.3904 13.2 10.0038C13.2 9.61723 12.8866 9.30383 12.5 9.30383H7.50005Z"
    />
  </g>
  <defs>
    <clipPath id="clip0_159_1478">
      <rect width="20" height="20" fill="white" transform="translate(0 0.00378418)" />
    </clipPath>
  </defs>
</template>
