import { defaultKeymap, indentLess, indentMore } from '@codemirror/commands'
import { searchKeymap } from '@codemirror/search'
import { Extension } from '@codemirror/state'
import { keymap } from '@codemirror/view'

// 들여쓰기 (Tab), 들여쓰기 취소 (Shift+Tab) 기능 정의
const tabKeymap = keymap.of([
  { key: 'Tab', run: indentMore },
  { key: 'Shift-Tab', run: indentLess },
])

// 기본 키맵과 들여쓰기 키맵 결합
const generalKeymap: Extension = [
  tabKeymap,
  keymap.of([...defaultKeymap, ...searchKeymap]), // 기본 키맵 추가
]

export default generalKeymap
