import axios, { type AxiosError } from 'axios'

import { snakeToCamel } from './api/utils'
import { ERROR_CODE } from './schema/error'
import { type ErrorResponse } from './schema/response'

export const isMurfyError = (error: Error) => {
  if (!axios.isAxiosError(error)) {
    return false
  }
  const response = (error as AxiosError).response
  if (!response) {
    return false
  }
  const { data } = response
  if (!data) {
    return false
  }
  const { errorCode } = snakeToCamel(data) as ErrorResponse
  return !!errorCode
}

export const shouldRefreshAccessToken = (error: Error) => {
  if (!axios.isAxiosError(error)) {
    return false
  }
  const response = (error as AxiosError).response
  if (!response) {
    return false
  }
  const { data } = response
  if (!data) {
    return false
  }
  const { errorCode } = snakeToCamel(data) as ErrorResponse
  const TARGET_ERROR_CODES = [
    ERROR_CODE.EXPIRED_ACCESS_TOKEN,
    ERROR_CODE.INVALID_ACCESS_TOKEN,
    ERROR_CODE.MISSING_ACCESS_TOKEN,
  ]
  return TARGET_ERROR_CODES.includes(errorCode)
}

export const setAccessToken = (accessToken: string) => {
  sessionStorage.setItem('@murfy-package.api-client.accessToken', accessToken)
}

export const getAccessToken = () => sessionStorage.getItem('@murfy-package.api-client.accessToken')

export const clearAccessToken = () => {
  sessionStorage.removeItem('@murfy-package.api-client.accessToken')
}

export const setRefreshToken = (refreshToken: string) => {
  localStorage.setItem('@murfy-package.api-client.refreshToken', refreshToken)
}

export const getRefreshToken = () => localStorage.getItem('@murfy-package.api-client.refreshToken')

export const clearRefreshToken = () => {
  localStorage.removeItem('@murfy-package.api-client.refreshToken')
}
