<script setup lang="ts">
import { Orientation } from '@/types'
import { RadioGroup } from 'radix-vue/namespaced'

import { IconBase, IconCircle, IconCircleF } from '../../atoms/icon'

/**
 * @name RadioGroup
 * @description
 * A group of radio buttons.
 * see [Radix RadioGroup](https://www.radix-vue.com/components/radio-group.html)
 */
type RadioGroupProps = {
  defaultValue?: string
  disabled?: boolean
  name?: string
  orientation?: Orientation
  required?: boolean
  options: string[]
  showRadio?: boolean
  size?: 'sm' | 'md'
}

withDefaults(defineProps<RadioGroupProps>(), {
  defaultValue: undefined,
  disabled: false,
  name: undefined,
  orientation: 'horizontal',
  showRadio: false,
  required: false,
  size: 'sm',
})

const model = defineModel<string>()
</script>

<template>
  <RadioGroup.Root
    v-slot="{ modelValue }"
    v-model="model"
    :defaultValue="defaultValue"
    :disabled="disabled"
    :name="name"
    :orientation="orientation"
    :required="required"
    class="flex gap-2"
    :class="[orientation === 'horizontal' ? 'flex-row' : 'flex-col']"
  >
    <div v-for="option in options" :key="option" class="flex items-center">
      <RadioGroup.Item
        :id="option"
        :value="option"
        :class="[
          modelValue === option
            ? 'text-color-text-selected enabled:hover:bg-color-bg-info-tertiary-hover enabled:active:bg-color-bg-info-tertiary-pressed bg-transparent'
            : 'text-color-text-tertiary enabled:hover:bg-color-bg-global-secondary enabled:active:bg-color-bg-global-tertiary bg-transparent',
          {
            md: 'h-10',
            sm: 'h-9',
          }[size],
        ]"
        class="body-sm disabled:text-color-text-disabled flex cursor-pointer items-center gap-2 rounded-lg p-2 disabled:cursor-not-allowed"
      >
        <IconBase
          v-if="showRadio"
          :width="{ md: 24, sm: 20 }[size]"
          :height="{ md: 24, sm: 20 }[size]"
        >
          <IconCircleF v-if="modelValue === option" />
          <IconCircle v-else />
        </IconBase>
        <slot :option="option">
          {{ option }}
        </slot>
      </RadioGroup.Item>
    </div>
  </RadioGroup.Root>
</template>
