import { Range, Snippet } from '@/extensions/formula-turbo/formulaTurbo'
import { Transaction } from '@codemirror/state'
import { EditorView, ViewPlugin, ViewUpdate } from '@codemirror/view'

import { useMathPatterns } from '../../hooks/useMathPatterns'
import { DEFAULT_SNIPPETS } from './config'

// 트리거를 키로 하는 맵 생성
const snippetMap = DEFAULT_SNIPPETS.reduce(
  (map, snippet) => {
    map[snippet.trigger] = snippet
    return map
  },
  {} as { [trigger: string]: Snippet },
)

// 정규 표현식 컴파일
const placeholderRegex = /\$(\d+)/g

class FormulaTurboSnippet {
  view: EditorView
  isProcessing: boolean // Snippet 이 처리 되고 한번 더 업데이트 되는 것을 방지하기 위한 플래그

  constructor(view: EditorView) {
    this.view = view
    this.isProcessing = false
    this.processSnippets(view)
  }

  update(update: ViewUpdate): void {
    // delete 또는 backspace 트랜잭션이 포함된 경우 무시
    if (
      update.transactions.some(
        (tr) =>
          tr.annotation(Transaction.userEvent) === 'delete.forward' ||
          tr.annotation(Transaction.userEvent) === 'delete.backward',
      )
    ) {
      return
    }

    if (this.isProcessing) {
      return
    }

    if (update.docChanged) {
      this.processSnippets(update.view)
    }
  }

  processSnippets(view: EditorView) {
    const { ranges } = useMathPatterns(view.state.doc) // 문서에서 수학 패턴 범위를 찾음
    const cursorPos: number = view.state.selection.main.head

    // 커서 위치가 수학 환경 변수 안에 있는지 확인 합니다.
    const relevantRanges = ranges.value.filter(
      (range: Range) => cursorPos > range.from && cursorPos < range.to - range.offset,
    )

    if (relevantRanges.length === 0) return

    const state = view.state
    const cursor = state.selection.main.head

    // 커서 위치에서 텍스트 추출 및 첫 번째 일치하는 트리거 찾기
    const trigger = Object.keys(snippetMap).find((trigger) => {
      const from = cursor - trigger.length
      const to = cursor
      const text = state.doc.sliceString(from, to)

      // from - 1 위치의 문자가 '\'이면 undefined를 반환
      if (state.doc.sliceString(from - 1, from) === '\\') {
        return undefined
      }

      return text === trigger
    })

    if (trigger && trigger.length > 1) {
      const snippet = snippetMap[trigger]
      const { replacement } = snippet

      // 치환 문자열에서 $0, $1 등의 패턴 처리
      const placeholders: { [key: number]: number } = {}
      let match: RegExpExecArray | null

      while ((match = placeholderRegex.exec(replacement)) !== null) {
        placeholders[parseInt(match[1])] = match.index
      }

      this.isProcessing = true

      // 현재 업데이트 사이클 후에 트랜잭션 디스패치
      requestAnimationFrame(() => {
        const currentState = view.state
        const processedReplacement = replacement.replace(placeholderRegex, '')

        // 트랜잭션 디스패치와 커서 위치 설정
        const transaction = currentState.update({
          changes: {
            from: cursor - trigger.length,
            to: cursor,
            insert: processedReplacement,
          },
          selection: {
            anchor:
              placeholders[0] !== undefined
                ? cursor - trigger.length + placeholders[0]
                : cursor - trigger.length + processedReplacement.length,
          },
        })

        view.dispatch(transaction)
        this.isProcessing = false
      })
    }
  }
}

// ViewPlugin을 사용하여 FormulaTurboSnippetHandler를 Extension으로 등록
const formulaTurboSnippet = ViewPlugin.fromClass(FormulaTurboSnippet)

export default formulaTurboSnippet
