<template>
  <g clip-path="url(#clip0_130_14386)">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.8483 8.15225C16.317 8.62088 16.317 9.38068 15.8483 9.84931L9.84833 15.8493C9.3797 16.3179 8.61991 16.3179 8.15128 15.8493C7.68265 15.3807 7.68265 14.6209 8.15128 14.1523L14.1513 8.15225C14.6199 7.68362 15.3797 7.68362 15.8483 8.15225Z"
      fill="currentColor"
    />
    <path
      d="M11 6.00031L11.463 5.46431C12.4008 4.52663 13.6727 3.99991 14.9989 4C16.325 4.00009 17.5968 4.527 18.5345 5.46481C19.4722 6.40261 19.9989 7.6745 19.9988 9.00066C19.9987 10.3268 19.4718 11.5986 18.534 12.5363L18 13.0003"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.0001 18L12.6031 18.534C11.6544 19.4722 10.3739 19.9984 9.03964 19.9984C7.70535 19.9984 6.42489 19.4722 5.47614 18.534C5.0085 18.0716 4.63724 17.521 4.38385 16.9141C4.13047 16.3073 4 15.6561 4 14.9985C4 14.3408 4.13047 13.6897 4.38385 13.0829C4.63724 12.476 5.0085 11.9254 5.47614 11.463L6.00014 11"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </g>
  <defs>
    <clipPath id="clip0_130_14386">
      <rect width="24" height="24" fill="white" />
    </clipPath>
  </defs>
</template>
