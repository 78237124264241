<script setup lang="ts">
import DOMPurify from 'dompurify'
import { marked } from 'marked'
import { onMounted, ref, watch } from 'vue'

interface Props {
  filePath: string
}

const props = defineProps<Props>()

const content = ref<string>('')

const fetchMarkdown = async (filePath: string) => {
  try {
    const response = await fetch(filePath)
    const markdown = await response.text()
    const rawHtml = await marked.parse(markdown)
    /**
     * vue 의 v-html 에 XSS 취약점이 있어서 DOMPurify 를 사용 합니다.
     * https://github.com/cure53/DOMPurify
     */
    return DOMPurify.sanitize(rawHtml)
  } catch (_error) {
    return 'Error loading markdown file.'
  }
}

const updateContent = async (filePath: string) => {
  content.value = await fetchMarkdown(filePath)
}

onMounted(() => {
  updateContent(props.filePath)
})

watch(
  () => props.filePath,
  (newFilePath) => {
    updateContent(newFilePath)
  },
)
</script>

<template>
  <div class="prose mx-auto">
    <!-- eslint-disable-next-line vue/no-v-html -->
    <div v-html="content" />
  </div>
</template>
