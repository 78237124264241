import { i18n } from '@/main'
import { toastService } from '@/utils/toast'
import { APIError, type CancelToken, ERROR_CODE, useAPIClient } from '@murfy-package/api-client'
import { defineStore } from 'pinia'
import { ref } from 'vue'

import { useEditorStore } from './editor'
import { useErrorStore } from './error'
import { useProjectStore } from './project'

const FILE_UPLOAD_BYTE_SIZE_MAX = 50 * 1024 * 1024

export const useProjectFileStore = defineStore('project-file', () => {
  const apiClient = useAPIClient()
  const editorStore = useEditorStore()
  const projectStore = useProjectStore()
  const errorStore = useErrorStore()

  /**
   * 웹소켓 연결이 끊겼을 때, 자신이 끊었는 지 여부를 판단하기 위한 플래그.
   * 이 플래그가 참이면, 자신이 끊은 것이고, 다른 유저로 인해 끊긴 것입니다.
   */
  const isCurrentFileMovingOrDeleting = ref(false)

  const uploadFile = async (file: File, fullPath: string, cancelToken?: CancelToken) => {
    if (file.size > FILE_UPLOAD_BYTE_SIZE_MAX) {
      // 단일 파일 업로드 사이즈 제한에 대한 논의는 한 적 없어 임시조치. => 50MB 이상의 파일 업로드를 막음
      const maxFileSizeInMB = Math.round(FILE_UPLOAD_BYTE_SIZE_MAX / 1024 / 1024)
      toastService.error(
        i18n.t('global.error.exceedMaxFileSize.summary'),
        i18n.t('global.error.exceedMaxFileSize.detail', { maxFileSize: `${maxFileSizeInMB}MB` }),
      )
      return
    }
    if (!projectStore.projectId) return
    try {
      await apiClient.project.uploadFile(projectStore.projectId, file, fullPath, { cancelToken })
    } catch (error) {
      errorStore.setError(error)
    } finally {
      await projectStore.fetchProject()
    }
  }
  const uploadFolder = async (file: File, cancelToken?: CancelToken) => {
    if (!projectStore.projectId) return
    try {
      await apiClient.project.uploadFolder(projectStore.projectId, file, { cancelToken })
    } catch (error) {
      errorStore.setError(error)
    } finally {
      await projectStore.fetchProject()
    }
  }
  const moveFile = async (oldPath: string, newPath: string) => {
    if (!projectStore.projectId) return
    if (editorStore.currentFilePath === oldPath) {
      isCurrentFileMovingOrDeleting.value = true
    }
    try {
      await apiClient.project.moveFile(projectStore.projectId, oldPath, newPath)
      if (isCurrentFileMovingOrDeleting.value) {
        await editorStore.openFile(newPath)
      }
    } catch (error) {
      errorStore.setError(error)
    } finally {
      isCurrentFileMovingOrDeleting.value = false
      await projectStore.fetchProject()
    }
  }

  const createFile = async (fullPath: string) => {
    if (!projectStore.projectId) return
    try {
      await apiClient.project.createTextFile(projectStore.projectId, fullPath, '')
      await projectStore.fetchProject()
      await editorStore.openFile(fullPath)
    } catch (error) {
      errorStore.setError(error)
      if (error instanceof APIError && error.errorCode === ERROR_CODE.ALREADY_EXISTS) {
        await projectStore.fetchProject()
        await editorStore.openFile(fullPath)
      }
    }
  }

  const duplicateFile = async (targetPath: string, newPath: string) => {
    if (!projectStore.projectId) return
    try {
      await apiClient.project.duplicateFile(projectStore.projectId, targetPath, newPath)
      await projectStore.fetchProject()
    } catch (error) {
      errorStore.setError(error)
      if (error instanceof APIError && error.errorCode === ERROR_CODE.ALREADY_EXISTS) {
        await projectStore.fetchProject()
      }
    }
  }

  const deleteFile = async (targetPath: string) => {
    if (!projectStore.projectId) return
    if (editorStore.currentFilePath === targetPath) {
      isCurrentFileMovingOrDeleting.value = true
    }
    try {
      await apiClient.project.deleteFile(projectStore.projectId, targetPath)
    } catch (error) {
      errorStore.setError(error)
    } finally {
      if (isCurrentFileMovingOrDeleting.value) {
        await editorStore.openFile(null)
        isCurrentFileMovingOrDeleting.value = false
      }
      await projectStore.fetchProject()
    }
  }

  const createFolder = async (fullPath: string) => {
    if (!projectStore.projectId) return
    try {
      await apiClient.project.createFolder(projectStore.projectId, fullPath)
    } catch (error) {
      errorStore.setError(error)
    } finally {
      await projectStore.fetchProject()
    }
  }

  const moveFolder = async (oldPath: string, newPath: string) => {
    if (!projectStore.projectId) return
    if (editorStore.currentFilePath?.startsWith(oldPath)) {
      isCurrentFileMovingOrDeleting.value = true
    }
    try {
      await apiClient.project.renameFolder(projectStore.projectId, oldPath, newPath)
      if (editorStore.currentFilePath && isCurrentFileMovingOrDeleting.value) {
        const newCurrentFilePath = editorStore.currentFilePath.replace(oldPath, newPath)
        await editorStore.openFile(newCurrentFilePath)
      }
    } catch (error) {
      errorStore.setError(error)
    } finally {
      isCurrentFileMovingOrDeleting.value = false
      await projectStore.fetchProject()
    }
  }

  const deleteFolder = async (targetPath: string) => {
    if (!projectStore.projectId) return
    if (editorStore.currentFilePath?.startsWith(targetPath)) {
      isCurrentFileMovingOrDeleting.value = true
    }
    try {
      await apiClient.project.deleteFolder(projectStore.projectId, targetPath)
      if (isCurrentFileMovingOrDeleting.value) {
        await editorStore.openFile(null)
      }
    } catch (error) {
      errorStore.setError(error)
    } finally {
      isCurrentFileMovingOrDeleting.value = false
      await projectStore.fetchProject()
    }
  }
  const $reset = () => {
    isCurrentFileMovingOrDeleting.value = false
  }
  return {
    isCurrentFileMovingOrDeleting,
    uploadFile,
    uploadFolder,
    moveFile,
    createFile,
    duplicateFile,
    deleteFile,
    createFolder,
    moveFolder,
    deleteFolder,
    $reset,
  }
})
