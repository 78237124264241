/**
 * 프론트엔드에서 사용되는 프로젝트의 파일 경로는 다음과 같은 규칙을 따릅니다.
 *
 * 1. 경로 구분자는 '/'입니다.
 * 2. 경로는 '/'로 시작하지 않습니다. 빈 문자열 ('') 은 루트 디렉토리를 나타냅니다.
 * 3. '/'로 끝나는 경로는 디렉토리를 나타냅니다.
 * 4. '/'로 끝나지 않는 경로는 파일을 나타냅니다.
 * 5. 확장자는 '.'을 포함하며, 대소문자는 구분하지 않습니다.
 *
 * 이 파일에서는 위 규칙을 준수하는 파일 경로를 다루는 함수와 파일 이름과 관련된 함수를 구현합니다.
 */

/**
 * 주어진 파일 경로가 디렉토리인지 확인합니다.
 *
 * @param path
 * @returns
 * @example
 * isDir('a/b/c') => false
 * isDir('a/b/c/') => true
 * isDir('') => true
 */
export const isDir = (path: string) => path.endsWith('/') || path === ''

/**
 * 주어진 파일 경로가 파일인지 확인합니다.
 *
 * @param path
 * @returns
 * @example
 * isFile('a/b/c') => true
 * isFile('a/b/c/') => false
 */
export const isFile = (path: string) => !isDir(path)

/**
 * 주어진 경로의 디렉토리 경로를 반환합니다.
 *
 * @param path
 * @returns
 * @example
 * dirname('a/b/c') => 'a/b/'
 * dirname('a/b/c/') => 'a/b/'
 * dirname('a') => ''
 */
export const dirname = (path: string) => {
  const targetPath = path.endsWith('/') ? path.slice(0, -1) : path
  const lastSlashIndex = targetPath.lastIndexOf('/')
  const dirPath = lastSlashIndex === -1 ? '' : targetPath.slice(0, lastSlashIndex + 1)
  return dirPath
}

/**
 * 주어진 경로의 파일 확장자를 반환합니다.
 *
 * @param path
 * @returns
 * @example
 * extname('a/b/c') => ''
 * extname('a/b/c.txt') => '.txt'
 * extname('a/b/c.TXT') => '.txt'
 * extname('a/b/c.') => '.'
 */
export const extname = (path: string) => {
  const lastDotIndex = path.lastIndexOf('.')
  if (lastDotIndex === -1) return ''
  const lastSlashIndex = path.lastIndexOf('/')
  if (lastDotIndex < lastSlashIndex) return ''
  return path.slice(lastDotIndex).toLowerCase()
}

/**
 * 주어진 경로의 이름을 반환합니다.
 * 폴더인 경우 마지막 '/'를 제외한 이름을 반환합니다.
 *
 * @param path
 * @param ext - 제거할 확장자 문자열
 * @returns
 * @example
 * basename('a/b/c') => 'c'
 * basename('a/b/c/') => 'c'
 * basename('a/b/c.txt') => 'c.txt'
 * basename('a/b/c.txt', '.txt') => 'c'
 * basename('a/b/c.TXT', '.txt') => 'c'
 */
export const basename = (path: string, ext?: string) => {
  const targetPath = path.endsWith('/') ? path.slice(0, -1) : path
  const lastSlashIndex = targetPath.lastIndexOf('/')
  const name = targetPath.slice(lastSlashIndex + 1)
  if (ext?.startsWith('.') && name.toLowerCase().endsWith(ext.toLowerCase())) {
    return name.slice(0, -ext.length)
  }
  return name
}

/**
 * 복사할 파일의 이름이 중복되지 않도록 새로운 이름을 생성합니다.
 * - 파일 이름 뒤에 ' copy', ' copy2', ' copy3', ... 을 붙여서 중복을 피합니다.
 * - 경로는 GCP의 파일 경로 형식을 따릅니다.
 *
 * @param path 복사할 파일의 경로
 * @param files 복사할 파일이 포함되어 있는 프로젝트의 파일 목록 (전체 경로)
 * @returns 중복되지 않는 파일 이름
 *
 * @example
 * const path = 'a/b/c.txt'
 * const files = ['a/b/c.txt', 'a/b/c copy.txt']
 * const newName = nextDuplicatedFilePath(path, files)
 * console.log(newName) // '/a/b/c copy2.txt'
 */
export const nextDuplicatedFilePath = (path: string, files: string[]) => {
  const parentPath = dirname(path)
  const extension = extname(path)
  const basenameWithoutExt = basename(path, extension)
  let i = 1
  let newPath = path
  while (files.includes(newPath)) {
    const suffix = i === 1 ? ' copy' : ` copy${i}`
    const newBasename = `${basenameWithoutExt}${suffix}${extension}`
    newPath = parentPath + newBasename
    i++
  }
  return newPath
}

export const nextDuplicatedFileName = (path: string, files: string[]) => {
  const duplicatedPath = nextDuplicatedFilePath(path, files)
  return basename(duplicatedPath)
}

//'eps' can be rendered but preview is not supported yet
const supportedImageFormats = ['.jpg', '.jpeg', '.png']
/**
 * 주어진 파일 경로가 지원하는 이미지 파일인지 확인합니다.
 * - 지원하는 이미지 파일 형식: jpg, jpeg, png
 *
 * @param path 포맷을 확인할 파일의 경로
 * @returns 지원하는 이미지 파일이면 true, 아니면 false
 */
export const isSupportedImage = (path: string) => {
  const extension = extname(path).toLowerCase()
  return supportedImageFormats.includes(extension)
}

/**
 * 주어진 파일 경로가 PDF 파일인지 확인합니다.
 *
 * @param path 포맷을 확인할 파일의 경로
 * @returns PDF 파일이면 true, 아니면 false
 */
export const isPDF = (path: string) => extname(path).toLowerCase() === '.pdf'

const compressedFormats = ['.zip', '.tar', '.gz']
/**
 * 주어진 파일 경로가 압축 파일로 예상되는지 확인합니다.
 * - 대상 압축 파일 형식: zip, tar, gz
 *
 * @param path 포맷을 확인할 파일의 경로
 * @returns 압축 파일로 예상되면 true, 아니면 false
 *
 */
export const isCompressed = (path: string) => {
  const extension = extname(path).toLowerCase()
  return compressedFormats.includes(extension)
}

/**
 * 주어진 파일 포맷에 해당하는 아이콘 경로를 반환합니다.
 *
 * @param path 아이콘을 가져올 파일의 경로
 * @returns 파일 포맷에 해당하는 아이콘 경로
 */
export const iconPathForFileFormat = (path: string) => {
  // icon 파일 이름은 figma에 설정되어 있는 이름을 사용하였습니다.
  if (isSupportedImage(path)) {
    return '/icons/photo.svg'
  }
  if (isPDF(path)) {
    return '/icons/picture_as_pdf.svg'
  }
  if (isCompressed(path)) {
    return '/icons/view_list.svg'
  }
  return '/icons/note.svg'
}
