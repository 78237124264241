import { clamp } from '@vueuse/core'
import { computed, ref } from 'vue'

/**
 * 주어진 스텝 값에 따라 가장 가까운 값으로 증가 및 감소하는 훅
 *
 * @param initialValue - 초기값
 * @param steps - 기준이 되는 스텝 값들
 * @returns 현재 값과 증가 및 감소 함수
 */
export const useSteppedValue = (initialValue: number, steps: number[]) => {
  const sortedSteps = computed(() => steps.sort((a, b) => a - b))
  const internalCurrentValue = ref(
    clamp(initialValue, sortedSteps.value[0], sortedSteps.value[steps.length - 1]),
  )
  const currentValue = computed({
    get: () => internalCurrentValue.value,
    set: (value) => {
      internalCurrentValue.value = clamp(
        value,
        sortedSteps.value[0],
        sortedSteps.value[steps.length - 1],
      )
    },
  })
  const nextStepUp = computed(() => sortedSteps.value.find((step) => step > currentValue.value))
  const nextStepDown = computed(() =>
    sortedSteps.value
      .slice()
      .reverse()
      .find((step) => step < currentValue.value),
  )

  const increase = () => {
    if (nextStepUp.value) {
      currentValue.value = nextStepUp.value
    } else {
      currentValue.value = sortedSteps.value[sortedSteps.value.length - 1]
    }
  }
  const decrease = () => {
    if (nextStepDown.value) {
      currentValue.value = nextStepDown.value
    } else {
      currentValue.value = sortedSteps.value[0]
    }
  }
  return { currentValue, increase, decrease }
}
