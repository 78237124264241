<script setup>
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
</script>

<script>
import { BaseButton } from '@murfy-package/ui'
import PrimeVueDialog from 'primevue/dialog'

/* eslint-disable vue/component-api-style */
export default {
  components: {
    PrimeVueDialog,
    BaseButton,
  },
  /* FIXME: props 타입 정의 필요 */
  /* eslint-disable-next-line vue/require-prop-types */
  props: ['controller'],
  emits: ['close', 'submit'],
  data() {
    return {
      target: null,
      isDisable: true,
    }
  },
  beforeMount() {
    this.controller.on('open', this.onOpen)
    this.controller.on('disable', this.onDisable)
  },
  beforeUnmount() {
    this.controller.off('open', this.onOpen)
    this.controller.off('disable', this.onDisable)
  },
  methods: {
    onOpen(args) {
      if (args) {
        this.target = args
        this.isDisable = false
      }
    },
    onDisable() {
      this.isDisable = true
    },
    onSubmit() {
      this.$emit('submit', this.target)
    },
  },
}
</script>

<template>
  <div>
    <PrimeVueDialog :visible="controller.visible" modal :closable="false">
      <template #header>
        <div class="inline-flex items-center justify-center">
          <span class="h2 gray-9">{{ t('header') }}</span>
        </div>
      </template>
      <template #default>
        <span class="p2 gray-7">{{ t('content') }}</span>
      </template>
      <template #footer>
        <div class="flex grow items-center justify-center">
          <BaseButton
            class="w-full"
            severity="tertiary"
            :disabled="isDisable"
            @click="
              // FIXME: Composition API로 변경하면 해결되는 문제임.
              /* eslint-disable-next-line vue/require-explicit-emits */
              $emit('close')
            "
          >
            <div class="flex w-full flex-wrap justify-center">
              <span class="h4">{{ t('cancel') }}</span>
            </div>
          </BaseButton>
        </div>
        <div class="flex grow items-center justify-center">
          <BaseButton
            class="w-full"
            severity="secondary"
            actionType="destructive"
            :disabled="isDisable"
            @click="onSubmit"
          >
            <div class="flex w-full flex-wrap justify-center">
              <span class="h4">{{ t('archive') }}</span>
            </div>
          </BaseButton>
        </div>
      </template>
    </PrimeVueDialog>
  </div>
</template>
<style module>
.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
</style>
<style scoped>
.modalButton {
  width: 100%;
  margin: 0;
}
</style>

<i18n>
{
  "en": {
    "header": "Archive Project",
    "content": "Archiving your project keeps it safe while decluttering your workspace. Archived projects can be restored anytime. Ready to proceed? Confirm below, and your project will find a cozy spot in the archive.",
    "cancel": "Cancel",
    "archive": "Archive",
  }
}
</i18n>
