import { APIClient } from '../client'
import type { AuthResponse, RolePermissions } from '../schema/response'

/**
 * Auth related API methods.
 */
export class AuthAPI {
  private client: APIClient
  constructor(client: APIClient) {
    this.client = client
  }

  /**
   * Login with Google.
   *
   * @param code
   * @returns
   */
  async googleLogin(code: string): Promise<AuthResponse> {
    // FIXME: Untested
    const response = await this.client.get<AuthResponse>('/auth/google', {
      params: {
        code: code,
      },
    })
    sessionStorage.setItem('@murfy-package.api-client.accessToken', response.data.accessToken)
    localStorage.setItem('@murfy-package.api-client.refreshToken', response.data.refreshToken)
    return response.data
  }

  /**
   * Get permission of all roles.
   */
  async rolePermissions(): Promise<RolePermissions> {
    const response = await this.client.get<RolePermissions>('/roles/permissions')
    return response.data
  }
}
