<script setup lang="ts">
import { computed, ref, watch } from 'vue'

const props = withDefaults(
  defineProps<{
    modelValue?: string
    label?: string
    labelClass?: string
    placeholder?: string
    errorMessage?: string
    validate?: (value: string) => boolean
    disabled?: boolean
  }>(),
  {
    modelValue: '',
    label: '',
    labelClass: 'p1',
    placeholder: '',
    errorMessage: '',
    validate: () => true,
    disabled: false,
  },
)

const emit = defineEmits<{
  'update:modelValue': [string]
  submit: [string]
}>()

const inputRef = ref<HTMLInputElement | null>(null)
const isEdited = ref(false)
const viewValue = ref(props.modelValue || '')
watch(viewValue, (newValue) => {
  emit('update:modelValue', newValue)
  viewValue.value = newValue
  isEdited.value = true
})

const isValid = computed(() => {
  if (!isEdited.value) return true
  if (!props.validate) return true
  return props.validate(viewValue.value)
})

const inputId = `input-${Math.random().toString(36).slice(2)}`
defineExpose({
  focus: () => inputRef.value?.focus(),
})
</script>

<template>
  <fieldset :class="$style.container">
    <label v-if="props.label" :for="inputId" :class="[$style.label, props.labelClass]">
      {{ props.label }}
    </label>
    <div :class="[$style.form, { [$style.invalid]: !isValid }]">
      <input
        :id="inputId"
        ref="inputRef"
        v-model="viewValue"
        type="text"
        :class="[$style.input, 'h5']"
        :placeholder="props.placeholder"
        :disabled="props.disabled"
        autocomplete="off"
        @keydown.enter="emit('submit', viewValue)"
      />
      <img
        v-if="viewValue"
        src="/icons/cancel.svg"
        alt="cancel"
        @click="
          () => {
            viewValue = ''
            if (inputRef) inputRef.focus()
          }
        "
      />
    </div>
    <span v-if="!isValid && props.errorMessage" :class="['p4', $style.invalidMessage]">
      {{ props.errorMessage }}
    </span>
  </fieldset>
</template>

<style module>
.container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 8px;
}
.label {
  border: none !important;
  height: 24px;
  color: var(--gray-7);
}
.form {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 8px;
}
.invalid {
  border: none !important;
  outline: 2px solid var(--destructive-primary) !important;
}
.form:focus-within {
  outline: 2px solid var(--cta-primary);
}
.form:hover {
  border: 1px solid var(--cta-hover);
}
.input {
  flex-grow: 2;
  border: none;
  color: var(--gray-8);
  background-color: transparent;
}
input:focus {
  outline: none;
}

.input::placeholder {
  color: var(--gray-5);
}
.invalidMessage {
  color: var(--destructive-primary);
}
</style>
