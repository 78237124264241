<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M6.86204 2.42418C7.85691 2.01209 8.92321 1.79999 10 1.79999C11.0769 1.79999 12.1432 2.01209 13.1381 2.42418C14.1329 2.83626 15.0369 3.44027 15.7983 4.20171C16.5598 4.96315 17.1638 5.86711 17.5759 6.86198C17.9879 7.85685 18.2 8.92315 18.2 9.99999C18.2 11.0768 17.9879 12.1431 17.5759 13.138C17.1638 14.1329 16.5598 15.0368 15.7983 15.7983C15.0369 16.5597 14.1329 17.1637 13.1381 17.5758C12.1432 17.9879 11.0769 18.2 10 18.2C8.92321 18.2 7.85691 17.9879 6.86204 17.5758C5.86717 17.1637 4.96321 16.5597 4.20177 15.7983C3.44033 15.0368 2.83633 14.1329 2.42424 13.138C2.01215 12.1431 1.80005 11.0768 1.80005 9.99999C1.80005 8.92315 2.01215 7.85685 2.42424 6.86198C2.83633 5.86711 3.44033 4.96315 4.20177 4.20171C4.96321 3.44027 5.86717 2.83626 6.86204 2.42418ZM10 3.19999C9.10706 3.19999 8.22282 3.37587 7.3978 3.71761C6.57279 4.05934 5.82316 4.56022 5.19172 5.19166C4.56028 5.8231 4.0594 6.57273 3.71767 7.39774C3.37594 8.22275 3.20005 9.107 3.20005 9.99999C3.20005 10.893 3.37594 11.7772 3.71767 12.6022C4.0594 13.4272 4.56028 14.1769 5.19172 14.8083C5.82316 15.4398 6.57279 15.9406 7.3978 16.2824C8.22282 16.6241 9.10706 16.8 10 16.8C10.893 16.8 11.7773 16.6241 12.6023 16.2824C13.4273 15.9406 14.1769 15.4398 14.8084 14.8083C15.4398 14.1769 15.9407 13.4272 16.2824 12.6022C16.6242 11.7772 16.8 10.893 16.8 9.99999C16.8 9.107 16.6242 8.22275 16.2824 7.39774C15.9407 6.57273 15.4398 5.8231 14.8084 5.19166C14.1769 4.56022 13.4273 4.05934 12.6023 3.71761C11.7773 3.37587 10.893 3.19999 10 3.19999Z"
  />
  <path
    d="M15 10C15 12.7614 12.7614 15 10 15C7.23858 15 5 12.7614 5 10C5 7.23858 7.23858 5 10 5C12.7614 5 15 7.23858 15 10Z"
  />
</template>
