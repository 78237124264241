<script setup lang="ts">
import ProgressSpinner from 'primevue/progressspinner'

const props = withDefaults(
  defineProps<{ strokeWidth?: string; width?: string; height?: string; color?: string }>(),
  {
    strokeWidth: '4',
    width: '64px',
    height: '64px',
    color: 'var(--cta-primary)',
  },
)
</script>

<template>
  <ProgressSpinner
    :strokeWidth="props.strokeWidth"
    :pt="{
      root: $style.root,
      circle: $style.progressSpinnerCircle,
    }"
  />
</template>

<style module>
.root {
  margin: 0;
  width: v-bind(props.width);
  height: v-bind(props.height);
}
.progressSpinnerCircle {
  animation: progress-spinner-dash 1.5s ease-in-out infinite;
  stroke: v-bind(props.color);
}
@keyframes progress-spinner-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}
</style>
