<script setup>
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
const emit = defineEmits(['update:value'])
</script>
<script>
/* eslint-disable vue/component-api-style */
export default {
  /* FIXME: props 타입 정의 필요 */
  /* eslint-disable-next-line vue/require-prop-types */
  props: ['value', 'items', 'name'],
  data() {
    return {}
  },
}
</script>

<template>
  <div class="select-one flex flex-row gap-6">
    <template v-for="item in items" :key="item.id">
      <div
        class="select-item"
        :class="{ checked: value == item.value, uncheck: value != item.value }"
      >
        <input
          :id="item.id"
          :checked="item.value == value"
          type="radio"
          :value="item.value"
          :name="name"
          @input="emit('update:value', $event.target.value)"
        />
        <label :for="item.id" class="h5">
          <span>{{ t(item.label) }}</span>
        </label>
      </div>
    </template>
  </div>
</template>

<style scoped>
.select-one {
  .select-item {
    padding: 8px 0 5px 0;
  }
  input {
    visibility: hidden;
    display: none;
  }
  label {
    cursor: pointer;
  }
  .checked {
    color: var(--gray-8);
    border-bottom: 3px solid var(--gray-8);
  }
  .uncheck {
    color: var(--gray-6);
  }
}
</style>

<i18n>
  {
    "en": {
      "myProjects": "My Projects",
      "emptyMessageMain": "Get Started on Murfy!",
      "emptyMessageSub": "New to Murfy? Let's create your first document!",
      "emptyMessageButton": "Create Document",
      "searchMessageMain": "No Project Found",
      "searchMessageSub": "Oops! It seems there are no results matching your search",
      "archiveMessageMain": "Can’t Find Archived Projects",
      "archiveMessageSub": "You don’t have any archived project.",
      "all": "All",
      "archive": "Archive",
      "shared": "Shared",
      "workspacePlaceholder": "All workspaces",    
    }
  }
  </i18n>
