<script setup lang="ts">
import { BaseList, BaseListItem } from '@murfy-package/murds'
import { computed } from 'vue'

interface BibFields {
  title?: string
  author?: string
  year?: string | number
  journal?: string
  [key: string]: string | number | undefined
}

const props = defineProps<{
  fields: BibFields
}>()

const fieldsOrder: Array<keyof BibFields> = ['title', 'author', 'year', 'journal']

const orderedFields = computed(() => {
  const ordered: BibFields = {}

  // 지정한 순서대로 필드 추가
  fieldsOrder.forEach((key) => {
    if (props.fields[key] !== undefined) {
      ordered[key] = props.fields[key]
    }
  })

  // 나머지 필드 추가
  Object.keys(props.fields).forEach((key) => {
    if (!ordered[key]) {
      ordered[key] = props.fields[key]
    }
  })

  return ordered
})
</script>

<template>
  <BaseList size="sm">
    <BaseListItem
      v-for="(value, key) in orderedFields"
      :key="key"
      :title="key.toString()"
      :description="value?.toString()"
    />
  </BaseList>
</template>
