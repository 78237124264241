// This file was generated by lezer-generator. You probably shouldn't edit it.
export const
  VerbContent = 1,
  LstInlineContent = 2,
  LiteralArgContent = 3,
  SpaceDelimitedLiteralArgContent = 4,
  VerbatimContent = 5,
  MathDelimiter = 6,
  Csname = 7,
  TrailingWhitespaceOnly = 8,
  TrailingContent = 9,
  Begin = 10,
  End = 11,
  RefCtrlSeq = 12,
  RefStarrableCtrlSeq = 13,
  CiteCtrlSeq = 14,
  CiteStarrableCtrlSeq = 15,
  LabelCtrlSeq = 16,
  MathTextCtrlSeq = 17,
  HboxCtrlSeq = 18,
  TitleCtrlSeq = 19,
  DocumentClassCtrlSeq = 20,
  UsePackageCtrlSeq = 21,
  HrefCtrlSeq = 22,
  UrlCtrlSeq = 23,
  VerbCtrlSeq = 24,
  LstInlineCtrlSeq = 25,
  IncludeGraphicsCtrlSeq = 26,
  CaptionCtrlSeq = 27,
  DefCtrlSeq = 28,
  LeftCtrlSeq = 29,
  RightCtrlSeq = 30,
  NewCommandCtrlSeq = 31,
  RenewCommandCtrlSeq = 32,
  NewEnvironmentCtrlSeq = 33,
  RenewEnvironmentCtrlSeq = 34,
  BookCtrlSeq = 35,
  PartCtrlSeq = 36,
  ChapterCtrlSeq = 37,
  SectionCtrlSeq = 38,
  SubSectionCtrlSeq = 39,
  SubSubSectionCtrlSeq = 40,
  ParagraphCtrlSeq = 41,
  SubParagraphCtrlSeq = 42,
  InputCtrlSeq = 43,
  IncludeCtrlSeq = 44,
  ItemCtrlSeq = 45,
  NewTheoremCtrlSeq = 46,
  TheoremStyleCtrlSeq = 47,
  CenteringCtrlSeq = 48,
  BibliographyCtrlSeq = 49,
  BibliographyStyleCtrlSeq = 50,
  AuthorCtrlSeq = 51,
  AffilCtrlSeq = 52,
  AffiliationCtrlSeq = 53,
  MaketitleCtrlSeq = 54,
  TextColorCtrlSeq = 55,
  ColorBoxCtrlSeq = 56,
  HLineCtrlSeq = 57,
  TopRuleCtrlSeq = 58,
  MidRuleCtrlSeq = 59,
  BottomRuleCtrlSeq = 60,
  MultiColumnCtrlSeq = 61,
  DocumentEnvName = 62,
  TabularEnvName = 63,
  EquationEnvName = 64,
  EquationArrayEnvName = 65,
  VerbatimEnvName = 66,
  TikzPictureEnvName = 67,
  FigureEnvName = 68,
  ListEnvName = 69,
  TableEnvName = 70,
  OpenParenCtrlSym = 71,
  CloseParenCtrlSym = 72,
  OpenBracketCtrlSym = 73,
  CloseBracketCtrlSym = 74,
  LineBreakCtrlSym = 75,
  Comment = 76,
  LaTeX = 77,
  Text = 78,
  BlankLine = 79,
  KnownEnvironment = 80,
  DocumentEnvironment = 81,
  OpenBrace = 84,
  CloseBrace = 85,
  OptionalArgument = 86,
  OpenBracket = 87,
  ShortOptionalArg = 88,
  Command = 89,
  KnownCommand = 90,
  Whitespace = 92,
  TextArgument = 93,
  LongArg = 94,
  CloseBracket = 95,
  Environment = 97,
  EnvName = 100,
  ShortTextArgument = 108,
  ShortArg = 109,
  PackageArgument = 116,
  UrlArgument = 120,
  FilePathArgument = 128,
  LabelArgument = 131,
  RefArgument = 133,
  BibKeyArgument = 135,
  CtrlSym = 137,
  MacroParameter = 138,
  OptionalMacroParameter = 139,
  DefinitionArgument = 140,
  NewLine = 141,
  DefinitionFragment = 142,
  CtrlSeq = 143,
  Dollar = 145,
  KnownCtrlSym = 146,
  Normal = 148,
  Ampersand = 149,
  Tilde = 150,
  SectioningArgument = 152,
  BareFilePathArgument = 162,
  TabularArgument = 172,
  TabularContent = 173,
  UnknownCommand = 174,
  DollarMath = 175,
  InlineMath = 176,
  Math = 177,
  MathTextCommand = 178,
  MathCommand = 179,
  MathCtrlSym = 180,
  MathGroup = 181,
  MathDelimitedGroup = 182,
  MathOpening = 183,
  MathClosing = 184,
  MathSpecialChar = 185,
  Number = 186,
  MathChar = 187,
  DisplayMath = 190,
  BracketMath = 191,
  OpenBracketMath = 192,
  CloseBracketMath = 193,
  ParenMath = 194,
  OpenParenMath = 195,
  CloseParenMath = 196,
  TabularEnvironment = 199,
  EquationEnvironment = 204,
  EquationArrayEnvironment = 209,
  VerbatimEnvironment = 213,
  TikzPictureEnvironment = 218,
  TikzPictureContent = 222,
  FigureEnvironment = 225,
  ListEnvironment = 229,
  TableEnvironment = 233,
  Book = 238,
  Part = 241,
  Chapter = 244,
  Section = 247,
  SubSection = 250,
  SubSubSection = 253,
  Paragraph = 256,
  SubParagraph = 259
