<script setup lang="ts">
import { useErrorStore, useUserStore } from '@/stores'
import { BaseButton } from '@murfy-package/ui'
import PrimeVueCard from 'primevue/card'
import { computed, onMounted, ref } from 'vue'
import { googleAuthCodeLogin } from 'vue3-google-login'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'

import { LoadingView } from '.'

const { t } = useI18n()

const userStore = useUserStore()
const { setError } = useErrorStore()
const router = useRouter()

const route = useRoute()
const loading = ref(false)
const routeAfterLogin = () => {
  if (route.query.redirect) {
    router.push(route.query.redirect as string)
  } else {
    router.push('/')
  }
}
onMounted(() => {
  if (localStorage.getItem('@murfy-package.api-client.refreshToken')) {
    loading.value = true
    userStore
      .fetchMe()
      .then((me) => {
        me && routeAfterLogin()
      })
      .finally(() => (loading.value = false))
  }
})

const googleLogin = () => {
  googleAuthCodeLogin()
    .then((res) => {
      loading.value = true
      return userStore.login(res.code)
    })
    .then(() => routeAfterLogin())
    .catch((error) => setError(error))
    .finally(() => (loading.value = false))
}

const isAccessDenied = computed(() => {
  const redirectUrl = route.query.redirect
  return !!redirectUrl
})
</script>

<template>
  <LoadingView v-if="loading" :message="t('loadingUserInfo')" />
  <div v-if="!loading" :class="$style.container">
    <PrimeVueCard
      :pt="{
        root: $style.cardRoot,
        title: ['h2', $style.cardTitle],
        body: $style.cardBody,
        content: ['p2', $style.cardContent],
        footer: $style.cardFooter,
      }"
    >
      <template #title>
        {{ isAccessDenied ? t('accessDenied.title') : t('title') }}
      </template>
      <template #content>
        {{ isAccessDenied ? t('accessDenied.content') : t('content') }}
      </template>
      <template #footer>
        <BaseButton :class="['h4', $style.button]" @click="googleLogin">
          <img src="/icons/Google_white.svg" />
          {{ t('buttonLabel') }}
        </BaseButton>
      </template>
    </PrimeVueCard>
  </div>
</template>

<style module>
.container {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 48px;
}
.cardRoot {
  border-radius: 16px;
  border: 1px solid var(--gray-2);
  box-shadow: 0 2px 40px 0 rgba(0, 0, 0, 0.1);
  width: 480px;
  height: fit-content;
}
.cardBody {
  padding: 16px 0;
}
.cardTitle {
  color: var(--gray-8);
  padding: 16px 32px 0 32px;
  margin: 0 0 12px 0;
}
.cardContent {
  padding: 0 32px 16px 32px;
  color: var(--gray-8);
}
.cardFooter {
  padding: 16px 32px;
}
.button {
  display: flex;
  justify-content: center;
  gap: 8px;
  width: 100%;
}
</style>

<i18n>
{
  "en": {
    "title": "Get started with Murfy!",
    "content": "Your document creation journey begins with a simple login! Secure your work, access it across devices, and collaborate effortlessly. Use your Google account for a seamless experience. Let’s get started—log in now and unleash your creativity!",
    "buttonLabel": "Log in with Google Account",
    "accessDenied": {
      "title": "Access Denied",
      "content": "You do not have permission to access this document. Please login or contact the document owner for access rights.",
    },
    "loadingUserInfo": "Murfy is loading your information. Please wait a moment.",
  }
}
</i18n>
