<script setup lang="ts">
import { computed, nextTick, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const props = defineProps<{
  value?: string
  customValidate?: (value: string) => boolean
  customErrorMessage?: string
}>()

const emit = defineEmits<{
  'update:value': [string]
  save: [string]
  blur: []
}>()

const viewValue = ref(props.value || '')
const inputRef = ref<HTMLInputElement | null>(null)

// input이 blur처리 되어도 form 자체는 focus를 유지하도록 구현
const inputFocus = ref(false)
const keepFocus = ref(false)
const focused = computed(() => inputFocus.value || keepFocus.value)
watch(focused, (newValue) => {
  if (!newValue) {
    // Blur 처리가 되어야할 때의 로직 - Window 파일 이름 변경 로직을 참고함
    if (validate(viewValue.value)) {
      // validation에 성공한 경우는 save 이벤트를 emit
      emit('save', viewValue.value)
    }
    emit('blur')
  }
})

// 외부에서 focus를 줄 수 있도록 설정
const focus = () => {
  inputRef.value?.focus()
}
defineExpose({
  focus,
})

// input에 대한 validation
const errorMessage = ref('')
const validate = (value: string) => {
  if (!value.trim()) {
    errorMessage.value = t('error.emptyName')
    return false
  }
  if (value.trim().length > 128) {
    errorMessage.value = t('error.tooLongName')
    return false
  }
  const nameRegex = /^[^\\/:*?"<>|]+$/
  if (!nameRegex.test(value.trim())) {
    errorMessage.value = t('error.invalidCharacterInName', {
      invalidCharacter: '\\ / : * ? " < > |',
    })
    return false
  }
  if (props.customValidate && !props.customValidate(value) && props.customErrorMessage) {
    errorMessage.value = props.customErrorMessage
    return false
  }
  errorMessage.value = ''
  return true
}
const refreshTooltip = () => {
  keepFocus.value = true
  inputRef.value?.blur()
  nextTick(() => {
    inputRef.value?.focus()
    keepFocus.value = false
  })
}
watch(viewValue, (newValue) => {
  validate(newValue)
  emit('update:value', newValue)
  refreshTooltip()
})
const onSave = () => {
  if (validate(viewValue.value)) {
    emit('save', viewValue.value)
  }
  refreshTooltip()
}
</script>

<template>
  <div :class="$style.container" @click.stop>
    <form :class="$style.form" @keydown.enter.stop @submit.prevent="onSave">
      <input
        id="input"
        ref="inputRef"
        v-model="viewValue"
        v-tooltip.focus.bottom="{
          value: errorMessage,
          pt: {
            root: $style.tooltipRoot,
            text: [$style.tooltipText, 'p2', 'gray-2'],
            arrow: $style.tooltipArrow,
          },
        }"
        type="text"
        :class="[$style.input, 'p1', 'gray-7']"
        autocomplete="off"
        @focus="inputFocus = true"
        @blur="inputFocus = false"
      />
      <button
        :class="[$style.saveButton, 'h5']"
        @click.stop="onSave"
        @mousedown="keepFocus = true"
        @focusout="keepFocus = false"
      >
        {{ t('save') }}
      </button>
    </form>
  </div>
</template>

<style module>
.container {
  height: 24px;
  display: flex;
  flex-direction: column;
}

.form {
  display: flex;
  flex-direction: row;
  width: 100%;
  border: none;
  gap: 8px;
}
.form:hover {
  border: none;
}

.form:focus-within {
  border: none;
  outline: none;
}

.input {
  background: none;
  width: 100%;
  border: none !important;
  caret-color: var(--cta-primary);
  font-family: 'Pretendard', sans-serif;
  font-feature-settings: 'cv02', 'cv03', 'cv04', 'cv11';
  padding: 0;
}
input:focus {
  outline: none;
}

.saveButton {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  color: var(--cta-primary);
}
.tooltipRoot {
  background-color: var(--gray-9);
  height: 56px;
  padding: 16px 24px;
  border-radius: 8px;
  width: fit-content;
  max-width: none;
}
.tooltipText {
  padding: 0;
  background: none;
  text-wrap: nowrap;
}
.tooltipArrow {
  display: none;
}
</style>
<i18n>
{
  "en":{
    "error": {
      "emptyName": "Please enter a name.",
      "invalidCharacterInName": "Invalid character in name : {invalidCharacter}",
      "tooLongName": "Name is too long. Please use 128 characters or less.",
      "duplicateName": "This name already exists.",
    },
    "save": "save"
  }
}
</i18n>
