import { EditorView, ViewPlugin, keymap } from '@codemirror/view'

import { DEFAULT_SNIPPETS } from './config'
import { Snippet } from './formulaTurbo'

// 트리거를 키로 하는 맵 생성
const snippetMap = DEFAULT_SNIPPETS.filter(
  (snippet) => snippet.trigger.length === 1 && snippet.replacement.includes('{VISUAL}'),
).reduce(
  (map, snippet) => {
    map[snippet.trigger] = snippet
    return map
  },
  {} as { [trigger: string]: Snippet },
)

// FormulaTurboVisualSnippet 클래스 정의
class FormulaTurboVisualSnippet {
  view: EditorView

  constructor(view: EditorView) {
    this.view = view
  }

  // 스니펫을 적용하는 메서드
  processSnippets(view: EditorView, triggerKey: string) {
    const { selection, doc } = view.state
    const { from, to, head: cursorPos } = selection.main
    const selectedText = doc.slice(from, to).toString()
    const snippet = snippetMap[triggerKey]

    if (selectedText.trim().length === 0 || !snippet) return false

    const offset = ['(', '{', '['].includes(snippet.trigger) && !selectedText ? -1 : 0
    const replacementText = snippet.replacement.replace(/\$\{VISUAL}/g, selectedText)

    let insertPosition = cursorPos + replacementText.length
    const $0Index = replacementText.indexOf('$0')
    if ($0Index !== -1) insertPosition = cursorPos + $0Index

    this.view.dispatch({
      changes: {
        from,
        to,
        insert: replacementText.replace('$0', ''),
      },
      selection: { anchor: insertPosition + offset },
    })

    return true
  }
}

// 키맵 정의
const snippetKeymap = Object.keys(snippetMap).map((key) => ({
  key,
  run: (view: EditorView) => {
    const plugin = view.plugin(formulaTurboVisualSnippet)
    return plugin ? plugin.processSnippets(view, key) : false
  },
}))

const formulaTurboVisualSnippet = ViewPlugin.fromClass(FormulaTurboVisualSnippet)

// 최종 플러그인과 키맵을 함께 사용
const formulaTurboVisualSnippetAndKeymap = [formulaTurboVisualSnippet, keymap.of(snippetKeymap)]

/**
 * Drag 한 상태에서 키보드에서 trigger 에 해당하는 키를 눌렀을 때
 * ${VISUAL}가 드래그한 텍스트 부분이 대체 되는 기능을 합니다.
 */
export default formulaTurboVisualSnippetAndKeymap
