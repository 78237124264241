<script setup lang="ts">
import { useEditorStore, useErrorStore, useProjectStore } from '@/stores'
import {
  AIChatMessage,
  AIChatMessageContent,
  AIChatMessageContentImage,
  AIChatThread,
  useAPIClient,
} from '@murfy-package/api-client'
import { BaseButton, IconBase, IconPhoto, TextArea } from '@murfy-package/murds'
import { AddPhoto } from '@murfy-package/murds'
import { useDropZone } from '@vueuse/core'
import { nextTick, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import EditorSideAIChatThread, { ViewAIChatMessage } from './EditorSideAIChatThread.vue'

const { t } = useI18n()
const errorStore = useErrorStore()
const projectStore = useProjectStore()
const editorStore = useEditorStore()
const apiClient = useAPIClient()

const userPrompt = ref('')
const userPromptImage = ref<AIChatMessageContentImage | null>(null)
const currentThread = ref<AIChatThread | null>(null)

const viewCurrentMessageId = ref<string>()
const viewMessageMapping = ref<Record<string, AIChatMessage>>({})
const viewMessages = ref<ViewAIChatMessage[]>([])
const getCurrentVisibleMessages = (): AIChatMessage[] => {
  if (!viewCurrentMessageId.value) {
    return []
  }
  const messages: AIChatMessage[] = []
  let currentNodeId: string | undefined = viewCurrentMessageId.value
  while (currentNodeId) {
    const message: AIChatMessage = viewMessageMapping.value[currentNodeId]
    if (!message) {
      break
    }
    messages.push(message)
    currentNodeId = message.parentId
  }
  return messages.reverse()
}

watch(viewCurrentMessageId, () => {
  viewMessages.value = getCurrentVisibleMessages()
})

const fileInputRef = ref<HTMLInputElement | null>(null)
const handleClickPhoto = () => {
  fileInputRef.value?.click()
}
const fileUploading = ref(false)
const handleFileChange = (event: Event) => {
  const target = event.target as HTMLInputElement
  const file = target.files?.[0]
  if (!file) {
    return
  }
  uploadFile(file)
}
const uploadFile = (file: File) => {
  const projectId = projectStore.projectId
  if (!projectId) {
    return
  }
  fileUploading.value = true
  apiClient.askAI
    .uploadImage(projectId, file)
    .then((image) => {
      userPromptImage.value = image
    })
    .catch((error) => {
      errorStore.setError(error)
    })
    .finally(() => {
      fileUploading.value = false
    })
}
const dropZoneRef = ref<HTMLDivElement>()
const handleDrop = (files: File[] | null) => {
  if (!files || files.length === 0) {
    return
  }
  const file = files[0]
  if (!file) {
    return
  }
  uploadFile(file)
}
const { isOverDropZone } = useDropZone(dropZoneRef, {
  onDrop: handleDrop,
  dataTypes: ['image/png'],
  multiple: false,
  preventDefaultForUnhandled: false,
})
const handleDeletePhoto = () => {
  const imageId = userPromptImage.value?.id
  if (!imageId) {
    return
  }
  const projectId = projectStore.projectId
  if (!projectId) {
    return
  }
  apiClient.askAI.deleteImage(projectId, imageId).catch((error) => {
    errorStore.setError(error)
  })
  userPromptImage.value = null
}
const threadRef = ref<HTMLElement | null>(null)
const textAreaRef = ref<HTMLTextAreaElement | null>(null)
const answering = ref(false)
const handleSubmitPrompt = () => {
  const projectId = projectStore.projectId
  if ((!userPrompt.value.trim() && !userPromptImage.value) || !projectId) {
    return
  }
  const messageContents: AIChatMessageContent[] = [
    {
      contentType: 'text',
      text: userPrompt.value,
    },
  ]
  // 프롬프트에 이미지 추가
  if (userPromptImage.value) {
    messageContents.push({
      ...userPromptImage.value,
    })
  }
  // 프롬프트에 selection 추가
  const selection = editorStore.editorView?.state.selection.main
  if (selection && !selection.empty) {
    const selectedText = editorStore.editorView?.state.sliceDoc(selection.from, selection.to)
    if (selectedText && selectedText.trim().length > 0) {
      messageContents.push({
        contentType: 'selection',
        filePath: editorStore.currentFilePath ?? '',
        text: selectedText,
      })
      // selection 해제
      editorStore.useCursor()?.set(selection.from)
    }
  }

  askQuestion(messageContents).finally(() => {
    userPrompt.value = ''
    userPromptImage.value = null
    nextTick(() => {
      textAreaRef.value?.focus()
    })
  })
}
const handlePaste = (event: ClipboardEvent) => {
  // 이미지를 붙여넣을 경우 이미지를 업로드
  const items = event.clipboardData?.items
  if (!items) {
    return
  }
  for (let i = 0; i < items.length; i++) {
    const item = items[i]
    if (item.type.indexOf('image') === -1) {
      continue
    }
    const file = item.getAsFile()
    if (!file) {
      continue
    }
    uploadFile(file)
    break
  }
}

const handleRetry = (retryTargetAnswer: AIChatMessage) => {
  const questionId = retryTargetAnswer.parentId
  const projectId = projectStore.projectId
  if (!questionId || !projectId) {
    return
  }
  const isQuestionInMapping = !!viewMessageMapping.value[questionId]
  const retryTargetQuestion = isQuestionInMapping
    ? viewMessageMapping.value[questionId]
    : viewMessages.value.find((message) => message.id === questionId)
  if (!retryTargetQuestion) {
    return
  }
  if (isQuestionInMapping) {
    viewCurrentMessageId.value = retryTargetQuestion.id
  } else {
    // error가 발생한 케이스.
    viewMessages.value = viewMessages.value.filter(
      (message) => message.id !== retryTargetAnswer.id && message.id !== retryTargetQuestion.id,
    )
  }
  nextTick(() => askQuestion(retryTargetQuestion.contents, isQuestionInMapping))
}

const askQuestion = (contents: AIChatMessageContent[], isRetry = false) => {
  const projectId = projectStore.projectId
  if (!projectId) {
    return Promise.reject(new Error('projectId is not defined'))
  }
  const threadId = currentThread.value?.id
  answering.value = true
  const question: AIChatMessage = {
    id: String(Math.random().toString(36).slice(2, 9)),
    role: 'user',
    createdAt: new Date().toISOString(),
    contents,
    parentId: viewCurrentMessageId.value,
    childrenIds: [],
  }
  if (!isRetry) {
    // retry인 경우는 이미 질문이 랜더링 되어있음
    viewMessages.value.push(question)
  }
  const answeringMessage: ViewAIChatMessage = {
    id: String(Math.random().toString(36).slice(2, 9)),
    role: 'assistant',
    createdAt: new Date().toISOString(),
    contents: [],
    loading: true,
    parentId: question.id,
    childrenIds: [],
  }
  viewMessages.value.push(answeringMessage)
  nextTick(() => scrollThreadToBottom())
  return new Promise<void>((resolve) => {
    nextTick(() => {
      apiClient.askAI
        .askQuestion({
          projectId,
          messageContents: question.contents,
          parentMessageId: question.parentId,
          threadId,
        })
        .then((response) => handleResponse({ ...response, isRetry }))
        .catch((error) => {
          viewMessages.value = viewMessages.value.map((message) => {
            if (message.id === answeringMessage.id) {
              message.loading = false
              message.error = true
              message.errorMessage = errorStore.getErrorMessage(error)
            }
            return message
          })
        })
        .finally(() => {
          scrollThreadToBottom()
          answering.value = false
          resolve()
        })
    })
  })
}

const handleResponse = ({
  threadId,
  question,
  answer,
  isRetry,
}: {
  threadId: string
  question: AIChatMessage
  answer: AIChatMessage
  isRetry: boolean
}) => {
  // FIXME: 서버에서 메시지의 부모-자식 관계 정보를 제공하지 않아 임시로 처리
  // retry 인 경우는 입력으로 받은 question 대신 ViewCurrentMessageId를 활용함
  if (isRetry && viewCurrentMessageId.value) {
    question = viewMessageMapping.value[viewCurrentMessageId.value]
  } else if (viewCurrentMessageId.value) {
    const parentMessage = viewMessageMapping.value[viewCurrentMessageId.value]
    if (parentMessage) {
      parentMessage.childrenIds.push(question.id)
    }
    question.parentId = viewCurrentMessageId.value
  }
  question.childrenIds = [...question.childrenIds, answer.id]
  answer.parentId = question.id

  if (question.parentId) {
    const parentMessage = viewMessageMapping.value[question.parentId]
    if (parentMessage && !parentMessage.childrenIds.includes(question.id)) {
      parentMessage.childrenIds.push(question.id)
    }
  }
  viewMessageMapping.value = {
    ...viewMessageMapping.value,
    [question.id]: question,
    [answer.id]: answer,
  }
  viewCurrentMessageId.value = answer.id
  if (currentThread.value?.id !== threadId) {
    /** FIXME: threadId로 새로운 스레드를 가져오는 API가 필요함
    apiClient.askAI.getThread(threadId).then((thread) => {
      currentThread.value = thread
    })
    */
    currentThread.value = {
      id: threadId,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      title: 'dummyThread',
      mapping: {
        ...viewMessageMapping.value,
      },
      currentMessageId: answer.id,
    }
  }
}

const scrollThreadToBottom = () => {
  threadRef.value?.scrollTo({
    top: threadRef.value.scrollHeight,
    behavior: 'smooth',
  })
}

const changeCurrentNode = (targetMessage: AIChatMessage, isPrev: boolean) => {
  const targetId = targetMessage.id
  const targetParentId = targetMessage.parentId
  if (!targetParentId) {
    return
  }
  const parentMessage = viewMessageMapping.value[targetParentId]
  if (!parentMessage) {
    return
  }
  const targetIndex = parentMessage.childrenIds.findIndex((childId) => childId === targetId)
  if (targetIndex === -1) {
    return
  }
  const nextIndex = targetIndex + (isPrev ? -1 : 1)
  if (nextIndex < 0 || nextIndex >= parentMessage.childrenIds.length) {
    return
  }
  const nextId = parentMessage.childrenIds[nextIndex]
  let leafMessage = viewMessageMapping.value[nextId]
  while (leafMessage.childrenIds.length !== 0) {
    leafMessage = viewMessageMapping.value[leafMessage.childrenIds[0]]
  }
  viewCurrentMessageId.value = leafMessage.id
}
const handleClickPrev = (targetMessage: AIChatMessage) => {
  changeCurrentNode(targetMessage, true)
}
const handleClickNext = (targetMessage: AIChatMessage) => {
  changeCurrentNode(targetMessage, false)
}
</script>
<template>
  <div ref="dropZoneRef" class="flex h-full flex-col justify-between">
    <div v-show="isOverDropZone">
      <div
        class="absolute right-0 top-0 flex h-full w-full items-center justify-center bg-gray-200"
      >
        <div class="text-color-text-secondary flex flex-col items-center gap-4">
          <IconBase :width="100" :height="100">
            <IconPhoto />
          </IconBase>
          <div class="body-lg">{{ t('dropImage') }}</div>
        </div>
      </div>
    </div>
    <header
      class="border-color-border-primary flex h-[52px] flex-none items-center border-b px-4 py-2"
    >
      <span class="head-xs text-color-text-primary w-full">{{ t('murfyAI') }}</span>
    </header>
    <main ref="threadRef" class="flex h-full flex-col gap-4 overflow-auto p-4">
      <EditorSideAIChatThread
        :messageMapping="viewMessageMapping"
        :currentMessageId="viewCurrentMessageId"
        :messages="viewMessages"
        @retry="handleRetry"
        @prev="handleClickPrev"
        @next="handleClickNext"
      />
    </main>
    <footer class="border-color-border-primary flex flex-none flex-col gap-4 border-t p-4">
      <AddPhoto
        v-if="userPromptImage || fileUploading"
        :imageUrl="userPromptImage?.url ?? ''"
        :loading="fileUploading"
        deletable
        @delete="handleDeletePhoto"
      />
      <TextArea
        ref="textAreaRef"
        v-model="userPrompt"
        class="max-h-[70vh]"
        :disabled="answering"
        :placeholder="t('textAreaPlaceholder')"
        @submit="handleSubmitPrompt"
        @paste="handlePaste"
      >
        <template #actions>
          <BaseButton severity="secondary" variant="text" @click.stop="handleClickPhoto">
            <template #icon>
              <IconPhoto />
            </template>
          </BaseButton>
        </template>
      </TextArea>
      <input
        ref="fileInputRef"
        type="file"
        accept="image/*"
        class="hidden"
        @change="handleFileChange"
      />
    </footer>
  </div>
</template>

<i18n>
{
  "en": {
    "murfyAI": "Murfy AI",
    "placeholder": "Murfy AI can help you!",
    "textAreaPlaceholder": "Get help from AI",
    "onlyOneFile": "Only one file can be uploaded at a time",
    "dropImage": "Drop image here to upload",
  }
}
</i18n>
