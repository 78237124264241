import type { PDFPageProxy, PageViewport } from 'pdfjs-dist'
import { RenderingCancelledException, TextLayer } from 'pdfjs-dist'
import { RenderTask, type TextContent, type TextItem } from 'pdfjs-dist/types/src/display/api'
import { computed, nextTick, ref } from 'vue'

export const useRenderPDF = (pagePromise: Promise<PDFPageProxy>) => {
  const page = ref<(() => PDFPageProxy) | null>(null)
  const renderTask = ref<(() => RenderTask) | null>(null)
  const isRendering = computed(() => renderTask.value !== null)
  const error = ref<unknown | null>(null)
  const isError = computed(() => error.value !== null)
  const canvas = ref<HTMLCanvasElement | null>(null)
  const viewport = ref<PageViewport | null>(null)
  const textLayerContainer = ref<HTMLDivElement | null>(null)
  const textContent = ref<(() => TextContent) | null>(null)
  const textLayer = ref<(() => TextLayer) | null>(null)

  const initRender = async () => {
    if (renderTask.value) {
      renderTask.value().cancel()
    }
    if (!page.value) {
      const pageValue = await pagePromise
      page.value = () => pageValue
    }

    if (!viewport.value) {
      viewport.value = page.value().getViewport({ scale: 1 })
    }

    if (!textContent.value) {
      const textContentValue = await page.value().getTextContent()
      textContent.value = () => textContentValue
      textContent.value().items = textContent.value().items.filter((item) => {
        const { width, height } = item as TextItem
        return width !== 0 && height !== 0
      })
    }
    // Initialize text layer
    if (!textLayerContainer.value) {
      throw new Error('Text layer container is not found')
    }
    if (!textLayer.value) {
      const textContentValue = textContent.value()
      const containerValue = textLayerContainer.value
      const viewportValue = viewport.value
      textLayer.value = () =>
        new TextLayer({
          textContentSource: textContentValue,
          container: containerValue,
          viewport: viewportValue,
        })
      await textLayer.value().render()
    }
  }
  const prepareRenderContext = (scale: number) => {
    if (!page.value) {
      return
    }
    viewport.value = page.value().getViewport({ scale: scale })
    // Support HiDPI-display
    const outputScale = window.devicePixelRatio
    if (!canvas.value) {
      throw new Error('Canvas element is not found')
    }
    const canvasContext = canvas.value.getContext('2d', { willReadFrequently: true })
    if (!canvasContext) {
      throw new Error('Canvas context is not found')
    }
    canvasContext.clearRect(0, 0, canvas.value.width, canvas.value.height)

    canvas.value.width = Math.floor(viewport.value.width * outputScale)
    canvas.value.height = Math.floor(viewport.value.height * outputScale)

    const transform = outputScale !== 1 ? [outputScale, 0, 0, outputScale, 0, 0] : undefined
    return {
      canvasContext,
      viewport: viewport.value,
      transform,
    }
  }
  const render = async (scale: number) => {
    try {
      await initRender()
      if (!page.value) {
        const pageValue = await pagePromise
        throw new Error(`Page ${pageValue.pageNumber} is not found`)
      }
      const renderContext = prepareRenderContext(scale)
      await nextTick()
      if (!renderContext) {
        throw new Error('Render context is not found')
      }
      const currentRenderTask = page.value().render(renderContext)
      renderTask.value = () => currentRenderTask
      await currentRenderTask.promise
      error.value = null
      return page.value ? page.value() : null
    } catch (e) {
      if (e instanceof RenderingCancelledException) {
        return
      }
      error.value = e
    } finally {
      renderTask.value = null
    }
  }
  return {
    canvas,
    error,
    render,
    viewport,
    isRendering,
    isError,
    textLayerContainer,
  }
}
