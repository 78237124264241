import { EditorView, keymap } from '@codemirror/view'

import { useMathPatterns } from '../../hooks/useMathPatterns'
import { splitReplacement } from '../../utils/stringUtils'
import { DEFAULT_SNIPPETS, NOT_FOUND } from './config'

// 탭 위치로 이동하는 공통 함수
const navigateToTabStop = (view: EditorView, direction: 'next' | 'previous'): void => {
  const { state, dispatch } = view
  const cursorPos = state.selection.main.head
  const line = state.doc.lineAt(cursorPos)
  const text = line.text
  const beforeCursor = text.slice(0, cursorPos - line.from)
  const currentPos = beforeCursor.length
  const fullText = beforeCursor + text.slice(cursorPos - line.from)

  let tabPosition = NOT_FOUND // 초기값으로 NOT_FOUND 상수 사용
  let spacePosition = NOT_FOUND // 초기값으로 NOT_FOUND 상수 사용

  // 이동할 문자 리스트
  const openBrackets = ['(', '{', '[']
  const closeBrackets = [')', '}', ']']
  const brackets = [...openBrackets, ...closeBrackets]

  for (const snippet of DEFAULT_SNIPPETS) {
    const parts = splitReplacement(snippet.replacement)

    if (
      parts.length === 0 ||
      beforeCursor.indexOf(parts[0].trim()) === NOT_FOUND ||
      brackets.includes(parts[0])
    )
      continue

    if (direction === 'next') {
      parts.some((part, i) => {
        const pos = fullText.lastIndexOf(part) + part.length
        if (pos <= currentPos) return false

        tabPosition = pos
        spacePosition =
          i < parts.length - 1
            ? line.from + fullText.lastIndexOf(parts[i + 1])
            : tabPosition === NOT_FOUND
              ? line.to
              : line.from + tabPosition
        return true
      })
    } else {
      for (let i = parts.length - 1; i >= 0; i--) {
        const part = parts[i]
        const pos = fullText.lastIndexOf(part)
        if (pos < currentPos && pos !== NOT_FOUND && i !== 0) {
          tabPosition = pos
          spacePosition = line.from + line.text.indexOf(parts[i - 1]) + parts[i - 1].length
          break
        }
      }
    }

    const anchor = tabPosition === NOT_FOUND ? line.from : line.from + tabPosition
    if (spacePosition !== NOT_FOUND) {
      view.dispatch({ selection: { anchor, head: spacePosition } })
    }
    if (tabPosition !== NOT_FOUND) break
  }

  // (', '{', '[' 와 같은 괄호 처리
  const doc = state.doc
  const fullDocumentText = doc.toString()

  if (tabPosition === NOT_FOUND) {
    // 커서 위치 주변의 다양한 LaTeX Math 모드 범위를 찾는 함수 호출
    const { rangeAtCursor } = useMathPatterns(doc, cursorPos)

    const mathRange = rangeAtCursor.value
    if (!mathRange) return // 수학 범위를 찾지 못한 경우 함수 종료

    // 커서를 주어진 위치로 이동시키는 함수
    const moveToPosition = (position: number) => {
      const transaction = state.update({
        selection: { anchor: position },
      })
      dispatch(transaction)
    }

    if (direction === 'next') {
      let found = false // 괄호를 찾았는지 여부를 추적

      // 커서 위치에서 mathRange.to까지 루프 실행
      for (let i = cursorPos + 1; i < mathRange.to; i++) {
        const char = fullDocumentText[i]
        const beforeChar = fullDocumentText[i - 1]

        // 열린 괄호나 닫힌 괄호를 찾은 경우
        if (brackets.includes(char)) {
          moveToPosition(openBrackets.includes(char) ? i : i) // 열린 괄호의 경우 커서 위치 조정
          found = true
          break
        } else if (brackets.includes(beforeChar)) {
          moveToPosition(closeBrackets.includes(char) ? i + 1 : i) // 닫힌 괄호의 경우 커서 위치 조정
          found = true
          break
        }
      }

      if (!found) {
        // Math 모드 안에서 문자를 찾지 못한 경우, mathRange의 끝 부분 바깥으로 커서를 이동
        moveToPosition(mathRange.to + mathRange.close.length + 1)
      }
    } else if (direction === 'previous') {
      let found = false // 괄호를 찾았는지 여부를 추적

      // 커서 위치에서 mathRange.from까지 역순으로 루프 실행
      for (let i = cursorPos - 1; i >= mathRange.from; i--) {
        const char = fullDocumentText[i]

        // 괄호를 찾은 경우
        if (brackets.includes(char)) {
          if (brackets.includes(char) && i === cursorPos - 1) {
            moveToPosition(i) // 열린 괄호 바로 다음 위치에 있을 경우, 이전 문자로 이동
          } else {
            moveToPosition(i + 1) // 일반적인 경우 커서를 다음 위치로 이동
          }
          found = true
          break
        }
      }

      if (!found) {
        // Math 모드 안에서 문자를 찾지 못한 경우, mathRange의 시작 부분 바깥으로 커서를 이동
        moveToPosition(mathRange.from - mathRange.open.length - 1)
      }
    }
  }
}

// Tab 및 Shift+Tab 키에 대한 키맵 정의
const formulaTurboTabNavigator = keymap.of([
  {
    key: 'Tab',
    run: (view: EditorView): boolean => {
      navigateToTabStop(view, 'next')
      return true
    },
  },
  {
    key: 'Shift-Tab',
    run: (view: EditorView): boolean => {
      navigateToTabStop(view, 'previous')
      return true
    },
  },
])

/*
formulaTurboTabNavigator는 Tab 및 Shift+Tab 키를 사용하여 스니펫의 다음 및 이전 탭 위치로 이동하는 기능을 제공합니다.

DEFAULT_SNIPPETS 배열의 replacement 속성은 문자열입니다.
replacement 문자열을 $0, $1, $2 등을 기준으로 분리하여 배열에 저장합니다.
예를 들어, replacement가 '\hat{$1}$0'일 때, ['\hat{', '}']로 분리하여 배열에 저장합니다.

현재 커서 앞 부분에 분리된 배열의 첫 번째 요소 (replacement[0])가 있는지 확인합니다.
Tab 키를 누르면 현재 커서 위치에서 다음 탭 위치로 이동하고,
Shift+Tab 키를 누르면 현재 커서 위치에서 이전 탭 위치로 이동합니다.
*/

export default formulaTurboTabNavigator
