import { onBeforeUnmount, onMounted, ref } from 'vue'

export const useDragResize = () => {
  const isResizing = ref(false)
  const resizedWidth = ref(0)

  const onMouseMove = (e: MouseEvent) => {
    if (isResizing.value) {
      resizedWidth.value = e.clientX
    }
  }

  const onMouseUp = () => {
    isResizing.value = false
    document.removeEventListener('mousemove', onMouseMove)
    document.removeEventListener('mouseup', onMouseUp)
  }

  const onMouseDown = () => {
    isResizing.value = true
    document.addEventListener('mousemove', onMouseMove)
    document.addEventListener('mouseup', onMouseUp)
  }

  onMounted(() => {
    document.addEventListener('mouseup', onMouseUp)
  })

  onBeforeUnmount(() => {
    document.removeEventListener('mousemove', onMouseMove)
    document.removeEventListener('mouseup', onMouseUp)
  })

  return {
    isResizing,
    resizedWidth,
    onMouseDown,
  }
}
