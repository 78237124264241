<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref, watch } from 'vue'

/**
 * props로 전달받은 text를 container의 width에 맞게 줄여주는 컴포넌트
 * VeryLooooooooongText -> VeryLooooo... (container의 width에 맞게 줄임)
 * 줄여졌을 경우 tooltip에 text를 표시
 *
 * @example
 * <TextOverflowWithTooltip text="This is a long text" />
 *
 */

const props = defineProps<{
  /**
   * 줄여야 할 text
   */
  text: string
}>()
const container = ref<HTMLSpanElement | null>(null)
const tooltipText = ref('')
const currentWidth = ref(0)
// ResizeObserver를 이용해서 container의 width를 감지
let resizeObserver: ResizeObserver | null = null
onMounted(() => {
  resizeObserver = new ResizeObserver((entries) => {
    for (const entry of entries) {
      currentWidth.value = entry.contentRect.width
    }
  })
  resizeObserver.observe(container.value)
})
onBeforeUnmount(() => {
  if (resizeObserver) {
    resizeObserver.disconnect()
  }
})
// container의 width가 바뀌면 tooltipText를 업데이트
watch(currentWidth, () => {
  if (container?.value?.offsetWidth === container?.value?.scrollWidth) {
    // 줄어들지 않았을 경우 tooltip을 표시하지 않음
    tooltipText.value = ''
  } else {
    tooltipText.value = props.text
  }
})
</script>

<template>
  <span ref="container" v-tooltip.top="tooltipText" :class="[$style.container]">{{
    props.text
  }}</span>
</template>

<style module>
.container {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
