import type { EditorView } from '@codemirror/view'
import { type Comment, Permission, useAPIClient } from '@murfy-package/api-client'
import { defineStore, storeToRefs } from 'pinia'
import { computed, nextTick, ref, watch } from 'vue'

import {
  addCommentToSelection,
  commentField,
  getCommentElementById,
  loadComments,
  removeCommentMark,
} from '@/editor/extensions/commentMark'

import { useEditorStore } from './editor'
import { useErrorStore } from './error'
import { usePermission } from './permission'
import { useProjectStore } from './project'
import { ProjectUIMode, useProjectUIStore } from './projectUI'
import { useUserStore } from './user'

export const useCommentStore = defineStore('comment', () => {
  const apiClient = useAPIClient()
  const { currentFilePath, websocketConnectionState } = storeToRefs(useEditorStore())
  const { project } = storeToRefs(useProjectStore())
  const { mode } = storeToRefs(useProjectUIStore())
  watch(websocketConnectionState, (state) => {
    const { checkPermission } = usePermission()
    if (state === 'synced' && checkPermission(Permission.commentRead)) {
      fetchComments()
    }
  })
  const projectComments = ref<Comment[]>([])
  const fileComments = computed(() => {
    if (!currentFilePath.value) {
      return []
    }
    if (!projectComments.value) {
      return []
    }
    return (
      projectComments.value.filter((comment) => comment.fullPath === currentFilePath.value) || []
    )
  })

  /** Comment Filters **/

  /**
   * 해결된 코멘트를 보여줄지 여부
   */
  const showResolved = ref(false)
  /**
   * 현재 파일에 있는 코멘트만 보여줄지 여부
   */
  const showOnlyCurrentFile = ref(true)
  /**
   * 내가 작성한 코멘트만 보여줄지 여부
   */
  const showOnlyMine = ref(false)

  /**
   * Editor Side에서 보여지는 코멘트들
   */
  const visibleComments = computed(() => {
    let comments = [...projectComments.value]
    if (showOnlyCurrentFile.value) {
      comments = comments.filter((c) => c.fullPath === currentFilePath.value)
    }
    if (showOnlyMine.value) {
      const { me } = useUserStore()
      comments = comments.filter((c) => c.userId === me?.id)
    }
    if (!showResolved.value) {
      comments = comments.filter((c) => !c.resolved)
    }
    return comments
  })
  /**
   * 현재 파일에 보여지는 코멘트들
   */
  const visibleFileComments = computed(() =>
    visibleComments.value.filter((c) => c.fullPath === currentFilePath.value),
  )
  // visibleFileComments가 변경되면 에디터에 코멘트를 랜더링함.
  watch(visibleFileComments, (newValue, oldValue) => {
    const { editorView } = useEditorStore()
    if (!editorView) {
      return
    }
    // 기존 코멘트를 제거
    oldValue.forEach((comment) => removeCommentMark(editorView as EditorView, comment.id))
    // 새로운 코멘트를 추가
    const commentField = newValue.map((comment) => ({
      from: comment.from,
      to: comment.to,
      commentId: comment.id,
      resolved: comment.resolved,
    }))
    // comments의 위치가 문서를 벗어나면 랜더링하지 않음
    const filteredCommentField = commentField.filter(
      ({ from, to }) => from < editorView.state.doc.length && to <= editorView.state.doc.length,
    )
    loadComments(editorView as EditorView, filteredCommentField)
  })

  /** Comment Floating 관련 로직 **/
  const selectedComment = ref<Comment | null>(null)
  const selectedCommentElement = ref<HTMLElement | null>(null)
  const commentFloatingVisible = ref(false)
  // 선택된 코멘트나 모드가 변경되면 Comment Floating을 업데이트
  watch([selectedComment, mode], ([newComment, newMode]) => {
    commentFloatingVisible.value = !!newComment && newMode === ProjectUIMode.Edit
  })
  // 현재 파일이 변경되면 선택된 코멘트를 초기화
  watch(currentFilePath, () => {
    selectedComment.value = null
    selectedCommentElement.value = null
  })
  /**
   * Comment Floating을 열고 선택된 코멘트를 불러옴
   *
   * @param commentId
   * @returns
   */
  const openComment = async (commentId: string | null) => {
    if (!commentId) {
      return
    }
    selectedComment.value = null
    await nextTick()
    // 선택된 코멘트가 현재 파일에 없으면 파일을 열고 코멘트를 불러옴
    const comment = projectComments.value.find((c) => c.id === commentId)
    if (!comment) {
      setError(new Error('Comment not found'))
      return
    }
    if (comment.fullPath && currentFilePath.value !== comment.fullPath) {
      const { openFile } = useEditorStore()
      await openFile(comment.fullPath)
      await fetchComments()
    }
    const projectId = project.value?.id
    if (!projectId) return
    try {
      const detailedComment = await apiClient.comment.getComment(projectId, commentId)
      selectedComment.value = detailedComment
      await nextTick()
      updateCommentFloatingAnchor()
    } catch (e) {
      setError(e)
    }
  }
  /**
   * Comment Floating을 닫음
   */
  const closeComment = () => {
    selectedComment.value = null
    selectedCommentElement.value = null
  }
  /**
   * Comment Floating을 토글함. 닫힌 상태에서 열면 true, 열린 상태에서 닫으면 false를 반환
   */
  const toggleComment = async (commentId: string) => {
    if (selectedComment.value?.id === commentId) {
      closeComment()
      return false
    } else {
      await openComment(commentId)
      return true
    }
  }
  /**
   * Comment Floating의 위치 기준점
   */
  const commentFloatingAnchor = ref({ top: 0, left: 0 })
  /**
   * Comment Floating의 위치를 업데이트하는 함수
   */
  const updateCommentFloatingAnchor = () => {
    const { editorView } = useEditorStore()
    if (!selectedComment.value || !editorView) {
      return
    }
    selectedCommentElement.value = getCommentElementById(
      editorView as EditorView,
      selectedComment.value?.id,
    )
    if (!selectedCommentElement.value) {
      return
    }
    commentFloatingAnchor.value.left = selectedCommentElement.value.getBoundingClientRect().right
    commentFloatingAnchor.value.top = selectedCommentElement.value.getBoundingClientRect().bottom
  }

  /** Comment APIs **/
  const { setError } = useErrorStore()
  const fetchComments = async () => {
    if (!project.value?.id) {
      throw new Error('Project is not loaded')
    }
    try {
      projectComments.value = await apiClient.comment.getProjectComments(project.value?.id)
    } catch (e) {
      setError(e)
    }
  }
  const fetchComment = async (commentId: string) => {
    if (!project.value?.id) {
      return Promise.reject(new Error('Project is not loaded'))
    }
    return await apiClient.comment.getComment(project.value?.id, commentId)
  }
  const addComment = async (text: string, from: number, to: number) => {
    if (!project.value?.id) {
      setError(new Error('Project is not loaded'))
    }
    if (!currentFilePath.value) {
      setError(new Error('File is not loaded'))
    }
    try {
      const comment = await apiClient.comment.createComment(
        project.value?.id,
        currentFilePath?.value,
        text,
        from,
        to,
      )
      const { editorView } = useEditorStore()
      addCommentToSelection(editorView, comment.id, comment.resolved)
      await fetchComments()
      return comment
    } catch (e) {
      setError(e)
    }
  }
  const removeComment = async (commentId: string) => {
    try {
      if (!project.value?.id) {
        throw new Error('Project is not loaded')
      }
      await apiClient.comment.deleteComment(project.value?.id, commentId)
      await fetchComments()
      const { editorView } = useEditorStore()
      removeCommentMark(editorView, commentId)
    } catch (e) {
      setError(e)
    }
  }
  const resolveComment = async (commentId: string) => {
    try {
      if (!project.value?.id) {
        throw new Error('Project is not loaded')
      }
      await apiClient.comment.resolveComment(project.value?.id, commentId)
      await fetchComments()
      if (selectedComment.value?.id === commentId) {
        closeComment()
      }
    } catch (e) {
      setError(e)
    }
  }
  const unresolveComment = async (commentId: string) => {
    try {
      if (!project.value?.id) {
        throw new Error('Project is not loaded')
      }
      await apiClient.comment.unresolveComment(project.value?.id, commentId)
      await fetchComments()
    } catch (e) {
      setError(e)
    }
  }
  const modifyComment = async (commentId: string, text: string) => {
    try {
      if (!project.value?.id) {
        throw new Error('Project is not loaded')
      }
      await apiClient.comment.modifyComment(project.value?.id, commentId, text)
      await fetchComments()
    } catch (e) {
      setError(e)
    }
  }
  const saveAllCommentLocations = async () => {
    try {
      if (!project.value?.id) {
        throw new Error('Project is not loaded')
      }
      const { editorView } = useEditorStore()
      if (!editorView) {
        return
      }
      const comments = editorView.state.toJSON({ commentField })?.commentField?.comments as {
        from: number
        to: number
        commentId: string
      }[]
      if (!comments) {
        return
      }
      // comments에 없는 코멘트는 삭제요청
      const currentComments = fileComments.value
      const commentsToRemove = currentComments.filter(
        (comment) => !comments.find((c) => c.commentId === comment.id),
      )
      await Promise.all(commentsToRemove.map((comment) => removeComment(comment.id)))
      await Promise.all(
        comments.map((comment) =>
          apiClient.comment.updateCommentLocation(
            project.value?.id,
            comment.commentId,
            comment.from,
            comment.to,
          ),
        ),
      )
    } catch (e) {
      setError(e)
    }
  }
  const addReply = async (commentId: string, text: string) => {
    try {
      if (!project.value?.id) {
        throw new Error('Project is not loaded')
      }
      const reply = await apiClient.comment.createReply(project.value?.id, commentId, text)
      await fetchComments()
      return reply
    } catch (e) {
      setError(e)
    }
  }
  const modifyReply = async (replyId: string, text: string) => {
    try {
      if (!project.value?.id) {
        throw new Error('Project is not loaded')
      }
      await apiClient.comment.modifyReply(project.value?.id, replyId, text)
      await fetchComments()
    } catch (e) {
      setError(e)
    }
  }
  const removeReply = async (replyId: string) => {
    try {
      if (!project.value?.id) {
        throw new Error('Project is not loaded')
      }
      await apiClient.comment.deleteReply(project.value?.id, replyId)
      await fetchComments()
    } catch (e) {
      setError(e)
    }
  }

  // 기타 유틸리티
  const isCommentPos = (pos: number) =>
    visibleFileComments.value.some((comment) => pos >= comment.from && pos <= comment.to)

  const $reset = () => {
    projectComments.value = []
    showResolved.value = false
    showOnlyCurrentFile.value = true
    showOnlyMine.value = false
    selectedComment.value = null
    selectedCommentElement.value = null
    commentFloatingVisible.value = false
  }
  return {
    selectedComment,
    visibleComments,
    visibleFileComments,
    projectComments,
    showResolved,
    showOnlyCurrentFile,
    showOnlyMine,
    fetchComments,
    fetchComment,
    addComment,
    removeComment,
    resolveComment,
    unresolveComment,
    modifyComment,
    openComment,
    closeComment,
    toggleComment,
    addReply,
    modifyReply,
    removeReply,
    saveAllCommentLocations,
    commentFloatingVisible,
    commentFloatingAnchor,
    updateCommentFloatingAnchor,
    isCommentPos,
    $reset,
  }
})
