import { type PageViewport, Util } from 'pdfjs-dist'
import { TextItem } from 'pdfjs-dist/types/src/display/api'
import { type TextContent } from 'pdfjs-dist/types/src/display/text_layer'
import { ComputedRef, Ref } from 'vue'

const SVG_NAMESPACE = 'http://www.w3.org/2000/svg'

// SVG를 사용하여 텍스트 레이어 생성 (투명하게 처리)
export const buildTextLayer = (viewport: PageViewport, textContent: TextContent, scale: number) => {
  const svg = document.createElementNS(SVG_NAMESPACE, 'svg')
  svg.setAttribute('width', `${viewport.width}px`)
  svg.setAttribute('height', `${viewport.height}px`)
  svg.setAttribute('font-size', '1')

  textContent.items
    .filter((item): item is TextItem => 'str' in item)
    .forEach((textItem: TextItem) => {
      const tx = Util.transform(
        Util.transform(viewport.transform, textItem.transform),
        [1, 0, 0, -1, 0, 0],
      )
      const style = textContent.styles[textItem.fontName]
      const fontSize = Math.sqrt(tx[0] ** 2 + tx[1] ** 2)
      const [x, y] = [tx[4], tx[5]]

      const text = document.createElementNS(SVG_NAMESPACE, 'text')
      text.setAttribute('x', x.toString())
      text.setAttribute('y', y.toString())
      text.setAttribute('font-family', style.fontFamily)
      text.setAttribute('font-size', `${fontSize}px`)
      text.textContent = textItem.str

      // 텍스트에만 투명도 적용
      text.setAttribute('fill-opacity', '0')

      if (textItem.width > 0) {
        text.setAttribute('textLength', `${textItem.width * scale}`)
      }

      svg.appendChild(text)
    })
  return svg
}

export type AreaSelectedEmit = (
  event: 'areaSelected',
  payload: { pageNumber: number; x: number; y: number },
) => void

export const makeAreaSelectedEmitter =
  (
    canvas: Ref<HTMLCanvasElement | null>,
    currentScale: ComputedRef<number>,
    pageIndexNumber: number,
    emit: AreaSelectedEmit,
  ) =>
  (event: MouseEvent) => {
    if (!canvas.value) return

    const rect = canvas.value.getBoundingClientRect()
    const scale = currentScale.value

    // 클릭한 위치를 페이지 좌표로 변환
    const x = (event.clientX - rect.left) / scale
    const y = (event.clientY - rect.top) / scale

    emit('areaSelected', { pageNumber: pageIndexNumber, x: x, y: y })
  }
