import {
  PDF_VIEWER_MAX_ZOOM,
  PDF_VIEWER_MIN_ZOOM,
  PDF_VIEWER_ZOOM_DEFAULT,
  PDF_VIEWER_ZOOM_DEFAULT_SELECT,
  PDF_VIEWER_ZOOM_STEP,
} from '@/config'
import { type Ref, computed, onBeforeUnmount, onMounted, ref, watch } from 'vue'

export const usePDFViewerZoom = (
  wrapper: Ref<HTMLElement | null>,
  documentWidth: Ref<number | null>,
  documentHeight: Ref<number | null>,
) => {
  const innerSelectedZoomValue = ref<string>('')
  const percentSignVisible = ref<boolean>(true)
  const selectedZoomValue = computed({
    get: () =>
      innerSelectedZoomValue.value
        ? innerSelectedZoomValue.value
        : percentSignVisible.value
          ? `${currentZoom.value}%`
          : // 리턴 타입을 모두 string으로 맞추기 위해
            `${currentZoom.value}`,
    set: (v) => {
      changeZoomBySelect(v)
    },
  })
  const currentZoom = ref<number>(PDF_VIEWER_ZOOM_DEFAULT)

  const validatePageZoom = (zoomInputNumberValue: number) =>
    zoomInputNumberValue < PDF_VIEWER_MIN_ZOOM
      ? PDF_VIEWER_MIN_ZOOM
      : zoomInputNumberValue > PDF_VIEWER_MAX_ZOOM
        ? PDF_VIEWER_MAX_ZOOM
        : zoomInputNumberValue

  const setValidatedValue = (value: string) => {
    if (value && value.replace) {
      value = value.replace(/\D/g, '')
    }
    const n = !value || Number.isNaN(value) ? 0 : Number(value)
    currentZoom.value = validatePageZoom(n)
  }
  const changeZoomBySelect = (value: string) => {
    switch (value) {
      case 'actual':
        innerSelectedZoomValue.value = value
        currentZoom.value = PDF_VIEWER_ZOOM_DEFAULT
        break
      case 'fit-width':
        innerSelectedZoomValue.value = value
        if (documentWidth.value && wrapper.value) {
          currentZoom.value = validatePageZoom(
            (wrapper.value.clientWidth / documentWidth.value) * 100,
          )
        }
        break
      case 'fit-height':
        innerSelectedZoomValue.value = value
        if (documentHeight.value && wrapper.value) {
          currentZoom.value = validatePageZoom(
            (wrapper.value.clientHeight / documentHeight.value) * 100,
          )
        }
        break
      case '50p':
      // fallthrough
      case '75p':
      // fallthrough
      case '100p':
      // fallthrough
      case '200p':
      // fallthrough
      case '300p':
      // fallthrough
      case '500p':
      // fallthrough
      case '1000p':
        innerSelectedZoomValue.value = value
        setValidatedValue(value)
        break
      default:
        // 사용자 입력 무시
        break
    }
  }

  const increaseZoom = () => {
    if (currentZoom.value >= PDF_VIEWER_MAX_ZOOM) {
      return
    }
    innerSelectedZoomValue.value = ''
    currentZoom.value =
      Math.floor(currentZoom.value / PDF_VIEWER_ZOOM_STEP) * PDF_VIEWER_ZOOM_STEP +
      PDF_VIEWER_ZOOM_STEP
  }
  const decreaseZoom = () => {
    if (currentZoom.value <= PDF_VIEWER_MIN_ZOOM) {
      return
    }
    innerSelectedZoomValue.value = ''
    currentZoom.value =
      Math.floor(currentZoom.value / PDF_VIEWER_ZOOM_STEP) * PDF_VIEWER_ZOOM_STEP -
      PDF_VIEWER_ZOOM_STEP
  }
  const containerHeight = ref(0)

  // 기본 줌 설정
  changeZoomBySelect(PDF_VIEWER_ZOOM_DEFAULT_SELECT)

  // 줌 재계산
  const recalculateZoom = () => changeZoomBySelect(innerSelectedZoomValue.value)
  watch([documentHeight, documentWidth], recalculateZoom)

  let obs: ResizeObserver
  const observeResize = () => {
    if (!wrapper.value) {
      return
    }
    obs = new ResizeObserver(() => {
      recalculateZoom()
      containerHeight.value = wrapper.value?.clientHeight || 0
    })
    obs.observe(wrapper.value)
  }

  const disconnectResizeObserve = () => obs?.disconnect()

  onMounted(() => {
    disconnectResizeObserve()
    observeResize()
  })

  onBeforeUnmount(disconnectResizeObserve)

  watch(wrapper, (el) => {
    if (!el) {
      return
    }
    obs?.disconnect()
    observeResize()
  })

  return {
    currentZoom,
    selectedZoomValue,
    increaseZoom,
    decreaseZoom,
    recalculateZoom,
    containerHeight,
  }
}
