<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M2.6333 5.83712C2.6333 5.45052 2.9467 5.13712 3.3333 5.13712H16.6666C17.0532 5.13712 17.3666 5.45052 17.3666 5.83712C17.3666 6.22371 17.0532 6.53712 16.6666 6.53712H3.3333C2.9467 6.53712 2.6333 6.22371 2.6333 5.83712Z"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M4.10846 5.13954C4.49372 5.10744 4.83207 5.39373 4.86417 5.77899L5.69751 15.779C5.69912 15.7983 5.69992 15.8177 5.69992 15.8371C5.69992 16.0935 5.80177 16.3394 5.98305 16.5207C6.16434 16.7019 6.41022 16.8038 6.66659 16.8038H13.3333C13.5896 16.8038 13.8355 16.7019 14.0168 16.5207C14.1981 16.3394 14.2999 16.0935 14.2999 15.8371C14.2999 15.8177 14.3007 15.7983 14.3023 15.779L15.1357 5.77899C15.1678 5.39373 15.5061 5.10744 15.8914 5.13954C16.2767 5.17165 16.5629 5.50999 16.5308 5.89526L15.6997 15.8688C15.6915 16.485 15.4431 17.0742 15.0067 17.5106C14.5629 17.9544 13.9609 18.2038 13.3333 18.2038H6.66659C6.03891 18.2038 5.43694 17.9544 4.99311 17.5106C4.55674 17.0742 4.30837 16.485 4.30014 15.8688L3.46901 5.89526C3.4369 5.50999 3.7232 5.17165 4.10846 5.13954Z"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M8.33338 3.20377C8.29802 3.20377 8.26411 3.21782 8.2391 3.24282C8.2141 3.26783 8.20005 3.30174 8.20005 3.33711V5.83711C8.20005 6.2237 7.88665 6.53711 7.50005 6.53711C7.11345 6.53711 6.80005 6.2237 6.80005 5.83711V3.33711C6.80005 2.93044 6.9616 2.54043 7.24915 2.25287C7.53671 1.96532 7.92672 1.80377 8.33338 1.80377H11.6667C12.0734 1.80377 12.4634 1.96532 12.7509 2.25287C13.0385 2.54043 13.2 2.93044 13.2 3.33711V5.83711C13.2 6.2237 12.8866 6.53711 12.5 6.53711C12.1134 6.53711 11.8 6.2237 11.8 5.83711V3.33711C11.8 3.30174 11.786 3.26783 11.761 3.24282C11.736 3.21782 11.7021 3.20377 11.6667 3.20377H8.33338Z"
  />
</template>
