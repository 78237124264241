<script setup lang="ts">
import { LogoutModal, MenuButton, ProfileEditModal, TermsAndConditionModal } from '@/components'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const editModalVisible = ref(false)
const termsAndConditionModalVisible = ref(false)
const logoutModalVisible = ref(false)

const menuItems = [
  {
    label: t('editProfile'),
    action: () => {
      editModalVisible.value = true
    },
  },
  {
    label: t('termsAndCondition'),
    action: () => {
      termsAndConditionModalVisible.value = true
    },
  },
  {
    label: t('logout'),
    action: () => {
      logoutModalVisible.value = true
    },
  },
]
</script>

<template>
  <MenuButton icon="/icons/account_circle.svg" iconSize="24px" :model="menuItems" />
  <ProfileEditModal
    :visible="editModalVisible"
    @close="editModalVisible = false"
    @update:visible="editModalVisible = $event"
  />
  <TermsAndConditionModal
    :visible="termsAndConditionModalVisible"
    @close="termsAndConditionModalVisible = false"
    @update:visible="termsAndConditionModalVisible = $event"
  />
  <LogoutModal
    :visible="logoutModalVisible"
    @close="logoutModalVisible = false"
    @update:visible="logoutModalVisible = $event"
  />
</template>

<i18n>
{
  "en": {
    "editProfile": "Edit Profile",
    "termsAndCondition": "Terms & Conditions",
    "logout": "Log out",
  }
}
</i18n>
