import { EditorView, ViewPlugin, ViewUpdate } from '@codemirror/view'

import { useMathPatterns } from '../../hooks/useMathPatterns'

class FormulaTurboFracSnippet {
  view: EditorView

  constructor(view: EditorView) {
    this.view = view
    this.replaceFractionPattern(view)
  }

  update(update: ViewUpdate): void {
    if (update.selectionSet || update.docChanged) {
      this.replaceFractionPattern(update.view)
    }
  }

  replaceFractionPattern(view: EditorView) {
    const state = view.state
    const cursor = state.selection.main.head
    const line = state.doc.lineAt(cursor)
    const text = state.doc.toString().slice(line.from, cursor)

    // Math 모드 인지 체크 합니다.
    const { rangeAtCursor } = useMathPatterns(view.state.doc, cursor)
    if (!rangeAtCursor.value) return

    const match = /([^/{\s]+)\/([^/\s]*)$/.exec(text)
    if (!match) return

    const beforeText = match[1]
    const afterText = match[2] || '' // b 값이 없을 수 있으므로 기본값으로 빈 문자열 설정

    if (!this.isBalanced(line.text)) return

    const from = cursor - match[0].length
    const to = cursor

    const replacement = `\\frac{${beforeText}}{${afterText}}`

    requestAnimationFrame(() => {
      const currentState = view.state
      const transaction = currentState.update({
        changes: {
          from: from,
          to: to,
          insert: replacement,
        },
        selection: {
          anchor: from + replacement.length - (afterText ? 0 : 1),
        },
      })
      view.dispatch(transaction)
    })
  }

  isBalanced(text: string): boolean {
    const stack = []
    const pairs: { [key: string]: string } = {
      '(': ')',
      '{': '}',
      '[': ']',
    }

    for (const char of text) {
      if (pairs[char]) {
        stack.push(char)
      } else if (Object.values(pairs).includes(char)) {
        if (stack.length === 0 || pairs[stack.pop()!] !== char) {
          return false
        }
      }
    }
    return stack.length === 0
  }
}

const formulaTurboFracSnippet = ViewPlugin.fromClass(FormulaTurboFracSnippet)

export default formulaTurboFracSnippet
