<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M6.86204 2.42794C7.85691 2.01586 8.92321 1.80376 10 1.80376C11.0769 1.80376 12.1432 2.01586 13.1381 2.42794C14.1329 2.84003 15.0369 3.44404 15.7983 4.20548C16.5598 4.96692 17.1638 5.87088 17.5759 6.86575C17.9879 7.86062 18.2 8.92692 18.2 10.0038C18.2 11.0806 17.9879 12.1469 17.5759 13.1418C17.1638 14.1366 16.5598 15.0406 15.7983 15.802C15.0369 16.5635 14.1329 17.1675 13.1381 17.5796C12.1432 17.9917 11.0769 18.2038 10 18.2038C8.92321 18.2038 7.85691 17.9917 6.86204 17.5796C5.86717 17.1675 4.96321 16.5635 4.20177 15.802C3.44033 15.0406 2.83633 14.1366 2.42424 13.1418C2.01215 12.1469 1.80005 11.0806 1.80005 10.0038C1.80005 8.92692 2.01215 7.86062 2.42424 6.86575C2.83633 5.87088 3.44033 4.96692 4.20177 4.20548C4.96321 3.44404 5.86717 2.84003 6.86204 2.42794ZM10 3.20376C9.10706 3.20376 8.22282 3.37964 7.3978 3.72138C6.57279 4.06311 5.82316 4.56399 5.19172 5.19543C4.56028 5.82687 4.0594 6.5765 3.71767 7.40151C3.37594 8.22652 3.20005 9.11077 3.20005 10.0038C3.20005 10.8967 3.37594 11.781 3.71767 12.606C4.0594 13.431 4.56028 14.1806 5.19172 14.8121C5.82316 15.4435 6.57279 15.9444 7.3978 16.2861C8.22282 16.6279 9.10706 16.8038 10 16.8038C10.893 16.8038 11.7773 16.6279 12.6023 16.2861C13.4273 15.9444 14.1769 15.4435 14.8084 14.8121C15.4398 14.1806 15.9407 13.431 16.2824 12.606C16.6242 11.781 16.8 10.8967 16.8 10.0038C16.8 9.11077 16.6242 8.22652 16.2824 7.40151C15.9407 6.5765 15.4398 5.82687 14.8084 5.19543C14.1769 4.56399 13.4273 4.06311 12.6023 3.72138C11.7773 3.37964 10.893 3.20376 10 3.20376Z"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M7.90498 7.90878C8.17834 7.63541 8.62156 7.63541 8.89493 7.90878L10.0666 9.08047L11.2383 7.90878C11.5117 7.63541 11.9549 7.63541 12.2283 7.90878C12.5016 8.18214 12.5016 8.62536 12.2283 8.89873L11.0566 10.0704L12.2283 11.2421C12.5016 11.5155 12.5016 11.9587 12.2283 12.2321C11.9549 12.5054 11.5117 12.5054 11.2383 12.2321L10.0666 11.0604L8.89493 12.2321C8.62156 12.5054 8.17834 12.5054 7.90498 12.2321C7.63161 11.9587 7.63161 11.5155 7.90498 11.2421L9.07667 10.0704L7.90498 8.89873C7.63161 8.62536 7.63161 8.18214 7.90498 7.90878Z"
  />
</template>
