import { type ProjectAsset } from '@murfy-package/api-client'
import type { MenuItem } from 'primevue/menuitem'

export class FileTreeNode {
  data: ProjectAsset
  type: 'file' | 'folder'
  children?: FileTreeNode[]
  /**
   * @param isForCreate 새로운 노드를 생성할 때 사용합니다. 기본값은 false입니다.
   */
  isForCreate = false
  isForDuplicate = false
  constructor(data: ProjectAsset) {
    this.data = data
    this.type = data.fullPath.endsWith('/') ? 'folder' : 'file'
    this.children = this.type === 'folder' ? [] : undefined
  }
  add(node: FileTreeNode) {
    if (this.type !== 'folder') throw new Error('Cannot add child to file node')
    if (!this.children) this.children = []
    this.children.push(node)
  }
  isEqual(node: FileTreeNode) {
    return this.data.fullPath === node.data.fullPath
  }
  toMenuItem(): MenuItem {
    return {
      key: this.data.fullPath,
      label: this.data.filename || this.data.fullPath.split('/').slice(-2)[0] || '',
      items: this.children?.map((child) => child.toMenuItem()),
      data: this.data,
      type: this.type,
      isForCreate: this.isForCreate,
      isForDuplicate: this.isForDuplicate,
    }
  }
  static createNew(type: 'file' | 'folder') {
    const randomKey = Math.random().toString(36).substring(7)
    const newNode = new FileTreeNode({
      filename: randomKey,
      fullPath: randomKey + (type === 'folder' ? '/' : ''),
      sizeBytes: 0,
      updatedAt: '',
    })
    newNode.isForCreate = true
    newNode.type = type
    return newNode
  }
  static createDuplicate(node: FileTreeNode) {
    const randomKey = Math.random().toString(36).substring(7)
    const newFullPath = node.data.fullPath + '_copy' + randomKey
    const newNode = new FileTreeNode({
      filename: node.data.filename,
      fullPath: newFullPath,
      sizeBytes: node.data.sizeBytes,
      updatedAt: node.data.updatedAt,
    })
    newNode.isForDuplicate = true
    newNode.type = node.type
    return newNode
  }
}

export class FileTree {
  root: FileTreeNode
  constructor(data?: ProjectAsset[]) {
    this.root = new FileTreeNode({ filename: '/', fullPath: '/', sizeBytes: 0, updatedAt: '' })
    if (data) {
      data.forEach((asset) => {
        this.add(new FileTreeNode(asset))
      })
    }
  }
  isEqual(target: FileTree) {
    const sortedSource = this.toFileList().sort((a, b) => a.fullPath.localeCompare(b.fullPath))
    const sortedTarget = target.toFileList().sort((a, b) => a.fullPath.localeCompare(b.fullPath))
    if (sortedSource.length !== sortedTarget.length) return false
    for (let i = 0; i < sortedSource.length; i++) {
      if (sortedSource[i].fullPath !== sortedTarget[i].fullPath) return false
    }
    return true
  }
  get(fullPath: string) {
    // node.data.fullPath === fullPath 인 노드 탐색
    const stack = [this.root]
    while (stack.length > 0) {
      const current = stack.pop()!
      if (current.data.fullPath === fullPath) return current
      if (current.children) stack.push(...current.children)
    }
    return undefined
  }
  /**
   * node.data.fullPath를 기준으로 노드를 추가함
   * 파일의 경우, 파일이 속한 폴더까지 모두 추가됨
   *
   * @param node 추가할 노드
   */
  add(node: FileTreeNode) {
    const ancestorDirListWithoutEmpty = node.data.fullPath
      .split('/')
      .filter((dirName) => dirName !== '')
      .slice(0, -1)
    const ancestorPathList = ancestorDirListWithoutEmpty.map(
      (_, index) => ancestorDirListWithoutEmpty.slice(0, index + 1).join('/') + '/',
    )
    // Create all ancestor folders
    let current = this.root
    for (const ancestorPath of ancestorPathList) {
      const existing = current.children?.find((child) => child.data.fullPath === ancestorPath)
      if (existing) {
        current = existing
      } else {
        const newFolder = new FileTreeNode({
          filename: ancestorPath.split('/').slice(-2)[0] || '',
          fullPath: ancestorPath,
          sizeBytes: 0,
          updatedAt: '',
        })
        if (!current.children) current.children = []
        current.children.push(newFolder)
        current = newFolder
      }
    }
    // Add the Node
    if (!current.children) current.children = []
    current.children.push(node)
  }
  remove(fullPath: string) {
    if (fullPath === '/') throw new Error('Cannot remove root node')
    const stack = [this.root]
    while (stack.length > 0) {
      const current = stack.pop()!
      if (current.children) {
        const index = current.children.findIndex((child) => child.data.fullPath === fullPath)
        if (index !== -1) {
          current.children.splice(index, 1)
          return true
        }
        stack.push(...current.children)
      }
    }
    return false
  }
  toFileList(onlyFiles = true) {
    const stack = [this.root]
    const fileList: ProjectAsset[] = []
    while (stack.length > 0) {
      const current = stack.pop()!
      if (onlyFiles && current.type === 'folder') continue
      fileList.push(current.data)
      if (current.children) stack.push(...current.children)
    }
    return fileList
  }
  toMenuItem() {
    return this.root.toMenuItem()
  }
}

// FIXME: LegacyFileTreeNode 타입을 사용하는 코드를 수정해야 합니다.
export type LegacyFileTreeNode = {
  key: string
  label: string
  type: string
  fullPath: string
  data?: {
    sizeBytes: number
  }
  children?: LegacyFileTreeNode[]
  open?: boolean
  selected?: boolean
}

export const toLegacyTreeNode = (node: FileTreeNode): LegacyFileTreeNode => ({
  key: Math.random().toString(36).substring(7),
  label: node.data.fullPath.endsWith('/')
    ? node.data.fullPath.split('/').slice(-2)[0]
    : node.data.fullPath.split('/').pop() || '',
  type: node.data.fullPath.endsWith('/') ? 'folder' : 'file',
  fullPath: node.data.fullPath,
  data: {
    sizeBytes: node.data.sizeBytes,
  },
  children: node.children?.map((item) => toLegacyTreeNode(item)),
})

export const fromLegacyTreeNode = (node: LegacyFileTreeNode): FileTreeNode => {
  const projectAsset: ProjectAsset = {
    filename: node.label,
    fullPath: node.fullPath,
    sizeBytes: node.data?.sizeBytes || 0,
    updatedAt: '',
  }
  return new FileTreeNode(projectAsset)
}
