import { Permission, Role, type RolePermissions, useAPIClient } from '@murfy-package/api-client'
import { defineStore, storeToRefs } from 'pinia'
import { computed, ref, watch } from 'vue'

import { useProjectStore } from './project'

/**
 * 프로젝트가 변경될 때, 사용자 권한을 업데이트 합니다.
 *
 */
export const usePermission = defineStore('permission', () => {
  const apiClient = useAPIClient()
  const { project } = storeToRefs(useProjectStore())

  const userRole = ref<Role>(Role.viewer)
  const rolePermissions = ref<RolePermissions | null>(null)
  watch(project, async (newProject) => {
    if (!newProject) {
      return
    }
    userRole.value = await apiClient.project.getUserRole(newProject.id)
  })

  const fetchRolePermissions = async () => {
    rolePermissions.value = await apiClient.auth.rolePermissions()
    // FIXME: Backend에 Comment 관련 권한 구현이 되면 아래 내용 제거
    if (rolePermissions.value) {
      rolePermissions.value[Role.editor] = [
        ...rolePermissions.value[Role.editor],
        Permission.commentCreate,
        Permission.commentRead,
        Permission.commentUpdate,
        Permission.commentDelete,
      ]
      rolePermissions.value[Role.owner] = [
        ...rolePermissions.value[Role.owner],
        Permission.commentCreate,
        Permission.commentRead,
        Permission.commentUpdate,
        Permission.commentDelete,
      ]
    }
  }

  const userPermissions = computed(() => rolePermissions.value?.[userRole.value] || [])

  const checkPermission = (permission: Permission) => {
    if (!userPermissions.value) return false
    return userPermissions.value.includes(permission) ?? false
  }

  const checkPermissionByRole = (role: Role, permission: Permission) =>
    rolePermissions.value?.[role]?.includes(permission) ?? false
  return {
    fetchRolePermissions,
    userRole,
    userPermissions,
    checkPermission,
    checkPermissionByRole,
  }
})
