import { startCompletion } from '@codemirror/autocomplete'
import { EditorView } from '@codemirror/view'

import { autoCompleteLatexCommands } from '../autocomplete'

export const latexUpdateListener = EditorView.updateListener.of((update) => {
  if (update.selectionSet || update.docChanged) {
    const { state } = update.view
    const cursorPos = state.selection.main.head

    const textBeforeCursor = state.doc.sliceString(0, cursorPos)
    const textAfterCursor = state.doc.sliceString(cursorPos)

    // 커서가 어떤 명령어의 중괄호 안에 있는지 확인
    const isInCommandBraces = () => {
      for (const cmd of autoCompleteLatexCommands) {
        const command = cmd.command
        const commandStartPos = textBeforeCursor.lastIndexOf(command)
        if (commandStartPos === -1) continue

        const openBracePos = commandStartPos + command.length
        const closeBracePos = textAfterCursor.indexOf('}', openBracePos)

        // 중괄호가 닫히지 않았거나, 닫히는 중괄호가 커서 뒤에 있는 경우
        if (closeBracePos === -1 || closeBracePos > cursorPos) {
          return true
        }
      }
      return false
    }

    if (isInCommandBraces()) {
      startCompletion(update.view)
    }
  }
})
