import {
  HighlightStyle,
  LRLanguage,
  LanguageSupport,
  foldInside,
  foldNodeProp,
  syntaxHighlighting,
} from '@codemirror/language'
import { NodeProp } from '@lezer/common'
import { Tag, styleTags, tags as t, tags } from '@lezer/highlight'
import { parser } from '@murfy-package/lezer-tex/src/parser'
import * as termsModule from '@murfy-package/lezer-tex/src/parser.terms'

export const tokenNames: Array<string> = Object.keys(termsModule)

export const Tokens: Record<string, Array<string>> = {
  ctrlSeq: tokenNames.filter((name) => name.match(/^(Begin|End|.*CtrlSeq)$/)),
  ctrlSym: tokenNames.filter((name) => name.match(/^.*CtrlSym$/)),
  envName: tokenNames.filter((name) => name.match(/^.*EnvName$/)),
}

const styleOverrides: Record<string, Tag> = {
  DocumentClassCtrlSeq: t.keyword,
  UsePackageCtrlSeq: t.keyword,
  CiteCtrlSeq: t.keyword,
  CiteStarrableCtrlSeq: t.keyword,
  RefCtrlSeq: t.keyword,
  RefStarrableCtrlSeq: t.keyword,
  LabelCtrlSeq: t.keyword,
}

const styleEntry = (token: string, defaultStyle: Tag) => [
  token,
  styleOverrides[token] || defaultStyle,
]

const Styles = {
  ctrlSeq: Object.fromEntries(Tokens.ctrlSeq.map((token) => styleEntry(token, t.tagName))),
  ctrlSym: Object.fromEntries(Tokens.ctrlSym.map((token) => styleEntry(token, t.literal))),
  envName: Object.fromEntries(Tokens.envName.map((token) => styleEntry(token, t.attributeValue))),
}

const typeMap: Record<string, string[]> = {
  // commands that are section headings
  PartCtrlSeq: ['$SectioningCommand'],
  ChapterCtrlSeq: ['$SectioningCommand'],
  SectionCtrlSeq: ['$SectioningCommand'],
  SubSectionCtrlSeq: ['$SectioningCommand'],
  SubSubSectionCtrlSeq: ['$SectioningCommand'],
  ParagraphCtrlSeq: ['$SectioningCommand'],
  SubParagraphCtrlSeq: ['$SectioningCommand'],
  // commands that have a "command tooltip"
  HrefCommand: ['$CommandTooltipCommand'],
  Include: ['$CommandTooltipCommand'],
  Input: ['$CommandTooltipCommand'],
  Ref: ['$CommandTooltipCommand'],
  UrlCommand: ['$CommandTooltipCommand'],
}

export const latexLanguage = LRLanguage.define({
  name: 'latex',
  parser: parser.configure({
    props: [
      foldNodeProp.add({
        Group: foldInside,
        NonEmptyGroup: foldInside,
        TextArgument: foldInside,
        // TODO: Why isn't
        // `Content: node => node,`
        // enough? For some reason it doesn't work if there's a newline after
        // \section{a}, but works for \section{a}b
        $Environment: (node) => node.getChild('Content'),
        $SectioningCommand: (node) => {
          const BACKWARDS = -1
          const lastChild = node.resolveInner(node.to, BACKWARDS)
          const content = node.getChild('Content')
          if (!content) {
            return null
          }
          if (lastChild.type.is(termsModule.NewLine)) {
            // Ignore last newline for sectioning commands
            return { from: content!.from, to: lastChild.from }
          }
          if (lastChild.type.is(termsModule.Whitespace)) {
            // If the sectioningcommand is indented on a newline
            let sibling = lastChild.prevSibling
            while (sibling?.type.is(termsModule.Whitespace)) {
              sibling = sibling.prevSibling
            }
            if (sibling?.type.is(termsModule.NewLine)) {
              return { from: content!.from, to: sibling.from }
            }
          }
          if (lastChild.type.is(termsModule.BlankLine)) {
            // HACK: BlankLine can contain any number above 2 of \n's.
            // Include every one except for the last one
            return { from: content!.from, to: lastChild.to - 1 }
          }
          return content
        },
      }),
      // TODO: does this override groups defined in the grammar?
      NodeProp.group.add((type) => {
        const types = []

        if (Tokens.ctrlSeq.includes(type.name) || Tokens.ctrlSym.includes(type.name)) {
          types.push('$CtrlSeq')
          if (Tokens.ctrlSym.includes(type.name)) {
            types.push('$CtrlSym')
          }
        } else if (Tokens.envName.includes(type.name)) {
          types.push('$EnvName')
        } else if (type.name.endsWith('Command')) {
          types.push('$Command')
        } else if (type.name.endsWith('Argument')) {
          types.push('$Argument')
          if (type.name.endsWith('TextArgument') || type.is('SectioningArgument')) {
            types.push('$TextArgument')
          }
        } else if (type.name.endsWith('Environment')) {
          types.push('$Environment')
        } else if (type.name.endsWith('Brace')) {
          types.push('$Brace')
        } else if (['BracketMath', 'ParenMath', 'DollarMath'].includes(type.name)) {
          types.push('$MathContainer')
        }

        if (type.name in typeMap) {
          types.push(...typeMap[type.name])
        }

        return types.length > 0 ? types : undefined
      }),
      styleTags({
        ...Styles.ctrlSeq,
        ...Styles.ctrlSym,
        ...Styles.envName,
        'HrefCommand/ShortTextArgument/ShortArg/...': t.link,
        'HrefCommand/UrlArgument/...': t.monospace,
        'CtrlSeq Csname': t.tagName,
        'DocumentClass/OptionalArgument/ShortOptionalArg/...': t.attributeValue,
        'DocumentClass/ShortTextArgument/ShortArg/Normal': t.typeName,
        'ListEnvironment/BeginEnv/OptionalArgument/...': t.monospace,
        Number: t.number,
        OpenBrace: t.brace,
        CloseBrace: t.brace,
        OpenBracket: t.squareBracket,
        CloseBracket: t.squareBracket,
        Dollar: t.string,
        Math: t.string,
        'Math/MathChar': t.string,
        'Math/MathSpecialChar': t.string,
        'Math/Number': t.string,
        'MathGroup/OpenBrace MathGroup/CloseBrace': t.string,
        'MathTextCommand/TextArgument/OpenBrace MathTextCommand/TextArgument/CloseBrace': t.string,
        'MathOpening/LeftCtrlSeq MathClosing/RightCtrlSeq MathCommand/CtrlSeq MathTextCommand/CtrlSeq':
          t.literal,
        MathDelimiter: t.literal,
        DoubleDollar: t.keyword,
        Tilde: t.keyword,
        Ampersand: t.keyword,
        LineBreakCtrlSym: t.keyword,
        Comment: t.comment,
        AuthorCtrlSeq: t.tagName,
        'Text/Command/KnownCommand/Author/AuthorCtrlSeq/TextArgument/LongArg/Normal': t.tagName,
        'Text/Command/UnknownCommand/CtrlSeq/TextArgument/LongArg/Normal': t.strong,
        'UsePackage/OptionalArgument/ShortOptionalArg/Normal': t.attributeValue,
        'UsePackage/ShortTextArgument/ShortArg/Normal': t.tagName,
        'Affiliation/OptionalArgument/ShortOptionalArg/Normal': t.attributeValue,
        'Affil/OptionalArgument/ShortOptionalArg/Normal': t.attributeValue,
        'LiteralArgContent VerbContent VerbatimContent LstInlineContent': t.string,
        'NewCommand/LiteralArgContent': t.typeName,
        'LabelArgument/ShortTextArgument/ShortArg/...': t.attributeValue,
        'RefArgument/ShortTextArgument/ShortArg/...': t.attributeValue,
        'BibKeyArgument/ShortTextArgument/ShortArg/...': t.attributeValue,
        'ShortTextArgument/ShortArg/Normal': t.monospace,
        'UrlArgument/LiteralArgContent': [t.attributeValue, t.url],
        'FilePathArgument/LiteralArgContent': t.attributeValue,
        'BareFilePathArgument/SpaceDelimitedLiteralArgContent': t.attributeValue,
        TrailingContent: t.comment,
        'Item/OptionalArgument/ShortOptionalArg/...': t.strong,
      }),
    ],
  }),
  languageData: {
    commentTokens: { line: '%' },
  },
})

// FIXME: This is a temporary fix to make the latex highlight style work.
const latexHighlightStyle = HighlightStyle.define([
  { tag: tags.comment, color: '#ACB4BB', fontStyle: 'italic' },
  { tag: tags.lineComment, color: '#495056' },
  { tag: tags.blockComment, color: '#495056' },
  { tag: tags.docComment, color: '#495056' },
  { tag: tags.name, color: '#495056' },
  { tag: tags.variableName, color: '#495056' },
  { tag: tags.typeName, color: '#495056' },
  { tag: tags.tagName, color: '#3289FF' },
  { tag: tags.propertyName, color: '#495056' },
  { tag: tags.attributeName, color: '#495056' },
  { tag: tags.className, color: '#3289FF' },
  { tag: tags.labelName, color: '#495056' },
  { tag: tags.namespace, color: '#495056' },
  { tag: tags.macroName, color: '#495056' },
  { tag: tags.literal, color: '#495056' },
  { tag: tags.string, color: '#3289FF' },
  { tag: tags.docString, color: '#495056' },
  { tag: tags.character, color: '#495056' },
  { tag: tags.attributeValue, color: '#70C41C' },
  { tag: tags.number, color: '#495056' },
  { tag: tags.integer, color: '#495056' },
  { tag: tags.float, color: '#495056' },
  { tag: tags.bool, color: '#495056' },
  { tag: tags.regexp, color: '#495056' },
  { tag: tags.escape, color: '#495056' },
  { tag: tags.color, color: '#495056' },
  { tag: tags.url, color: '#495056' },
  { tag: tags.keyword, color: '#3289FF' },
  { tag: tags.self, color: '#495056' },
  { tag: tags.null, color: '#495056' },
  { tag: tags.atom, color: '#495056' },
  { tag: tags.unit, color: '#495056' },
  { tag: tags.modifier, color: '#495056' },
  { tag: tags.operatorKeyword, color: '#495056' },
  { tag: tags.controlKeyword, color: '#495056' },
  { tag: tags.definitionKeyword, color: '#495056' },
  { tag: tags.moduleKeyword, color: '#495056' },
  { tag: tags.operator, color: '#495056' },
  { tag: tags.derefOperator, color: '#495056' },
  { tag: tags.arithmeticOperator, color: '#495056' },
  { tag: tags.logicOperator, color: '#495056' },
  { tag: tags.bitwiseOperator, color: '#495056' },
  { tag: tags.compareOperator, color: '#495056' },
  { tag: tags.updateOperator, color: '#495056' },
  { tag: tags.definitionOperator, color: '#495056' },
  { tag: tags.typeOperator, color: '#495056' },
  { tag: tags.controlOperator, color: '#495056' },
  { tag: tags.punctuation, color: '#495056' },
  { tag: tags.separator, color: '#495056' },
  { tag: tags.bracket, color: '#495056' },
  { tag: tags.angleBracket, color: '#495056' },
  { tag: tags.squareBracket, color: '#495056' },
  { tag: tags.paren, color: '#495056' },
  { tag: tags.brace, color: '#495056' },
  { tag: tags.content, color: '#70C41C' },
  { tag: tags.heading, color: '#495056' },
  { tag: tags.heading1, color: '#495056' },
  { tag: tags.heading2, color: '#495056' },
  { tag: tags.heading3, color: '#495056' },
  { tag: tags.heading4, color: '#495056' },
  { tag: tags.heading5, color: '#495056' },
  { tag: tags.heading6, color: '#495056' },
  { tag: tags.contentSeparator, color: '#495056' },
  { tag: tags.list, color: '#495056' },
  { tag: tags.quote, color: '#495056' },
  { tag: tags.emphasis, color: '#495056' },
  { tag: tags.strong, color: '#495056' },
  { tag: tags.link, color: '#495056' },
  { tag: tags.monospace, color: '#70C41C' },
  { tag: tags.strikethrough, color: '#495056' },
  { tag: tags.inserted, color: '#495056' },
  { tag: tags.deleted, color: '#495056' },
  { tag: tags.changed, color: '#495056' },
  { tag: tags.invalid, color: '#495056' },
  { tag: tags.meta, color: '#495056' },
  { tag: tags.documentMeta, color: '#495056' },
  { tag: tags.annotation, color: '#495056' },
  { tag: tags.processingInstruction, color: '#495056' },
])

export const latex = () =>
  new LanguageSupport(latexLanguage, [syntaxHighlighting(latexHighlightStyle)])
