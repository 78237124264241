<script setup lang="ts">
import { FileTreeSelectItem as TreeItem } from '@/components'
import {
  type FileTreeNode,
  type LegacyFileTreeNode,
  fromLegacyTreeNode,
  toLegacyTreeNode,
} from '@/utils/fileTree'
import { ref } from 'vue'

const props = withDefaults(
  defineProps<{
    fileTree: FileTreeNode[]
    disabled?: boolean
  }>(),
  {
    disabled: false,
  },
)

const emit = defineEmits<{
  select: [node: FileTreeNode]
}>()

const openAll = (node: LegacyFileTreeNode) => {
  node.open = true
  node.children?.forEach((child) => {
    openAll(child)
  })
  return node
}

const viewFileTree = ref<LegacyFileTreeNode>(
  openAll({
    key: 'root',
    label: 'root',
    type: 'folder',
    fullPath: '',
    children: props.fileTree.map((item) => toLegacyTreeNode(item)),
    open: true,
    selected: false,
  }),
)

const onToggle = (node: LegacyFileTreeNode) => {
  node.open = !node.open
}

const isSameNode = (a: LegacyFileTreeNode, b: LegacyFileTreeNode) => a.key === b.key

const selectNode = (
  node: LegacyFileTreeNode,
  selectedNode: LegacyFileTreeNode,
): LegacyFileTreeNode => ({
  ...node,
  selected: isSameNode(node, selectedNode),
  children: node.children?.map((child) => selectNode(child, selectedNode)),
})

const onSelect = (node: LegacyFileTreeNode) => {
  emit('select', fromLegacyTreeNode(node))
  viewFileTree.value = selectNode(viewFileTree.value, node)
}
</script>

<template>
  <ul :class="$style.ul" :style="{ opacity: disabled ? '50%' : '100%' }">
    <TreeItem :disabled="disabled" :node="viewFileTree" @toggle="onToggle" @select="onSelect" />
  </ul>
</template>

<style module>
.ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  height: 380px;
}
</style>
