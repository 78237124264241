<template>
  <!--<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
  <g clip-path="url(#clip0_159_1472)">
    <path
      d="M11.9973 7.26949C10.3154 7.26806 8.70279 6.5993 7.51351 5.41001C6.32422 4.22072 5.65545 2.60811 5.65403 0.926208H0C0 4.10809 1.264 7.15965 3.51393 9.40959C5.76386 11.6595 8.81542 12.9235 11.9973 12.9235C13.5733 12.9242 15.1339 12.6144 16.5901 12.0118C18.0463 11.4092 19.3695 10.5256 20.4842 9.41149C21.5988 8.29737 22.483 6.97455 23.0862 5.51862C23.6895 4.06269 24 2.50217 24 0.926208L18.346 0.926208C18.3431 2.60861 17.6732 4.22121 16.483 5.41034C15.2929 6.59947 13.6797 7.26806 11.9973 7.26949Z"
      fill="#005580"
    />
    <path d="M24 0.926208H18.3459V23.0738H24V0.926208Z" fill="#0086CB" />
    <path d="M5.65403 0.926208H0V23.0738H5.65403V0.926208Z" fill="#0086CB" />
  </g>
  <defs>
    <clipPath id="clip0_159_1472">
      <rect width="24" height="24" fill="white" />
    </clipPath>
  </defs>
</template>
