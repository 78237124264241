/**
 * CamelCased version of the UserInfoAPIResponse type.
 */
export interface UserInfo {
  id: string
  authProvider?: string
  providerUserId?: string
  /**
   * If true, the user must sign up to access the service.
   */
  signupRequired: boolean
  name: string
  /**
   * URL of the user's profile picture.
   */
  picture?: string
  givenName?: string
  familyName?: string
  jobTitle?: string
  organization?: string
  termsOfServiceAgreementDate?: string
  privacyPolicyAgreementDate?: string
  promotionAgreementDate?: string
  email: string
  /**
   * The time the user was created.
   * @format date-time
   */
  createdAt: string
  /**
   * The time the user was updated.
   * @format date-time
   */
  updatedAt: string
}

/**
 * API response for activating a user.
 */
export interface ActivateUserResponse {
  activated: boolean
}

/**
 * API response for deleting a user.
 */
export interface DeleteUserResponse {
  deleted: boolean
}

/**
 * API response for authenticating a user.
 */
export interface AuthResponse {
  accessToken: string
  refreshToken: string
  signupRequired: boolean
}

export interface ProjectInfoSummary {
  id: string
  name: string
  archived: boolean
  thumbnail?: string
  workspaceId: string
  createdAt: string
  updatedAt: string
  role?: Role
}

export interface ProjectInfoDetail {
  /**
   * The unique identifier of the project.
   */
  id: string
  /**
   * The unique identifier of the workspace.
   */
  workspaceId: string
  /**
   * The name of the project.
   */
  name: string
  /**
   * If true, the project is archived.
   */
  archived: boolean
  /**
   * URL of the project's thumbnail.
   */
  createdAt: string
  /**
   * DateTime the project was updated.
   */
  updatedAt: string
  /**
   * Editor content of the project.
   */
  thumbnail?: string
  /**
   * DateTime the project was created.
   */
  editorState?: string
  /**
   * Last opened tex path.
   * This is driven by updated time of the tex file in GCS.
   */
  lastOpenedTexPath?: string
  /**
   * Deprecated: Citation state of the project.
   */
  citationState?: string
  /**
   * Deprecated: filename of bib.
   */
  bibName?: string
  /**
   * Assets of the project.
   */
  assets?: ProjectAsset[]
}

export interface ProjectAsset {
  filename: string
  fullPath: string
  sizeBytes: number
  updatedAt: string
}

export interface ProjectTextFile {
  fullPath: string
  content: string
}

export interface Workspace {
  id: string
  name: string
  /**
   * currently we have only default type.
   */
  type: 'default' | 'standard'
  createdAt: string
  updatedAt: string
}

export interface CompileLogResponse {
  compileLog: string
}

export interface ErrorResponse {
  /**
   * HTTP status code.
   */
  statusCode: number
  /**
   * Error code for client.
   */
  errorCode: string
  /**
   * Message for Frontend developers.
   */
  message: string
  /**
   * Detail information for debugging.
   */
  detail: ErrorDetail[]
}

export interface ErrorDetail {
  type: string
  violations: ErrorDetailFieldViolation[]
}

export interface ErrorDetailFieldViolation {
  type: string
  type_: string
  subject: string
  description: string
}

export interface AskAIResponse {
  /**
   * ID for ai response.
   * This will be used for feedback.
   */
  id: string
  /**
   * Result of Ask AI
   */
  content: string
}

export enum Role {
  owner = 'OWNER',
  editor = 'EDITOR',
  viewer = 'VIEWER',
}

export enum Permission {
  projectRead = 'project.read',
  projectUpdate = 'project.update',
  projectDelete = 'project.delete',
  projectRoleOwnerRead = 'projectRoleOwner.read',
  projectRoleEditorCreate = 'projectRoleEditor.create',
  projectRoleEditorRead = 'projectRoleEditor.read',
  projectRoleEditorUpdate = 'projectRoleEditor.update',
  projectRoleEditorDelete = 'projectRoleEditor.delete',
  projectRoleViewerCreate = 'projectRoleViewer.create',
  projectRoleViewerRead = 'projectRoleViewer.read',
  projectRoleViewerUpdate = 'projectRoleViewer.update',
  projectRoleViewerDelete = 'projectRoleViewer.delete',
  inviteEditorLinkRead = 'inviteEditorLink.read',
  inviteViewerLinkRead = 'inviteViewerLink.read',
  fileCreate = 'file.create',
  fileRead = 'file.read',
  fileUpdate = 'file.update',
  fileDelete = 'file.delete',
  askAIOn = 'askAI.on',
  formulaTurboOn = 'formulaTurbo.on',
  lintOn = 'lint.on',
  commentCreate = 'comment.create',
  commentRead = 'comment.read',
  commentUpdate = 'comment.update',
  commentDelete = 'comment.delete',
}

// owner can do anything
export type RolePermissions = Record<Role, Permission[]>

export interface InviteLinkResponse {
  editLink: string
  viewLink: string
}
export interface VerifyInviteResponse {
  role: string
  projectId: string
}

export type AccessSource = 'OWNER' | 'INVITE_LINK'

export interface ProjectMember {
  userId: string
  role: Role
  name: string
  email: string
}

export type Comment = {
  id: string
  userName: string
  updatedAt: string
  createdAt: string
  modified: boolean
  resolved: boolean
  text: string
  from: number
  to: number
  fullPath?: string
  numberOfReplies: number
  // chronological order
  replies?: Reply[]
  userId: string
}

export type Reply = {
  id: string
  userName: string
  updatedAt: string
  createdAt: string
  modified: boolean
  text: string
  userId: string
}

export interface CollaborateRoomTokenResponse {
  token: string
  expiresAt: string
  fileKey: string
}

export interface SyncCodeToPdfResponse {
  page: number
  v: number
  h: number
  width: number
  height: number
}

export interface SyncPdfToCodeResponse {
  file: string
  row: number
  column: number
}

export type AIChatMessageContentImage = {
  contentType: 'image'
  id: string
  height: number
  width: number
  url: string
}

export type AIChatMessageContentSelection = {
  contentType: 'selection'
  filePath: string
  text: string
}

export type AIChatMessageContentText = {
  contentType: 'text'
  text: string
}

export type AIChatMessageContent =
  | AIChatMessageContentImage
  | AIChatMessageContentSelection
  | AIChatMessageContentText

export type AIChatMessage = {
  id: string
  role: 'user' | 'system' | 'assistant'
  createdAt: string
  contents: AIChatMessageContent[]
  parentId?: string
  childrenIds: string[]
}

export type AIChatThread = {
  id: string
  title: string
  createdAt: string
  updatedAt: string
  mapping: {
    [id: string]: AIChatMessage
  }
  currentMessageId: string
}
