<script setup lang="ts">
import {
  ProjectUIMode,
  useEditorStore,
  useProjectCompileStore,
  useProjectStore,
  useProjectUIStore,
} from '@/stores'
import type { LogEntry } from '@murfy-package/latex-log-parser'
import { IconBase, IconChevronRight, IconExpandMore, IconInsertLink } from '@murfy-package/ui'
import { watchOnce } from '@vueuse/core'
import { storeToRefs } from 'pinia'
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import { computed, ref } from 'vue'

import BaseFilledCircle from './BaseFilledCircle.vue'

const editorStore = useEditorStore()
const { useCursor, openFile } = editorStore
const { currentFilePath, currentFileContent } = storeToRefs(editorStore)
const { parsedCompileLog, previewLog } = storeToRefs(useProjectCompileStore())
const { mode } = storeToRefs(useProjectUIStore())
const { projectFileList } = storeToRefs(useProjectStore())

const viewParsedCompileLog = computed<LogEntry[]>(() => [
  ...parsedCompileLog.value,
  {
    level: 'info',
    code: 'RAW_LOGS',
    fileName: '',
    message: 'Raw Logs',
    rawLog: previewLog.value,
  },
])

const activeIndex = ref<number[]>([])
const levelToStatus = (level: string) => {
  switch (level) {
    case 'error':
      return 'bad'
    case 'warning':
      return 'fair'
    default:
      return 'good'
  }
}
const errorLink = (fileName: string, lineNumber?: number) => {
  if (!lineNumber) return fileName
  return `${fileName}, ${lineNumber}`
}
const onClickErrorLink = (fileName: string, lineNumber?: number) => {
  const isFileExist = projectFileList.value.some((asset) => asset.fullPath === fileName)
  if (!isFileExist) {
    return
  }
  if (mode.value === ProjectUIMode.Preview) {
    mode.value = ProjectUIMode.Edit
  }
  if (currentFilePath.value !== fileName) {
    openFile(fileName)
    if (lineNumber) {
      // openFile.then()에서는 아직 editor가 업데이트 되지 않은 시점이어서 moveToLine이 제대로 동작하지 않아서 우회하여 구현함.
      // openFile이 editor 로딩 포함한 promise를 반환하도록 변경하면 좀 더 편리할 것 같음.
      watchOnce(currentFileContent, () => {
        useCursor()?.moveToLine(lineNumber)
      })
    }
  } else if (lineNumber) {
    useCursor()?.moveToLine(lineNumber)
  }
}
</script>

<template>
  <Accordion v-model:activeIndex="activeIndex" unstyled multiple>
    <AccordionTab
      v-for="(log, index) in viewParsedCompileLog"
      :key="`${log.fileName}-${log.lineNumber ?? ''}-${Math.random().toString(36).substring(4)}`"
      :pt="{
        root: 'px-8 py-4 w-full',
        headerIcon: 'hidden',
        headerAction: 'flex flex-row items-center gap-2 group',
      }"
    >
      <template #headericon />
      <template #header>
        <div class="flex w-full flex-row items-center gap-2">
          <!--아이콘 관련 로직 -->
          <template v-if="log.rawLog">
            <IconBase
              v-if="activeIndex.includes(index)"
              iconName="ExpandMore"
              class="group-hover:text-cta-primary flex-none"
            >
              <IconExpandMore />
            </IconBase>
            <IconBase v-else iconName="ChevronRight" class="group-hover:text-cta-primary flex-none">
              <IconChevronRight />
            </IconBase>
          </template>
          <IconBase v-else iconName="ChevronRight" class="text-gray-4 flex-none">
            <IconChevronRight />
          </IconBase>
          <!--에러 메시지 관련 로직-->
          <div class="flex min-w-0 flex-col">
            <div class="flex items-center gap-2">
              <BaseFilledCircle
                v-if="log.level !== 'info'"
                class="flex-none"
                :status="levelToStatus(log.level)"
                scale="small"
              />
              {{ log.message }}
            </div>
            <div
              v-if="log.fileName"
              class="text-cta-primary p3 hover:text-cta-hover flex w-fit min-w-0 max-w-full items-center gap-2"
              @click.stop="onClickErrorLink(log.fileName, log.lineNumber)"
            >
              <IconBase iconName="InsertLink" class="h-4 w-4 flex-none">
                <IconInsertLink />
              </IconBase>
              <div class="flex min-w-0 items-center">
                <div
                  class="overflow-hidden text-ellipsis whitespace-nowrap"
                  :title="errorLink(log.fileName, log.lineNumber)"
                >
                  {{ errorLink(log.fileName, log.lineNumber) }}
                </div>
                <IconBase iconName="ChevronRight" class="h-4 w-4 flex-none">
                  <IconChevronRight />
                </IconBase>
              </div>
            </div>
          </div>
        </div>
      </template>
      <div v-if="log.rawLog" class="border-gray-2 bg-gray-0 p1 mt-3 rounded-lg border p-4">
        <pre class="text-wrap break-words">{{ log.rawLog }}</pre>
      </div>
    </AccordionTab>
  </Accordion>
</template>
