<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M5.00003 4.03336C4.74365 4.03336 4.49778 4.13521 4.31649 4.31649C4.13521 4.49778 4.03336 4.74365 4.03336 5.00003V15C4.03336 15.2564 4.13521 15.5023 4.31649 15.6836C4.49778 15.8649 4.74365 15.9667 5.00003 15.9667H10C10.3866 15.9667 10.7 16.2801 10.7 16.6667C10.7 17.0533 10.3866 17.3667 10 17.3667H5.00003C4.37235 17.3667 3.77038 17.1174 3.32654 16.6735C2.88271 16.2297 2.63336 15.6277 2.63336 15V5.00003C2.63336 4.37235 2.88271 3.77038 3.32654 3.32654C3.77038 2.88271 4.37235 2.63336 5.00003 2.63336H10C10.3866 2.63336 10.7 2.94676 10.7 3.33336C10.7 3.71996 10.3866 4.03336 10 4.03336H5.00003Z"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M15 10.9667C15.3866 10.9667 15.7 11.2801 15.7 11.6667V17.5C15.7 17.8866 15.3866 18.2 15 18.2C14.6134 18.2 14.3 17.8866 14.3 17.5V11.6667C14.3 11.2801 14.6134 10.9667 15 10.9667Z"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M15 1.79999C15.3866 1.79999 15.7 2.11339 15.7 2.49999V8.33332C15.7 8.71992 15.3866 9.03332 15 9.03332C14.6134 9.03332 14.3 8.71992 14.3 8.33332V2.49999C14.3 2.11339 14.6134 1.79999 15 1.79999Z"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M12.005 14.505C12.2784 14.2316 12.7216 14.2316 12.995 14.505L15 16.51L17.005 14.505C17.2784 14.2316 17.7216 14.2316 17.995 14.505C18.2683 14.7784 18.2683 15.2216 17.995 15.495L15.495 17.995C15.2216 18.2683 14.7784 18.2683 14.505 17.995L12.005 15.495C11.7316 15.2216 11.7316 14.7784 12.005 14.505Z"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M14.505 2.00501C14.7784 1.73165 15.2216 1.73165 15.495 2.00501L17.995 4.50501C18.2683 4.77838 18.2683 5.2216 17.995 5.49496C17.7216 5.76833 17.2784 5.76833 17.005 5.49496L15 3.48994L12.995 5.49496C12.7216 5.76833 12.2784 5.76833 12.005 5.49496C11.7316 5.2216 11.7316 4.77838 12.005 4.50501L14.505 2.00501Z"
  />
</template>
