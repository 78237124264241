<template>
  <path d="M135.32 0H164.41V10.05H146.96V16.8H162.12V26.15H146.96V41.13H135.32V0Z" fill="#0086CB" />
  <path
    d="M69.48 41.71C66.1788 41.7734 62.9095 41.0538 59.94 39.61C57.2768 38.3245 55.0179 36.332 53.41 33.85C51.8141 31.3468 50.9931 28.4281 51.05 25.46V0H63.93V24.93C63.9129 25.9736 64.157 27.0048 64.64 27.93C65.0993 28.8017 65.7917 29.5287 66.64 30.03C67.5075 30.5461 68.5007 30.8126 69.51 30.8C70.5613 30.8195 71.5981 30.5534 72.51 30.03C73.3578 29.5425 74.0642 28.843 74.56 28C75.0748 27.0846 75.3403 26.0502 75.33 25V0H87.91V25.46C87.9621 28.4275 87.1415 31.3449 85.55 33.85C83.9343 36.3327 81.6689 38.3249 79 39.61C76.0362 41.0496 72.7744 41.7691 69.48 41.71Z"
    fill="#0086CB"
  />
  <path
    d="M121.47 25.79C123.661 24.6317 125.483 22.8822 126.73 20.74C128.022 18.4295 128.674 15.8165 128.62 13.17C128.658 10.7717 128.002 8.41357 126.73 6.38C125.426 4.38111 123.609 2.76847 121.47 1.71C119.082 0.538661 116.449 -0.0474893 113.79 1.84472e-06H94.41V41.36H106.34V27.59H108.34L116.09 41.36H130.33L121.33 25.85L121.47 25.79ZM106.34 10H111.54C112.444 9.9668 113.344 10.1413 114.17 10.51C114.832 10.8253 115.373 11.3486 115.71 12C116.06 12.6866 116.231 13.4498 116.21 14.22C116.231 15.0543 116.06 15.8824 115.71 16.64C115.382 17.2974 114.867 17.8434 114.23 18.21C113.476 18.6061 112.631 18.799 111.78 18.77H106.34V10Z"
    fill="#0086CB"
  />
  <path
    d="M22.28 11.78C19.1566 11.7774 16.1618 10.5354 13.9532 8.32679C11.7446 6.11819 10.5026 3.12344 10.5 0H0C0 5.90902 2.34735 11.576 6.52566 15.7543C10.704 19.9326 16.371 22.28 22.28 22.28C25.2067 22.2813 28.105 21.706 30.8093 20.5869C33.5135 19.4678 35.9709 17.8269 38.0408 15.7579C40.1108 13.6889 41.7528 11.2323 42.8731 8.5285C43.9934 5.82471 44.57 2.92669 44.57 0L34.07 0C34.0647 3.12436 32.8205 6.11908 30.6103 8.3274C28.4001 10.5357 25.4044 11.7774 22.28 11.78Z"
    fill="#005580"
  />
  <path d="M44.57 0H34.07V41.13H44.57V0Z" fill="#0086CB" />
  <path d="M10.5 0H0V41.13H10.5V0Z" fill="#0086CB" />
  <path
    d="M189 27.62C185.768 27.6836 182.568 26.9811 179.66 25.57C177.056 24.3038 174.844 22.3545 173.26 19.93C171.701 17.4755 170.898 14.6171 170.95 11.71V0H183.56V11.19C183.535 12.2331 183.776 13.2654 184.26 14.19C184.7 15.0421 185.368 15.7552 186.19 16.25C187.039 16.7547 188.012 17.0143 189 17C190.011 17.0335 191.011 16.7893 191.893 16.2939C192.774 15.7984 193.503 15.0708 194 14.19C194.514 13.2752 194.773 12.2391 194.75 11.19V0H207V11.71C207.052 14.6171 206.249 17.4755 204.69 19.93C203.106 22.3545 200.894 24.3038 198.29 25.57C195.397 26.9734 192.215 27.6757 189 27.62Z"
    fill="#0086CB"
  />
  <path d="M194.83 20.56H183.08V41.12H194.83V20.56Z" fill="#0086CB" />
</template>
