<script setup lang="ts">
import { clamp, useDebounceFn, useElementBounding, useVirtualList } from '@vueuse/core'
import { PDFDocumentProxy, PDFPageProxy } from 'pdfjs-dist'
import { computed, nextTick, onMounted, ref, watch } from 'vue'

import PDFViewerPage, { type PDFHighlightRect } from './PDFViewerPage.vue'

export type PDFHighlight = {
  pageNumber: number
  rect: PDFHighlightRect
}[]

const PAGE_GAP_IN_PIXEL = 16
type Props = {
  documentProxy: PDFDocumentProxy
  scale?: number
}
const props = withDefaults(defineProps<Props>(), {
  scale: 1,
})

const emit = defineEmits<{
  pageChanged: [pageNumber: number]
  pageDblclick: [event: MouseEvent, pageNumber: number]
  loaded: []
  zoomIn: []
  zoomOut: []
}>()

/**
 * ctrl + wheel을 통한 확대/축소가 아닐 경우의 스케일 중심점
 */
const defaultScaleOrigin = ref<{ x: number; y: number }>({ x: 0, y: 0 })
/**
 * 모든 확대/축소 이벤트의 스케일 중심점
 */
const scaleOrigin = ref<{ x: number; y: number }>(defaultScaleOrigin.value)
watch(defaultScaleOrigin, (newDefaultScaleOrigin) => {
  scaleOrigin.value = newDefaultScaleOrigin
})
const useVirtualPages = (documentProxy: PDFDocumentProxy) => {
  const pages = ref<Promise<PDFPageProxy>[]>([])
  const pageSizes = ref<({ height: number; width: number } | undefined)[]>([])
  /**
   * 스케일이 1일 때의 페이지 사이즈. 각 페이지의 사이즈가 렌더링될 때 업데이트됨.
   */
  const defaultPageSize = ref<{ height: number; width: number }>({ height: 0, width: 0 })
  const currentPageNumber = ref(1)
  const {
    list,
    containerProps,
    wrapperProps,
    scrollTo: internalScrollToPage,
  } = useVirtualList(pages, {
    itemHeight: (i) =>
      (pageSizes.value[i]?.height ?? defaultPageSize.value.height) * props.scale +
      PAGE_GAP_IN_PIXEL,
    itemWidth: (i) => (pageSizes.value[i]?.width ?? defaultPageSize.value.width) * props.scale,
    overscan: 3,
  })
  const isScrolledByFunction = ref(false)
  const containerPropsWithScroll = computed(() => ({
    ...containerProps,
    onScroll: (e: Event) => {
      containerProps.onScroll()
      useDebounceFn(() => {
        if (isScrolledByFunction.value) {
          isScrolledByFunction.value = false
          return
        }
        const scrollTop = (e.target as HTMLElement).scrollTop
        const middleScrollTop = scrollTop + (e.target as HTMLElement).clientHeight / 2
        let pageHeightSum = 0
        const currentPageIndex = pageSizes.value.findIndex((size) => {
          pageHeightSum +=
            (size?.height ?? defaultPageSize.value.height) * props.scale + PAGE_GAP_IN_PIXEL
          return pageHeightSum > middleScrollTop
        })
        currentPageNumber.value = currentPageIndex < 0 ? 1 : currentPageIndex + 1
      }, 100)()
    },
  }))

  const wrapperPropsWithWidth = computed(() => ({
    ...wrapperProps.value,
    style: {
      ...wrapperProps.value.style,
      width: `${defaultPageSize.value.width * props.scale}px`,
    },
  }))

  const getDefaultPageSizes = async () => {
    const firstPage = await props.documentProxy.getPage(1)
    defaultPageSize.value = firstPage.getViewport({ scale: 1 })
    pageSizes.value = Array.from({ length: documentProxy.numPages }, () => defaultPageSize.value)
  }

  const getPageTop = (pageNumber: number) => {
    if (pageNumber === 1) {
      return 0
    }
    return pageSizes.value
      .slice(0, pageNumber - 1)
      .reduce(
        (acc, size) =>
          acc + (size?.height ?? defaultPageSize.value.height) * props.scale + PAGE_GAP_IN_PIXEL,
        0,
      )
  }

  const loadDocument = async () => {
    await getDefaultPageSizes()
    pages.value = Array.from({ length: documentProxy.numPages }, (_, index) => {
      const pageNumber = index + 1
      return documentProxy.getPage(pageNumber)
    })
    await nextTick()
    emit('loaded')
  }

  const updatePageSize = (page: PDFPageProxy) => {
    const viewport = page.getViewport({ scale: 1 })
    pageSizes.value[page.pageNumber - 1] = {
      height: viewport.height,
      width: viewport.width,
    }
  }

  const scrollToPage = (pageNumber: number) => {
    isScrolledByFunction.value = true
    const boundedPageNumber = clamp(pageNumber, 1, pages.value.length)
    internalScrollToPage(boundedPageNumber - 1)
  }

  const scrollTo = (x: number, y: number, block: 'start' | 'center' | 'end' = 'start') => {
    const containerRef = containerProps.ref?.value
    if (!containerRef) {
      return
    }
    const offsetY =
      block === 'start'
        ? 0
        : block === 'center'
          ? containerRef.clientHeight / 2
          : containerRef.clientHeight
    containerRef.scrollTo(x, y - offsetY)
  }

  return {
    list,
    containerProps: containerPropsWithScroll,
    wrapperProps: wrapperPropsWithWidth,
    scrollTo,
    scrollToPage,
    defaultPageSize,
    pageSizes,
    updatePageSize,
    loadDocument,
    currentPageNumber,
    getDefaultPageSizes,
    getPageTop,
  }
}

const {
  list,
  containerProps,
  wrapperProps,
  scrollToPage,
  defaultPageSize,
  pageSizes,
  updatePageSize,
  loadDocument,
  currentPageNumber,
  getPageTop,
  scrollTo,
} = useVirtualPages(props.documentProxy)

const handleCtrlWheel = async (e: WheelEvent) => {
  const containerRef = containerProps.value.ref?.value
  if (!containerRef) {
    return
  }

  const boundingRect = containerRef.getBoundingClientRect()
  if (!boundingRect) {
    return
  }
  const offsetX = e.clientX - boundingRect.left
  const offsetY = e.clientY - boundingRect.top
  scaleOrigin.value = {
    x: offsetX,
    y: offsetY,
  }
  if (e.deltaY > 0) {
    emit('zoomOut')
  } else {
    emit('zoomIn')
  }
  await nextTick()
  scaleOrigin.value = defaultScaleOrigin.value
}

const { width: containerWidth, height: containerHeight } = useElementBounding(
  containerProps.value.ref,
)
watch([containerWidth, containerHeight], ([newWidth, _newHeight]) => {
  defaultScaleOrigin.value = {
    x: newWidth / 2,
    y: 0,
  }
})

onMounted(() => {
  watch(
    () => props.scale,
    async (newScale, oldScale) => {
      const containerRef = containerProps.value.ref?.value
      if (!containerRef) {
        return
      }
      const scaleRatio = newScale / oldScale
      const nextScrollTop =
        (containerRef.scrollTop + scaleOrigin.value.y) * scaleRatio - scaleOrigin.value.y
      const nextScrollLeft =
        (containerRef.scrollLeft + scaleOrigin.value.x) * scaleRatio - scaleOrigin.value.x
      await nextTick()
      containerRef.scrollTo(nextScrollLeft, nextScrollTop)
    },
  )
  watch(currentPageNumber, (currentPageNumber) => {
    emit('pageChanged', currentPageNumber)
  })
  loadDocument()
})
const currentPDFHighlight = ref<PDFHighlight | null>(null)
const getPDFHighlightRects = (pageNumber: number) =>
  currentPDFHighlight.value
    ?.filter((highlight) => highlight.pageNumber === pageNumber)
    .map((highlight) => highlight.rect) ?? []
defineExpose({
  scrollToPage,
  scrollTo,
  getScrollPosition: () => ({
    x: containerProps.value.ref?.value?.scrollLeft ?? 0,
    y: containerProps.value.ref?.value?.scrollTop ?? 0,
  }),
  getPageSize: (pageNumber: number) => pageSizes.value[pageNumber - 1],
  drawHighlight: async (pdfHighlight: PDFHighlight) => {
    currentPDFHighlight.value = pdfHighlight
    const firstHighlight = pdfHighlight[0]
    const pageTop = getPageTop(firstHighlight.pageNumber)
    const highlightTop = pageTop + firstHighlight.rect.top * props.scale
    const highlightLeft = firstHighlight.rect.left * props.scale
    scrollTo(highlightLeft, highlightTop, 'center')
  },
})
</script>
<template>
  <div v-bind="containerProps" class="flex" @wheel.ctrl.prevent="handleCtrlWheel">
    <div
      v-bind="wrapperProps"
      ref="wrapperRef"
      class="mx-auto flex flex-col"
      :style="{
        gap: `${PAGE_GAP_IN_PIXEL}px`,
      }"
    >
      <PDFViewerPage
        v-for="{ index, data } in list"
        :key="index"
        :pagePromise="data"
        :scale="props.scale"
        :defaultWidth="defaultPageSize.width"
        :defaultHeight="defaultPageSize.height"
        :highlightRects="getPDFHighlightRects(index + 1)"
        @rendered="updatePageSize($event)"
        @dblclick="emit('pageDblclick', $event, index + 1)"
        @highlightAnimationEnd="currentPDFHighlight = null"
      />
    </div>
  </div>
</template>
