<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M8.0558 6.53336C7.65201 6.53336 7.26475 6.69377 6.97923 6.97929C6.69371 7.26482 6.5333 7.65207 6.5333 8.05586V15.2775C6.5333 15.4775 6.57268 15.6754 6.64919 15.8602C6.72571 16.0449 6.83785 16.2127 6.97923 16.3541C7.12061 16.4955 7.28845 16.6076 7.47317 16.6841C7.65788 16.7606 7.85586 16.8 8.0558 16.8H15.2775C15.4774 16.8 15.6754 16.7606 15.8601 16.6841C16.0448 16.6076 16.2127 16.4955 16.354 16.3541C16.4954 16.2127 16.6076 16.0449 16.6841 15.8602C16.7606 15.6754 16.8 15.4775 16.8 15.2775V8.05586C16.8 7.85592 16.7606 7.65794 16.6841 7.47323C16.6076 7.28851 16.4954 7.12067 16.354 6.97929C16.2127 6.83791 16.0448 6.72577 15.8601 6.64926C15.6754 6.57274 15.4774 6.53336 15.2775 6.53336H8.0558ZM5.98928 5.98934C6.53736 5.44127 7.28071 5.13336 8.0558 5.13336H15.2775C15.6613 5.13336 16.0413 5.20895 16.3959 5.35582C16.7504 5.50269 17.0726 5.71796 17.344 5.98934C17.6154 6.26072 17.8306 6.58289 17.9775 6.93747C18.1244 7.29204 18.2 7.67207 18.2 8.05586V15.2775C18.2 15.6613 18.1244 16.0413 17.9775 16.3959C17.8306 16.7505 17.6154 17.0727 17.344 17.344C17.0726 17.6154 16.7504 17.8307 16.3959 17.9776C16.0413 18.1244 15.6613 18.2 15.2775 18.2H8.0558C7.67201 18.2 7.29198 18.1244 6.93741 17.9776C6.58283 17.8307 6.26066 17.6154 5.98928 17.344C5.7179 17.0727 5.50263 16.7505 5.35576 16.3959C5.20889 16.0413 5.1333 15.6613 5.1333 15.2775V8.05586C5.1333 7.28077 5.44121 6.53742 5.98928 5.98934Z"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M4.16672 3.19999C3.63665 3.19999 3.20005 3.63659 3.20005 4.16665V12.499C3.20059 12.6697 3.24611 12.8372 3.33203 12.9847C3.41807 13.1324 3.54158 13.2547 3.69006 13.3394C4.02592 13.5308 4.14297 13.9583 3.9515 14.2942C3.76004 14.63 3.33256 14.7471 2.9967 14.5556C2.63409 14.3489 2.33248 14.0501 2.12236 13.6895C1.91224 13.3288 1.80106 12.9191 1.80005 12.5017L1.80005 12.5V4.16665C1.80005 2.86339 2.86345 1.79999 4.16672 1.79999H12.5C12.9437 1.79999 13.3327 1.91769 13.6638 2.15798C13.9796 2.38726 14.1959 2.69468 14.3618 2.99312C14.5497 3.33099 14.4281 3.7572 14.0903 3.94509C13.7524 4.13298 13.3262 4.0114 13.1383 3.67352C13.0192 3.45946 12.923 3.35021 12.8413 3.29095C12.7749 3.2427 12.6814 3.19999 12.5 3.19999H4.16672Z"
  />
</template>
