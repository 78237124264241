<script setup lang="ts">
import { ref, watch } from 'vue'

import {
  IconArrowLeft,
  IconArrowRight,
  IconBase,
  IconCircleMinus,
  IconCirclePlus,
  IconFileArrowDown,
  IconSquareAlignHorizontal,
  IconSquareAlignVertical,
} from '../../atoms'
import { BaseButton, ToggleGroup } from '../../molecules'

export type FitScale = 'fitWidth' | 'fitHeight' | undefined

const props = withDefaults(
  defineProps<{
    pageNumber: number
    lastPageNumber: number
    scale: number
    disabled?: boolean
    fitScale?: FitScale
  }>(),
  {
    disabled: false,
    fitScale: undefined,
  },
)

const emit = defineEmits<{
  'update:pageNumber': [pageNumber: number]
  'update:scale': [scale: number]
  'update:navigationVisible': [visible: boolean]
  'update:fitScale': [fitScale: FitScale]
  zoomIn: []
  zoomOut: []
  download: []
}>()

const viewPageNumber = ref(props.pageNumber)
watch(
  () => props.pageNumber,
  (newValue) => {
    viewPageNumber.value = newValue
  },
)
const pageNumberInput = ref<HTMLInputElement | null>(null)
const submitPageNumber = () => {
  if (viewPageNumber.value < 1 || viewPageNumber.value > props.lastPageNumber) {
    viewPageNumber.value = props.pageNumber
    return
  }
  emit('update:pageNumber', viewPageNumber.value)
}
const handlePageNumberEnter = () => {
  submitPageNumber()
  pageNumberInput.value?.blur()
}
const goToPrevPage = () => {
  viewPageNumber.value = viewPageNumber.value - 1
  submitPageNumber()
}
const goToNextPage = () => {
  viewPageNumber.value = viewPageNumber.value + 1
  submitPageNumber()
}

const scaleInput = ref<HTMLInputElement | null>(null)
const handleScaleEnter = () => {
  submitScale()
  scaleInput.value?.blur()
}
const viewScaleString = ref(`${Math.floor(props.scale * 100)}%`)
watch(
  () => props.scale,
  (newValue) => {
    viewScaleString.value = `${Math.floor(newValue * 100)}%`
  },
)
const parseScaleString = (scaleString: string) => {
  const startingWithNumberRegex = /^\d+/
  const match = scaleString.match(startingWithNumberRegex)
  if (!match) {
    throw new Error('Invalid scale string')
  }
  return Number(match[0]) / 100
}
const submitScale = () => {
  try {
    const newScale = parseScaleString(viewScaleString.value)
    viewScaleString.value = `${Math.floor(newScale * 100)}%`
    emit('update:scale', newScale)
  } catch {
    viewScaleString.value = `${Math.floor(props.scale * 100)}%`
  }
}
</script>

<template>
  <menu
    class="bg-color-bg-global-primary border-color-border-primary flex items-center justify-between border-b px-4 py-2"
  >
    <li class="flex items-center gap-1">
      <BaseButton
        severity="secondary"
        variant="text"
        :disabled="disabled"
        @click.prevent="goToPrevPage"
      >
        <template #icon>
          <IconArrowLeft />
        </template>
      </BaseButton>
      <BaseButton
        severity="secondary"
        variant="text"
        :disabled="disabled"
        @click.prevent="goToNextPage"
      >
        <template #icon>
          <IconArrowRight />
        </template>
      </BaseButton>
      <div class="flex flex-row gap-2">
        <input
          ref="pageNumberInput"
          v-model="viewPageNumber"
          type="number"
          class="body-sm text-color-text-primary w-8 justify-center text-center"
          :disabled="disabled"
          @keydown.enter="handlePageNumberEnter"
          @blur="submitPageNumber"
        />
        <span class="body-sm text-color-text-secondary">/</span>
        <input
          type="text"
          class="body-sm text-color-text-secondary w-8 justify-center text-center outline-none"
          :value="lastPageNumber"
          readonly
        />
      </div>
    </li>
    <li class="flex items-center gap-1">
      <BaseButton
        severity="secondary"
        variant="text"
        :disabled="disabled"
        @click.prevent="emit('zoomOut')"
      >
        <template #icon>
          <IconCircleMinus />
        </template>
      </BaseButton>
      <BaseButton
        severity="secondary"
        variant="text"
        :disabled="disabled"
        @click.prevent="emit('zoomIn')"
      >
        <template #icon>
          <IconCirclePlus />
        </template>
      </BaseButton>
      <input
        ref="scaleInput"
        v-model="viewScaleString"
        type="text"
        class="body-sm text-color-text-primary h-5 w-10 justify-center text-center"
        :disabled="disabled"
        @keydown.enter="handleScaleEnter"
        @blur="submitScale"
      />
      <ToggleGroup
        :modelValue="props.fitScale"
        :disabled="disabled"
        orientation="horizontal"
        :options="['fitWidth', 'fitHeight']"
        @update:modelValue="emit('update:fitScale', $event as FitScale)"
      >
        <template #default="{ option }">
          <IconBase>
            <IconSquareAlignHorizontal v-if="option === 'fitWidth'" />
            <IconSquareAlignVertical v-else-if="option === 'fitHeight'" />
          </IconBase>
        </template>
      </ToggleGroup>
    </li>
    <li>
      <BaseButton
        severity="secondary"
        variant="text"
        :disabled="disabled"
        @click.prevent="emit('download')"
      >
        <template #icon>
          <IconFileArrowDown />
        </template>
      </BaseButton>
    </li>
  </menu>
</template>
<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox  */
input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
}
</style>
