import { APIClient } from '../client'
import type { CollaborateRoomTokenResponse } from '../schema/response'

/**
 * Collaborate related API methods.
 */
export class CollaborateAPI {
  private client: APIClient
  constructor(client: APIClient) {
    this.client = client
  }

  /**
   * Issue Collaborate room token
   */
  async issueCollaborateRoomToken(
    projectID: string,
    filePath: string,
  ): Promise<CollaborateRoomTokenResponse> {
    const response = await this.client.post<CollaborateRoomTokenResponse>(
      `/collaborate-rooms/${projectID}/tokens`,
      {
        projectID,
        filePath,
      },
    )
    return response.data
  }
}
