<script setup lang="ts">
import { Orientation } from '@/types'
import { ToggleGroup } from 'radix-vue/namespaced'

import { IconBase, IconFileEmpty } from '../../atoms'

type ActivityBarProps = {
  activities: string[]
  extraActivities?: string[]
  orientation?: Orientation
}
withDefaults(defineProps<ActivityBarProps>(), {
  extraActivities: undefined,
  orientation: 'horizontal',
})

const activeActivity = defineModel<string>()
</script>

<template>
  <ToggleGroup.Root
    v-model="activeActivity"
    type="single"
    class="flex h-full w-full justify-between px-4 py-3"
    :class="[{ horizontal: 'flex-row', vertical: 'flex-col' }[orientation]]"
  >
    <div
      class="flex items-center gap-2"
      :class="[{ horizontal: 'flex-row', vertical: 'flex-col' }[orientation]]"
    >
      <ToggleGroup.Item
        v-for="activity in activities"
        :key="activity"
        :value="activity"
        :title="activity"
        class="text-color-text-tertiary data-[state=on]:text-color-text-brand flex items-center justify-center rounded-lg p-2 hover:bg-blue-100 hover:text-blue-600 data-[state=on]:hover:text-blue-600"
      >
        <slot name="activity" :activity="activity">
          <IconBase>
            <IconFileEmpty />
          </IconBase>
        </slot>
      </ToggleGroup.Item>
    </div>
    <div
      v-if="extraActivities"
      class="flex items-center gap-2"
      :class="[{ horizontal: 'flex-row', vertical: 'flex-col' }[orientation]]"
    >
      <ToggleGroup.Item
        v-for="activity in extraActivities"
        :key="activity"
        :value="activity"
        :title="activity"
        class="text-color-text-tertiary data-[state=on]:text-color-text-brand flex items-center justify-center rounded-lg p-2 hover:bg-blue-100 hover:text-blue-600 data-[state=on]:hover:text-blue-600"
      >
        <slot name="activity" :activity="activity">
          <IconBase>
            <IconFileEmpty />
          </IconBase>
        </slot>
      </ToggleGroup.Item>
    </div>
  </ToggleGroup.Root>
</template>
