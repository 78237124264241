// disable-lint.ts
import { Diagnostic } from '@codemirror/lint'
import { Text } from '@codemirror/state'

export const DISABLE_COMMENT = '% latex-lint-disable'

// 문서 전체의 린터를 비활성화하는 함수
// % latex-lint-disable 주석이 존재 하면 true 를 반환합니다.
export const isLinterDisabled = (doc: Text): boolean => {
  for (const lineText of doc.iterLines()) {
    if (lineText.trim().includes(DISABLE_COMMENT)) {
      return true
    }
  }
  return false
}

// 특정 라인에 대해 린터를 비활성화하는 함수
// lineNumber 라인의 직전에 % latex-lint-disable-next-line 주석이 있는지 확인합니다.
// 만약 해당 주석이 존재하면, true 로 반환 합니다.
export const isLineLinterDisabled = (doc: Text, lineNumber: number) =>
  lineNumber >= 0 && doc.line(lineNumber).text.trim().startsWith('% latex-lint-disable-next-line')

// 린터 오류 목록을 필터링하는 함수
// 린터 오류(Diagnostic) 목록을 받아와서, 각 오류가 특정 라인 비활성화 주석에 해당하는지 확인합니다.
// 만약 해당 라인에 비활성화 주석이 있으면, 해당 오류를 목록에서 제외합니다.
export const filterDiagnostics = (diagnostics: Diagnostic[], doc: Text): Diagnostic[] =>
  diagnostics.filter((diagnostic) => {
    const lineNumber = doc.lineAt(diagnostic.from).number
    return !isLineLinterDisabled(doc, lineNumber)
  })

// 린터 비활성화 힌트를 추가하는 함수
// 만약 린터 오류가 존재하면, 문서 전체 비활성화 주석 또는 특정 라인 비활성화 주석을 추가하도록 안내합니다.
export const addDisableHint = (diagnostics: Diagnostic[]): void => {
  if (diagnostics.length > 0) {
    const disableHint: Diagnostic = {
      from: 0,
      to: 0,
      severity: 'info',
      message:
        'To disable linter for this document, add "% latex-lint-disable" at the top of the document, or\n' +
        'use "% latex-lint-disable-next-line" to disable linting for the next line.',
    }
    diagnostics.push(disableHint)
  }
}
