<script setup lang="ts">
import type { EditorView } from '@codemirror/view'
import { computed } from 'vue'

import AskAIStateActivate from './AskAIStateActivate.vue'
import AskAIStateAnswering from './AskAIStateAnswering.vue'
import AskAIStateComment from './AskAIStateComment.vue'
import AskAIStateCompleted from './AskAIStateCompleted.vue'
import AskAIStateIdle from './AskAIStateIdle.vue'
import { AskAIStateMachine } from './askAIStateMachine'

const props = defineProps<{
  stateMachine: AskAIStateMachine
  view: EditorView
}>()

const currentComponent = computed(() => {
  switch (props.stateMachine.state.tag) {
    case 'idle':
      return AskAIStateIdle
    case 'comment':
      return AskAIStateComment
    case 'activate':
      return AskAIStateActivate
    case 'answering':
      return AskAIStateAnswering
    case 'completed':
      return AskAIStateCompleted
    default:
      return AskAIStateIdle
  }
})
</script>

<template>
  <component :is="currentComponent" :stateMachine="props.stateMachine" :view="props.view" />
</template>
