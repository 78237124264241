<script lang="ts" setup>
import { PDF_VIEWER_INVALID_FLAG_VALUE_FOR_INITIALIZED_CHECK } from '@/config'
import { PDF_VIEWER_TOOLBAR_HEIGHT_PIXEL } from '@/stores'
import {
  BaseButton,
  IconArrowDown,
  IconArrowLeft,
  IconArrowRight,
  IconBars,
  IconBase,
  IconCircleMinus,
  IconCirclePlus,
  IconFileArrowDown,
} from '@murfy-package/murds'
import Dropdown from 'primevue/dropdown'
import { useI18n } from 'vue-i18n'

withDefaults(
  defineProps<{
    isToolbarDisabled: boolean
    currentPageIndexNumber: number
    lastPageNumber: number
    zoomValue: string
  }>(),
  {
    isToolbarDisabled: () => false,
    currentPageIndexNumber: () => PDF_VIEWER_INVALID_FLAG_VALUE_FOR_INITIALIZED_CHECK,
    lastPageNumber: () => PDF_VIEWER_INVALID_FLAG_VALUE_FOR_INITIALIZED_CHECK,
    zoomValue: () => '',
  },
)

const emit = defineEmits<{
  movePrev: []
  moveNext: []
  download: []
  toggleNavigation: []
  // 페이지 인덱스 번호 변경
  'update:currentPageIndexNumber': [number]
  decreaseZoom: []
  increaseZoom: []
  selectZoom: [string]
}>()

const { t } = useI18n()
const zoomOptions = [
  { name: t('zoom.actual'), code: 'actual' },
  { name: t('zoom.fit-width'), code: 'fit-width' },
  { name: t('zoom.fit-height'), code: 'fit-height' },
  { name: t('zoom.50%'), code: '50p' },
  { name: t('zoom.75%'), code: '75p' },
  { name: t('zoom.100%'), code: '100p' },
  { name: t('zoom.200%'), code: '200p' },
  { name: t('zoom.300%'), code: '300p' },
  { name: t('zoom.500%'), code: '500p' },
  { name: t('zoom.1000%'), code: '1000p' },
]

const emitPageIndexNumberChanged = (e: Event) => {
  const value = Number((e.target as HTMLInputElement).value)
  emit('update:currentPageIndexNumber', value)
}
</script>
<template>
  <nav
    :style="{
      '--pdf-viewer-toolbar-height': `${PDF_VIEWER_TOOLBAR_HEIGHT_PIXEL}px`,
    }"
    class="bg-color-bg-global-primary border-color-border-primary flex h-[--pdf-viewer-toolbar-height] items-center justify-between border-b px-4 py-2 text-[1rem]"
  >
    <div class="flex items-center gap-1">
      <BaseButton
        severity="secondary"
        variant="text"
        :disabled="isToolbarDisabled"
        @click.prevent="emit('toggleNavigation')"
      >
        <template #icon>
          <IconBars />
        </template>
      </BaseButton>
      <BaseButton
        severity="secondary"
        variant="text"
        :disabled="isToolbarDisabled"
        @click.prevent="emit('movePrev')"
      >
        <template #icon>
          <IconArrowLeft />
        </template>
      </BaseButton>
      <BaseButton
        severity="secondary"
        variant="text"
        :disabled="isToolbarDisabled"
        @click.prevent="emit('moveNext')"
      >
        <template #icon>
          <IconArrowRight />
        </template>
      </BaseButton>
      <div class="flex w-min min-w-[3rem] max-w-[10rem] flex-row gap-2">
        <input
          type="number"
          class="body-sm text-color-text-primary w-8 justify-center text-center"
          :value="currentPageIndexNumber"
          :disabled="isToolbarDisabled"
          @change="emitPageIndexNumberChanged"
        />
        <span class="body-sm text-color-text-secondary">/</span>
        <input
          type="text"
          class="body-sm text-color-text-secondary w-8 justify-center text-center"
          :value="lastPageNumber"
          readonly
        />
      </div>
    </div>
    <div class="flex gap-1">
      <BaseButton
        severity="secondary"
        variant="text"
        :disabled="isToolbarDisabled"
        @click.prevent="emit('decreaseZoom')"
      >
        <template #icon>
          <IconCircleMinus />
        </template>
      </BaseButton>
      <BaseButton
        severity="secondary"
        variant="text"
        :disabled="isToolbarDisabled"
        @click.prevent="emit('increaseZoom')"
      >
        <template #icon>
          <IconCirclePlus />
        </template>
      </BaseButton>
      <Dropdown
        :modelValue="zoomValue"
        :options="zoomOptions"
        :pt="{
          root: 'flex items-center gap-1 outline outline-1 outline-color-border-primary hover:outline-color-border-primary rounded-lg px-2',
          item: 'body-sm text-color-text-primary px-4 py-2.5 hover:bg-color-bg-info-tertiary-hover cursor-pointer active:bg-color-bg-info-tertiary-pressed',
          panel:
            'max-h-[20rem] overflow-y-auto border border-color-border-primary rounded-lg py-2 bg-color-bg-global-primary',
          input: 'body-sm text-color-text-secondary w-20',
          trigger: 'flex items-center cursor-pointer',
        }"
        optionLabel="name"
        optionValue="code"
        editable
        unstyled
        @update:modelValue="emit('selectZoom', $event)"
      >
        <template #dropdownicon>
          <IconBase>
            <IconArrowDown />
          </IconBase>
        </template>
      </Dropdown>
    </div>
    <div class="flex gap-1">
      <BaseButton severity="secondary" variant="text" @click.prevent="emit('download')">
        <template #icon>
          <IconFileArrowDown />
        </template>
      </BaseButton>
    </div>
  </nav>
</template>
<style scoped>
button > img {
  width: 100%;
  height: 100%;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox  */
input[type='number'] {
  -moz-appearance: textfield;
}
</style>
<style module>
.buttonColorings {
  background-color: transparent;
  border: none;
}

button.buttonColorings:hover {
  cursor: pointer;
}

.buttonColorings:disabled {
  opacity: 0.5;
}

.buttonColorings:disabled:hover {
  cursor: not-allowed;
}

.buttonHeight {
  max-width: 5em;
  width: min-content;
  min-width: 1em;
  padding: 0;
  height: 24px;
  box-sizing: border-box;
  text-align: center;
}

.buttonSizing {
  padding: 0;
  width: 24px;
  height: 24px;
  box-sizing: border-box;
}
</style>
<i18n>
{
  "ko": {
    "zoom": {
      "actual": "실제 크기",
      "fit-width": "너비 맞춤",
      "fit-height": "높이 맞춤",
      "50%": "50%",
      "75%": "75%",
      "100%": "100%",
      "200%": "200%",
      "300%": "300%",
      "500%": "500%",
      "1000%": "1000%"
    }
  },
  "en": {
    "zoom": {
      "actual": "Actual Size",
      "fit-width": "Fit Width",
      "fit-height": "Fit Height",
      "50%": "50%",
      "75%": "75%",
      "100%": "100%",
      "200%": "200%",
      "300%": "300%",
      "500%": "500%",
      "1000%": "1000%"
    }
  }
}
</i18n>
