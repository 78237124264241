import { LanguageDescription } from '@codemirror/language'

export const languages = [
  LanguageDescription.of({
    name: 'latex',
    extensions: ['tex'],
    load: () => import('./latex').then((m) => m.latex()),
  }),
  LanguageDescription.of({
    name: 'bibtex',
    extensions: ['bib'],
    load: () => import('./bibtex').then((m) => m.bibtex()),
  }),
  LanguageDescription.of({
    name: 'markdown',
    extensions: ['md', 'markdown'],
    load: () => import('./markdown').then((m) => m.markdown()),
  }),
]

export const getLanguageByExtension = (extension: string | undefined): string | null => {
  if (!extension) return null
  for (const lang of languages) {
    if (lang.extensions.includes(extension)) {
      return lang.name
    }
  }
  return null
}
