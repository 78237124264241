<script setup lang="ts">
import type { PDFPageProxy } from 'pdfjs-dist'
import { computed, nextTick, onMounted, ref, watch } from 'vue'

import { IconBase, IconCircleExclamationF, IconLoading } from '../../atoms'
import { useRenderPDF } from './useRenderPDF'

export type PDFHighlightRect = {
  top: number
  left: number
  width: number
  height: number
}
const A4_WIDTH_IN_PIXEL = 612
const A4_HEIGHT_IN_PIXEL = 792
const props = withDefaults(
  defineProps<{
    pagePromise: Promise<PDFPageProxy>
    /**
     * 뷰어의 확대 비율. 실제 크기가 1입니다.
     */
    scale?: number
    defaultWidth?: number
    defaultHeight?: number
    highlightRects?: PDFHighlightRect[]
  }>(),
  {
    scale: 1,
    defaultWidth: A4_WIDTH_IN_PIXEL,
    defaultHeight: A4_HEIGHT_IN_PIXEL,
    highlightRects: () => [],
  },
)

const emit = defineEmits<{
  rendered: [page: PDFPageProxy]
  dblclick: [event: MouseEvent]
  highlightAnimationEnd: [event: AnimationEvent]
}>()

const sizeStyle = computed(() => ({
  width: `${props.defaultWidth * props.scale}px`,
  height: `${props.defaultHeight * props.scale}px`,
}))

const { canvas, error, isRendering, isError, render, textLayerContainer } = useRenderPDF(
  props.pagePromise,
)

const scaledHighlightRects = computed(() =>
  props.highlightRects.map((rect) => ({
    top: rect.top * props.scale,
    left: rect.left * props.scale,
    width: rect.width * props.scale,
    height: rect.height * props.scale,
  })),
)
const visibleHighlightRects = ref<PDFHighlightRect[]>([])
const renderAllElement = ref(true)
onMounted(async () => {
  renderAllElement.value = false
  await new Promise((resolve) => setTimeout(resolve, 100))
  renderAllElement.value = true
  watch(
    () => props.scale,
    async () => {
      if (!props.scale) {
        return
      }
      await new Promise((resolve) => setTimeout(resolve, 20))
      const renderedPage = await render(props.scale)
      if (renderedPage) {
        emit('rendered', renderedPage)
        visibleHighlightRects.value = scaledHighlightRects.value
      }
    },
    {
      immediate: true,
    },
  )
  watch(scaledHighlightRects, async (newV) => {
    if (visibleHighlightRects.value) {
      // 새로운 하이라이트의 애니메이션이 처음부터 시작되도록 하기 위해 기존 하이라이트를 확실하게 제거
      visibleHighlightRects.value = []
      await nextTick()
    }
    visibleHighlightRects.value = newV
  })
})

defineExpose({ isRendering, error })
</script>

<template>
  <div
    class="bg-color-bg-global-primary relative mx-auto shadow-xl"
    :style="sizeStyle"
    @dblclick="emit('dblclick', $event)"
  >
    <template v-if="renderAllElement">
      <div
        v-show="isRendering"
        class="bg-color-bg-global-primary text-color-text-secondary absolute z-30 flex h-full w-full items-center justify-center gap-1"
      >
        <IconBase :width="32" :height="32">
          <IconLoading />
        </IconBase>
      </div>
      <div
        v-show="isError"
        class="bg-color-bg-global-secondary absolute z-20 flex h-full w-full items-center justify-center gap-1"
      >
        <IconBase>
          <IconCircleExclamationF />
        </IconBase>
        {{ error }}
      </div>
      <canvas ref="canvas" :style="sizeStyle" />
      <div
        ref="textLayerContainer"
        :style="{
          ...sizeStyle,
          '--scale-factor': props.scale,
        }"
        class="absolute left-0 top-0 z-10 text-transparent *:absolute *:origin-top-left *:whitespace-pre selection:bg-[#0086cb]/40 selection:text-transparent"
      />
      <svg
        :style="{ position: 'absolute', top: '0', left: '0', pointerEvents: 'none', ...sizeStyle }"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          v-for="(rect, index) in visibleHighlightRects"
          :key="index"
          :x="rect.left"
          :y="rect.top"
          :width="rect.width"
          :height="rect.height"
          fill="rgba(0, 134, 203, 0.4)"
          class="blink-fadeout"
          @animationend="emit('highlightAnimationEnd', $event)"
        />
      </svg>
    </template>
  </div>
</template>

<style lang="scss" scoped>
:deep(br)::selection {
  background-color: transparent;
}

@keyframes blink {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.blink-fadeout {
  animation: blink 1s ease-in-out 2;
}
</style>
