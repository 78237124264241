<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M5.49825 3.43102C4.94901 3.43102 4.42227 3.64921 4.0339 4.03758C3.64553 4.42595 3.42734 4.95269 3.42734 5.50193V14.5056C3.42734 15.0548 3.64553 15.5815 4.0339 15.9699C4.42227 16.3583 4.94901 16.5765 5.49825 16.5765H14.5019C14.7738 16.5765 15.0431 16.5229 15.2944 16.4188C15.5456 16.3148 15.7739 16.1622 15.9662 15.9699C16.1585 15.7776 16.3111 15.5493 16.4152 15.2981C16.5192 15.0468 16.5728 14.7775 16.5728 14.5056V5.50193C16.5728 5.22997 16.5192 4.96068 16.4152 4.70943C16.3111 4.45818 16.1585 4.22988 15.9662 4.03758C15.7739 3.84527 15.5456 3.69273 15.2944 3.58866C15.0431 3.48459 14.7738 3.43102 14.5019 3.43102H5.49825ZM3.04395 3.04763C3.69487 2.39671 4.57771 2.03102 5.49825 2.03102H14.5019C14.9577 2.03102 15.409 2.1208 15.8301 2.29523C16.2513 2.46966 16.6339 2.72532 16.9562 3.04763C17.2785 3.36993 17.5342 3.75256 17.7086 4.17367C17.883 4.59478 17.9728 5.04612 17.9728 5.50193V14.5056C17.9728 14.9614 17.883 15.4127 17.7086 15.8338C17.5342 16.2549 17.2785 16.6376 16.9562 16.9599C16.6339 17.2822 16.2513 17.5378 15.8301 17.7123C15.409 17.8867 14.9577 17.9765 14.5019 17.9765H5.49825C4.57771 17.9765 3.69487 17.6108 3.04395 16.9599C2.39303 16.3089 2.02734 15.4261 2.02734 14.5056V5.50193C2.02734 4.58139 2.39303 3.69855 3.04395 3.04763Z"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M7.2728 9.79467C7.19455 9.79467 7.11849 9.80862 7.04954 9.83435C6.98062 9.86007 6.92247 9.89614 6.87675 9.93733L3.1957 13.2513C2.90838 13.5099 2.46578 13.4867 2.20711 13.1994C1.94845 12.9121 1.97168 12.4695 2.259 12.2108L5.93956 8.89729C5.93948 8.89737 5.93964 8.89722 5.93956 8.89729C6.12081 8.73402 6.33238 8.60767 6.56009 8.5227C6.78786 8.4377 7.02986 8.39467 7.2728 8.39467C7.51575 8.39467 7.75775 8.4377 7.98552 8.5227C8.21322 8.60767 8.42455 8.73381 8.6058 8.89708C8.60572 8.897 8.60588 8.89715 8.6058 8.89708L12.2866 12.2108C12.5739 12.4695 12.5972 12.9121 12.3385 13.1994C12.0798 13.4867 11.6372 13.5099 11.3499 13.2513L7.6691 9.93755C7.62338 9.89636 7.56498 9.86007 7.49607 9.83435C7.42712 9.80862 7.35106 9.79467 7.2728 9.79467Z"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M13.5605 10.7038C13.4701 10.7038 13.3826 10.7191 13.3036 10.7472C13.2245 10.7753 13.159 10.8143 13.1085 10.8576L11.3647 12.353C11.0712 12.6046 10.6293 12.5707 10.3776 12.2772C10.126 11.9838 10.1599 11.5418 10.4533 11.2902L12.1967 9.79525C12.3846 9.6341 12.6022 9.51066 12.8347 9.42804C13.0673 9.34539 13.3136 9.30376 13.5605 9.30376C13.8074 9.30376 14.0538 9.34539 14.2863 9.42804C14.5188 9.51066 14.7362 9.63389 14.9241 9.79504C14.924 9.79497 14.9241 9.79511 14.9241 9.79504L17.7283 12.1996C18.0218 12.4513 18.0557 12.8932 17.804 13.1867C17.5524 13.4802 17.1105 13.5141 16.817 13.2624L14.0127 10.8578C13.9622 10.8145 13.8965 10.7753 13.8175 10.7472C13.7385 10.7191 13.6509 10.7038 13.5605 10.7038Z"
  />
</template>
