<script setup lang="ts">
import { computedAsync } from '@vueuse/core'
import DOMPurify from 'dompurify'
import { marked } from 'marked'

type ProseSize = 'base' | 'sm' | 'lg' | 'xl' | '2xl'

type Props = {
  content: string
  size?: ProseSize
}

const proseClasses: Record<ProseSize, string> = {
  base: 'prose-base',
  sm: 'prose-sm',
  lg: 'prose-lg',
  xl: 'prose-xl',
  '2xl': 'prose-2xl',
}

const props = withDefaults(defineProps<Props>(), {
  size: 'base',
})

const htmlContent = computedAsync<string>(async () => {
  const rawHtml = await marked(props.content)
  // XSS 방지
  return DOMPurify.sanitize(rawHtml)
})
</script>

<template>
  <!-- eslint-disable-next-line vue/no-v-html -->
  <div :class="`prose flex flex-col ${proseClasses[size]}`" v-html="htmlContent" />
</template>
