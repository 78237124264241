<script setup>
import { useI18n } from 'vue-i18n'
import { TextOverflowWithTooltip } from '..'
const { t } = useI18n()
</script>

<script>
import { BaseButton } from '@murfy-package/ui'
import PrimeVueDialog from 'primevue/dialog'

/* eslint-disable vue/component-api-style */
export default {
  components: {
    PrimeVueDialog,
    BaseButton,
  },
  /* FIXME: props 타입 정의 필요 */
  /* eslint-disable-next-line vue/require-prop-types */
  props: ['controller'],
  emits: ['close', 'submit'],
  data() {
    return {
      target: null,
      isDisable: true,
    }
  },
  beforeMount() {
    this.controller.on('open', this.onOpen)
    this.controller.on('disable', this.onDisable)
  },
  beforeUnmount() {
    this.controller.off('open', this.onOpen)
    this.controller.off('disable', this.onDisable)
  },
  methods: {
    onOpen(args) {
      if (args) {
        this.target = args
        this.isDisable = false
      }
    },
    onDisable() {
      this.isDisable = true
    },
    onSubmit() {
      this.$emit('submit', this.target)
    },
  },
}
</script>

<template>
  <div>
    <PrimeVueDialog :visible="controller.visible" modal :closable="false">
      <template #header>
        <div class="inline-flex items-center justify-center">
          <span class="h2 gray-9">{{ t('header') }}</span>
        </div>
      </template>
      <template #default>
        <I18nT :class="$style.content" keypath="content" tag="div">
          <TextOverflowWithTooltip :class="[$style.label, 'p2', 'gray-7']" :text="target.name" />
        </I18nT>
      </template>
      <template #footer>
        <div class="flex grow items-center justify-center">
          <BaseButton
            class="w-full"
            severity="tertiary"
            :disabled="isDisable"
            @click="
              // eslint-disable-next-line vue/require-explicit-emits 리팩토링하면 해결되는 문제
              $emit('close')
            "
          >
            <div class="flex w-full flex-wrap justify-center">
              <span class="h4">{{ t('cancel') }}</span>
            </div>
          </BaseButton>
        </div>
        <div class="flex grow items-center justify-center">
          <BaseButton class="w-full" :disabled="isDisable" @click="onSubmit">
            <div class="flex w-full flex-wrap justify-center">
              <span class="h4">{{ t('restore') }}</span>
            </div>
          </BaseButton>
        </div>
      </template>
    </PrimeVueDialog>
  </div>
</template>
<style module>
.label:global(.p2) {
  display: inline-block;
  max-width: 380px;
  font-weight: bold;
}
.content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px;
}
.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
</style>

<i18n>
{
  "en":{
    "header": "Confirm To Restore",
    "content": "Are you sure you want to restore  {0}?",
    "cancel": "Cancel",
    "restore": "Restore",
  }
}
</i18n>
