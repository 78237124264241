<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M4.20177 4.20171C5.73957 2.66391 7.82527 1.79999 10 1.79999C12.1748 1.79999 14.2605 2.66391 15.7983 4.20171C17.3361 5.73951 18.2 7.82521 18.2 9.99999C18.2 11.0768 17.9879 12.1431 17.5759 13.138C17.1638 14.1329 16.5598 15.0368 15.7983 15.7983C15.0369 16.5597 14.1329 17.1637 13.1381 17.5758C12.1432 17.9879 11.0769 18.2 10 18.2C8.92321 18.2 7.85691 17.9879 6.86204 17.5758C5.86717 17.1637 4.96321 16.5597 4.20177 15.7983C3.44033 15.0368 2.83633 14.1329 2.42424 13.138C2.01215 12.1431 1.80005 11.0768 1.80005 9.99999C1.80005 7.82521 2.66397 5.73951 4.20177 4.20171ZM10 3.19999C8.19658 3.19999 6.46697 3.91641 5.19172 5.19166C3.91648 6.46691 3.20005 8.19652 3.20005 9.99999C3.20005 10.893 3.37594 11.7772 3.71767 12.6022C4.0594 13.4272 4.56028 14.1769 5.19172 14.8083C5.82316 15.4398 6.57279 15.9406 7.3978 16.2824C8.22282 16.6241 9.10706 16.8 10 16.8C10.893 16.8 11.7773 16.6241 12.6023 16.2824C13.4273 15.9406 14.1769 15.4398 14.8084 14.8083C15.4398 14.1769 15.9407 13.4272 16.2824 12.6022C16.6242 11.7772 16.8 10.893 16.8 9.99999C16.8 8.19652 16.0836 6.46691 14.8084 5.19166C13.5331 3.91641 11.8035 3.19999 10 3.19999Z"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M10.495 6.1717C10.7683 6.44507 10.7683 6.88828 10.495 7.16165L7.16165 10.495C6.88828 10.7683 6.44507 10.7683 6.1717 10.495C5.89833 10.2216 5.89833 9.7784 6.1717 9.50503L9.50503 6.1717C9.7784 5.89833 10.2216 5.89833 10.495 6.1717Z"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M10 5.96667C10.3866 5.96667 10.7 6.28008 10.7 6.66667V13.3333C10.7 13.7199 10.3866 14.0333 10 14.0333C9.61345 14.0333 9.30005 13.7199 9.30005 13.3333V6.66667C9.30005 6.28008 9.61345 5.96667 10 5.96667Z"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M9.50507 6.1717C9.77844 5.89833 10.2217 5.89833 10.495 6.1717L13.8284 9.50503C14.1017 9.7784 14.1017 10.2216 13.8284 10.495C13.555 10.7683 13.1118 10.7683 12.8384 10.495L9.50507 7.16165C9.23171 6.88828 9.23171 6.44507 9.50507 6.1717Z"
  />
</template>
