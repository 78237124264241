import { i18n } from '@/main'
import { APIError } from '@murfy-package/api-client'
import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useErrorStore = defineStore('error', () => {
  const error = ref<unknown>(null)
  /**
   * Set error.
   * ErrorBoundary component will catch this error and display it.
   *
   * @param e - Error
   * @param _vm - Vue instance
   * @param _info - Additional information
   */
  /* eslint-disable @typescript-eslint/no-explicit-any */
  const setError = (e: unknown, _vm?: any, _info?: string) => {
    error.value = e
  }

  const getErrorMessage = (e: unknown) => {
    if (e instanceof Error) {
      return e.message
    } else if (e instanceof APIError) {
      return i18n.t(e.errorCode)
    }
    return i18n.t('error.unknown')
  }
  return { error, setError, getErrorMessage }
})
