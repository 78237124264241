<script setup lang="ts">
// FIXME : 추후에 디자인 시스템 FileArea 컴포넌트로 대체될 예정입니다.
import { BaseButton } from '@murfy-package/murds'
import JSZip from 'jszip'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

type UploadType = 'file' | 'folder'

const { t } = useI18n()
const props = withDefaults(
  defineProps<{
    type?: UploadType
    /**
     * Used to select multiple files at once from file dialog.
     */
    multiple?: boolean
    /**
     * Pattern to restrict the allowed file types such as 'image/*'.
     * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#attr-accept
     */
    accept?: string
  }>(),
  {
    type: 'file',
    multiple: false,
    accept: undefined, // Accept all files
  },
)

const emit = defineEmits<{
  upload: [files: File[]]
}>()

const onUpload = (uploadFiles: File[]) => {
  emit('upload', uploadFiles)
}

const isDragOver = ref(false)

const handleDragOver = () => {
  isDragOver.value = true
}

const handleDragLeave = () => {
  isDragOver.value = false
}

const fileInputRef = ref<HTMLInputElement | null>(null)
const folderInputRef = ref<HTMLInputElement | null>(null)

// JSZip으로 생성한 Blob 데이터를 File 객체로 변환
const generateZipFile = async (zipInstance: JSZip, fileName: string): Promise<File> => {
  const zipBlob = await zipInstance.generateAsync({ type: 'blob' }) // Blob 생성
  return new File([zipBlob], `${fileName}.zip`, { type: 'application/zip' }) // File 객체 생성
}

const getFileFromEntry = (entry: FileSystemFileEntry): Promise<File> =>
  new Promise((resolve, reject) => {
    entry.file(
      (file) => resolve(file),
      (error) => reject(error),
    )
  })

// folderEntry를 탐색하며 폴더 내용을 압축
const processDirectory = async (rootDirectory: FileSystemDirectoryEntry): Promise<JSZip> => {
  const zipRoot = new JSZip()
  const stack: { directory: FileSystemDirectoryEntry; zip: JSZip | null }[] = [
    { directory: rootDirectory, zip: zipRoot.folder(rootDirectory.name) },
  ]

  while (stack.length > 0) {
    const item = stack.pop()
    if (!item) {
      continue
    }
    const { directory, zip } = item
    if (zip === null) {
      throw new Error('zip instance is null')
    }
    const reader = directory.createReader()
    const entries: FileSystemEntry[] = await new Promise((resolve, reject) => {
      reader.readEntries(resolve, reject)
    })
    for (const entry of entries) {
      if (entry.isDirectory) {
        const subFolder = zip.folder(entry.name)
        if (!subFolder) {
          throw new Error(`Failed to create folder: ${entry.name}`)
        }
        stack.push({ directory: entry as FileSystemDirectoryEntry, zip: subFolder })
      } else if (entry.isFile) {
        const file = await getFileFromEntry(entry as FileSystemFileEntry)
        zip.file(entry.name, file)
      }
    }
  }
  return zipRoot
}

// 드래그 앤 드롭 처리
const handleDrop = (() => {
  const handler: Record<UploadType, (event: DragEvent) => void> = {
    file: (event) => {
      const items = event.dataTransfer?.files
      if (!items?.length) {
        return
      }
      onUpload(Array.from(items))
    },
    folder: async (event) => {
      const items = event.dataTransfer?.items
      if (!items) {
        return
      }
      const [dataTransferItem] = event.dataTransfer.items
      const folderEntry = dataTransferItem.webkitGetAsEntry?.()
      if (folderEntry?.isDirectory) {
        const folder = folderEntry as FileSystemDirectoryEntry
        const zipInstance = await processDirectory(folder)
        const zipFile = await generateZipFile(zipInstance, folder.name)
        onUpload([zipFile])
      }
    },
  }
  return (event: DragEvent) => {
    handleDragLeave()
    handler[props.type](event)
  }
})()

// 폴더 선택 처리
const handleFolderInput = async (event: Event) => {
  const { files } = event.target as HTMLInputElement
  if (!files) {
    return
  }
  const [firstFile] = files
  const relativePath = firstFile.webkitRelativePath || ''
  const zip = Array.from(files).reduce<JSZip>(
    (zip, file) => zip.file(file.webkitRelativePath || file.name, file),
    new JSZip(),
  )
  const zipFile = await generateZipFile(zip, relativePath.split('/')[0] || 'unknown')
  onUpload([zipFile])
}

// 파일 선택 처리
const handleFileInput = (event: Event) => {
  const { files } = event.target as HTMLInputElement
  if (!files) {
    return
  }
  onUpload(Array.from(files))
}

const onClickBrowse = () => {
  props.type === 'file' ? fileInputRef.value?.click() : folderInputRef.value?.click()
}

const drag = t('drag', { target: props.type === 'file' ? 'files' : 'folder' })
</script>

<template>
  <div
    class="w-full rounded-lg border p-6"
    :class="[isDragOver ? 'border-blue-500 bg-blue-200' : 'border-gray-200 bg-gray-50']"
    @dragover.prevent="handleDragOver"
    @dragleave="handleDragLeave"
    @drop.prevent.stop="handleDrop"
  >
    <div class="flex flex-col items-center justify-center gap-4">
      <img src="/icons/upload.svg" :class="$style.uploadIcon" />
      <p class="h4 gray-7">{{ drag }}</p>
      <BaseButton size="lg" @click="onClickBrowse">
        {{ t('browse') }}
      </BaseButton>
    </div>

    <!-- 파일 선택 input -->
    <input
      ref="fileInputRef"
      type="file"
      :multiple="multiple"
      :accept="accept"
      class="hidden"
      @change="handleFileInput"
    />

    <!-- 폴더 선택 input -->
    <input
      ref="folderInputRef"
      type="file"
      webkitdirectory
      class="hidden"
      @change="handleFolderInput"
    />
  </div>
</template>

<style module>
.uploadIcon {
  width: 40px;
  height: 40px;
  filter: invert(80%) sepia(12%) saturate(191%) hue-rotate(166deg) brightness(90%) contrast(83%);
}
</style>

<i18n>
{
  "en": {
    "drag": "Drag {target} here or",
    "browse": "Browse"
  },
}
</i18n>
