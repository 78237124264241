import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

// FIXME: Layout 과 mode를 통합하는 것을 고려해보기.

/** editor의 레이아웃을 가리킵니다.
 *  - single: Editor 또는 Preview 하나 만 보이는 레이아웃입니다.
 *  - double: 2개의 열을 이용하여 왼쪽에 Editor, 오른쪽에 Preview가 보이는 레이아웃입니다.
 */
export enum ProjectUILayout {
  Single = 'single',
  Double = 'double',
}

/** single layout일 때, 현재 보이는 화면을 가리킵니다.
 *  - edit: Editor 화면을 의미합니다.
 *  - preview: Preview 화면을 의미합니다.
 *  - double layout일 때는 항상 edit를 가리킵니다.
 */
export enum ProjectUIMode {
  Edit = 'edit',
  Preview = 'preview',
}

export const useProjectUIStore = defineStore('project-ui', () => {
  const _layout = ref<ProjectUILayout>(ProjectUILayout.Double)
  const layout = computed({
    get: () => _layout.value,
    set: (newLayout: ProjectUILayout) => {
      if (newLayout === ProjectUILayout.Double) {
        mode.value = ProjectUIMode.Edit
      }
      _layout.value = newLayout
    },
  })
  const _mode = ref<ProjectUIMode>(ProjectUIMode.Edit)

  const mode = computed({
    get: () => _mode.value,
    set: (newMode: ProjectUIMode) => {
      if (newMode === ProjectUIMode.Edit) {
        compileLogVisible.value = false
      }
      _mode.value = newMode
    },
  })

  /**
   * Preview 화면에 로그를 보이게 할지 여부를 가리킵니다.
   * 이 값이 참이면 로그를, 거짓이면 pdf를 보여줍니다.
   */
  const compileLogVisible = ref(false)

  const $reset = () => {
    layout.value = ProjectUILayout.Double
    mode.value = ProjectUIMode.Edit
    compileLogVisible.value = false
  }

  return {
    layout,
    mode,
    compileLogVisible,
    $reset,
  }
})
