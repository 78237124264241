import { EditorView, ViewPlugin, ViewUpdate } from '@codemirror/view'

import { useMathPatterns } from '../../hooks/useMathPatterns'
import { mathModeCompartment } from '../mathModeCompartment'
import { Range } from './formulaTurbo'
import formulaTurboExtension from './index'

class FormulaTurboDynamicExtensions {
  constructor(private view: EditorView) {}

  // 뷰 업데이트 메서드
  update(update: ViewUpdate) {
    if (update.selectionSet && !update.docChanged) {
      this.buildDecorations(update.view)
    }
  }

  // 데코레이션을 빌드하는 메서드
  buildDecorations(view: EditorView) {
    // 선택 영역이 변경되고 문서가 변경되지 않은 경우
    const { ranges } = useMathPatterns(view.state.doc)
    const { selection, doc } = view.state
    const { from, to, head: cursorPos } = selection.main
    const selectedText = doc.slice(from, to).toString()

    if (selectedText.length > 0) return

    // 커서가 수학 환경 범위 내에 있는지 확인
    const relevantRanges = ranges.value.filter(
      (range: Range) => cursorPos > range.from && cursorPos < range.to - range.offset,
    )

    /**
     * requestAnimationFrame을 사용하여 업데이트 완료 후 dispatch 호출
     * - dynamicCompartment 를 등록 해 놓고 그 안에 Extension 을 추가하거나 제거
     * - formulaTurboExtension 자체를 추가 하거나 제거 할 수 없습니다.
     */
    requestAnimationFrame(() => {
      if (relevantRanges.length === 0) {
        this.view.dispatch({
          effects: mathModeCompartment.reconfigure([]),
        })
      } else {
        this.view.dispatch({
          effects: mathModeCompartment.reconfigure([formulaTurboExtension]), // 확장 추가
        })
      }
    })
  }
}

const formulaTurboDynamicExtensions = ViewPlugin.fromClass(FormulaTurboDynamicExtensions)

export default formulaTurboDynamicExtensions
