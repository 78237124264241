import { LRLanguage } from '@codemirror/language'
import { parser } from '@murfy-package/lezer-bibtex/src/parser'

import { bibtexEntryCompletions } from './completions/snippets'

export const BibTeXLanguage = LRLanguage.define({
  name: 'bibtex',
  parser,
  languageData: {
    autocomplete: bibtexEntryCompletions,
  },
})
