import { EditorState } from '@codemirror/state'
import { EditorView, ViewUpdate } from '@codemirror/view'

export enum EventKey {
  Change = 'change',
  Update = 'update',
  Focus = 'focus',
  Blur = 'blur',
  Ready = 'ready',
  ModelUpdate = 'update:modelValue',
  Scroll = 'scroll',
}

export const editorEvents = {
  // when content(doc) change only
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [EventKey.Change]: (value: string, viewUpdate: ViewUpdate) => true,
  // when codemirror state change
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [EventKey.Update]: (viewUpdate: ViewUpdate) => true,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [EventKey.Focus]: (viewUpdate: ViewUpdate) => true,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [EventKey.Blur]: (viewUpdate: ViewUpdate) => true,
  // when component mounted
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [EventKey.Ready]: (payload: {
    view: EditorView
    state: EditorState
    container: HTMLDivElement
  }) => true,
  [EventKey.Scroll]: () => true,
}

export const modelUpdateEvent = {
  [EventKey.ModelUpdate]: editorEvents[EventKey.Change],
}

export const events = {
  ...editorEvents,
  ...modelUpdateEvent,
}

export type Events = typeof events
