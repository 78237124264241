// Reference: https://github.com/overleaf/overleaf/blob/7eacbe898e6ccb8a3c546dd6f24805a58bbab222/services/web/frontend/js/ide/human-readable-logs/HumanReadableLogsRules.tsx

export enum LatexCompileRuleCode {
  DisplayMathShouldEndWith = 'displayMathShouldEndWith',
  ReferenceUndefined = 'referenceUndefined',
  CitationOnPageUndefinedOnInputLine = 'citationOnPageUndefinedOnInputLine',
  LabelMultiplyDefinedLabels = 'labelMultiplyDefinedLabels',
  UndefinedControlSequence = 'undefinedControlSequence',
  UndefinedEnvironment = 'undefinedEnvironment',
  UnknownGraphicsExtension = 'unknownGraphicsExtension',
  UnknownFloatOptionH = 'unknownFloatOptionH',
  UnknownFloatOptionQ = 'unknownFloatOptionQ',
  MathAllowedOnlyInMathMode = 'mathAllowedOnlyInMathMode',
  MismatchedEnvironment = 'mismatchedEnvironment',
  MismatchedBrackets = 'mismatchedBrackets',
  CanBeUsedOnlyInPreamble = 'canBeUsedOnlyInPreamble',
  DoubleSuperscript = 'doubleSuperscript',
  DoubleSubscript = 'doubleSubscript',
  SomethingsWrongPerhapsAMissingItem = 'somethingsWrongPerhapsAMissingItem',
  NoLineHereToEnd = 'noLineHereToEnd',
  VerbEndedByEndOfLine = 'verbEndedByEndOfLine',
  MismatchedEnvironment2 = 'mismatchedEnvironment2',
  MismatchedEnvironment3 = 'mismatchedEnvironment3',
  MismatchedEnvironment4 = 'mismatchedEnvironment4',
  CharacterInvalidAtThisPoint = 'characterInvalidAtThisPoint',
  CommandAllowedOnlyInMathMode = 'commandAllowedOnlyInMathMode',
  CommandUndefined = 'commandUndefined',
  LtInMidLine = 'ltInMidLine',
  ABoxWasSupposedToBeHere = 'aBoxWasSupposedToBeHere',
  AccentNotProvidedByFontFamily = 'accentNotProvidedByFontFamily',
  ArgumentOfHasAnExtra = 'argumentOfHasAnExtra',
  BadLineOrVectorArgument = 'badLineOrVectorArgument',
  BadMathEnvironmentDelimiter = 'badMathEnvironmentDelimiter',
  BeginEnvAllowedOnlyInParagraphMode = 'beginEnvAllowedOnlyInParagraphMode',
  BeginEnvOnInputLineXEndedByEndX = 'beginEnvOnInputLineXEndedByEndX',
  BeginSplitWontWorkHere = 'beginSplitWontWorkHere',
  CannotBeUsedInPreamble = 'cannotBeUsedInPreamble',
  CannotDefineUnicodeCharValueLt00A0 = 'cannotDefineUnicodeCharValueLt00A0',
  CannotDetermineSizeOfGraphicInFile = 'cannotDetermineSizeOfGraphicInFile',
  CannotIncludeGraphicsOfType = 'cannotIncludeGraphicsOfType',
  CaptionOutsideFloat = 'captionOutsideFloat',
  CommandAlreadyDefined = 'commandAlreadyDefined',
  CommandNotDefinedAsAMathAlphabet = 'commandNotDefinedAsAMathAlphabet',
  CorruptedNfsTables = 'corruptedNfsTables',
  CounterTooLarge = 'counterTooLarge',
  DimensionTooLarge = 'dimensionTooLarge',
  DisplayBreakCannotBeAppliedHere = 'displayBreakCannotBeAppliedHere',
  DivisionBy0 = 'divisionBy0',
  EncodingFileNotFound = 'encodingFileNotFound',
  EncodingSchemeUnknown = 'encodingSchemeUnknown',
  EnvironmentUndefined = 'environmentUndefined',
  ErroneousNestingOfEquationStructures = 'erroneousNestingOfEquationStructures',
  ExtraOnThisLine = 'extraOnThisLine',
  ExtraAlignmentTabHasBeenChangedToCr = 'extraAlignmentTabHasBeenChangedToCr',
  ExtraEndGroup = 'extraEndGroup',
  ExtraOr = 'extraOr',
  ExtraRight = 'extraRight',
  ExtraOrForgotten = 'extraOrForgotten',
  ExtraOrForgottenEndGroup = 'extraOrForgottenEndGroup',
  FileNotFound = 'fileNotFound',
  FileEndedWhileScanning = 'fileEndedWhileScanning',
  FloatLost = 'floatLost',
  FontFamilyUnknown = 'fontFamilyUnknown',
  FontNotFound = 'fontNotFound',
  FontNotLoadedNotEnoughRoomLeft = 'fontNotLoadedNotEnoughRoomLeft',
  FontShapeNotFound = 'fontShapeNotFound',
  ICantFindFile = 'iCantFindFile',
  ICantWriteOnFile = 'iCantWriteOnFile',
  IllegalCharacterInArrayArg = 'illegalCharacterInArrayArg',
  IllegalParameterNumberInDefinitionOf = 'illegalParameterNumberInDefinitionOf',
  IllegalUnitOfMeasurePtInserted = 'illegalUnitOfMeasurePtInserted',
  ImproperArgumentForMathAccent = 'improperArgumentForMathAccent',
  ImproperDiscretionaryList = 'improperDiscretionaryList',
  ImproperHyphenation = 'improperHyphenation',
  ImproperPrevDepth = 'improperPrevDepth',
  ImproperSpaceFactor = 'improperSpaceFactor',
  IncludeCannotBeNested = 'includeCannotBeNested',
  IncompatibleListCantBeUnboxed = 'incompatibleListCantBeUnboxed',
  IncompleteAllTextWasIgnoredAfterLine = 'incompleteAllTextWasIgnoredAfterLine',
  InfiniteGlueShrinkageFound = 'infiniteGlueShrinkageFound',
  Interruption = 'interruption',
  InvalidUseOfCommand = 'invalidUseOfCommand',
  KeyboardCharacterUsedIsUndefinedInInputEncoding = 'keyboardCharacterUsedIsUndefinedInInputEncoding',
  LanguageDefinitionFileNotFound = 'languageDefinitionFileNotFound',
  LimitControlsMustFollowAMathOperator = 'limitControlsMustFollowAMathOperator',
  LoadClassInPackageFile = 'loadClassInPackageFile',
  LonelyItemPerhapsAMissingListEnvironment = 'lonelyItemPerhapsAMissingListEnvironment',
  MathAlphabetIdentifierIsUndefinedInMathVersion = 'mathAlphabetIdentifierIsUndefinedInMathVersion',
  MathVersionIsNotDefined = 'mathVersionIsNotDefined',
  MisplacedAlignmentTabCharacter = 'misplacedAlignmentTabCharacter',
  MisplacedCr = 'misplacedCr',
  MisplacedCrCr = 'misplacedCrCr',
  MisplacedNoAlign = 'misplacedNoAlign',
  MisplacedOmit = 'misplacedOmit',
  MissingBeginDocument = 'missingBeginDocument',
  MissingControlSequenceInserted = 'missingControlSequenceInserted',
  MissingCrInserted = 'missingCrInserted',
  MissingDelimiterInserted = 'missingDelimiterInserted',
  MissingEndCsNameInserted = 'missingEndCsNameInserted',
  MissingNumberTreatedAsZero = 'missingNumberTreatedAsZero',
  MissingPArgInArrayArg = 'missingPArgInArrayArg',
  MissingExpInArrayArg = 'missingExpInArrayArg',
  MissingInsertedInAlignmentPreamble = 'missingInsertedInAlignmentPreamble',
  MissingInsertedForIfNum = 'missingInsertedForIfNum',
  MissingInsertedForIfDim = 'missingInsertedForIfDim',
  MissingInserted = 'missingInserted',
  MissingEndGroupInserted = 'missingEndGroupInserted',
  MissingRightInserted = 'missingRightInserted',
  MultipleLabelsLabelTiquetteWillBeLost = 'multipleLabelsLabelTiquetteWillBeLost',
  MultipleTag = 'multipleTag',
  NoCounterDefined = 'noCounterDefined',
  NoCyrillicEncodingDefinitionFilesWereFound = 'noCyrillicEncodingDefinitionFilesWereFound',
  NoDeclarationForShape = 'noDeclarationForShape',
  NoDriverSpecified = 'noDriverSpecified',
  NoRoomForANewRegister = 'noRoomForANewRegister',
  NoTitleGiven = 'noTitleGiven',
  NotALetter = 'notALetter',
  NotInOuterParMode = 'notInOuterParMode',
  NumberTooBig = 'numberTooBig',
  OkSeeTheTranscriptFile = 'okSeeTheTranscriptFile',
  OldFormShouldBeBegin = 'oldFormShouldBeBegin',
  OnlyOneIsAllowedPerTab = 'onlyOneIsAllowedPerTab',
  OptionClashForPackage = 'optionClashForPackage',
  PageHeightAlreadyTooLarge = 'pageHeightAlreadyTooLarge',
  ParagraphEndedBeforeCommandWasComplete = 'paragraphEndedBeforeCommandWasComplete',
  PleaseTypeACommandOrSayEnd = 'pleaseTypeACommandOrSayEnd',
  PushtabsAndPoptabsDontMatch = 'pushtabsAndPoptabsDontMatch',
  RequirepackageOrLoadclassInOptionsSection = 'requirepackageOrLoadclassInOptionsSection',
  RotationNotSupported = 'rotationNotSupported',
  Runaway = 'runaway',
  YouHaventSpecifiedALanguageOption = 'youHaventSpecifiedALanguageOption',
  CalculatingMathSizesForSize = 'calculatingMathSizesForSize',
  CheckingDefaultsFor = 'checkingDefaultsFor',
  CitationOnPageUndefined = 'citationOnPageUndefined',
  CommandInvalidInMathMode = 'commandInvalidInMathMode',
  DocumentClass = 'documentClass',
  EmptyThebibliographyEnvironment = 'emptyThebibliographyEnvironment',
  EncodingHasChangedToFor = 'encodingHasChangedToFor',
  EndOccurredInsideAGroupAtLevel = 'endOccurredInsideAGroupAtLevel',
  WhenConditionOnLineWasIncomplete = 'whenConditionOnLineWasIncomplete',
  ExternalFontLoadedForSize = 'externalFontLoadedForSize',
  FakingForFontFamilyInTs1Encoding = 'fakingForFontFamilyInTs1Encoding',
  FileAlreadyExistsOnTheSystemNotGeneratingItFromThisSource = 'fileAlreadyExistsOnTheSystemNotGeneratingItFromThisSource',
  File = 'file',
  FileFontDefinition = 'fileFontDefinition',
  FloatTooLargeForPageBy = 'floatTooLargeForPageBy',
  FontShapeInSizeNotAvailableExternalFontUsed = 'fontShapeInSizeNotAvailableExternalFontUsed',
  FontShapeInSizeNotAvailableSizeSubstituted = 'fontShapeInSizeNotAvailableSizeSubstituted',
  FontShapeInSizeNotAvailableShapeFormeTried = 'fontShapeInSizeNotAvailableShapeFormeTried',
  FontShapeFormeUndefinedUsingAutreFormeInstead = 'fontShapeFormeUndefinedUsingAutreFormeInstead',
  FontShapeFormeWillBeScaledToSizeTaille = 'fontShapeFormeWillBeScaledToSizeTaille',
  ForeignCommand = 'foreignCommand',
  FormFeedHasBeenConvertedToBlankLine = 'formFeedHasBeenConvertedToBlankLine',
  FloatSpecifierChanged = 'floatSpecifierChanged',
  IgnoringTextAfterEnd = 'ignoringTextAfterEnd',
  LabelMultiplyDefined = 'labelMultiplyDefined',
  LabelsMayHaveChangedRerunToGetCrossReferencesRight = 'labelsMayHaveChangedRerunToGetCrossReferencesRight',
  LooseHboxBadness = 'looseHboxBadness',
  LooseVboxBadness = 'looseVboxBadness',
  MakingAnActiveCharacter = 'makingAnActiveCharacter',
  MarginparOnPageMoved = 'marginparOnPageMoved',
  MissingCharacterThereIsNoInFont = 'missingCharacterThereIsNoInFont',
  NoAuthorGiven = 'noAuthorGiven',
  NoAuxiliaryOutputFiles = 'noAuxiliaryOutputFiles',
  NoCharactersDefinedByInputEncodingChangeTo = 'noCharactersDefinedByInputEncodingChangeTo',
  NoFile = 'noFile',
  NoHyphenationPatternsWereLoadedForTheLanguage = 'noHyphenationPatternsWereLoadedForTheLanguage',
  NoInputEncodingSpecifiedForLanguage = 'noInputEncodingSpecifiedForLanguage',
  NoPositionsInOptionalFloatSpecifier = 'noPositionsInOptionalFloatSpecifier',
  OldstyleDigitsUnavailableForFamily = 'oldstyleDigitsUnavailableForFamily',
  OptionalArgumentOfTwocolumnTooTallOnPage = 'optionalArgumentOfTwocolumnTooTallOnPage',
  OvalCircleOrLineSizeUnavailable = 'ovalCircleOrLineSizeUnavailable',
  OverfullHboxTooWideQuelquePart = 'overfullHboxTooWideQuelquePart',
  OverfullVboxPtTooWide = 'overfullVboxPtTooWide',
  OverwritingEncodingSchemeQuelqueChoSeDefaults = 'overwritingEncodingSchemeQuelqueChoSeDefaults',
  OverwritingInVersion = 'overwritingInVersion',
  Package = 'package',
  RedeclaringFontEncoding = 'redeclaringFontEncoding',
  RedeclaringMathAccent = 'redeclaringMathAccent',
  RedeclaringMathAlphabet = 'redeclaringMathAlphabet',
  RedeclaringMathSymbol = 'redeclaringMathSymbol',
  RedeclaringMathVersion = 'redeclaringMathVersion',
  RedeclaringSymbolFont = 'redeclaringSymbolFont',
  ReferenceOnPageUndefined = 'referenceOnPageUndefined',
  SizeSubstitutionsWithDifferencesUpToHaveOccurred = 'sizeSubstitutionsWithDifferencesUpToHaveOccurred',
  SomeFontShapesWereNotAvailableDefaultsSubstituted = 'someFontShapesWereNotAvailableDefaultsSubstituted',
  TabHasBeenConvertedToBlankSpace = 'tabHasBeenConvertedToBlankSpace',
  TextPageContainsOnlyFloats = 'textPageContainsOnlyFloats',
  ThereWereMultiplyDefinedLabels = 'thereWereMultiplyDefinedLabels',
  ThereWereUndefinedReferences = 'thereWereUndefinedReferences',
  TightHboxBadness = 'tightHboxBadness',
  TightVboxBadness = 'tightVboxBadness',
  TryLoadingFontInformationFor = 'tryLoadingFontInformationFor',
  UnableToRedefineMathAccent = 'unableToRedefineMathAccent',
  HboxBadnessDetectedAtLine = 'hboxBadnessDetectedAtLine',
  HboxBadnessHasOccurredWhileOutputIsActive = 'hboxBadnessHasOccurredWhileOutputIsActive',
  HboxBadnessInAlignmentAtLines = 'hboxBadnessInAlignmentAtLines',
  HboxBadnessInParagraphAtLines = 'hboxBadnessInParagraphAtLines',
  VboxBadnessDetectedAtLine = 'vboxBadnessDetectedAtLine',
  VboxBadnessHasOccurredWhileOutputIsActive = 'vboxBadnessHasOccurredWhileOutputIsActive',
  VboxBadnessInAlignmentAtLines = 'vboxBadnessInAlignmentAtLines',
  UnusedGlobalOptions = 'unusedGlobalOptions',
  WritingFile = 'writingFile',
  WritingTextBeforeEndAsLastLineOf = 'writingTextBeforeEndAsLastLineOf',
  YouHaveMoreThanOnceSelectedTheAttributeForLanguage = 'youHaveMoreThanOnceSelectedTheAttributeForLanguage',
  YouHaveRequestedPackageOrClassButThePackageOrClassProvides = 'youHaveRequestedPackageOrClassButThePackageOrClassProvides',
  YouHaveRequestedReleaseDateOfLatexButOnlyReleaseAncienneDateIsAvailable = 'youHaveRequestedReleaseDateOfLatexButOnlyReleaseAncienneDateIsAvailable',
  YouHaveRequestedOnLineVersionOfButOnlyVersionIsAvailable = 'youHaveRequestedOnLineVersionOfButOnlyVersionIsAvailable',
  HboxWarning = 'hboxWarning',
  UncaughtError = 'uncaughtError',
  UncaughtLatexWarning = 'uncaughtLatexWarning',
  UncaughtPackageWarning = 'uncaughtPackageWarning',
  UncaughtPdftexWarning = 'uncaughtPdftexWarning',
  NoPdfError = 'noPdfError',
}
