import type { CompletionContext, CompletionResult } from '@codemirror/autocomplete'

import { useMathPatterns } from '../../hooks/useMathPatterns'
import { autoCompleteLatexCommands } from '../autocomplete'
import { bibKeywords, bibliographyFiles, latexKeywords } from './latexKeywords'

const findCommandCompletion = (
  beforeCursor: string,
  cursor: number,
  command: string,
  keywords: typeof bibKeywords | typeof bibliographyFiles,
): CompletionResult | null => {
  const commandStartPos = beforeCursor.lastIndexOf(command)
  if (commandStartPos === -1) return null

  const openBracePos = commandStartPos + command.length
  const closeBracePos = beforeCursor.indexOf('}', openBracePos)

  if (cursor >= openBracePos && (closeBracePos === -1 || cursor <= closeBracePos)) {
    const currentText = beforeCursor.slice(openBracePos, cursor)
    const options = keywords.filter((option) => !currentText.includes(option.label))
    if (options.length === 0) return null // 일치하는 항목이 있으면 제안하지 않음
    return {
      from: openBracePos,
      to: cursor,
      options,
    }
  }

  return null
}

const latexCompletions = (context: CompletionContext): CompletionResult | null => {
  const { state } = context
  const cursor = state.selection.main.head

  // Math 모드에서는 AutoCompletion을 하지 않습니다
  const { rangeAtCursor } = useMathPatterns(state.doc, cursor)
  if (rangeAtCursor.value) return null

  const beforeCursor = state.doc.sliceString(0, cursor)

  // 특정 LaTeX 명령어 처리
  for (const { command, keywords } of autoCompleteLatexCommands) {
    const completion = findCommandCompletion(beforeCursor, cursor, command, keywords)
    if (completion) {
      return completion
    }
  }

  // 기본 단어 매칭, \ 또는 %로 시작하는 단어
  const word = context.matchBefore(/[\\%]\w*/)
  if (!word || (word.from === word.to && !context.explicit)) return null

  const options = latexKeywords.filter((option) => !word.text.includes(option.label))

  return options.length > 0
    ? {
        from: word.from,
        options,
      }
    : null // 일치하는 항목이 있으면 제안하지 않음
}

export default latexCompletions
