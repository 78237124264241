<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M7.85091 7.27767C7.71193 8.14984 7.55729 9.1202 7.35312 10.1411C7.15447 11.1343 7.00364 12.0807 6.8639 12.9575L6.85017 13.0437C6.70792 13.936 6.574 14.7686 6.41129 15.4737C6.25139 16.1666 6.04739 16.8152 5.7288 17.3015C5.38681 17.8234 4.87649 18.2038 4.16672 18.2038C3.53904 18.2038 2.93707 17.9544 2.49323 17.5106C2.04939 17.0668 1.80005 16.4648 1.80005 15.8371C1.80005 15.4505 2.11345 15.1371 2.50005 15.1371C2.88665 15.1371 3.20005 15.4505 3.20005 15.8371C3.20005 16.0935 3.30189 16.3394 3.48318 16.5206C3.66446 16.7019 3.91034 16.8038 4.16672 16.8038C4.29027 16.8038 4.40496 16.7674 4.55775 16.5342C4.73395 16.2653 4.89454 15.8201 5.04714 15.1589C5.19693 14.5098 5.32343 13.7278 5.46763 12.8233C5.47257 12.7922 5.47754 12.7611 5.48252 12.7298C5.6215 11.8577 5.77614 10.8873 5.98031 9.86649C6.17897 8.8732 6.3298 7.92677 6.46953 7.05C6.47412 7.02122 6.47869 6.99251 6.48326 6.96388C6.62551 6.07155 6.75943 5.23896 6.92214 4.53387C7.08204 3.84097 7.28604 3.19236 7.60463 2.70609C7.94662 2.1841 8.45694 1.80377 9.16671 1.80377C9.79439 1.80377 10.3964 2.05312 10.8402 2.49695C11.284 2.94079 11.5334 3.54276 11.5334 4.17044C11.5334 4.55704 11.22 4.87044 10.8334 4.87044C10.4468 4.87044 10.1334 4.55704 10.1334 4.17044C10.1334 3.91406 10.0315 3.66819 9.85025 3.4869C9.66896 3.30562 9.42309 3.20377 9.16671 3.20377C9.04316 3.20377 8.92847 3.24011 8.77567 3.47333C8.59948 3.74226 8.43889 4.1874 8.28629 4.84867C8.1365 5.49775 8.01 6.27974 7.8658 7.18429C7.86086 7.21529 7.8559 7.24642 7.85091 7.27767Z"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M12.1615 9.50886C11.8882 9.23549 11.4449 9.23549 11.1716 9.50886C10.8982 9.78223 10.8982 10.2254 11.1716 10.4988L13.1766 12.5038L11.1716 14.5089C10.8982 14.7822 10.8982 15.2254 11.1716 15.4988C11.4449 15.7722 11.8882 15.7722 12.1615 15.4988L14.1666 13.4938L16.1716 15.4988C16.4449 15.7722 16.8882 15.7722 17.1615 15.4988C17.4349 15.2254 17.4349 14.7822 17.1615 14.5089L15.1565 12.5038L17.1615 10.4988C17.4349 10.2254 17.4349 9.78223 17.1615 9.50886C16.8882 9.23549 16.4449 9.23549 16.1716 9.50886L14.1666 11.5139L12.1615 9.50886Z"
  />
</template>
