<script setup lang="ts">
import { BaseInputText, TextButton } from '@/components'
import { MAX_NAME_LENGTH } from '@/config'
import { useErrorStore, useUserStore } from '@/stores'
import { toastService } from '@/utils/toast'
import { BaseButton } from '@murfy-package/ui'
import PrimeVueDialog from 'primevue/dialog'
import { computed, ref, watch } from 'vue'
import { googleAuthCodeLogin } from 'vue3-google-login'
import { useI18n } from 'vue-i18n'

const userStore = useUserStore()
const { t } = useI18n()
const { setError } = useErrorStore()

const props = defineProps<{
  visible: boolean
}>()

const emit = defineEmits<{
  close: []
  'update:visible': [value: boolean]
}>()

const firstName = ref(userStore.me?.givenName || '')
const lastName = ref(userStore.me?.familyName || '')
const jobTitle = ref(userStore.me?.jobTitle || '')
const organization = ref(userStore.me?.organization || '')

watch(
  () => props.visible,
  (newVisible) => {
    if (newVisible) {
      firstName.value = userStore.me?.givenName || ''
      lastName.value = userStore.me?.familyName || ''
      jobTitle.value = userStore.me?.jobTitle || ''
      organization.value = userStore.me?.organization || ''
    }
  },
)

const inputItems = [
  {
    title: 'firstName.title',
    placeHolder: 'firstName.placeHolder',
    name: 'firstName',
    errorMessage: 'firstName.error',
    value: firstName,
    validate: (value: string) => !!value && value.length <= MAX_NAME_LENGTH,
  },
  {
    title: 'lastName.title',
    placeHolder: 'lastName.placeHolder',
    name: 'lastName',
    errorMessage: 'lastName.error',
    value: lastName,
    validate: (value: string) => (value ? value.length <= MAX_NAME_LENGTH : true),
  },
  {
    title: 'jobTitle.title',
    placeHolder: 'jobTitle.placeHolder',
    name: 'jobTitle',
    errorMessage: 'jobTitle.error',
    value: jobTitle,
    validate: (value: string) => (value ? value.length <= MAX_NAME_LENGTH : true),
  },
  {
    title: 'organization.title',
    placeHolder: 'organization.placeHolder',
    name: 'organization',
    errorMessage: 'organization.error',
    value: organization,
    validate: (value: string) => (value ? value.length <= MAX_NAME_LENGTH : true),
  },
]

const isInvalid = computed(() =>
  inputItems.some((item) => !item.validate(item.value.value?.trim())),
)

const isDirty = computed(() => {
  const isDirty0 = firstName.value !== userStore.me?.givenName
  const isDirty1 = lastName.value !== userStore.me?.familyName
  const isDirty2 = jobTitle.value !== userStore.me?.jobTitle
  const isDirty3 = organization.value !== userStore.me?.organization
  return isDirty0 || isDirty1 || isDirty2 || isDirty3
})

const submit = () => {
  if (isInvalid.value || !isDirty.value) {
    return
  }
  userStore
    .updateMe({
      givenName: firstName.value.trim(),
      familyName: lastName.value.trim(),
      jobTitle: jobTitle.value.trim(),
      organization: organization.value.trim(),
    })
    .then(() => {
      emit('close')
    })
    .catch((error) => {
      setError(error)
    })
}
const isDeleting = ref(false)
const confirmDeletionInput = ref('')
const confirmDeletionText = t('confirmDelete.confirmText')
const onClickDeleteAccount = () => {
  googleAuthCodeLogin()
    .then((res) => userStore.login(res.code))
    .then(() => {
      confirmDeletionInput.value = ''
      isDeleting.value = true
    })
    .catch((error) => {
      setError(error)
    })
}
const isDeletionProgressing = ref(false)
const onConfirmAccountDeletion = () => {
  isDeletionProgressing.value = true
  userStore
    .deleteMe()
    .then(() => {
      emit('close')
      toastService.error(t('confirmDelete.toast.summary'), t('confirmDelete.toast.detail'), 10000)
    })
    .catch((error) => {
      setError(error)
    })
    .finally(() => {
      isDeletionProgressing.value = false
    })
}
</script>

<template>
  <PrimeVueDialog
    :visible="visible"
    modal
    :draggable="false"
    :closable="!isDeleting"
    :pt="{
      root: 'w-[480px]',
      header: ['h2', $style.header],
      footer: $style.footer,
    }"
    @update:visible="(visible) => emit('update:visible', visible)"
  >
    <template #header>
      {{ isDeleting ? t('confirmDelete.header') : t('header') }}
    </template>
    <template v-if="isDeleting" #default>
      <div class="pb-4">
        <p class="p2 text-gray-7">{{ t('confirmDelete.content') }}</p>
        <p class="p2 text-gray-7 pt-2">{{ t('confirmDelete.content2') }}</p>
      </div>
      <div class="pt-4">
        <BaseInputText
          v-model="confirmDeletionInput"
          :disabled="isDeletionProgressing"
          :validate="(value) => value === confirmDeletionText"
          :label="t('confirmDelete.inputLabel', [t('confirmDelete.confirmText')])"
          labelClass="h5 gray-9"
          :errorMessage="t('confirmDelete.inputError', [t('confirmDelete.confirmText')])"
        />
      </div>
    </template>
    <template v-else #default>
      <div class="flex flex-col gap-6">
        <BaseInputText
          v-for="item in inputItems"
          :key="item.name"
          v-model="item.value.value"
          :label="t(item.title)"
          :placeHolder="t(item.placeHolder)"
          :errorMessage="t(item.errorMessage)"
          :validate="item.validate"
        />
        <TextButton
          class="w-fit"
          :label="t('deleteAccount')"
          scale="p1"
          severity="error"
          @click="onClickDeleteAccount"
        />
      </div>
    </template>
    <template v-if="isDeleting" #footer>
      <BaseButton
        class="w-full"
        severity="secondary"
        :disabled="isDeletionProgressing"
        :label="t('confirmDelete.cancelButton')"
        @click="isDeleting = false"
      />
      <BaseButton
        class="w-full"
        severity="secondary"
        actionType="destructive"
        :loading="isDeletionProgressing"
        :disabled="confirmDeletionInput !== confirmDeletionText"
        :label="t('confirmDelete.deleteButton')"
        @click="onConfirmAccountDeletion"
      />
    </template>
    <template v-else #footer>
      <BaseButton
        :class="$style.button"
        :disabled="isInvalid || !isDirty"
        :label="t('save')"
        @click="submit"
      />
    </template>
  </PrimeVueDialog>
</template>

<style module>
.header {
  color: var(--gray-9);
}
.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.button {
  width: 100%;
}
.button:disabled {
  cursor: not-allowed;
}
</style>

<i18n>
{
  "en": {
    "header": "Edit Profile",
    "save": "Save Changes",
    "firstName": {
      "title": "First Name",
      "placeHolder": "",
      "error": "Looks like your first name is too short/long. It should be 1 to 50 alphabet. Also, it should not contain any special characters or numbers."
    },
    "lastName": {
      "title": "Last Name",
      "placeHolder": "",
      "error": "Looks like your last name is too short/long. It should be 1 to 50 alphabet or Korean. Also, it should not contain any special characters or numbers."
    },
    "jobTitle": {
      "title": "Job Title",
      "placeHolder": "",
      "error": "Looks like your job title is too short/long. It should be 1 to 50 alphabet or Korean. Also, it should not contain any special characters or numbers."
    },
    "organization": {
      "title": "Organization",
      "placeHolder": "",
      "error":
        "Looks like your organization's name is too short/long. It should be 1 to 50 alphabet or Korean. Also, it should not contain any special characters or numbers."
    },
    "deleteAccount": "Delete This Account >",
    "confirmDelete": {
      "header": "Confirm Account Deletion",
      "content": "Are you sure you want to delete your account? This action is permanent and will remove all your data.",
      "content2": "To confirm the deletion of your account and all associated data, please type “Delete this account” in the box below.",
      "inputLabel": "Type \"{0}\" to confirm",
      "inputError": "Type \"{0}\" correctly.",
      "confirmText": "Delete this account",
      "cancelButton": "Cancel",
      "deleteButton": "Delete",
      "toast": {
        "summary": "We're sorry to see you go",
        "detail": "Your account has been successfully deleted. All your data has been permanently removed from our system."
      }
    }
  }
}
</i18n>
