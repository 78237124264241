<script lang="ts" setup>
import { useProjectCompileStore, useProjectStore, useProjectUIStore } from '@/stores'
import { LoadingCover } from '@murfy-package/murds'
import { storeToRefs } from 'pinia'

import ProjectCompileLogAccordion from './ProjectCompileLogAccordion.vue'
import ProjectCompileResultViewer from './ProjectCompileResultViewer.vue'

const { compileLogVisible } = storeToRefs(useProjectUIStore())
const { project } = storeToRefs(useProjectStore())
const { pdfPreviewSrc } = storeToRefs(useProjectCompileStore())
</script>
<template>
  <div class="bg-gray-white-n-black relative m-0 flex p-0">
    <LoadingCover
      v-if="!compileLogVisible && !pdfPreviewSrc"
      class="absolute z-[51] h-full w-full"
      animation
    />
    <ProjectCompileLogAccordion
      v-show="compileLogVisible"
      class="bg-gray-white-n-black absolute left-0 top-0 z-50 h-full w-full overflow-y-auto overflow-x-hidden pt-4"
    />
    <ProjectCompileResultViewer
      v-if="pdfPreviewSrc"
      class="h-full w-full grow"
      :title="`${project?.name || 'download'}.pdf`"
      :src="pdfPreviewSrc"
    />
  </div>
</template>
<style module>
@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,100..800;1,100..800&display=swap');

.previewLog {
  font-family: 'JetBrains Mono', monospace;
  font-size: 12px;
  width: 100%;
  height: 100%;
  padding: 8px;
  background-color: var(--gray-9);
  opacity: 0.6;
  color: var(--gray-1);
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 30;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: 0;
}
</style>
<i18n>
{
  "en": {
    "renderingPDF": "Rendering PDF...",
    "renderFailed": "Failed to render PDF",
    "showLogs": "Show Logs",
    "close": "Close"
  }
}
</i18n>
