import { APIClient } from '../client'
import { SyncCodeToPdfResponse, SyncPdfToCodeResponse } from '../schema/response'

export class SyncAPI {
  private client: APIClient
  constructor(client: APIClient) {
    this.client = client
  }

  async syncCodeToPdf(
    projectId: string,
    texPath: string,
    row: number,
    col: number,
  ): Promise<SyncCodeToPdfResponse[]> {
    const response = await this.client.get<SyncCodeToPdfResponse[]>(
      `/projects/${projectId}/sync/code-to-pdf?tex_path=${texPath}&row=${row}&col=${col}`,
    )
    return response.data
  }

  async syncPdfToCode(
    projectId: string,
    page: number,
    h: number,
    v: number,
  ): Promise<SyncPdfToCodeResponse[]> {
    const response = await this.client.get<SyncPdfToCodeResponse[]>(
      `/projects/${projectId}/sync/pdf-to-code?page=${page}&h=${h}&v=${v}`,
    )
    return response.data
  }
}
