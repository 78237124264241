<script setup lang="ts">
import type { EditorView } from '@codemirror/view'
import { BaseButton, IconCommentLine, IconSparkles } from '@murfy-package/murds'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { Actions, AskAIStateMachine } from './askAIStateMachine'

const { t } = useI18n()

const props = defineProps<{
  view: EditorView
  stateMachine: AskAIStateMachine
}>()

const isRangeSelected = computed(() =>
  props.view.state.selection.ranges.some((range) => !range.empty),
)
</script>

<template>
  <div
    class="bg-color-bg-global-primary border-color-border-primary pointer-events-auto flex w-full flex-row gap-3 rounded-lg border p-1"
  >
    <BaseButton
      severity="secondary"
      variant="text"
      @click="props.stateMachine.send(Actions.AskAI())"
    >
      <template #icon>
        <IconSparkles />
      </template>
      {{ t('askAI') }}
    </BaseButton>
    <BaseButton
      v-if="isRangeSelected"
      severity="secondary"
      variant="text"
      @click="props.stateMachine.send(Actions.AddComment())"
    >
      <template #icon>
        <IconCommentLine />
      </template>
      {{ t('comments') }}
    </BaseButton>
  </div>
</template>

<i18n>
{
  "en": {
    "askAI": "AI Command",
    "comments": "Comments"
  }
}
</i18n>
