import { Extension } from '@codemirror/state'
import { EditorView, keymap } from '@codemirror/view'

// LaTeX 명령어를 토글하는 유틸리티 함수
const toggleLatexCommand = (
  editorView: EditorView,
  commandStart: string,
  commandEnd: string,
): boolean => {
  const { from, to, empty } = editorView.state.selection.main
  const selectedText = editorView.state.doc.sliceString(from, to)

  const startRegex = new RegExp(`^\\${commandStart}`)
  const endRegex = new RegExp(`${commandEnd}$`)

  if (empty) {
    // 텍스트가 선택되지 않은 경우
    const line = editorView.state.doc.lineAt(from)
    const lineText = line.text
    const offset = from - line.from

    const beforeCursor = lineText.slice(0, offset)
    const afterCursor = lineText.slice(offset)

    const startIdx = beforeCursor.lastIndexOf(commandStart)
    const endIdx = afterCursor.indexOf(commandEnd)

    if (startIdx !== -1 && endIdx !== -1) {
      // 커서가 LaTeX 명령어 내부에 있는 경우, 명령어는 제거하고 텍스트는 유지
      const start = line.from + startIdx
      const end = from + endIdx + commandEnd.length
      const innerText = editorView.state.doc.sliceString(
        start + commandStart.length,
        end - commandEnd.length,
      )
      editorView.dispatch({
        changes: { from: start, to: end, insert: innerText },
        selection: { anchor: start, head: start + innerText.length }, // 텍스트를 선택 상태로 유지
      })
      return true
    } else {
      // LaTeX 명령어를 삽입하고 커서를 중괄호 사이에 배치
      const changes = { from, to, insert: `${commandStart}${commandEnd}` }
      const cursorPos = from + commandStart.length
      editorView.dispatch({
        changes,
        selection: { anchor: cursorPos, head: cursorPos },
      })
      return true
    }
  } else if (startRegex.test(selectedText) && endRegex.test(selectedText)) {
    // 선택된 텍스트가 이미 감싸져 있는 경우, 이를 해제
    const newText = selectedText.replace(startRegex, '').replace(endRegex, '')
    editorView.dispatch({
      changes: { from, to, insert: newText },
      selection: { anchor: from, head: from + newText.length },
    })
    return true
  } else {
    // 선택된 텍스트가 감싸져 있지 않은 경우, 이를 감싸기
    const wrappedText = `${commandStart}${selectedText}${commandEnd}`
    editorView.dispatch({
      changes: { from, to, insert: wrappedText },
      selection: { anchor: from, head: from + wrappedText.length },
    })
    return true
  }
}

// Ctrl+b: 굵게
const boldKeymap = keymap.of([
  {
    key: 'Mod-b',
    run: (editorView: EditorView) => toggleLatexCommand(editorView, '\\textbf{', '}'),
  },
])

// Ctrl+I: 기울이기
const italicKeymap = keymap.of([
  {
    key: 'Mod-i',
    run: (editorView: EditorView) => toggleLatexCommand(editorView, '\\textit{', '}'),
  },
])

// Ctrl+u: 밑줄
const underlineKeymap = keymap.of([
  {
    key: 'Mod-u',
    run: (editorView: EditorView) => toggleLatexCommand(editorView, '\\underline{', '}'),
  },
])

// Ctrl+e: 강조 (\em 과 \emph 동일)
const emphasizeKeymap = keymap.of([
  { key: 'Mod-e', run: (editorView: EditorView) => toggleLatexCommand(editorView, '\\emph{', '}') },
])

// 모든 LaTeX 관련 키맵 Extension 결합
const latexKeymap: Extension = [boldKeymap, italicKeymap, underlineKeymap, emphasizeKeymap]

export default latexKeymap
