import { Extension } from '@codemirror/state'

import formulaTurboFracParSnippet from './formulaTurboFracParSnippet'
import formulaTurboFracSnippet from './formulaTurboFracSnippet'
// import formulaTurboInlinePreview from './formulaTurboInlinePreview'
// import formulaTurboPreview from './formulaTurboPreview'
import formulaTurboSnippet from './formulaTurboSnippet'
import formulaTurboTabNavigator from './formulaTurboTabNavigator'
import formulaTurboVisualSnippetAndKeymap from './formulaTurboVisualSnippet'

/**
 * Extension 배열 0번째부터 순서대로 실행
 *  - Extension 에 안에 keymap 은 Extension 배열 순서와 상관 없이 제일 먼저 실행 됩니다.
 */
const formulaTurboExtension: Extension = [
  // formulaTurboPreview,
  formulaTurboFracParSnippet,
  formulaTurboSnippet,
  formulaTurboVisualSnippetAndKeymap,
  // formulaTurboInlinePreview,
  formulaTurboTabNavigator,
  formulaTurboFracSnippet,
]

export default formulaTurboExtension
