<script setup lang="ts">
import type { MenuItem } from '@murfy-package/murds'
import { DropdownMenu, IconBase, IconGlobe } from '@murfy-package/murds'
import { useI18n } from 'vue-i18n'

const { locale } = useI18n()
const languages: MenuItem<string>[] = [
  {
    id: 'English',
    label: 'English',
    value: 'en',
    onClick: () => {
      locale.value = 'en'
    },
  },
  {
    id: 'Korean',
    label: '한국어',
    value: 'ko',
    onClick: () => {
      locale.value = 'ko'
    },
  },
]
</script>

<template>
  <DropdownMenu :items="languages">
    <template #trigger>
      <div class="flex items-center p-2">
        <IconBase width="24" height="24">
          <IconGlobe />
        </IconBase>
      </div>
    </template>
    <template #item="{ item }">
      <span :class="{ 'text-color-text-brand': locale === item.value }">{{ item.label }}</span>
    </template>
  </DropdownMenu>
</template>
