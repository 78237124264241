<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M2.73731 7.74103C3.33742 7.14091 4.15136 6.80377 5.00005 6.80377C5.84874 6.80377 6.66267 7.14091 7.26279 7.74103C7.86291 8.34115 8.20005 9.15508 8.20005 10.0038C8.20005 10.8525 7.86291 11.6664 7.26279 12.2665C6.66267 12.8666 5.84874 13.2038 5.00005 13.2038C4.15136 13.2038 3.33742 12.8666 2.73731 12.2665C2.13719 11.6664 1.80005 10.8525 1.80005 10.0038C1.80005 9.15508 2.13719 8.34115 2.73731 7.74103ZM5.00005 8.20377C4.52266 8.20377 4.06482 8.39341 3.72726 8.73098C3.38969 9.06854 3.20005 9.52638 3.20005 10.0038C3.20005 10.4812 3.38969 10.939 3.72726 11.2766C4.06482 11.6141 4.52266 11.8038 5.00005 11.8038C5.47744 11.8038 5.93528 11.6141 6.27284 11.2766C6.61041 10.939 6.80005 10.4812 6.80005 10.0038C6.80005 9.52638 6.61041 9.06854 6.27284 8.73098C5.93528 8.39341 5.47744 8.20377 5.00005 8.20377Z"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M12.7373 2.74103C13.3374 2.14091 14.1514 1.80377 15 1.80377C15.8487 1.80377 16.6627 2.14091 17.2628 2.74103C17.8629 3.34115 18.2 4.15508 18.2 5.00377C18.2 5.85246 17.8629 6.6664 17.2628 7.26651C16.6627 7.86663 15.8487 8.20377 15 8.20377C14.1514 8.20377 13.3374 7.86663 12.7373 7.26651C12.1372 6.6664 11.8 5.85246 11.8 5.00377C11.8 4.15508 12.1372 3.34115 12.7373 2.74103ZM15 3.20377C14.5227 3.20377 14.0648 3.39341 13.7273 3.73098C13.3897 4.06855 13.2 4.52638 13.2 5.00377C13.2 5.48116 13.3897 5.939 13.7273 6.27656C14.0648 6.61413 14.5227 6.80377 15 6.80377C15.4774 6.80377 15.9353 6.61413 16.2728 6.27656C16.6104 5.939 16.8 5.48116 16.8 5.00377C16.8 4.52638 16.6104 4.06855 16.2728 3.73098C15.9353 3.39341 15.4774 3.20377 15 3.20377Z"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M12.7373 12.741C13.3374 12.1409 14.1514 11.8038 15 11.8038C15.8487 11.8038 16.6627 12.1409 17.2628 12.741C17.8629 13.3411 18.2 14.1551 18.2 15.0038C18.2 15.8525 17.8629 16.6664 17.2628 17.2665C16.6627 17.8666 15.8487 18.2038 15 18.2038C14.1514 18.2038 13.3374 17.8666 12.7373 17.2665C12.1372 16.6664 11.8 15.8525 11.8 15.0038C11.8 14.1551 12.1372 13.3411 12.7373 12.741ZM15 13.2038C14.5227 13.2038 14.0648 13.3934 13.7273 13.731C13.3897 14.0685 13.2 14.5264 13.2 15.0038C13.2 15.4812 13.3897 15.939 13.7273 16.2766C14.0648 16.6141 14.5227 16.8038 15 16.8038C15.4774 16.8038 15.9353 16.6141 16.2728 16.2766C16.6104 15.939 16.8 15.4812 16.8 15.0038C16.8 14.5264 16.6104 14.0685 16.2728 13.731C15.9353 13.3934 15.4774 13.2038 15 13.2038Z"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M13.3722 5.76654C13.5493 6.11022 13.4142 6.53235 13.0705 6.70939L7.57052 9.54272C7.22684 9.71977 6.80471 9.58469 6.62767 9.24101C6.45062 8.89734 6.5857 8.47521 6.92938 8.29816L12.4294 5.46483C12.7731 5.28778 13.1952 5.42286 13.3722 5.76654Z"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M6.62767 10.7665C6.80471 10.4229 7.22684 10.2878 7.57052 10.4648L13.0705 13.2982C13.4142 13.4752 13.5493 13.8973 13.3722 14.241C13.1952 14.5847 12.7731 14.7198 12.4294 14.5427L6.92938 11.7094C6.5857 11.5323 6.45062 11.1102 6.62767 10.7665Z"
  />
</template>
