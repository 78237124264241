<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M4.99997 4.03708C4.74359 4.03708 4.49772 4.13893 4.31643 4.32021C4.13515 4.5015 4.0333 4.74738 4.0333 5.00375V15.0038C4.0333 15.2601 4.13515 15.506 4.31643 15.6873C4.49772 15.8686 4.74359 15.9704 4.99997 15.9704H15C15.2563 15.9704 15.5022 15.8686 15.6835 15.6873C15.8648 15.506 15.9666 15.2601 15.9666 15.0038V5.00375C15.9666 4.74738 15.8648 4.5015 15.6835 4.32021C15.5022 4.13893 15.2563 4.03708 15 4.03708H4.99997ZM3.32648 3.33027C3.77032 2.88643 4.37229 2.63708 4.99997 2.63708H15C15.6276 2.63708 16.2296 2.88643 16.6735 3.33027C17.1173 3.7741 17.3666 4.37607 17.3666 5.00375V15.0038C17.3666 15.6314 17.1173 16.2334 16.6735 16.6772C16.2296 17.1211 15.6276 17.3704 15 17.3704H4.99997C4.37229 17.3704 3.77032 17.1211 3.32648 16.6772C2.88265 16.2334 2.6333 15.6314 2.6333 15.0038V5.00375C2.6333 4.37607 2.88265 3.7741 3.32648 3.33027Z"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M10 2.63708C10.3866 2.63708 10.7 2.95049 10.7 3.33708V16.6704C10.7 17.057 10.3866 17.3704 10 17.3704C9.61345 17.3704 9.30005 17.057 9.30005 16.6704V3.33708C9.30005 2.95049 9.61345 2.63708 10 2.63708Z"
  />
</template>
