import { APIClient } from '../client'
import type { Workspace } from '../schema/response'

/**
 * Workspace related API methods.
 */
export class WorkspaceAPI {
  private client: APIClient
  constructor(client: APIClient) {
    this.client = client
  }

  /**
   * Get the list of workspaces.
   *
   * @returns
   */
  async getList(): Promise<Workspace[]> {
    const response = await this.client.get<{ workspaceList: Workspace[] }>('/workspaces')
    return response.data.workspaceList || []
  }
}
