// FIXME: common package 로 이동 해야 함
// 정규 표현식에서 특수 문자를 이스케이프 처리하는 함수
export const escapeRegExp = (string: string): string =>
  string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $&는 일치하는 전체 문자열을 의미

// 교체 문자열을 $0, $1, $2 및 ${VISUAL} 등으로 분할하는 헬퍼 함수
export const splitReplacement = (string: string): string[] =>
  string.split(/\$\d|\${VISUAL}/).filter(Boolean)

// 주석 제거 함수
export const removeLatexComments = (string: string): string =>
  string.replace(/(?<!\\)%.*$/gm, '').trim()

/**
 * 주석을 제거하고 제거한 글자만큼 공백을 채우는 함수
 *  - 글자를 제거하면 커서의 위치가 달라지기 때문에 공백을 채워줌
 */
export const replaceCommentsToBlank = (text: string): string =>
  text.replace(/(?<!\\)%.*/g, (match) => ' '.repeat(match.length))
