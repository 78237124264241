import { useUserStore } from '@/stores'
import { DashboardView, EditorView, InviteView, LoginView, NotFoundView, SignupView } from '@/views'
import { createRouter, createWebHistory } from 'vue-router'

declare module 'vue-router' {
  interface RouteMeta {
    requiresAuth: boolean
    layout: 'default' | 'dashboard' | 'editor'
  }
}

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      redirect: (_to) => ({ name: 'dashboard' }),
    },
    {
      path: '/login',
      name: 'login',
      component: LoginView,
      meta: { layout: 'default', requiresAuth: false },
    },
    {
      path: '/signup',
      name: 'signup',
      component: SignupView,
      meta: { layout: 'default', requiresAuth: false },
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: DashboardView,
      meta: { layout: 'dashboard', requiresAuth: true },
    },
    {
      path: '/editor/:id',
      name: 'editor',
      component: EditorView,
      meta: { layout: 'editor', requiresAuth: true },
    },
    {
      path: '/invite/:token',
      name: 'invite',
      component: InviteView,
      meta: { layout: 'default', requiresAuth: true },
      props: true,
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'not-found',
      component: NotFoundView,
      meta: { layout: 'default', requiresAuth: false },
    },
  ],
})

router.beforeEach((to, _from, next) => {
  if (to.meta.requiresAuth) {
    const userStore = useUserStore()
    const me = userStore.me
    if (me && !me.signupRequired) {
      next()
    } else if (me && me.signupRequired) {
      next({ name: 'signup', query: to.name !== 'dashboard' ? { redirect: to.fullPath } : {} })
    } else {
      next({ name: 'login', query: to.name !== 'dashboard' ? { redirect: to.fullPath } : {} })
    }
  } else {
    next()
  }
})

export default router
